import { ToastOptions } from 'react-toastify'
import { UploadFile } from '../generated/graphql'

export const APP_NAME = 'LUCANIA'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: ToastOptions = {
  position: 'bottom-left',
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export const ERROR_TOAST: ToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  id: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const TOUR_STEPS = [
  {
    title: 'Welcome to Lucania',
    content: 'We have a mobile application download available for you to complete your programmes.',
    placement: 'center' as 'center',
    target: 'body'
  },
  {
    target: '.miraMax',
    title: 'Your programmes',
    content: 'Access your programmes directly from your dashboard',
    spotlightPadding: 0,
    placement: 'right' as 'right'
  },
  {
    target: '.notepadTour',
    title: 'Notepad',
    content: 'Make notes any time you like and view them all in ‘My works’ in the navigation bar.',
    spotlightPadding: 5,
    placement: 'left' as 'left'
  },
  {
    target: '.readingMaterial',
    title: 'Reading Material',
    content: 'Access reading at any time from your dashboard.',
    spotlightPadding: 0,
    placement: 'left' as 'left'
  },
  {
    target: '.calendar',
    title: 'Scheduled Events',
    content: 'View your scheduled events in your calendar view',
    spotlightPadding: 0,
    placement: 'left' as 'left'
  },
  {
    target: '.myWork',
    title: 'My Work',
    content: 'Keep your notes, documents and reports in one place.',
    spotlightPadding: 0,
    placement: 'right' as 'right'
  }
]
