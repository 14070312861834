import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as React from 'react'
import * as ApolloReactComponents from '@apollo/react-components'
import * as ApolloReactHoc from '@apollo/react-hoc'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
  ProgrammeTypeProgrammeQuestionsDynamicZoneInput: any
  SectionQuestionsDynamicZoneInput: any
}

export type ConfirmAppointmentResponse = {
  __typename?: 'ConfirmAppointmentResponse'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type AdminUser = {
  __typename?: 'AdminUser'
  id: Scalars['ID']
  username?: Maybe<Scalars['String']>
}

export type Appointments = {
  __typename?: 'Appointments'
  id: Scalars['ID']
  created_at: Scalars['DateTime']
  updated_at: Scalars['DateTime']
  calendarId?: Maybe<Scalars['Long']>
  businessId?: Maybe<Scalars['Long']>
  appointmentTitle?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
  clientEmail?: Maybe<Scalars['String']>
  appointmentScheduleId?: Maybe<Scalars['Long']>
  appointmentUrl?: Maybe<Scalars['String']>
  appointmentStartDate?: Maybe<Scalars['DateTime']>
  appointmentEndDate?: Maybe<Scalars['DateTime']>
  coach_session?: Maybe<CoachSession>
  status?: Maybe<Scalars['Boolean']>
  created_by?: Maybe<AdminUser>
  updated_by?: Maybe<AdminUser>
}

export type EBooking = {
  __typename?: 'EBooking'
  id?: Maybe<Scalars['ID']>
  userId?: Maybe<Scalars['ID']>
  eventTypeId?: Maybe<Scalars['ID']>
  uid?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
}

export type EventLocation = {
  __typename?: 'EventLocation'
  type?: Maybe<Scalars['String']>
}

export type EventType = {
  __typename?: 'EventType'
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  locations?: Maybe<Array<Maybe<EventLocation>>>
  length?: Maybe<Scalars['Int']>
  hidden?: Maybe<Scalars['Boolean']>
  userId?: Maybe<Scalars['ID']>
  teamId?: Maybe<Scalars['ID']>
  eventName?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
  periodType?: Maybe<Scalars['String']>
  periodStartDate?: Maybe<Scalars['String']>
  periodEndDate?: Maybe<Scalars['String']>
  periodDays?: Maybe<Scalars['Int']>
  periodCountCalendarDays?: Maybe<Scalars['Boolean']>
  requiresConfirmation?: Maybe<Scalars['Boolean']>
  recurringEvent?: Maybe<Scalars['Boolean']>
  disableGuests?: Maybe<Scalars['Boolean']>
  hideCalendarNotes?: Maybe<Scalars['Boolean']>
  minimumBookingNotice?: Maybe<Scalars['Int']>
  beforeEventBuffer?: Maybe<Scalars['Int']>
  afterEventBuffer?: Maybe<Scalars['Int']>
  seatsPerTimeSlot?: Maybe<Scalars['Int']>
  schedulingType?: Maybe<Scalars['String']>
  scheduleId?: Maybe<Scalars['ID']>
  price?: Maybe<Scalars['Int']>
  currency?: Maybe<Scalars['String']>
  slotInterval?: Maybe<Scalars['Int']>
  successRedirectUrl?: Maybe<Scalars['String']>
}

export type Notes = {
  __typename?: 'Notes'
  id: Scalars['ID']
  created_at: Scalars['DateTime']
  updated_at: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  sub_module_submission?: Maybe<SubModuleSubmission>
  sectionOrSubmissionId?: Maybe<Scalars['String']>
  programme_submission?: Maybe<ProgrammeSubmission>
  notesId?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponent>>>
  programme?: Maybe<Programmes>
  user?: Maybe<UsersPermissionsUser>
  created_by?: Maybe<AdminUser>
  updated_by?: Maybe<AdminUser>
  document?: Maybe<Array<Maybe<UploadFile>>>
}

export type NotesDocumentArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type Programmes = {
  __typename?: 'Programmes'
  id: Scalars['ID']
  created_at: Scalars['DateTime']
  updated_at: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  start?: Maybe<Scalars['DateTime']>
  isCustomJourney?: Maybe<Scalars['Boolean']>
  showTimeline?: Maybe<Scalars['Boolean']>
  programmeTitle?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  logo?: Maybe<UploadFile>
  sendTypeform?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<TypeFormCollection>
  whatToExpect?: Maybe<Scalars['String']>
  completion?: Maybe<Scalars['DateTime']>
  isTemplate?: Maybe<Scalars['Boolean']>
  three_60_form_id?: Maybe<Scalars['String']>
  created_by?: Maybe<AdminUser>
  updated_by?: Maybe<AdminUser>
  companies?: Maybe<Array<Maybe<Company>>>
  programme_types?: Maybe<Array<Maybe<ProgrammeType>>>
  programme_submissions?: Maybe<Array<Maybe<ProgrammeSubmission>>>
  users?: Maybe<Array<Maybe<UsersPermissionsUser>>>
  articles?: Maybe<Array<Maybe<Article>>>
  email_logs?: Maybe<Array<Maybe<EmailLog>>>
  email_templates?: Maybe<Array<Maybe<EmailTemplate>>>
  notes?: Maybe<Array<Maybe<Notes>>>
  sub_module_submissions?: Maybe<Array<Maybe<SubModuleSubmission>>>
}

export type ProgrammesCompaniesArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesProgramme_TypesArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesProgramme_SubmissionsArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesUsersArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesArticlesArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesEmail_LogsArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesEmail_TemplatesArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesNotesArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type ProgrammesSub_Module_SubmissionsArgs = {
  sort?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where?: Maybe<Scalars['JSON']>
}

export type EditNoteInput = {
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  sub_module_submission?: Maybe<Scalars['ID']>
  sectionOrSubmissionId?: Maybe<Scalars['String']>
  programme_submission?: Maybe<Scalars['ID']>
  document?: Maybe<Array<Maybe<Scalars['ID']>>>
  notesId?: Maybe<Array<Maybe<EditComponentSubmissionsSaboteurComponentInput>>>
  programme?: Maybe<Scalars['ID']>
  user?: Maybe<Scalars['ID']>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
}

export type EditComponentSubmissionsSaboteurComponentInput = {
  id?: Maybe<Scalars['ID']>
  saboteurProofValue?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type ReportsGrouped = {
  __typename?: 'ReportsGrouped'
  companyName: Scalars['String']
  programmeType?: Maybe<Array<Maybe<ProgrammeTypeReport>>>
}

export type ProgrammeTypeReport = {
  __typename?: 'ProgrammeTypeReport'
  programmeType?: Maybe<Scalars['String']>
  programmeName?: Maybe<Scalars['String']>
  sections?: Maybe<Array<Maybe<SectionReport>>>
}

export type SectionReport = {
  __typename?: 'SectionReport'
  programmeName?: Maybe<Scalars['String']>
  report?: Maybe<ReportDetail>
  programmeType?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ReportDetail = {
  __typename?: 'ReportDetail'
  id: Scalars['ID']
  url?: Maybe<Scalars['String']>
}

export type ProgrammeSubmissionResponse = {
  __typename?: 'ProgrammeSubmissionResponse'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type EmailsSent = {
  __typename?: 'EmailsSent'
  emails?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EmailSentProgramme = {
  __typename?: 'EmailSentProgramme'
  successed?: Maybe<Scalars['String']>
}

export type EditProgrammeSubmissionInput = {
  status?: Maybe<Enum_Programmesubmission_Status>
  progress?: Maybe<Scalars['Int']>
  user?: Maybe<Scalars['ID']>
  language?: Maybe<Scalars['ID']>
  programme_type?: Maybe<Scalars['ID']>
  coach_sessions?: Maybe<Array<Maybe<Scalars['ID']>>>
  programmeResponses?: Maybe<Array<Maybe<EditComponentSubmissionsProgrammeSubmissionInput>>>
  myNotes?: Maybe<Array<Maybe<EditComponentSubmissionsNoteInput>>>
  submissionparticipants?: Maybe<Array<Maybe<EditComponentSubmissionsParticipantInput>>>
  programme_report?: Maybe<Scalars['ID']>
  programme?: Maybe<Scalars['ID']>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  competency_url?: Maybe<Scalars['ID']>
  feedfoward_url?: Maybe<Scalars['ID']>
  selected?: Maybe<Scalars['Boolean']>
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>
  uploaded_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  emailSent?: Maybe<Scalars['Boolean']>
  isUpdatedByStakeholder?: Maybe<Scalars['Boolean']>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
}

export type EditComponentSubmissionsNoteInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  document?: Maybe<Scalars['ID']>
}

export type EditComponentSubmissionsParticipantInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsAnswerInput = {
  id?: Maybe<Scalars['ID']>
  questionId?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
  nestedAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsRepeatAnswerInput>>>
  isCorrect?: Maybe<Scalars['Boolean']>
  feedback?: Maybe<Scalars['String']>
  outcome?: Maybe<Enum_Componentsubmissionsanswer_Outcome>
  questionTitle?: Maybe<Scalars['String']>
  questionType?: Maybe<Scalars['String']>
}

export type EditComponentGravitasScaleItemsGravitasScaleItemInput = {
  id?: Maybe<Scalars['ID']>
  labelOne?: Maybe<Scalars['String']>
  labelTwo?: Maybe<Scalars['String']>
  answerOne?: Maybe<Scalars['Int']>
  answerTwo?: Maybe<Scalars['Int']>
  answerThree?: Maybe<Scalars['Int']>
  question?: Maybe<Scalars['String']>
  labelThree?: Maybe<Scalars['String']>
}

export type EditComponentStakeholderMapItemStackholderMapItemInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  valueX?: Maybe<Scalars['Int']>
  valueY?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
}

export type EditComponentXyGraphItemXyGraphItemInput = {
  id?: Maybe<Scalars['ID']>
  valueX?: Maybe<Scalars['Int']>
  valueY?: Maybe<Scalars['Int']>
  image?: Maybe<Scalars['ID']>
  color?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsProgrammeSubmissionInput = {
  id?: Maybe<Scalars['ID']>
  answers?: Maybe<Array<Maybe<EditComponentSubmissionsAnswerInput>>>
  gravitasScales?: Maybe<Array<Maybe<EditComponentGravitasScaleItemsGravitasScaleItemInput>>>
  stakeholderItems?: Maybe<Array<Maybe<EditComponentStakeholderMapItemStackholderMapItemInput>>>
  stakeholderChart?: Maybe<Scalars['ID']>
  xYGraphItems?: Maybe<Array<Maybe<EditComponentXyGraphItemXyGraphItemInput>>>
  xYGraphChart?: Maybe<Scalars['ID']>
  saboteurImage?: Maybe<Scalars['ID']>
  characteristics?: Maybe<Array<Maybe<EditComponentSubmissionsCharacteristicInput>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<Scalars['ID']>
  gapsAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsValuesGapInput>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<EditComponentSubmissionsSkipProofComponentInput>>>
  competencyAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsCompetencyAnswerInput>>>
  sabtourVideosProofs?: Maybe<Array<Maybe<EditComponentSubmissionsSabtourVideosComponentInput>>>
  saboteur?: Maybe<Array<Maybe<EditComponentSubmissionsSaboteurComponentInput>>>
  gravitasSliderAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsGravitasSlidersAnswerInput>>>
  gravitasGap?: Maybe<Array<Maybe<EditComponentSubmissionsGravitasGapInput>>>
}

export type EditComponentSubmissionsRepeatAnswerInput = {
  id?: Maybe<Scalars['ID']>
  answer?: Maybe<Scalars['String']>
  questionTitle?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsCharacteristicInput = {
  id?: Maybe<Scalars['ID']>
  answer?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsValuesGapInput = {
  id?: Maybe<Scalars['ID']>
  gap?: Maybe<Scalars['String']>
  commitment?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsSkipProofComponentInput = {
  id?: Maybe<Scalars['ID']>
  skipProofValue?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsCompetencyAnswerInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['Int']>
  response?: Maybe<Scalars['String']>
  isSimpleQuestion?: Maybe<Scalars['Boolean']>
  steps?: Maybe<Scalars['Int']>
  textboxQuestion?: Maybe<Scalars['String']>
  headingQuestion?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsSabtourVideosComponentInput = {
  id?: Maybe<Scalars['ID']>
  sabtourValue?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsGravitasSlidersAnswerInput = {
  id?: Maybe<Scalars['ID']>
  negativeQuestion?: Maybe<Scalars['String']>
  positiveQuestion?: Maybe<Scalars['String']>
  colorAndRating?: Maybe<Array<Maybe<EditComponentOptionColorAndRatingInput>>>
}

export type EditComponentSubmissionsGravitasGapInput = {
  id?: Maybe<Scalars['ID']>
  gap?: Maybe<Scalars['String']>
  commitment?: Maybe<Scalars['String']>
}

export type EditComponentOptionColorAndRatingInput = {
  id?: Maybe<Scalars['ID']>
  color?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Int']>
}

export type ReportsSubmissionGrouped = {
  __typename?: 'ReportsSubmissionGrouped'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  sectionOrModuleId?: Maybe<Scalars['String']>
  created_by?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
  programmeType?: Maybe<Scalars['String']>
  sub_module_submission?: Maybe<Scalars['Int']>
  notification?: Maybe<Scalars['String']>
  report?: Maybe<ReportDetail>
}

export type SubModuleSubmissionReport = {
  __typename?: 'SubModuleSubmissionReport'
  id: Scalars['ID']
  sub_module: SubModule
  reports: Array<Report>
}

export type SubModuleSubmissionResponse = {
  __typename?: 'SubModuleSubmissionResponse'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type Emails = {
  __typename?: 'Emails'
  emails?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EmailSent = {
  __typename?: 'EmailSent'
  success?: Maybe<Scalars['String']>
}

export type CheckConfirmed = {
  __typename?: 'CheckConfirmed'
  success?: Maybe<Scalars['String']>
}

export type OrderedSubmissions = {
  __typename?: 'OrderedSubmissions'
  programmeSubmission: ProgrammeSubmission
  subModuleSubmissions: Array<SubModuleSubmission>
}

export type EditSubModuleSubmissionInput = {
  language?: Maybe<Scalars['ID']>
  user?: Maybe<Scalars['ID']>
  view?: Maybe<Scalars['Int']>
  sub_module?: Maybe<Scalars['ID']>
  reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  section?: Maybe<Array<Maybe<EditComponentSubmissionsSectionSubmissionInput>>>
  progress?: Maybe<Scalars['Int']>
  status?: Maybe<Enum_Submodulesubmission_Status>
  timeSpent?: Maybe<Scalars['String']>
  coach_sessions?: Maybe<Array<Maybe<Scalars['ID']>>>
  submissionparticipants?: Maybe<Array<Maybe<EditComponentSubmissionsParticipantInput>>>
  competency_participants?: Maybe<Array<Maybe<EditComponentSubmissionsCompetencyParticipantInput>>>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  feedfoward_url?: Maybe<Scalars['ID']>
  competency_url?: Maybe<Scalars['ID']>
  uploaded_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  sectionTypeform?: Maybe<Scalars['Boolean']>
  programmeTypeForm?: Maybe<Scalars['Boolean']>
  three_60_survey_form_id?: Maybe<Scalars['String']>
  programme?: Maybe<Scalars['ID']>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
}

export type EditComponentSubmissionsCompetencyParticipantInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type EditComponentSubmissionsSectionSubmissionInput = {
  id?: Maybe<Scalars['ID']>
  section?: Maybe<Scalars['ID']>
  answers?: Maybe<Array<Maybe<EditComponentSubmissionsAnswerInput>>>
  progress?: Maybe<Scalars['Int']>
  gravitasScales?: Maybe<Array<Maybe<EditComponentGravitasScaleItemsGravitasScaleItemInput>>>
  stakeholderItems?: Maybe<Array<Maybe<EditComponentStakeholderMapItemStackholderMapItemInput>>>
  stakeholderChart?: Maybe<Scalars['ID']>
  xYGraphItems?: Maybe<Array<Maybe<EditComponentXyGraphItemXyGraphItemInput>>>
  xYGraphChart?: Maybe<Scalars['ID']>
  saboteurImage?: Maybe<Scalars['ID']>
  characteristics?: Maybe<Array<Maybe<EditComponentSubmissionsCharacteristicInput>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<Scalars['ID']>
  gapsAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsValuesGapInput>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<EditComponentSubmissionsSkipProofComponentInput>>>
  sabtourVideosProofs?: Maybe<Array<Maybe<EditComponentSubmissionsSabtourVideosComponentInput>>>
  saboteur?: Maybe<Array<Maybe<EditComponentSubmissionsSaboteurComponentInput>>>
  competencyAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsCompetencyAnswerInput>>>
  uploadDocuments?: Maybe<Array<Maybe<EditComponentSubmissionsUploadDocumentInput>>>
  gravitasSliderAnswers?: Maybe<Array<Maybe<EditComponentSubmissionsGravitasSlidersAnswerInput>>>
  gravitasGap?: Maybe<Array<Maybe<EditComponentSubmissionsGravitasGapInput>>>
  emailSent?: Maybe<Scalars['Boolean']>
}

export type EditComponentSubmissionsUploadDocumentInput = {
  id?: Maybe<Scalars['ID']>
  saboteurProofValue?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type EditSubmissionInput = {
  language?: Maybe<Scalars['ID']>
  section?: Maybe<Array<Maybe<EditComponentSubmissionsSectionSubmissionInput>>>
  progress?: Maybe<Scalars['Int']>
  status?: Maybe<Enum_Submission_Status>
  timeSpent?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['ID']>
  views?: Maybe<Scalars['Int']>
  module?: Maybe<Scalars['ID']>
  reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  competency_url?: Maybe<Scalars['ID']>
  participants?: Maybe<Array<Maybe<EditComponentSubmissionsParticipantInput>>>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  uploaded_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
}

export type EditUserUploadedDocumentInput = {
  name?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['ID']>
  documents?: Maybe<Array<Maybe<EditComponentQuestionTypesDocumentInput>>>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
}

export type EditComponentQuestionTypesDocumentInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  document?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type AccountChangeInput = {
  user?: Maybe<Scalars['ID']>
  oldDivision?: Maybe<Scalars['ID']>
  oldRegion?: Maybe<Scalars['ID']>
  newDivision?: Maybe<Scalars['ID']>
  newRegion?: Maybe<Scalars['ID']>
  accept?: Maybe<Scalars['Boolean']>
  userUpdated?: Maybe<Scalars['Boolean']>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
  old_division?: Maybe<Scalars['ID']>
  old_region?: Maybe<Scalars['ID']>
  new_division?: Maybe<Scalars['ID']>
  new_region?: Maybe<Scalars['ID']>
  user_updated?: Maybe<Scalars['Boolean']>
}

export type EditUserInput = {
  username?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  resetPasswordToken?: Maybe<Scalars['String']>
  confirmed?: Maybe<Scalars['Boolean']>
  blocked?: Maybe<Scalars['Boolean']>
  role?: Maybe<Scalars['ID']>
  phoneNumber?: Maybe<Scalars['String']>
  profilePicture?: Maybe<Scalars['ID']>
  language?: Maybe<Scalars['ID']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  modulesRead?: Maybe<Scalars['JSON']>
  homePromptsRead?: Maybe<Scalars['Boolean']>
  programmePromptsRead?: Maybe<Scalars['Boolean']>
  modulePromptsRead?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['JSON']>
  submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  report?: Maybe<Scalars['ID']>
  company?: Maybe<Scalars['ID']>
  articlesViewed?: Maybe<Scalars['JSON']>
  postCard?: Maybe<Scalars['ID']>
  timezone?: Maybe<EditComponentOptionTimezoneInput>
  certificate?: Maybe<Scalars['ID']>
  loginAttempts?: Maybe<Scalars['Int']>
  notifications?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  feedForwardUrl?: Maybe<Scalars['String']>
  division?: Maybe<Scalars['ID']>
  region?: Maybe<Scalars['ID']>
  sub_module_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  programmes?: Maybe<Array<Maybe<Scalars['ID']>>>
  coach_submitted_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  loginWithSSO?: Maybe<Scalars['Boolean']>
  programmeEvalutionEmailSent?: Maybe<Scalars['Boolean']>
  updatedDetails?: Maybe<Scalars['Boolean']>
  account_changes?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  user_uploaded_documents?: Maybe<Array<Maybe<Scalars['ID']>>>
  onboarding?: Maybe<Scalars['Boolean']>
  triggerReport?: Maybe<Scalars['Boolean']>
  bookings?: Maybe<Array<Maybe<Scalars['ID']>>>
  clientId?: Maybe<Scalars['Long']>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  current_survey?: Maybe<Scalars['JSON']>
  coach?: Maybe<Scalars['ID']>
  three_60_form_id?: Maybe<Scalars['String']>
  submodule_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  created_by?: Maybe<Scalars['ID']>
  updated_by?: Maybe<Scalars['ID']>
}

export type EditComponentOptionTimezoneInput = {
  id?: Maybe<Scalars['ID']>
  abbrev?: Maybe<Scalars['String']>
  altName?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type Article = {
  __typename?: 'Article'
  id: Scalars['ID']
  title_english?: Maybe<Scalars['String']>
  content_english?: Maybe<Scalars['String']>
  image?: Maybe<UploadFileEntityResponse>
  date_published?: Maybe<Scalars['Date']>
  read_time?: Maybe<Scalars['Int']>
  title_french?: Maybe<Scalars['String']>
  title_german?: Maybe<Scalars['String']>
  title_spanish?: Maybe<Scalars['String']>
  title_mandarin?: Maybe<Scalars['String']>
  content_french?: Maybe<Scalars['String']>
  content_german?: Maybe<Scalars['String']>
  content_spanish?: Maybe<Scalars['String']>
  content_mandarin?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  companies?: Maybe<CompanyRelationResponseCollection>
  programme_types?: Maybe<ProgrammeTypeRelationResponseCollection>
  position?: Maybe<Scalars['Long']>
  programmes?: Maybe<ProgrammeRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ArticleCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ArticleProgramme_TypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ArticleProgrammesArgs = {
  filters?: Maybe<ProgrammeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UploadFile = {
  __typename?: 'UploadFile'
  id: Scalars['ID']
  name: Scalars['String']
  alternativeText?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  formats?: Maybe<Scalars['JSON']>
  hash: Scalars['String']
  ext?: Maybe<Scalars['String']>
  mime: Scalars['String']
  size: Scalars['Float']
  url: Scalars['String']
  previewUrl?: Maybe<Scalars['String']>
  provider: Scalars['String']
  provider_metadata?: Maybe<Scalars['JSON']>
  related?: Maybe<Array<Maybe<GenericMorph>>>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type Report = {
  __typename?: 'Report'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  type?: Maybe<Enum_Report_Type>
  submission?: Maybe<SubmissionEntityResponse>
  report?: Maybe<UploadFileEntityResponse>
  section_or_module_id?: Maybe<Scalars['String']>
  notification?: Maybe<NotificationEntityResponse>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SubModule = {
  __typename?: 'SubModule'
  id: Scalars['ID']
  name_english?: Maybe<Scalars['String']>
  name_french?: Maybe<Scalars['String']>
  name_german?: Maybe<Scalars['String']>
  name_spanish?: Maybe<Scalars['String']>
  name_mandarin?: Maybe<Scalars['String']>
  sections?: Maybe<SectionRelationResponseCollection>
  cover?: Maybe<UploadFileEntityResponse>
  description_english?: Maybe<Scalars['String']>
  description_french?: Maybe<Scalars['String']>
  description_german?: Maybe<Scalars['String']>
  description_mandarin?: Maybe<Scalars['String']>
  description_spanish?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  sub_module_submissions?: Maybe<SubModuleSubmissionRelationResponseCollection>
  programme_type?: Maybe<ProgrammeTypeEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SubModuleSectionsArgs = {
  filters?: Maybe<SectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSub_Module_SubmissionsArgs = {
  filters?: Maybe<SubModuleSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmission = {
  __typename?: 'SubModuleSubmission'
  id: Scalars['ID']
  language?: Maybe<LanguageEntityResponse>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  view?: Maybe<Scalars['Int']>
  sub_module?: Maybe<SubModuleEntityResponse>
  reports?: Maybe<ReportRelationResponseCollection>
  section?: Maybe<Array<Maybe<ComponentSubmissionsSectionSubmission>>>
  progress?: Maybe<Scalars['Int']>
  status?: Maybe<Enum_Submodulesubmission_Status>
  time_spent?: Maybe<Scalars['String']>
  coach_sessions?: Maybe<CoachSessionRelationResponseCollection>
  submission_participants?: Maybe<Array<Maybe<ComponentSubmissionsParticipants>>>
  competency_participants?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyParticipants>>>
  respondents_answers?: Maybe<RespondentsAnswerRelationResponseCollection>
  feedfoward_url?: Maybe<FeedfowardUrlEntityResponse>
  competency_url?: Maybe<CompetencyUrlEntityResponse>
  uploaded_reports?: Maybe<UploadedReportRelationResponseCollection>
  participants?: Maybe<ParticipantRelationResponseCollection>
  notes?: Maybe<NoteRelationResponseCollection>
  section_typeform?: Maybe<Scalars['Boolean']>
  programme_typeForm?: Maybe<Scalars['Boolean']>
  three_60_survey_form_id?: Maybe<Scalars['String']>
  programme?: Maybe<ProgrammeEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SubModuleSubmissionReportsArgs = {
  filters?: Maybe<ReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionSectionArgs = {
  filters?: Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionCoach_SessionsArgs = {
  filters?: Maybe<CoachSessionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionSubmission_ParticipantsArgs = {
  filters?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionCompetency_ParticipantsArgs = {
  filters?: Maybe<ComponentSubmissionsCompetencyParticipantsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionRespondents_AnswersArgs = {
  filters?: Maybe<RespondentsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionUploaded_ReportsArgs = {
  filters?: Maybe<UploadedReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionParticipantsArgs = {
  filters?: Maybe<ParticipantFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubModuleSubmissionNotesArgs = {
  filters?: Maybe<NoteFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmission = {
  __typename?: 'ProgrammeSubmission'
  id: Scalars['ID']
  status?: Maybe<Enum_Programmesubmission_Status>
  progress?: Maybe<Scalars['Int']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  language?: Maybe<LanguageEntityResponse>
  programme_type?: Maybe<ProgrammeTypeEntityResponse>
  coach_sessions?: Maybe<CoachSessionRelationResponseCollection>
  programme_responses?: Maybe<Array<Maybe<ComponentSubmissionsProgrammeSubmissions>>>
  my_notes?: Maybe<Array<Maybe<ComponentSubmissionsNotes>>>
  submission_participants?: Maybe<Array<Maybe<ComponentSubmissionsParticipants>>>
  programme_report?: Maybe<ProgrammeReportEntityResponse>
  programme?: Maybe<ProgrammeEntityResponse>
  respondents_answers?: Maybe<RespondentsAnswerRelationResponseCollection>
  competency_url?: Maybe<CompetencyUrlEntityResponse>
  feedfoward_url?: Maybe<FeedfowardUrlEntityResponse>
  selected?: Maybe<Scalars['Boolean']>
  participants?: Maybe<ParticipantRelationResponseCollection>
  uploaded_reports?: Maybe<UploadedReportRelationResponseCollection>
  notes?: Maybe<NoteRelationResponseCollection>
  email_sent?: Maybe<Scalars['Boolean']>
  is_updated_by_stakeholder?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ProgrammeSubmissionCoach_SessionsArgs = {
  filters?: Maybe<CoachSessionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionProgramme_ResponsesArgs = {
  filters?: Maybe<ComponentSubmissionsProgrammeSubmissionsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionMy_NotesArgs = {
  filters?: Maybe<ComponentSubmissionsNotesFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionSubmission_ParticipantsArgs = {
  filters?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionRespondents_AnswersArgs = {
  filters?: Maybe<RespondentsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionParticipantsArgs = {
  filters?: Maybe<ParticipantFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionUploaded_ReportsArgs = {
  filters?: Maybe<UploadedReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSubmissionNotesArgs = {
  filters?: Maybe<NoteFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Submission = {
  __typename?: 'Submission'
  id: Scalars['ID']
  language?: Maybe<LanguageEntityResponse>
  section?: Maybe<Array<Maybe<ComponentSubmissionsSectionSubmission>>>
  progress?: Maybe<Scalars['Int']>
  status?: Maybe<Enum_Submission_Status>
  time_spent?: Maybe<Scalars['String']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  views?: Maybe<Scalars['Int']>
  module?: Maybe<ModuleEntityResponse>
  reports?: Maybe<ReportRelationResponseCollection>
  competency_url?: Maybe<CompetencyUrlEntityResponse>
  participants?: Maybe<Array<Maybe<ComponentSubmissionsParticipants>>>
  respondents_answers?: Maybe<RespondentsAnswerRelationResponseCollection>
  uploaded_reports?: Maybe<UploadedReportRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SubmissionSectionArgs = {
  filters?: Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubmissionReportsArgs = {
  filters?: Maybe<ReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubmissionParticipantsArgs = {
  filters?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubmissionRespondents_AnswersArgs = {
  filters?: Maybe<RespondentsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubmissionUploaded_ReportsArgs = {
  filters?: Maybe<UploadedReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser'
  id: Scalars['ID']
  username: Scalars['String']
  email: Scalars['String']
  provider?: Maybe<Scalars['String']>
  confirmed?: Maybe<Scalars['Boolean']>
  blocked?: Maybe<Scalars['Boolean']>
  role?: Maybe<UsersPermissionsRoleEntityResponse>
  phoneNumber?: Maybe<Scalars['String']>
  profilePicture?: Maybe<UploadFileEntityResponse>
  language?: Maybe<LanguageEntityResponse>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  modulesRead?: Maybe<Scalars['JSON']>
  homePromptsRead?: Maybe<Scalars['Boolean']>
  programmePromptsRead?: Maybe<Scalars['Boolean']>
  modulePromptsRead?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['JSON']>
  submissions?: Maybe<SubmissionRelationResponseCollection>
  report?: Maybe<UploadFileEntityResponse>
  company?: Maybe<CompanyEntityResponse>
  articlesViewed?: Maybe<Scalars['JSON']>
  postCard?: Maybe<PostCardEntityResponse>
  timezone?: Maybe<ComponentOptionTimezone>
  certificate?: Maybe<UploadFileEntityResponse>
  loginAttempts?: Maybe<Scalars['Int']>
  notifications?: Maybe<NotificationRelationResponseCollection>
  programme_submissions?: Maybe<ProgrammeSubmissionRelationResponseCollection>
  feedForwardUrl?: Maybe<Scalars['String']>
  division?: Maybe<DivisionEntityResponse>
  region?: Maybe<RegionEntityResponse>
  sub_module_submissions?: Maybe<SubModuleSubmissionRelationResponseCollection>
  programmes?: Maybe<ProgrammeRelationResponseCollection>
  coach_submitted_reports?: Maybe<CoachSubmittedReportRelationResponseCollection>
  loginWithSSO?: Maybe<Scalars['Boolean']>
  programmeEvalutionEmailSent?: Maybe<Scalars['Boolean']>
  updatedDetails?: Maybe<Scalars['Boolean']>
  account_changes?: Maybe<AccountChangeRelationResponseCollection>
  notes?: Maybe<NoteRelationResponseCollection>
  user_uploaded_documents?: Maybe<UserUploadedDocumentRelationResponseCollection>
  onboarding?: Maybe<Scalars['Boolean']>
  triggerReport?: Maybe<Scalars['Boolean']>
  bookings?: Maybe<BookingRelationResponseCollection>
  clientId?: Maybe<Scalars['Long']>
  respondents_answers?: Maybe<RespondentsAnswerRelationResponseCollection>
  current_survey?: Maybe<Scalars['JSON']>
  coach?: Maybe<CoachEntityResponse>
  three_60_form_id?: Maybe<Scalars['String']>
  submodule_reports?: Maybe<SubmoduleReportRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UsersPermissionsUserSubmissionsArgs = {
  filters?: Maybe<SubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserNotificationsArgs = {
  filters?: Maybe<NotificationFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserProgramme_SubmissionsArgs = {
  filters?: Maybe<ProgrammeSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserSub_Module_SubmissionsArgs = {
  filters?: Maybe<SubModuleSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserProgrammesArgs = {
  filters?: Maybe<ProgrammeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserCoach_Submitted_ReportsArgs = {
  filters?: Maybe<CoachSubmittedReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserAccount_ChangesArgs = {
  filters?: Maybe<AccountChangeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserNotesArgs = {
  filters?: Maybe<NoteFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserUser_Uploaded_DocumentsArgs = {
  filters?: Maybe<UserUploadedDocumentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserBookingsArgs = {
  filters?: Maybe<BookingFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserRespondents_AnswersArgs = {
  filters?: Maybe<RespondentsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsUserSubmodule_ReportsArgs = {
  filters?: Maybe<SubmoduleReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Query = {
  __typename?: 'Query'
  getMyAppointments: Array<Appointments>
  getAllBookings?: Maybe<Array<EBooking>>
  getMyBookings?: Maybe<Array<EBooking>>
  getFirstTwelveArticles?: Maybe<Array<Maybe<Article>>>
  getAllEventTypes: Array<EventType>
  getForwaredCards?: Maybe<Array<Maybe<ForwardedCard>>>
  getFirstFourNotes?: Maybe<Array<Maybe<Notes>>>
  getPostCards?: Maybe<Array<Maybe<PostCard>>>
  getProgammeReportsGrouped?: Maybe<Array<Maybe<ReportsGrouped>>>
  getMyProgrammeSubmissions?: Maybe<Array<Maybe<ProgrammeSubmission>>>
  checkResponsesFromTypeFormProgramme?: Maybe<EmailsSent>
  getSectionReport: Report
  getAllsectionReport?: Maybe<Array<Maybe<Report>>>
  getReportsGrouped?: Maybe<Array<Maybe<ReportsGrouped>>>
  getSubmissionReportGrouped?: Maybe<Array<Maybe<ReportsSubmissionGrouped>>>
  getMySubModuleSubmissions?: Maybe<Array<Maybe<SubModuleSubmission>>>
  checkResponsesFromTypeForm?: Maybe<Emails>
  getMySubModuleSubmissionsReports?: Maybe<Array<Maybe<SubModuleSubmissionReport>>>
  generateMySubModuleSubmissionResponses?: Maybe<Array<Maybe<SubModuleSubmissionReport>>>
  generateMyMiniSectionSubmissionResponse?: Maybe<Array<Maybe<SubModuleSubmissionReport>>>
  getNewTypeformFeedResponses?: Maybe<CheckConfirmed>
  getTypeformCompetencyResponses?: Maybe<CheckConfirmed>
  getOrderedSubmissions?: Maybe<Array<Maybe<OrderedSubmissions>>>
  getMySubmissions?: Maybe<Array<Maybe<Submission>>>
  uploadFile?: Maybe<UploadFileEntityResponse>
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>
  uploadFolder?: Maybe<UploadFolderEntityResponse>
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>
  i18NLocale?: Maybe<I18NLocaleEntityResponse>
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>
  accountChange?: Maybe<AccountChangeEntityResponse>
  accountChanges?: Maybe<AccountChangeEntityResponseCollection>
  appointment?: Maybe<AppointmentEntityResponse>
  appointments?: Maybe<AppointmentEntityResponseCollection>
  article?: Maybe<ArticleEntityResponse>
  articles?: Maybe<ArticleEntityResponseCollection>
  auditTrail?: Maybe<AuditTrailEntityResponse>
  auditTrails?: Maybe<AuditTrailEntityResponseCollection>
  booking?: Maybe<BookingEntityResponse>
  bookings?: Maybe<BookingEntityResponseCollection>
  coach?: Maybe<CoachEntityResponse>
  coaches?: Maybe<CoachEntityResponseCollection>
  coachSession?: Maybe<CoachSessionEntityResponse>
  coachSessions?: Maybe<CoachSessionEntityResponseCollection>
  coachSubmittedReport?: Maybe<CoachSubmittedReportEntityResponse>
  coachSubmittedReports?: Maybe<CoachSubmittedReportEntityResponseCollection>
  company?: Maybe<CompanyEntityResponse>
  companies?: Maybe<CompanyEntityResponseCollection>
  competencyUrl?: Maybe<CompetencyUrlEntityResponse>
  competencyUrls?: Maybe<CompetencyUrlEntityResponseCollection>
  division?: Maybe<DivisionEntityResponse>
  divisions?: Maybe<DivisionEntityResponseCollection>
  emailLog?: Maybe<EmailLogEntityResponse>
  emailLogs?: Maybe<EmailLogEntityResponseCollection>
  emailTemplate?: Maybe<EmailTemplateEntityResponse>
  emailTemplates?: Maybe<EmailTemplateEntityResponseCollection>
  event?: Maybe<EventEntityResponse>
  events?: Maybe<EventEntityResponseCollection>
  feedfowardUrl?: Maybe<FeedfowardUrlEntityResponse>
  feedfowardUrls?: Maybe<FeedfowardUrlEntityResponseCollection>
  forwardedCard?: Maybe<ForwardedCardEntityResponse>
  forwardedCards?: Maybe<ForwardedCardEntityResponseCollection>
  invite?: Maybe<InviteEntityResponse>
  invites?: Maybe<InviteEntityResponseCollection>
  language?: Maybe<LanguageEntityResponse>
  languages?: Maybe<LanguageEntityResponseCollection>
  logo?: Maybe<LogoEntityResponse>
  logos?: Maybe<LogoEntityResponseCollection>
  modal?: Maybe<ModalEntityResponse>
  modals?: Maybe<ModalEntityResponseCollection>
  module?: Maybe<ModuleEntityResponse>
  modules?: Maybe<ModuleEntityResponseCollection>
  note?: Maybe<NoteEntityResponse>
  notes?: Maybe<NoteEntityResponseCollection>
  notification?: Maybe<NotificationEntityResponse>
  notifications?: Maybe<NotificationEntityResponseCollection>
  participant?: Maybe<ParticipantEntityResponse>
  participants?: Maybe<ParticipantEntityResponseCollection>
  postCard?: Maybe<PostCardEntityResponse>
  postCards?: Maybe<PostCardEntityResponseCollection>
  postCardImage?: Maybe<PostCardImageEntityResponse>
  postCardImages?: Maybe<PostCardImageEntityResponseCollection>
  postCardSentence?: Maybe<PostCardSentenceEntityResponse>
  postCardSentences?: Maybe<PostCardSentenceEntityResponseCollection>
  programme?: Maybe<ProgrammeEntityResponse>
  programmes?: Maybe<ProgrammeEntityResponseCollection>
  programmeReport?: Maybe<ProgrammeReportEntityResponse>
  programmeReports?: Maybe<ProgrammeReportEntityResponseCollection>
  programmeSubmission?: Maybe<ProgrammeSubmissionEntityResponse>
  programmeSubmissions?: Maybe<ProgrammeSubmissionEntityResponseCollection>
  programmeType?: Maybe<ProgrammeTypeEntityResponse>
  programmeTypes?: Maybe<ProgrammeTypeEntityResponseCollection>
  region?: Maybe<RegionEntityResponse>
  regions?: Maybe<RegionEntityResponseCollection>
  report?: Maybe<ReportEntityResponse>
  reports?: Maybe<ReportEntityResponseCollection>
  respondentsAnswer?: Maybe<RespondentsAnswerEntityResponse>
  respondentsAnswers?: Maybe<RespondentsAnswerEntityResponseCollection>
  section?: Maybe<SectionEntityResponse>
  sections?: Maybe<SectionEntityResponseCollection>
  subModule?: Maybe<SubModuleEntityResponse>
  subModules?: Maybe<SubModuleEntityResponseCollection>
  subModuleSubmission?: Maybe<SubModuleSubmissionEntityResponse>
  subModuleSubmissions?: Maybe<SubModuleSubmissionEntityResponseCollection>
  submission?: Maybe<SubmissionEntityResponse>
  submissions?: Maybe<SubmissionEntityResponseCollection>
  submoduleReport?: Maybe<SubmoduleReportEntityResponse>
  submoduleReports?: Maybe<SubmoduleReportEntityResponseCollection>
  surveyReport?: Maybe<SurveyReportEntityResponse>
  surveyReports?: Maybe<SurveyReportEntityResponseCollection>
  translation?: Maybe<TranslationEntityResponse>
  typeFormCollection?: Maybe<TypeFormCollectionEntityResponse>
  typeFormCollections?: Maybe<TypeFormCollectionEntityResponseCollection>
  uploadedReport?: Maybe<UploadedReportEntityResponse>
  uploadedReports?: Maybe<UploadedReportEntityResponseCollection>
  userUploadedDocument?: Maybe<UserUploadedDocumentEntityResponse>
  userUploadedDocuments?: Maybe<UserUploadedDocumentEntityResponseCollection>
  me?: Maybe<UsersPermissionsMe>
}

export type QueryGetMyAppointmentsArgs = {
  appointmentScheduleId: Scalars['ID']
}

export type QueryCheckResponsesFromTypeFormProgrammeArgs = {
  id: Scalars['ID']
  sectionid?: Maybe<Scalars['Int']>
}

export type QueryGetSectionReportArgs = {
  id: Scalars['ID']
}

export type QueryGetAllsectionReportArgs = {
  id: Scalars['ID']
}

export type QueryCheckResponsesFromTypeFormArgs = {
  id: Scalars['ID']
  sectionid?: Maybe<Scalars['Int']>
}

export type QueryGetOrderedSubmissionsArgs = {
  programme: Scalars['ID']
}

export type QueryUploadFileArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryUploadFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryUploadFolderArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryUploadFoldersArgs = {
  filters?: Maybe<UploadFolderFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryI18NLocaleArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryI18NLocalesArgs = {
  filters?: Maybe<I18NLocaleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryUsersPermissionsRoleArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryUsersPermissionsRolesArgs = {
  filters?: Maybe<UsersPermissionsRoleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryUsersPermissionsUserArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryUsersPermissionsUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryAccountChangeArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryAccountChangesArgs = {
  filters?: Maybe<AccountChangeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryAppointmentArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryAppointmentsArgs = {
  filters?: Maybe<AppointmentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryArticleArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryArticlesArgs = {
  filters?: Maybe<ArticleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryAuditTrailArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryAuditTrailsArgs = {
  filters?: Maybe<AuditTrailFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryBookingArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryBookingsArgs = {
  filters?: Maybe<BookingFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryCoachArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryCoachesArgs = {
  filters?: Maybe<CoachFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryCoachSessionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryCoachSessionsArgs = {
  filters?: Maybe<CoachSessionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryCoachSubmittedReportArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryCoachSubmittedReportsArgs = {
  filters?: Maybe<CoachSubmittedReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryCompanyArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryCompetencyUrlArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryCompetencyUrlsArgs = {
  filters?: Maybe<CompetencyUrlFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryDivisionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryDivisionsArgs = {
  filters?: Maybe<DivisionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryEmailLogArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryEmailLogsArgs = {
  filters?: Maybe<EmailLogFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryEmailTemplateArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryEmailTemplatesArgs = {
  filters?: Maybe<EmailTemplateFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryEventArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryEventsArgs = {
  filters?: Maybe<EventFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryFeedfowardUrlArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryFeedfowardUrlsArgs = {
  filters?: Maybe<FeedfowardUrlFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryForwardedCardArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryForwardedCardsArgs = {
  filters?: Maybe<ForwardedCardFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryInviteArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryInvitesArgs = {
  filters?: Maybe<InviteFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryLanguageArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryLanguagesArgs = {
  filters?: Maybe<LanguageFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryLogoArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryLogosArgs = {
  filters?: Maybe<LogoFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryModalArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryModalsArgs = {
  filters?: Maybe<ModalFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryModuleArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryModulesArgs = {
  filters?: Maybe<ModuleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryNoteArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryNotesArgs = {
  filters?: Maybe<NoteFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryNotificationArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryNotificationsArgs = {
  filters?: Maybe<NotificationFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryParticipantArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryParticipantsArgs = {
  filters?: Maybe<ParticipantFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryPostCardArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryPostCardsArgs = {
  filters?: Maybe<PostCardFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryPostCardImageArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryPostCardImagesArgs = {
  filters?: Maybe<PostCardImageFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryPostCardSentenceArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryPostCardSentencesArgs = {
  filters?: Maybe<PostCardSentenceFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryProgrammeArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryProgrammesArgs = {
  filters?: Maybe<ProgrammeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryProgrammeReportArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryProgrammeReportsArgs = {
  filters?: Maybe<ProgrammeReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryProgrammeSubmissionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryProgrammeSubmissionsArgs = {
  filters?: Maybe<ProgrammeSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryProgrammeTypeArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryProgrammeTypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryRegionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryRegionsArgs = {
  filters?: Maybe<RegionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryReportArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryReportsArgs = {
  filters?: Maybe<ReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryRespondentsAnswerArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryRespondentsAnswersArgs = {
  filters?: Maybe<RespondentsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QuerySectionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QuerySectionsArgs = {
  filters?: Maybe<SectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QuerySubModuleArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QuerySubModulesArgs = {
  filters?: Maybe<SubModuleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QuerySubModuleSubmissionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QuerySubModuleSubmissionsArgs = {
  filters?: Maybe<SubModuleSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QuerySubmissionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QuerySubmissionsArgs = {
  filters?: Maybe<SubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QuerySubmoduleReportArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QuerySubmoduleReportsArgs = {
  filters?: Maybe<SubmoduleReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QuerySurveyReportArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QuerySurveyReportsArgs = {
  filters?: Maybe<SurveyReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryTypeFormCollectionArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryTypeFormCollectionsArgs = {
  filters?: Maybe<TypeFormCollectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryUploadedReportArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryUploadedReportsArgs = {
  filters?: Maybe<UploadedReportFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryUserUploadedDocumentArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryUserUploadedDocumentsArgs = {
  filters?: Maybe<UserUploadedDocumentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Mutation = {
  __typename?: 'Mutation'
  confirmMyAppointments: ConfirmAppointmentResponse
  addNote: Notes
  deletedNote?: Maybe<Notes>
  updateMyProgrammeSubmissions: ProgrammeSubmission
  generateMyProgrammeSubmissionResponses?: Maybe<ProgrammeSubmissionResponse>
  generateCompetencyResponses?: Maybe<ProgrammeSubmissionResponse>
  resendProgrammeMail?: Maybe<EmailSentProgramme>
  updateMySubModuleSubmission: SubModuleSubmission
  generateMySubModuleSubmissionResponses?: Maybe<SubModuleSubmissionResponse>
  resendMail?: Maybe<EmailSent>
  generateMySubModuleSubmissionComptencyResponses?: Maybe<SubModuleSubmissionResponse>
  generateMyMiniSectionSubmissionResponse?: Maybe<SubModuleSubmissionResponse>
  updateMySubmission: Submission
  addDocument: UserUploadedDocument
  updateSelf: UsersPermissionsUser
  createUploadFile?: Maybe<UploadFileEntityResponse>
  updateUploadFile?: Maybe<UploadFileEntityResponse>
  deleteUploadFile?: Maybe<UploadFileEntityResponse>
  createUploadFolder?: Maybe<UploadFolderEntityResponse>
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>
  createAccountChange?: Maybe<AccountChangeEntityResponse>
  updateAccountChange?: Maybe<AccountChangeEntityResponse>
  deleteAccountChange?: Maybe<AccountChangeEntityResponse>
  createAppointment?: Maybe<AppointmentEntityResponse>
  updateAppointment?: Maybe<AppointmentEntityResponse>
  deleteAppointment?: Maybe<AppointmentEntityResponse>
  createArticle?: Maybe<ArticleEntityResponse>
  updateArticle?: Maybe<ArticleEntityResponse>
  deleteArticle?: Maybe<ArticleEntityResponse>
  createAuditTrail?: Maybe<AuditTrailEntityResponse>
  updateAuditTrail?: Maybe<AuditTrailEntityResponse>
  deleteAuditTrail?: Maybe<AuditTrailEntityResponse>
  createBooking?: Maybe<BookingEntityResponse>
  updateBooking?: Maybe<BookingEntityResponse>
  deleteBooking?: Maybe<BookingEntityResponse>
  createCoach?: Maybe<CoachEntityResponse>
  updateCoach?: Maybe<CoachEntityResponse>
  deleteCoach?: Maybe<CoachEntityResponse>
  createCoachSession?: Maybe<CoachSessionEntityResponse>
  updateCoachSession?: Maybe<CoachSessionEntityResponse>
  deleteCoachSession?: Maybe<CoachSessionEntityResponse>
  createCoachSubmittedReport?: Maybe<CoachSubmittedReportEntityResponse>
  updateCoachSubmittedReport?: Maybe<CoachSubmittedReportEntityResponse>
  deleteCoachSubmittedReport?: Maybe<CoachSubmittedReportEntityResponse>
  createCompany?: Maybe<CompanyEntityResponse>
  updateCompany?: Maybe<CompanyEntityResponse>
  deleteCompany?: Maybe<CompanyEntityResponse>
  createCompetencyUrl?: Maybe<CompetencyUrlEntityResponse>
  updateCompetencyUrl?: Maybe<CompetencyUrlEntityResponse>
  deleteCompetencyUrl?: Maybe<CompetencyUrlEntityResponse>
  createDivision?: Maybe<DivisionEntityResponse>
  updateDivision?: Maybe<DivisionEntityResponse>
  deleteDivision?: Maybe<DivisionEntityResponse>
  createEmailLog?: Maybe<EmailLogEntityResponse>
  updateEmailLog?: Maybe<EmailLogEntityResponse>
  deleteEmailLog?: Maybe<EmailLogEntityResponse>
  createEmailTemplate?: Maybe<EmailTemplateEntityResponse>
  updateEmailTemplate?: Maybe<EmailTemplateEntityResponse>
  deleteEmailTemplate?: Maybe<EmailTemplateEntityResponse>
  createEvent?: Maybe<EventEntityResponse>
  updateEvent?: Maybe<EventEntityResponse>
  deleteEvent?: Maybe<EventEntityResponse>
  createFeedfowardUrl?: Maybe<FeedfowardUrlEntityResponse>
  updateFeedfowardUrl?: Maybe<FeedfowardUrlEntityResponse>
  deleteFeedfowardUrl?: Maybe<FeedfowardUrlEntityResponse>
  createForwardedCard?: Maybe<ForwardedCardEntityResponse>
  updateForwardedCard?: Maybe<ForwardedCardEntityResponse>
  deleteForwardedCard?: Maybe<ForwardedCardEntityResponse>
  createInvite?: Maybe<InviteEntityResponse>
  updateInvite?: Maybe<InviteEntityResponse>
  deleteInvite?: Maybe<InviteEntityResponse>
  createLanguage?: Maybe<LanguageEntityResponse>
  updateLanguage?: Maybe<LanguageEntityResponse>
  deleteLanguage?: Maybe<LanguageEntityResponse>
  createLogo?: Maybe<LogoEntityResponse>
  updateLogo?: Maybe<LogoEntityResponse>
  deleteLogo?: Maybe<LogoEntityResponse>
  createModal?: Maybe<ModalEntityResponse>
  updateModal?: Maybe<ModalEntityResponse>
  deleteModal?: Maybe<ModalEntityResponse>
  createModule?: Maybe<ModuleEntityResponse>
  updateModule?: Maybe<ModuleEntityResponse>
  deleteModule?: Maybe<ModuleEntityResponse>
  createNote?: Maybe<NoteEntityResponse>
  updateNote?: Maybe<NoteEntityResponse>
  deleteNote?: Maybe<NoteEntityResponse>
  createNotification?: Maybe<NotificationEntityResponse>
  updateNotification?: Maybe<NotificationEntityResponse>
  deleteNotification?: Maybe<NotificationEntityResponse>
  createParticipant?: Maybe<ParticipantEntityResponse>
  updateParticipant?: Maybe<ParticipantEntityResponse>
  deleteParticipant?: Maybe<ParticipantEntityResponse>
  createPostCard?: Maybe<PostCardEntityResponse>
  updatePostCard?: Maybe<PostCardEntityResponse>
  deletePostCard?: Maybe<PostCardEntityResponse>
  createPostCardImage?: Maybe<PostCardImageEntityResponse>
  updatePostCardImage?: Maybe<PostCardImageEntityResponse>
  deletePostCardImage?: Maybe<PostCardImageEntityResponse>
  createPostCardSentence?: Maybe<PostCardSentenceEntityResponse>
  updatePostCardSentence?: Maybe<PostCardSentenceEntityResponse>
  deletePostCardSentence?: Maybe<PostCardSentenceEntityResponse>
  createProgramme?: Maybe<ProgrammeEntityResponse>
  updateProgramme?: Maybe<ProgrammeEntityResponse>
  deleteProgramme?: Maybe<ProgrammeEntityResponse>
  createProgrammeReport?: Maybe<ProgrammeReportEntityResponse>
  updateProgrammeReport?: Maybe<ProgrammeReportEntityResponse>
  deleteProgrammeReport?: Maybe<ProgrammeReportEntityResponse>
  createProgrammeSubmission?: Maybe<ProgrammeSubmissionEntityResponse>
  updateProgrammeSubmission?: Maybe<ProgrammeSubmissionEntityResponse>
  deleteProgrammeSubmission?: Maybe<ProgrammeSubmissionEntityResponse>
  createProgrammeType?: Maybe<ProgrammeTypeEntityResponse>
  updateProgrammeType?: Maybe<ProgrammeTypeEntityResponse>
  deleteProgrammeType?: Maybe<ProgrammeTypeEntityResponse>
  createRegion?: Maybe<RegionEntityResponse>
  updateRegion?: Maybe<RegionEntityResponse>
  deleteRegion?: Maybe<RegionEntityResponse>
  createReport?: Maybe<ReportEntityResponse>
  updateReport?: Maybe<ReportEntityResponse>
  deleteReport?: Maybe<ReportEntityResponse>
  createRespondentsAnswer?: Maybe<RespondentsAnswerEntityResponse>
  updateRespondentsAnswer?: Maybe<RespondentsAnswerEntityResponse>
  deleteRespondentsAnswer?: Maybe<RespondentsAnswerEntityResponse>
  createSection?: Maybe<SectionEntityResponse>
  updateSection?: Maybe<SectionEntityResponse>
  deleteSection?: Maybe<SectionEntityResponse>
  createSubModule?: Maybe<SubModuleEntityResponse>
  updateSubModule?: Maybe<SubModuleEntityResponse>
  deleteSubModule?: Maybe<SubModuleEntityResponse>
  createSubModuleSubmission?: Maybe<SubModuleSubmissionEntityResponse>
  updateSubModuleSubmission?: Maybe<SubModuleSubmissionEntityResponse>
  deleteSubModuleSubmission?: Maybe<SubModuleSubmissionEntityResponse>
  createSubmission?: Maybe<SubmissionEntityResponse>
  updateSubmission?: Maybe<SubmissionEntityResponse>
  deleteSubmission?: Maybe<SubmissionEntityResponse>
  createSubmoduleReport?: Maybe<SubmoduleReportEntityResponse>
  updateSubmoduleReport?: Maybe<SubmoduleReportEntityResponse>
  deleteSubmoduleReport?: Maybe<SubmoduleReportEntityResponse>
  createSurveyReport?: Maybe<SurveyReportEntityResponse>
  updateSurveyReport?: Maybe<SurveyReportEntityResponse>
  deleteSurveyReport?: Maybe<SurveyReportEntityResponse>
  updateTranslation?: Maybe<TranslationEntityResponse>
  deleteTranslation?: Maybe<TranslationEntityResponse>
  createTypeFormCollection?: Maybe<TypeFormCollectionEntityResponse>
  updateTypeFormCollection?: Maybe<TypeFormCollectionEntityResponse>
  deleteTypeFormCollection?: Maybe<TypeFormCollectionEntityResponse>
  createUploadedReport?: Maybe<UploadedReportEntityResponse>
  updateUploadedReport?: Maybe<UploadedReportEntityResponse>
  deleteUploadedReport?: Maybe<UploadedReportEntityResponse>
  createUserUploadedDocument?: Maybe<UserUploadedDocumentEntityResponse>
  updateUserUploadedDocument?: Maybe<UserUploadedDocumentEntityResponse>
  deleteUserUploadedDocument?: Maybe<UserUploadedDocumentEntityResponse>
  upload: UploadFileEntityResponse
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>
  updateFileInfo: UploadFileEntityResponse
  removeFile?: Maybe<UploadFileEntityResponse>
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse
  login: UsersPermissionsLoginPayload
  /** Register a user */
  register: UsersPermissionsLoginPayload
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>
}

export type MutationConfirmMyAppointmentsArgs = {
  appointmentScheduleId: Scalars['ID']
  programmeSubmissionId: Scalars['ID']
  subModule: Scalars['Boolean']
}

export type MutationAddNoteArgs = {
  input: EditNoteInput
}

export type MutationDeletedNoteArgs = {
  id: Scalars['ID']
}

export type MutationUpdateMyProgrammeSubmissionsArgs = {
  id: Scalars['ID']
  isPdf?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  input: EditProgrammeSubmissionInput
}

export type MutationGenerateMyProgrammeSubmissionResponsesArgs = {
  id: Scalars['ID']
}

export type MutationGenerateCompetencyResponsesArgs = {
  id: Scalars['ID']
}

export type MutationResendProgrammeMailArgs = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type MutationUpdateMySubModuleSubmissionArgs = {
  id: Scalars['ID']
  currentSection?: Maybe<Scalars['Int']>
  isPdf?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  programmeSubmissionId?: Maybe<Scalars['ID']>
  input: EditSubModuleSubmissionInput
}

export type MutationGenerateMySubModuleSubmissionResponsesArgs = {
  id: Scalars['ID']
  sectionId?: Maybe<Scalars['Int']>
}

export type MutationResendMailArgs = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type MutationGenerateMySubModuleSubmissionComptencyResponsesArgs = {
  id: Scalars['ID']
  sectionId?: Maybe<Scalars['Int']>
}

export type MutationGenerateMyMiniSectionSubmissionResponseArgs = {
  id: Scalars['ID']
  sectionId?: Maybe<Scalars['Int']>
  secId?: Maybe<Scalars['Int']>
}

export type MutationUpdateMySubmissionArgs = {
  id: Scalars['ID']
  currentSection?: Maybe<Scalars['Int']>
  programmeSubmissionId?: Maybe<Scalars['ID']>
  input: EditSubmissionInput
}

export type MutationAddDocumentArgs = {
  input: EditUserUploadedDocumentInput
  editFlag?: Maybe<Scalars['Boolean']>
}

export type MutationUpdateSelfArgs = {
  user: EditUserInput
}

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput
}

export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID']
  data: UploadFileInput
}

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']
}

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput
}

export type MutationUpdateUploadFolderArgs = {
  id: Scalars['ID']
  data: UploadFolderInput
}

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']
}

export type MutationCreateAccountChangeArgs = {
  data: AccountChangeInput
}

export type MutationUpdateAccountChangeArgs = {
  id: Scalars['ID']
  data: AccountChangeInput
}

export type MutationDeleteAccountChangeArgs = {
  id: Scalars['ID']
}

export type MutationCreateAppointmentArgs = {
  data: AppointmentInput
}

export type MutationUpdateAppointmentArgs = {
  id: Scalars['ID']
  data: AppointmentInput
}

export type MutationDeleteAppointmentArgs = {
  id: Scalars['ID']
}

export type MutationCreateArticleArgs = {
  data: ArticleInput
}

export type MutationUpdateArticleArgs = {
  id: Scalars['ID']
  data: ArticleInput
}

export type MutationDeleteArticleArgs = {
  id: Scalars['ID']
}

export type MutationCreateAuditTrailArgs = {
  data: AuditTrailInput
}

export type MutationUpdateAuditTrailArgs = {
  id: Scalars['ID']
  data: AuditTrailInput
}

export type MutationDeleteAuditTrailArgs = {
  id: Scalars['ID']
}

export type MutationCreateBookingArgs = {
  data: BookingInput
}

export type MutationUpdateBookingArgs = {
  id: Scalars['ID']
  data: BookingInput
}

export type MutationDeleteBookingArgs = {
  id: Scalars['ID']
}

export type MutationCreateCoachArgs = {
  data: CoachInput
}

export type MutationUpdateCoachArgs = {
  id: Scalars['ID']
  data: CoachInput
}

export type MutationDeleteCoachArgs = {
  id: Scalars['ID']
}

export type MutationCreateCoachSessionArgs = {
  data: CoachSessionInput
}

export type MutationUpdateCoachSessionArgs = {
  id: Scalars['ID']
  data: CoachSessionInput
}

export type MutationDeleteCoachSessionArgs = {
  id: Scalars['ID']
}

export type MutationCreateCoachSubmittedReportArgs = {
  data: CoachSubmittedReportInput
}

export type MutationUpdateCoachSubmittedReportArgs = {
  id: Scalars['ID']
  data: CoachSubmittedReportInput
}

export type MutationDeleteCoachSubmittedReportArgs = {
  id: Scalars['ID']
}

export type MutationCreateCompanyArgs = {
  data: CompanyInput
}

export type MutationUpdateCompanyArgs = {
  id: Scalars['ID']
  data: CompanyInput
}

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']
}

export type MutationCreateCompetencyUrlArgs = {
  data: CompetencyUrlInput
}

export type MutationUpdateCompetencyUrlArgs = {
  id: Scalars['ID']
  data: CompetencyUrlInput
}

export type MutationDeleteCompetencyUrlArgs = {
  id: Scalars['ID']
}

export type MutationCreateDivisionArgs = {
  data: DivisionInput
}

export type MutationUpdateDivisionArgs = {
  id: Scalars['ID']
  data: DivisionInput
}

export type MutationDeleteDivisionArgs = {
  id: Scalars['ID']
}

export type MutationCreateEmailLogArgs = {
  data: EmailLogInput
}

export type MutationUpdateEmailLogArgs = {
  id: Scalars['ID']
  data: EmailLogInput
}

export type MutationDeleteEmailLogArgs = {
  id: Scalars['ID']
}

export type MutationCreateEmailTemplateArgs = {
  data: EmailTemplateInput
}

export type MutationUpdateEmailTemplateArgs = {
  id: Scalars['ID']
  data: EmailTemplateInput
}

export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['ID']
}

export type MutationCreateEventArgs = {
  data: EventInput
}

export type MutationUpdateEventArgs = {
  id: Scalars['ID']
  data: EventInput
}

export type MutationDeleteEventArgs = {
  id: Scalars['ID']
}

export type MutationCreateFeedfowardUrlArgs = {
  data: FeedfowardUrlInput
}

export type MutationUpdateFeedfowardUrlArgs = {
  id: Scalars['ID']
  data: FeedfowardUrlInput
}

export type MutationDeleteFeedfowardUrlArgs = {
  id: Scalars['ID']
}

export type MutationCreateForwardedCardArgs = {
  data: ForwardedCardInput
}

export type MutationUpdateForwardedCardArgs = {
  id: Scalars['ID']
  data: ForwardedCardInput
}

export type MutationDeleteForwardedCardArgs = {
  id: Scalars['ID']
}

export type MutationCreateInviteArgs = {
  data: InviteInput
}

export type MutationUpdateInviteArgs = {
  id: Scalars['ID']
  data: InviteInput
}

export type MutationDeleteInviteArgs = {
  id: Scalars['ID']
}

export type MutationCreateLanguageArgs = {
  data: LanguageInput
}

export type MutationUpdateLanguageArgs = {
  id: Scalars['ID']
  data: LanguageInput
}

export type MutationDeleteLanguageArgs = {
  id: Scalars['ID']
}

export type MutationCreateLogoArgs = {
  data: LogoInput
}

export type MutationUpdateLogoArgs = {
  id: Scalars['ID']
  data: LogoInput
}

export type MutationDeleteLogoArgs = {
  id: Scalars['ID']
}

export type MutationCreateModalArgs = {
  data: ModalInput
}

export type MutationUpdateModalArgs = {
  id: Scalars['ID']
  data: ModalInput
}

export type MutationDeleteModalArgs = {
  id: Scalars['ID']
}

export type MutationCreateModuleArgs = {
  data: ModuleInput
}

export type MutationUpdateModuleArgs = {
  id: Scalars['ID']
  data: ModuleInput
}

export type MutationDeleteModuleArgs = {
  id: Scalars['ID']
}

export type MutationCreateNoteArgs = {
  data: NoteInput
}

export type MutationUpdateNoteArgs = {
  id: Scalars['ID']
  data: NoteInput
}

export type MutationDeleteNoteArgs = {
  id: Scalars['ID']
}

export type MutationCreateNotificationArgs = {
  data: NotificationInput
}

export type MutationUpdateNotificationArgs = {
  id: Scalars['ID']
  data: NotificationInput
}

export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']
}

export type MutationCreateParticipantArgs = {
  data: ParticipantInput
}

export type MutationUpdateParticipantArgs = {
  id: Scalars['ID']
  data: ParticipantInput
}

export type MutationDeleteParticipantArgs = {
  id: Scalars['ID']
}

export type MutationCreatePostCardArgs = {
  data: PostCardInput
}

export type MutationUpdatePostCardArgs = {
  id: Scalars['ID']
  data: PostCardInput
}

export type MutationDeletePostCardArgs = {
  id: Scalars['ID']
}

export type MutationCreatePostCardImageArgs = {
  data: PostCardImageInput
}

export type MutationUpdatePostCardImageArgs = {
  id: Scalars['ID']
  data: PostCardImageInput
}

export type MutationDeletePostCardImageArgs = {
  id: Scalars['ID']
}

export type MutationCreatePostCardSentenceArgs = {
  data: PostCardSentenceInput
}

export type MutationUpdatePostCardSentenceArgs = {
  id: Scalars['ID']
  data: PostCardSentenceInput
}

export type MutationDeletePostCardSentenceArgs = {
  id: Scalars['ID']
}

export type MutationCreateProgrammeArgs = {
  data: ProgrammeInput
}

export type MutationUpdateProgrammeArgs = {
  id: Scalars['ID']
  data: ProgrammeInput
}

export type MutationDeleteProgrammeArgs = {
  id: Scalars['ID']
}

export type MutationCreateProgrammeReportArgs = {
  data: ProgrammeReportInput
}

export type MutationUpdateProgrammeReportArgs = {
  id: Scalars['ID']
  data: ProgrammeReportInput
}

export type MutationDeleteProgrammeReportArgs = {
  id: Scalars['ID']
}

export type MutationCreateProgrammeSubmissionArgs = {
  data: ProgrammeSubmissionInput
}

export type MutationUpdateProgrammeSubmissionArgs = {
  id: Scalars['ID']
  data: ProgrammeSubmissionInput
}

export type MutationDeleteProgrammeSubmissionArgs = {
  id: Scalars['ID']
}

export type MutationCreateProgrammeTypeArgs = {
  data: ProgrammeTypeInput
}

export type MutationUpdateProgrammeTypeArgs = {
  id: Scalars['ID']
  data: ProgrammeTypeInput
}

export type MutationDeleteProgrammeTypeArgs = {
  id: Scalars['ID']
}

export type MutationCreateRegionArgs = {
  data: RegionInput
}

export type MutationUpdateRegionArgs = {
  id: Scalars['ID']
  data: RegionInput
}

export type MutationDeleteRegionArgs = {
  id: Scalars['ID']
}

export type MutationCreateReportArgs = {
  data: ReportInput
}

export type MutationUpdateReportArgs = {
  id: Scalars['ID']
  data: ReportInput
}

export type MutationDeleteReportArgs = {
  id: Scalars['ID']
}

export type MutationCreateRespondentsAnswerArgs = {
  data: RespondentsAnswerInput
}

export type MutationUpdateRespondentsAnswerArgs = {
  id: Scalars['ID']
  data: RespondentsAnswerInput
}

export type MutationDeleteRespondentsAnswerArgs = {
  id: Scalars['ID']
}

export type MutationCreateSectionArgs = {
  data: SectionInput
}

export type MutationUpdateSectionArgs = {
  id: Scalars['ID']
  data: SectionInput
}

export type MutationDeleteSectionArgs = {
  id: Scalars['ID']
}

export type MutationCreateSubModuleArgs = {
  data: SubModuleInput
}

export type MutationUpdateSubModuleArgs = {
  id: Scalars['ID']
  data: SubModuleInput
}

export type MutationDeleteSubModuleArgs = {
  id: Scalars['ID']
}

export type MutationCreateSubModuleSubmissionArgs = {
  data: SubModuleSubmissionInput
}

export type MutationUpdateSubModuleSubmissionArgs = {
  id: Scalars['ID']
  data: SubModuleSubmissionInput
}

export type MutationDeleteSubModuleSubmissionArgs = {
  id: Scalars['ID']
}

export type MutationCreateSubmissionArgs = {
  data: SubmissionInput
}

export type MutationUpdateSubmissionArgs = {
  id: Scalars['ID']
  data: SubmissionInput
}

export type MutationDeleteSubmissionArgs = {
  id: Scalars['ID']
}

export type MutationCreateSubmoduleReportArgs = {
  data: SubmoduleReportInput
}

export type MutationUpdateSubmoduleReportArgs = {
  id: Scalars['ID']
  data: SubmoduleReportInput
}

export type MutationDeleteSubmoduleReportArgs = {
  id: Scalars['ID']
}

export type MutationCreateSurveyReportArgs = {
  data: SurveyReportInput
}

export type MutationUpdateSurveyReportArgs = {
  id: Scalars['ID']
  data: SurveyReportInput
}

export type MutationDeleteSurveyReportArgs = {
  id: Scalars['ID']
}

export type MutationUpdateTranslationArgs = {
  data: TranslationInput
}

export type MutationCreateTypeFormCollectionArgs = {
  data: TypeFormCollectionInput
}

export type MutationUpdateTypeFormCollectionArgs = {
  id: Scalars['ID']
  data: TypeFormCollectionInput
}

export type MutationDeleteTypeFormCollectionArgs = {
  id: Scalars['ID']
}

export type MutationCreateUploadedReportArgs = {
  data: UploadedReportInput
}

export type MutationUpdateUploadedReportArgs = {
  id: Scalars['ID']
  data: UploadedReportInput
}

export type MutationDeleteUploadedReportArgs = {
  id: Scalars['ID']
}

export type MutationCreateUserUploadedDocumentArgs = {
  data: UserUploadedDocumentInput
}

export type MutationUpdateUserUploadedDocumentArgs = {
  id: Scalars['ID']
  data: UserUploadedDocumentInput
}

export type MutationDeleteUserUploadedDocumentArgs = {
  id: Scalars['ID']
}

export type MutationUploadArgs = {
  refId?: Maybe<Scalars['ID']>
  ref?: Maybe<Scalars['String']>
  field?: Maybe<Scalars['String']>
  info?: Maybe<FileInfoInput>
  file: Scalars['Upload']
}

export type MutationMultipleUploadArgs = {
  refId?: Maybe<Scalars['ID']>
  ref?: Maybe<Scalars['String']>
  field?: Maybe<Scalars['String']>
  files: Array<Maybe<Scalars['Upload']>>
}

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']
  info?: Maybe<FileInfoInput>
}

export type MutationRemoveFileArgs = {
  id: Scalars['ID']
}

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput
}

export type MutationUpdateUsersPermissionsRoleArgs = {
  id: Scalars['ID']
  data: UsersPermissionsRoleInput
}

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']
}

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput
}

export type MutationUpdateUsersPermissionsUserArgs = {
  id: Scalars['ID']
  data: UsersPermissionsUserInput
}

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']
}

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput
}

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationResetPasswordArgs = {
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
  code: Scalars['String']
}

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']
}

export type Error = {
  __typename?: 'Error'
  code: Scalars['String']
  message?: Maybe<Scalars['String']>
}

export type Pagination = {
  __typename?: 'Pagination'
  total: Scalars['Int']
  page: Scalars['Int']
  pageSize: Scalars['Int']
  pageCount: Scalars['Int']
}

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta'
  pagination: Pagination
}

export type IdFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['ID']>>>
  or?: Maybe<Array<Maybe<Scalars['ID']>>>
  not?: Maybe<IdFilterInput>
  eq?: Maybe<Scalars['ID']>
  eqi?: Maybe<Scalars['ID']>
  ne?: Maybe<Scalars['ID']>
  nei?: Maybe<Scalars['ID']>
  startsWith?: Maybe<Scalars['ID']>
  endsWith?: Maybe<Scalars['ID']>
  contains?: Maybe<Scalars['ID']>
  notContains?: Maybe<Scalars['ID']>
  containsi?: Maybe<Scalars['ID']>
  notContainsi?: Maybe<Scalars['ID']>
  gt?: Maybe<Scalars['ID']>
  gte?: Maybe<Scalars['ID']>
  lt?: Maybe<Scalars['ID']>
  lte?: Maybe<Scalars['ID']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['ID']>>>
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>
  between?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type BooleanFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  or?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  not?: Maybe<BooleanFilterInput>
  eq?: Maybe<Scalars['Boolean']>
  eqi?: Maybe<Scalars['Boolean']>
  ne?: Maybe<Scalars['Boolean']>
  nei?: Maybe<Scalars['Boolean']>
  startsWith?: Maybe<Scalars['Boolean']>
  endsWith?: Maybe<Scalars['Boolean']>
  contains?: Maybe<Scalars['Boolean']>
  notContains?: Maybe<Scalars['Boolean']>
  containsi?: Maybe<Scalars['Boolean']>
  notContainsi?: Maybe<Scalars['Boolean']>
  gt?: Maybe<Scalars['Boolean']>
  gte?: Maybe<Scalars['Boolean']>
  lt?: Maybe<Scalars['Boolean']>
  lte?: Maybe<Scalars['Boolean']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  notIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>
  between?: Maybe<Array<Maybe<Scalars['Boolean']>>>
}

export type StringFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['String']>>>
  or?: Maybe<Array<Maybe<Scalars['String']>>>
  not?: Maybe<StringFilterInput>
  eq?: Maybe<Scalars['String']>
  eqi?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  nei?: Maybe<Scalars['String']>
  startsWith?: Maybe<Scalars['String']>
  endsWith?: Maybe<Scalars['String']>
  contains?: Maybe<Scalars['String']>
  notContains?: Maybe<Scalars['String']>
  containsi?: Maybe<Scalars['String']>
  notContainsi?: Maybe<Scalars['String']>
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>
  between?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type IntFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Int']>>>
  or?: Maybe<Array<Maybe<Scalars['Int']>>>
  not?: Maybe<IntFilterInput>
  eq?: Maybe<Scalars['Int']>
  eqi?: Maybe<Scalars['Int']>
  ne?: Maybe<Scalars['Int']>
  nei?: Maybe<Scalars['Int']>
  startsWith?: Maybe<Scalars['Int']>
  endsWith?: Maybe<Scalars['Int']>
  contains?: Maybe<Scalars['Int']>
  notContains?: Maybe<Scalars['Int']>
  containsi?: Maybe<Scalars['Int']>
  notContainsi?: Maybe<Scalars['Int']>
  gt?: Maybe<Scalars['Int']>
  gte?: Maybe<Scalars['Int']>
  lt?: Maybe<Scalars['Int']>
  lte?: Maybe<Scalars['Int']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Int']>>>
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>
  between?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type LongFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Long']>>>
  or?: Maybe<Array<Maybe<Scalars['Long']>>>
  not?: Maybe<LongFilterInput>
  eq?: Maybe<Scalars['Long']>
  eqi?: Maybe<Scalars['Long']>
  ne?: Maybe<Scalars['Long']>
  nei?: Maybe<Scalars['Long']>
  startsWith?: Maybe<Scalars['Long']>
  endsWith?: Maybe<Scalars['Long']>
  contains?: Maybe<Scalars['Long']>
  notContains?: Maybe<Scalars['Long']>
  containsi?: Maybe<Scalars['Long']>
  notContainsi?: Maybe<Scalars['Long']>
  gt?: Maybe<Scalars['Long']>
  gte?: Maybe<Scalars['Long']>
  lt?: Maybe<Scalars['Long']>
  lte?: Maybe<Scalars['Long']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Long']>>>
  notIn?: Maybe<Array<Maybe<Scalars['Long']>>>
  between?: Maybe<Array<Maybe<Scalars['Long']>>>
}

export type FloatFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Float']>>>
  or?: Maybe<Array<Maybe<Scalars['Float']>>>
  not?: Maybe<FloatFilterInput>
  eq?: Maybe<Scalars['Float']>
  eqi?: Maybe<Scalars['Float']>
  ne?: Maybe<Scalars['Float']>
  nei?: Maybe<Scalars['Float']>
  startsWith?: Maybe<Scalars['Float']>
  endsWith?: Maybe<Scalars['Float']>
  contains?: Maybe<Scalars['Float']>
  notContains?: Maybe<Scalars['Float']>
  containsi?: Maybe<Scalars['Float']>
  notContainsi?: Maybe<Scalars['Float']>
  gt?: Maybe<Scalars['Float']>
  gte?: Maybe<Scalars['Float']>
  lt?: Maybe<Scalars['Float']>
  lte?: Maybe<Scalars['Float']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Float']>>>
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>
  between?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type DateFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Date']>>>
  or?: Maybe<Array<Maybe<Scalars['Date']>>>
  not?: Maybe<DateFilterInput>
  eq?: Maybe<Scalars['Date']>
  eqi?: Maybe<Scalars['Date']>
  ne?: Maybe<Scalars['Date']>
  nei?: Maybe<Scalars['Date']>
  startsWith?: Maybe<Scalars['Date']>
  endsWith?: Maybe<Scalars['Date']>
  contains?: Maybe<Scalars['Date']>
  notContains?: Maybe<Scalars['Date']>
  containsi?: Maybe<Scalars['Date']>
  notContainsi?: Maybe<Scalars['Date']>
  gt?: Maybe<Scalars['Date']>
  gte?: Maybe<Scalars['Date']>
  lt?: Maybe<Scalars['Date']>
  lte?: Maybe<Scalars['Date']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['Date']>>>
  notIn?: Maybe<Array<Maybe<Scalars['Date']>>>
  between?: Maybe<Array<Maybe<Scalars['Date']>>>
}

export type DateTimeFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  or?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  not?: Maybe<DateTimeFilterInput>
  eq?: Maybe<Scalars['DateTime']>
  eqi?: Maybe<Scalars['DateTime']>
  ne?: Maybe<Scalars['DateTime']>
  nei?: Maybe<Scalars['DateTime']>
  startsWith?: Maybe<Scalars['DateTime']>
  endsWith?: Maybe<Scalars['DateTime']>
  contains?: Maybe<Scalars['DateTime']>
  notContains?: Maybe<Scalars['DateTime']>
  containsi?: Maybe<Scalars['DateTime']>
  notContainsi?: Maybe<Scalars['DateTime']>
  gt?: Maybe<Scalars['DateTime']>
  gte?: Maybe<Scalars['DateTime']>
  lt?: Maybe<Scalars['DateTime']>
  lte?: Maybe<Scalars['DateTime']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  between?: Maybe<Array<Maybe<Scalars['DateTime']>>>
}

export type JsonFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['JSON']>>>
  or?: Maybe<Array<Maybe<Scalars['JSON']>>>
  not?: Maybe<JsonFilterInput>
  eq?: Maybe<Scalars['JSON']>
  eqi?: Maybe<Scalars['JSON']>
  ne?: Maybe<Scalars['JSON']>
  nei?: Maybe<Scalars['JSON']>
  startsWith?: Maybe<Scalars['JSON']>
  endsWith?: Maybe<Scalars['JSON']>
  contains?: Maybe<Scalars['JSON']>
  notContains?: Maybe<Scalars['JSON']>
  containsi?: Maybe<Scalars['JSON']>
  notContainsi?: Maybe<Scalars['JSON']>
  gt?: Maybe<Scalars['JSON']>
  gte?: Maybe<Scalars['JSON']>
  lt?: Maybe<Scalars['JSON']>
  lte?: Maybe<Scalars['JSON']>
  null?: Maybe<Scalars['Boolean']>
  notNull?: Maybe<Scalars['Boolean']>
  in?: Maybe<Array<Maybe<Scalars['JSON']>>>
  notIn?: Maybe<Array<Maybe<Scalars['JSON']>>>
  between?: Maybe<Array<Maybe<Scalars['JSON']>>>
}

export type ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput = {
  labelOne?: Maybe<StringFilterInput>
  labelTwo?: Maybe<StringFilterInput>
  answerOne?: Maybe<IntFilterInput>
  answerTwo?: Maybe<IntFilterInput>
  answerThree?: Maybe<IntFilterInput>
  question?: Maybe<StringFilterInput>
  labelThree?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>>>
  not?: Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>
}

export type ComponentGravitasScaleItemsGravitasScaleItemsInput = {
  id?: Maybe<Scalars['ID']>
  labelOne?: Maybe<Scalars['String']>
  labelTwo?: Maybe<Scalars['String']>
  answerOne?: Maybe<Scalars['Int']>
  answerTwo?: Maybe<Scalars['Int']>
  answerThree?: Maybe<Scalars['Int']>
  question?: Maybe<Scalars['String']>
  labelThree?: Maybe<Scalars['String']>
}

export type ComponentGravitasScaleItemsGravitasScaleItems = {
  __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems'
  id: Scalars['ID']
  labelOne?: Maybe<Scalars['String']>
  labelTwo?: Maybe<Scalars['String']>
  answerOne?: Maybe<Scalars['Int']>
  answerTwo?: Maybe<Scalars['Int']>
  answerThree?: Maybe<Scalars['Int']>
  question?: Maybe<Scalars['String']>
  labelThree?: Maybe<Scalars['String']>
}

export type ComponentOptionColorAndRatingFiltersInput = {
  color?: Maybe<StringFilterInput>
  rate?: Maybe<IntFilterInput>
  and?: Maybe<Array<Maybe<ComponentOptionColorAndRatingFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentOptionColorAndRatingFiltersInput>>>
  not?: Maybe<ComponentOptionColorAndRatingFiltersInput>
}

export type ComponentOptionColorAndRatingInput = {
  id?: Maybe<Scalars['ID']>
  color?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Int']>
}

export type ComponentOptionColorAndRating = {
  __typename?: 'ComponentOptionColorAndRating'
  id: Scalars['ID']
  color?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Int']>
}

export type ComponentOptionOptionFiltersInput = {
  optionEnglish?: Maybe<StringFilterInput>
  isCorrect?: Maybe<BooleanFilterInput>
  optionFrench?: Maybe<StringFilterInput>
  optionGerman?: Maybe<StringFilterInput>
  optionSpanish?: Maybe<StringFilterInput>
  optionMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentOptionOptionFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentOptionOptionFiltersInput>>>
  not?: Maybe<ComponentOptionOptionFiltersInput>
}

export type ComponentOptionOption = {
  __typename?: 'ComponentOptionOption'
  id: Scalars['ID']
  optionEnglish?: Maybe<Scalars['String']>
  isCorrect?: Maybe<Scalars['Boolean']>
  optionFrench?: Maybe<Scalars['String']>
  optionGerman?: Maybe<Scalars['String']>
  optionSpanish?: Maybe<Scalars['String']>
  optionMandarin?: Maybe<Scalars['String']>
}

export type ComponentOptionSliderHeadingsFiltersInput = {
  headingEnglish?: Maybe<StringFilterInput>
  headingMandarin?: Maybe<StringFilterInput>
  headingSpanish?: Maybe<StringFilterInput>
  headingFrench?: Maybe<StringFilterInput>
  headingGerman?: Maybe<StringFilterInput>
  color?: Maybe<StringFilterInput>
  rating?: Maybe<IntFilterInput>
  and?: Maybe<Array<Maybe<ComponentOptionSliderHeadingsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentOptionSliderHeadingsFiltersInput>>>
  not?: Maybe<ComponentOptionSliderHeadingsFiltersInput>
}

export type ComponentOptionSliderHeadings = {
  __typename?: 'ComponentOptionSliderHeadings'
  id: Scalars['ID']
  headingEnglish?: Maybe<Scalars['String']>
  headingMandarin?: Maybe<Scalars['String']>
  headingSpanish?: Maybe<Scalars['String']>
  headingFrench?: Maybe<Scalars['String']>
  headingGerman?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['Int']>
}

export type ComponentOptionSliderQuestionsFiltersInput = {
  questionPositiveEnglish?: Maybe<StringFilterInput>
  questionPositiveGerman?: Maybe<StringFilterInput>
  questionPositiveFrench?: Maybe<StringFilterInput>
  questionPositiveMandarin?: Maybe<StringFilterInput>
  questionPositiveSpanish?: Maybe<StringFilterInput>
  questionNegativeFrench?: Maybe<StringFilterInput>
  questionNegativeSpanish?: Maybe<StringFilterInput>
  questionNegativeEnglish?: Maybe<StringFilterInput>
  questionNegativeMandarin?: Maybe<StringFilterInput>
  questionNegativeGerman?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentOptionSliderQuestionsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentOptionSliderQuestionsFiltersInput>>>
  not?: Maybe<ComponentOptionSliderQuestionsFiltersInput>
}

export type ComponentOptionSliderQuestions = {
  __typename?: 'ComponentOptionSliderQuestions'
  id: Scalars['ID']
  questionPositiveEnglish?: Maybe<Scalars['String']>
  questionPositiveGerman?: Maybe<Scalars['String']>
  questionPositiveFrench?: Maybe<Scalars['String']>
  questionPositiveMandarin?: Maybe<Scalars['String']>
  questionPositiveSpanish?: Maybe<Scalars['String']>
  questionNegativeFrench?: Maybe<Scalars['String']>
  questionNegativeSpanish?: Maybe<Scalars['String']>
  questionNegativeEnglish?: Maybe<Scalars['String']>
  questionNegativeMandarin?: Maybe<Scalars['String']>
  questionNegativeGerman?: Maybe<Scalars['String']>
}

export type ComponentOptionTimezoneFiltersInput = {
  abbrev?: Maybe<StringFilterInput>
  altName?: Maybe<StringFilterInput>
  label?: Maybe<StringFilterInput>
  value?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentOptionTimezoneFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentOptionTimezoneFiltersInput>>>
  not?: Maybe<ComponentOptionTimezoneFiltersInput>
}

export type ComponentOptionTimezoneInput = {
  id?: Maybe<Scalars['ID']>
  abbrev?: Maybe<Scalars['String']>
  altName?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ComponentOptionTimezone = {
  __typename?: 'ComponentOptionTimezone'
  id: Scalars['ID']
  abbrev?: Maybe<Scalars['String']>
  altName?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesCompetency = {
  __typename?: 'ComponentQuestionTypesCompetency'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  minNumber?: Maybe<Scalars['Long']>
  maxNumber?: Maybe<Scalars['Long']>
  miniLabel?: Maybe<Scalars['String']>
  maxiLabel?: Maybe<Scalars['String']>
  simpleQuestion?: Maybe<Scalars['Boolean']>
}

export type ComponentQuestionTypesCompetency2 = {
  __typename?: 'ComponentQuestionTypesCompetency2'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  questionscompetency2?: Maybe<Array<Maybe<ComponentQuestionTypesQuestionscompetency2>>>
  titleFrench?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesCompetency2Questionscompetency2Args = {
  filters?: Maybe<ComponentQuestionTypesQuestionscompetency2FiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesDocumentsFiltersInput = {
  name?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentQuestionTypesDocumentsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentQuestionTypesDocumentsFiltersInput>>>
  not?: Maybe<ComponentQuestionTypesDocumentsFiltersInput>
}

export type ComponentQuestionTypesDocumentsInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  document?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type ComponentQuestionTypesDocuments = {
  __typename?: 'ComponentQuestionTypesDocuments'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  document?: Maybe<UploadFileRelationResponseCollection>
}

export type ComponentQuestionTypesDocumentsDocumentArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesFeedForward1 = {
  __typename?: 'ComponentQuestionTypesFeedForward1'
  id: Scalars['ID']
  description?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesFeedForward2 = {
  __typename?: 'ComponentQuestionTypesFeedForward2'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesGravitasGap = {
  __typename?: 'ComponentQuestionTypesGravitasGap'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  color1?: Maybe<Scalars['String']>
  color2?: Maybe<Scalars['String']>
  color3?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesGravitasScale = {
  __typename?: 'ComponentQuestionTypesGravitasScale'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  questionOneFrench?: Maybe<Scalars['String']>
  questionTwoFrench?: Maybe<Scalars['String']>
  questionThreeFrench?: Maybe<Scalars['String']>
  titleOneFrench?: Maybe<Scalars['String']>
  titleTwoFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  questionOneGerman?: Maybe<Scalars['String']>
  questionTwoGerman?: Maybe<Scalars['String']>
  questionThreeGerman?: Maybe<Scalars['String']>
  titleOneGerman?: Maybe<Scalars['String']>
  titleTwoGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  questionOneSpanish?: Maybe<Scalars['String']>
  questionTwoSpanish?: Maybe<Scalars['String']>
  questionThreeSpanish?: Maybe<Scalars['String']>
  titleOneSpanish?: Maybe<Scalars['String']>
  titleTwoSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  questionOneMandarin?: Maybe<Scalars['String']>
  questionTwoMandarin?: Maybe<Scalars['String']>
  questionThreeMandarin?: Maybe<Scalars['String']>
  titleOneMandarin?: Maybe<Scalars['String']>
  titleTwoMandarin?: Maybe<Scalars['String']>
  ratingInput?: Maybe<Array<Maybe<ComponentRatingInputRatingInput>>>
  subTitleEnglish?: Maybe<Scalars['String']>
  questionOneEnglish?: Maybe<Scalars['String']>
  questionTwoEnglish?: Maybe<Scalars['String']>
  questionThreeEnglish?: Maybe<Scalars['String']>
  titleEnglish?: Maybe<Scalars['String']>
  titleOneEnglish?: Maybe<Scalars['String']>
  titleTwoEnglish?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesGravitasScaleRatingInputArgs = {
  filters?: Maybe<ComponentRatingInputRatingInputFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesGravitasSliders = {
  __typename?: 'ComponentQuestionTypesGravitasSliders'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  positiveHeadingEnglish?: Maybe<Scalars['String']>
  positiveHeadingGerman?: Maybe<Scalars['String']>
  positiveHeadingMandarin?: Maybe<Scalars['String']>
  positiveHeadingSpanish?: Maybe<Scalars['String']>
  positiveHeadingFrench?: Maybe<Scalars['String']>
  negativeHeadingEnglish?: Maybe<Scalars['String']>
  negativeHeadingMandarin?: Maybe<Scalars['String']>
  negativeHeadingSpanish?: Maybe<Scalars['String']>
  negativeHeadingGerman?: Maybe<Scalars['String']>
  negativeHeadingFrench?: Maybe<Scalars['String']>
  sliderQuestions?: Maybe<Array<Maybe<ComponentOptionSliderQuestions>>>
  subTitleMandarin?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  sliderHeadings?: Maybe<Array<Maybe<ComponentOptionSliderHeadings>>>
}

export type ComponentQuestionTypesGravitasSlidersSliderQuestionsArgs = {
  filters?: Maybe<ComponentOptionSliderQuestionsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesGravitasSlidersSliderHeadingsArgs = {
  filters?: Maybe<ComponentOptionSliderHeadingsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesMultipleChoice = {
  __typename?: 'ComponentQuestionTypesMultipleChoice'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  option?: Maybe<Array<Maybe<ComponentOptionOption>>>
  numberOfAnswers?: Maybe<Scalars['Int']>
  viewPrevious?: Maybe<Scalars['Boolean']>
  titleEnglish?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesMultipleChoiceOptionArgs = {
  filters?: Maybe<ComponentOptionOptionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesNestedQuestions = {
  __typename?: 'ComponentQuestionTypesNestedQuestions'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  questions?: Maybe<Array<Maybe<ComponentQuestionTypesSimpleQuestion>>>
  viewPrevious?: Maybe<Scalars['Boolean']>
  titleEnglish?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesNestedQuestionsQuestionsArgs = {
  filters?: Maybe<ComponentQuestionTypesSimpleQuestionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesParticipant = {
  __typename?: 'ComponentQuestionTypesParticipant'
  id: Scalars['ID']
  email?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesQuestionscompetency2FiltersInput = {
  title?: Maybe<StringFilterInput>
  question?: Maybe<StringFilterInput>
  maxiNumber?: Maybe<LongFilterInput>
  maxiLabel?: Maybe<StringFilterInput>
  simpleQuestion?: Maybe<BooleanFilterInput>
  miniNumber?: Maybe<LongFilterInput>
  miniLabel?: Maybe<StringFilterInput>
  midiLabel?: Maybe<StringFilterInput>
  textboxQuestion?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentQuestionTypesQuestionscompetency2FiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentQuestionTypesQuestionscompetency2FiltersInput>>>
  not?: Maybe<ComponentQuestionTypesQuestionscompetency2FiltersInput>
}

export type ComponentQuestionTypesQuestionscompetency2 = {
  __typename?: 'ComponentQuestionTypesQuestionscompetency2'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  maxiNumber?: Maybe<Scalars['Long']>
  maxiLabel?: Maybe<Scalars['String']>
  simpleQuestion?: Maybe<Scalars['Boolean']>
  miniNumber?: Maybe<Scalars['Long']>
  miniLabel?: Maybe<Scalars['String']>
  midiLabel?: Maybe<Scalars['String']>
  textboxQuestion?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesSaboteurQuestionsFiltersInput = {
  link?: Maybe<StringFilterInput>
  labelEnglish?: Maybe<StringFilterInput>
  skipProof?: Maybe<BooleanFilterInput>
  labelFrench?: Maybe<StringFilterInput>
  labelGerman?: Maybe<StringFilterInput>
  labelSpanish?: Maybe<StringFilterInput>
  labelMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentQuestionTypesSaboteurQuestionsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentQuestionTypesSaboteurQuestionsFiltersInput>>>
  not?: Maybe<ComponentQuestionTypesSaboteurQuestionsFiltersInput>
}

export type ComponentQuestionTypesSaboteurQuestions = {
  __typename?: 'ComponentQuestionTypesSaboteurQuestions'
  id: Scalars['ID']
  link?: Maybe<Scalars['String']>
  labelEnglish?: Maybe<Scalars['String']>
  skipProof?: Maybe<Scalars['Boolean']>
  proof?: Maybe<UploadFileRelationResponseCollection>
  labelFrench?: Maybe<Scalars['String']>
  labelGerman?: Maybe<Scalars['String']>
  labelSpanish?: Maybe<Scalars['String']>
  labelMandarin?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesSaboteurQuestionsProofArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesSaboteurVideos = {
  __typename?: 'ComponentQuestionTypesSaboteurVideos'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  SaboteurVideo?: Maybe<Array<Maybe<ComponentSaboteurVideoSaboteurVideo>>>
  subTitleEnglish?: Maybe<Scalars['String']>
  titleEnglish?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesSaboteurVideosSaboteurVideoArgs = {
  filters?: Maybe<ComponentSaboteurVideoSaboteurVideoFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesSaboteur = {
  __typename?: 'ComponentQuestionTypesSaboteur'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  labelEnglish?: Maybe<Scalars['String']>
  labelFrench?: Maybe<Scalars['String']>
  labelGerman?: Maybe<Scalars['String']>
  labelSpanish?: Maybe<Scalars['String']>
  titleEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  saboteurQuestions?: Maybe<Array<Maybe<ComponentQuestionTypesSaboteurQuestions>>>
}

export type ComponentQuestionTypesSaboteurSaboteurQuestionsArgs = {
  filters?: Maybe<ComponentQuestionTypesSaboteurQuestionsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesSimpleQuestionFiltersInput = {
  titleFrench?: Maybe<StringFilterInput>
  subTitleFrench?: Maybe<StringFilterInput>
  titleGerman?: Maybe<StringFilterInput>
  subTitleGerman?: Maybe<StringFilterInput>
  titleSpanish?: Maybe<StringFilterInput>
  subTitleSpanish?: Maybe<StringFilterInput>
  titleMandarin?: Maybe<StringFilterInput>
  subTitleMandarin?: Maybe<StringFilterInput>
  isNumeric?: Maybe<BooleanFilterInput>
  viewPrevious?: Maybe<BooleanFilterInput>
  titleEnglish?: Maybe<StringFilterInput>
  subTitleEnglish?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentQuestionTypesSimpleQuestionFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentQuestionTypesSimpleQuestionFiltersInput>>>
  not?: Maybe<ComponentQuestionTypesSimpleQuestionFiltersInput>
}

export type ComponentQuestionTypesSimpleQuestion = {
  __typename?: 'ComponentQuestionTypesSimpleQuestion'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  isNumeric?: Maybe<Scalars['Boolean']>
  viewPrevious?: Maybe<Scalars['Boolean']>
  titleEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesSimpleReadOnly = {
  __typename?: 'ComponentQuestionTypesSimpleReadOnly'
  id: Scalars['ID']
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  contentFrench?: Maybe<Scalars['String']>
  contentGerman?: Maybe<Scalars['String']>
  contentSpanish?: Maybe<Scalars['String']>
  contentMandarin?: Maybe<Scalars['String']>
  photo?: Maybe<UploadFileEntityResponse>
  contentEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  titleEnglish?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesSliderQuestionsFiltersInput = {
  question?: Maybe<StringFilterInput>
  color?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentQuestionTypesSliderQuestionsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentQuestionTypesSliderQuestionsFiltersInput>>>
  not?: Maybe<ComponentQuestionTypesSliderQuestionsFiltersInput>
}

export type ComponentQuestionTypesSliderQuestions = {
  __typename?: 'ComponentQuestionTypesSliderQuestions'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesStakeholderMap = {
  __typename?: 'ComponentQuestionTypesStakeholderMap'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  questionX?: Maybe<Scalars['String']>
  questionY?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesUploadDocuments = {
  __typename?: 'ComponentQuestionTypesUploadDocuments'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesUploadQuestion = {
  __typename?: 'ComponentQuestionTypesUploadQuestion'
  id: Scalars['ID']
  file?: Maybe<UploadFileRelationResponseCollection>
}

export type ComponentQuestionTypesUploadQuestionFileArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesValuesBubble = {
  __typename?: 'ComponentQuestionTypesValuesBubble'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesValuesCharacters = {
  __typename?: 'ComponentQuestionTypesValuesCharacters'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesValuesGaps = {
  __typename?: 'ComponentQuestionTypesValuesGaps'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type ComponentQuestionTypesValuesNested = {
  __typename?: 'ComponentQuestionTypesValuesNested'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  questions?: Maybe<Array<Maybe<ComponentQuestionTypesSimpleQuestion>>>
}

export type ComponentQuestionTypesValuesNestedQuestionsArgs = {
  filters?: Maybe<ComponentQuestionTypesSimpleQuestionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesValuesSliders = {
  __typename?: 'ComponentQuestionTypesValuesSliders'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  sliderQuestions?: Maybe<Array<Maybe<ComponentQuestionTypesSliderQuestions>>>
}

export type ComponentQuestionTypesValuesSlidersSliderQuestionsArgs = {
  filters?: Maybe<ComponentQuestionTypesSliderQuestionsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentQuestionTypesXyGraph = {
  __typename?: 'ComponentQuestionTypesXYGraph'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  labelXEnglish?: Maybe<Scalars['String']>
  labelYEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  labelXFrench?: Maybe<Scalars['String']>
  labelYFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  labelXGerman?: Maybe<Scalars['String']>
  labelYGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  labelXSpanish?: Maybe<Scalars['String']>
  labelYSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  labelXMandarin?: Maybe<Scalars['String']>
  labelYMandarin?: Maybe<Scalars['String']>
}

export type ComponentRatingInputRatingInputFiltersInput = {
  labelOneEnglish?: Maybe<StringFilterInput>
  labelTwoEnglish?: Maybe<StringFilterInput>
  labelOneFrench?: Maybe<StringFilterInput>
  labelTwoFrench?: Maybe<StringFilterInput>
  labelOneGerman?: Maybe<StringFilterInput>
  labelTwoGerman?: Maybe<StringFilterInput>
  labelOneSpanish?: Maybe<StringFilterInput>
  labelTwoSpanish?: Maybe<StringFilterInput>
  labelOneMandarin?: Maybe<StringFilterInput>
  labelTwoMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentRatingInputRatingInputFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentRatingInputRatingInputFiltersInput>>>
  not?: Maybe<ComponentRatingInputRatingInputFiltersInput>
}

export type ComponentRatingInputRatingInput = {
  __typename?: 'ComponentRatingInputRatingInput'
  id: Scalars['ID']
  labelOneEnglish?: Maybe<Scalars['String']>
  labelTwoEnglish?: Maybe<Scalars['String']>
  labelOneFrench?: Maybe<Scalars['String']>
  labelTwoFrench?: Maybe<Scalars['String']>
  labelOneGerman?: Maybe<Scalars['String']>
  labelTwoGerman?: Maybe<Scalars['String']>
  labelOneSpanish?: Maybe<Scalars['String']>
  labelTwoSpanish?: Maybe<Scalars['String']>
  labelOneMandarin?: Maybe<Scalars['String']>
  labelTwoMandarin?: Maybe<Scalars['String']>
}

export type ComponentSaboteurVideoSaboteurVideoFiltersInput = {
  videoTitleEnglish?: Maybe<StringFilterInput>
  videoTitleFrench?: Maybe<StringFilterInput>
  videoTitleGerman?: Maybe<StringFilterInput>
  videoTitleSpanish?: Maybe<StringFilterInput>
  videoTitleMandarin?: Maybe<StringFilterInput>
  videoDescriptionEnglish?: Maybe<StringFilterInput>
  videoDescriptionFrench?: Maybe<StringFilterInput>
  videoDescriptionGerman?: Maybe<StringFilterInput>
  videoDescriptionSpanish?: Maybe<StringFilterInput>
  videoDescriptionMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSaboteurVideoSaboteurVideoFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSaboteurVideoSaboteurVideoFiltersInput>>>
  not?: Maybe<ComponentSaboteurVideoSaboteurVideoFiltersInput>
}

export type ComponentSaboteurVideoSaboteurVideo = {
  __typename?: 'ComponentSaboteurVideoSaboteurVideo'
  id: Scalars['ID']
  videoTitleEnglish?: Maybe<Scalars['String']>
  videoTitleFrench?: Maybe<Scalars['String']>
  videoTitleGerman?: Maybe<Scalars['String']>
  videoTitleSpanish?: Maybe<Scalars['String']>
  videoTitleMandarin?: Maybe<Scalars['String']>
  video?: Maybe<UploadFileEntityResponse>
  videoDescriptionEnglish?: Maybe<Scalars['String']>
  videoDescriptionFrench?: Maybe<Scalars['String']>
  videoDescriptionGerman?: Maybe<Scalars['String']>
  videoDescriptionSpanish?: Maybe<Scalars['String']>
  videoDescriptionMandarin?: Maybe<Scalars['String']>
}

export type ComponentStakeholderMapItemStackholderMapItemFiltersInput = {
  name?: Maybe<StringFilterInput>
  valueX?: Maybe<IntFilterInput>
  valueY?: Maybe<IntFilterInput>
  color?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>>>
  not?: Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>
}

export type ComponentStakeholderMapItemStackholderMapItemInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  valueX?: Maybe<Scalars['Int']>
  valueY?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
}

export type ComponentStakeholderMapItemStackholderMapItem = {
  __typename?: 'ComponentStakeholderMapItemStackholderMapItem'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  valueX?: Maybe<Scalars['Int']>
  valueY?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
}

export enum Enum_Componentsubmissionsanswer_Outcome {
  ResubmissionNeeded = 'Resubmission_Needed'
}

export type ComponentSubmissionsAnswerFiltersInput = {
  questionId?: Maybe<StringFilterInput>
  answer?: Maybe<StringFilterInput>
  nestedAnswers?: Maybe<ComponentSubmissionsRepeatAnswerFiltersInput>
  isCorrect?: Maybe<BooleanFilterInput>
  feedback?: Maybe<StringFilterInput>
  outcome?: Maybe<StringFilterInput>
  questionTitle?: Maybe<StringFilterInput>
  questionType?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsAnswerFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsAnswerFiltersInput>>>
  not?: Maybe<ComponentSubmissionsAnswerFiltersInput>
}

export type ComponentSubmissionsAnswerInput = {
  id?: Maybe<Scalars['ID']>
  questionId?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
  nestedAnswers?: Maybe<Array<Maybe<ComponentSubmissionsRepeatAnswerInput>>>
  isCorrect?: Maybe<Scalars['Boolean']>
  feedback?: Maybe<Scalars['String']>
  outcome?: Maybe<Enum_Componentsubmissionsanswer_Outcome>
  questionTitle?: Maybe<Scalars['String']>
  questionType?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsAnswer = {
  __typename?: 'ComponentSubmissionsAnswer'
  id: Scalars['ID']
  questionId?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
  nestedAnswers?: Maybe<Array<Maybe<ComponentSubmissionsRepeatAnswer>>>
  isCorrect?: Maybe<Scalars['Boolean']>
  feedback?: Maybe<Scalars['String']>
  outcome?: Maybe<Enum_Componentsubmissionsanswer_Outcome>
  questionTitle?: Maybe<Scalars['String']>
  questionType?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsAnswerNestedAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsRepeatAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsCharacteristicsFiltersInput = {
  answer?: Maybe<StringFilterInput>
  type?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristicsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristicsFiltersInput>>>
  not?: Maybe<ComponentSubmissionsCharacteristicsFiltersInput>
}

export type ComponentSubmissionsCharacteristicsInput = {
  id?: Maybe<Scalars['ID']>
  answer?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsCharacteristics = {
  __typename?: 'ComponentSubmissionsCharacteristics'
  id: Scalars['ID']
  answer?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsCompetencyAnswerFiltersInput = {
  question?: Maybe<StringFilterInput>
  rating?: Maybe<IntFilterInput>
  response?: Maybe<StringFilterInput>
  isSimpleQuestion?: Maybe<BooleanFilterInput>
  steps?: Maybe<IntFilterInput>
  textboxQuestion?: Maybe<StringFilterInput>
  headingQuestion?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>>>
  not?: Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>
}

export type ComponentSubmissionsCompetencyAnswerInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['Int']>
  response?: Maybe<Scalars['String']>
  isSimpleQuestion?: Maybe<Scalars['Boolean']>
  steps?: Maybe<Scalars['Int']>
  textboxQuestion?: Maybe<Scalars['String']>
  headingQuestion?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsCompetencyAnswer = {
  __typename?: 'ComponentSubmissionsCompetencyAnswer'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['Int']>
  response?: Maybe<Scalars['String']>
  isSimpleQuestion?: Maybe<Scalars['Boolean']>
  steps?: Maybe<Scalars['Int']>
  textboxQuestion?: Maybe<Scalars['String']>
  headingQuestion?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsCompetencyParticipantsFiltersInput = {
  name?: Maybe<StringFilterInput>
  email?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyParticipantsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyParticipantsFiltersInput>>>
  not?: Maybe<ComponentSubmissionsCompetencyParticipantsFiltersInput>
}

export type ComponentSubmissionsCompetencyParticipantsInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsCompetencyParticipants = {
  __typename?: 'ComponentSubmissionsCompetencyParticipants'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsGravitasGapFiltersInput = {
  gap?: Maybe<StringFilterInput>
  commitment?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsGravitasGapFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsGravitasGapFiltersInput>>>
  not?: Maybe<ComponentSubmissionsGravitasGapFiltersInput>
}

export type ComponentSubmissionsGravitasGapInput = {
  id?: Maybe<Scalars['ID']>
  gap?: Maybe<Scalars['String']>
  commitment?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsGravitasGap = {
  __typename?: 'ComponentSubmissionsGravitasGap'
  id: Scalars['ID']
  gap?: Maybe<Scalars['String']>
  commitment?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsGravitasSlidersAnswersFiltersInput = {
  negativeQuestion?: Maybe<StringFilterInput>
  positiveQuestion?: Maybe<StringFilterInput>
  colorAndRating?: Maybe<ComponentOptionColorAndRatingFiltersInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>>>
  not?: Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>
}

export type ComponentSubmissionsGravitasSlidersAnswersInput = {
  id?: Maybe<Scalars['ID']>
  negativeQuestion?: Maybe<Scalars['String']>
  positiveQuestion?: Maybe<Scalars['String']>
  colorAndRating?: Maybe<Array<Maybe<ComponentOptionColorAndRatingInput>>>
}

export type ComponentSubmissionsGravitasSlidersAnswers = {
  __typename?: 'ComponentSubmissionsGravitasSlidersAnswers'
  id: Scalars['ID']
  negativeQuestion?: Maybe<Scalars['String']>
  positiveQuestion?: Maybe<Scalars['String']>
  colorAndRating?: Maybe<Array<Maybe<ComponentOptionColorAndRating>>>
}

export type ComponentSubmissionsGravitasSlidersAnswersColorAndRatingArgs = {
  filters?: Maybe<ComponentOptionColorAndRatingFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsNotesFiltersInput = {
  title?: Maybe<StringFilterInput>
  description?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsNotesFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsNotesFiltersInput>>>
  not?: Maybe<ComponentSubmissionsNotesFiltersInput>
}

export type ComponentSubmissionsNotesInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  document?: Maybe<Scalars['ID']>
}

export type ComponentSubmissionsNotes = {
  __typename?: 'ComponentSubmissionsNotes'
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  document?: Maybe<UploadFileEntityResponse>
}

export type ComponentSubmissionsParticipantsFiltersInput = {
  name?: Maybe<StringFilterInput>
  email?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsParticipantsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsParticipantsFiltersInput>>>
  not?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
}

export type ComponentSubmissionsParticipantsInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsParticipants = {
  __typename?: 'ComponentSubmissionsParticipants'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsProgrammeSubmissionsFiltersInput = {
  answers?: Maybe<ComponentSubmissionsAnswerFiltersInput>
  gravitasScales?: Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>
  stakeholderItems?: Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>
  xYGraphItems?: Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>
  characteristics?: Maybe<ComponentSubmissionsCharacteristicsFiltersInput>
  sliderAnswers?: Maybe<JsonFilterInput>
  gapsAnswers?: Maybe<ComponentSubmissionsValuesGapsFiltersInput>
  saboteurSkipProofs?: Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>
  competencyAnswers?: Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>
  sabtourVideosProofs?: Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>
  saboteur?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  gravitasSliderAnswers?: Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>
  gravitasGap?: Maybe<ComponentSubmissionsGravitasGapFiltersInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsProgrammeSubmissionsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsProgrammeSubmissionsFiltersInput>>>
  not?: Maybe<ComponentSubmissionsProgrammeSubmissionsFiltersInput>
}

export type ComponentSubmissionsProgrammeSubmissionsInput = {
  id?: Maybe<Scalars['ID']>
  answers?: Maybe<Array<Maybe<ComponentSubmissionsAnswerInput>>>
  gravitasScales?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItemsInput>>>
  stakeholderItems?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItemInput>>>
  stakeholderChart?: Maybe<Scalars['ID']>
  xYGraphItems?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItemInput>>>
  xYGraphChart?: Maybe<Scalars['ID']>
  saboteurImage?: Maybe<Scalars['ID']>
  characteristics?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristicsInput>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<Scalars['ID']>
  gapsAnswers?: Maybe<Array<Maybe<ComponentSubmissionsValuesGapsInput>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponentInput>>>
  competencyAnswers?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswerInput>>>
  sabtourVideosProofs?: Maybe<Array<Maybe<ComponentSubmissionsSabtourVideosComponentInput>>>
  saboteur?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponentInput>>>
  gravitasSliderAnswers?: Maybe<Array<Maybe<ComponentSubmissionsGravitasSlidersAnswersInput>>>
  gravitasGap?: Maybe<Array<Maybe<ComponentSubmissionsGravitasGapInput>>>
}

export type ComponentSubmissionsProgrammeSubmissions = {
  __typename?: 'ComponentSubmissionsProgrammeSubmissions'
  id: Scalars['ID']
  answers?: Maybe<Array<Maybe<ComponentSubmissionsAnswer>>>
  gravitasScales?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItems>>>
  stakeholderItems?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItem>>>
  stakeholderChart?: Maybe<UploadFileEntityResponse>
  xYGraphItems?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItem>>>
  xYGraphChart?: Maybe<UploadFileEntityResponse>
  saboteurImage?: Maybe<UploadFileEntityResponse>
  characteristics?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristics>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<UploadFileEntityResponse>
  gapsAnswers?: Maybe<Array<Maybe<ComponentSubmissionsValuesGaps>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponent>>>
  competencyAnswers?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswer>>>
  sabtourVideosProofs?: Maybe<Array<Maybe<ComponentSubmissionsSabtourVideosComponent>>>
  saboteur?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponent>>>
  gravitasSliderAnswers?: Maybe<Array<Maybe<ComponentSubmissionsGravitasSlidersAnswers>>>
  gravitasGap?: Maybe<Array<Maybe<ComponentSubmissionsGravitasGap>>>
}

export type ComponentSubmissionsProgrammeSubmissionsAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsGravitasScalesArgs = {
  filters?: Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsStakeholderItemsArgs = {
  filters?: Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsXyGraphItemsArgs = {
  filters?: Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsCharacteristicsArgs = {
  filters?: Maybe<ComponentSubmissionsCharacteristicsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsGapsAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsValuesGapsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsSaboteurSkipProofsArgs = {
  filters?: Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsCompetencyAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsSabtourVideosProofsArgs = {
  filters?: Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsSaboteurArgs = {
  filters?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsGravitasSliderAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsProgrammeSubmissionsGravitasGapArgs = {
  filters?: Maybe<ComponentSubmissionsGravitasGapFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsRepeatAnswerFiltersInput = {
  answer?: Maybe<StringFilterInput>
  questionTitle?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsRepeatAnswerFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsRepeatAnswerFiltersInput>>>
  not?: Maybe<ComponentSubmissionsRepeatAnswerFiltersInput>
}

export type ComponentSubmissionsRepeatAnswerInput = {
  id?: Maybe<Scalars['ID']>
  answer?: Maybe<Scalars['String']>
  questionTitle?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsRepeatAnswer = {
  __typename?: 'ComponentSubmissionsRepeatAnswer'
  id: Scalars['ID']
  answer?: Maybe<Scalars['String']>
  questionTitle?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSaboteurComponentFiltersInput = {
  name?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>>>
  not?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
}

export type ComponentSubmissionsSaboteurComponentInput = {
  id?: Maybe<Scalars['ID']>
  saboteurProofValue?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSaboteurComponent = {
  __typename?: 'ComponentSubmissionsSaboteurComponent'
  id: Scalars['ID']
  saboteurProofValue?: Maybe<UploadFileEntityResponse>
  name?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSabtourVideosComponentFiltersInput = {
  sabtourValue?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>>>
  not?: Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>
}

export type ComponentSubmissionsSabtourVideosComponentInput = {
  id?: Maybe<Scalars['ID']>
  sabtourValue?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSabtourVideosComponent = {
  __typename?: 'ComponentSubmissionsSabtourVideosComponent'
  id: Scalars['ID']
  sabtourValue?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSectionSubmissionFiltersInput = {
  section?: Maybe<SectionFiltersInput>
  answers?: Maybe<ComponentSubmissionsAnswerFiltersInput>
  progress?: Maybe<IntFilterInput>
  gravitasScales?: Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>
  stakeholderItems?: Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>
  xYGraphItems?: Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>
  characteristics?: Maybe<ComponentSubmissionsCharacteristicsFiltersInput>
  sliderAnswers?: Maybe<JsonFilterInput>
  gapsAnswers?: Maybe<ComponentSubmissionsValuesGapsFiltersInput>
  saboteurSkipProofs?: Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>
  sabtourVideosProofs?: Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>
  saboteur?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  competencyAnswers?: Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>
  uploadDocuments?: Maybe<ComponentSubmissionsUploadDocumentsFiltersInput>
  gravitasSliderAnswers?: Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>
  gravitasGap?: Maybe<ComponentSubmissionsGravitasGapFiltersInput>
  emailSent?: Maybe<BooleanFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>>>
  not?: Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>
}

export type ComponentSubmissionsSectionSubmissionInput = {
  id?: Maybe<Scalars['ID']>
  section?: Maybe<Scalars['ID']>
  answers?: Maybe<Array<Maybe<ComponentSubmissionsAnswerInput>>>
  progress?: Maybe<Scalars['Int']>
  gravitasScales?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItemsInput>>>
  stakeholderItems?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItemInput>>>
  stakeholderChart?: Maybe<Scalars['ID']>
  xYGraphItems?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItemInput>>>
  xYGraphChart?: Maybe<Scalars['ID']>
  saboteurImage?: Maybe<Scalars['ID']>
  characteristics?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristicsInput>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<Scalars['ID']>
  gapsAnswers?: Maybe<Array<Maybe<ComponentSubmissionsValuesGapsInput>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponentInput>>>
  sabtourVideosProofs?: Maybe<Array<Maybe<ComponentSubmissionsSabtourVideosComponentInput>>>
  saboteur?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponentInput>>>
  competencyAnswers?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswerInput>>>
  uploadDocuments?: Maybe<Array<Maybe<ComponentSubmissionsUploadDocumentsInput>>>
  gravitasSliderAnswers?: Maybe<Array<Maybe<ComponentSubmissionsGravitasSlidersAnswersInput>>>
  gravitasGap?: Maybe<Array<Maybe<ComponentSubmissionsGravitasGapInput>>>
  emailSent?: Maybe<Scalars['Boolean']>
}

export type ComponentSubmissionsSectionSubmission = {
  __typename?: 'ComponentSubmissionsSectionSubmission'
  id: Scalars['ID']
  section?: Maybe<SectionEntityResponse>
  answers?: Maybe<Array<Maybe<ComponentSubmissionsAnswer>>>
  progress?: Maybe<Scalars['Int']>
  gravitasScales?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItems>>>
  stakeholderItems?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItem>>>
  stakeholderChart?: Maybe<UploadFileEntityResponse>
  xYGraphItems?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItem>>>
  xYGraphChart?: Maybe<UploadFileEntityResponse>
  saboteurImage?: Maybe<UploadFileEntityResponse>
  characteristics?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristics>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<UploadFileEntityResponse>
  gapsAnswers?: Maybe<Array<Maybe<ComponentSubmissionsValuesGaps>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponent>>>
  sabtourVideosProofs?: Maybe<Array<Maybe<ComponentSubmissionsSabtourVideosComponent>>>
  saboteur?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponent>>>
  competencyAnswers?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswer>>>
  uploadDocuments?: Maybe<Array<Maybe<ComponentSubmissionsUploadDocuments>>>
  gravitasSliderAnswers?: Maybe<Array<Maybe<ComponentSubmissionsGravitasSlidersAnswers>>>
  gravitasGap?: Maybe<Array<Maybe<ComponentSubmissionsGravitasGap>>>
  emailSent?: Maybe<Scalars['Boolean']>
}

export type ComponentSubmissionsSectionSubmissionAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionGravitasScalesArgs = {
  filters?: Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionStakeholderItemsArgs = {
  filters?: Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionXyGraphItemsArgs = {
  filters?: Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionCharacteristicsArgs = {
  filters?: Maybe<ComponentSubmissionsCharacteristicsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionGapsAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsValuesGapsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionSaboteurSkipProofsArgs = {
  filters?: Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionSabtourVideosProofsArgs = {
  filters?: Maybe<ComponentSubmissionsSabtourVideosComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionSaboteurArgs = {
  filters?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionCompetencyAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionUploadDocumentsArgs = {
  filters?: Maybe<ComponentSubmissionsUploadDocumentsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionGravitasSliderAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsGravitasSlidersAnswersFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSectionSubmissionGravitasGapArgs = {
  filters?: Maybe<ComponentSubmissionsGravitasGapFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSkipProofComponentFiltersInput = {
  skipProofValue?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>>>
  not?: Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>
}

export type ComponentSubmissionsSkipProofComponentInput = {
  id?: Maybe<Scalars['ID']>
  skipProofValue?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSkipProofComponent = {
  __typename?: 'ComponentSubmissionsSkipProofComponent'
  id: Scalars['ID']
  skipProofValue?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsSubModuleSubmission = {
  __typename?: 'ComponentSubmissionsSubModuleSubmission'
  id: Scalars['ID']
  section?: Maybe<SectionEntityResponse>
  answers?: Maybe<Array<Maybe<ComponentSubmissionsAnswer>>>
  progress?: Maybe<Scalars['Int']>
  gravitasScales?: Maybe<Array<Maybe<ComponentGravitasScaleItemsGravitasScaleItems>>>
  stakeholderItems?: Maybe<Array<Maybe<ComponentStakeholderMapItemStackholderMapItem>>>
  stakeholderChart?: Maybe<UploadFileEntityResponse>
  xYGraphItems?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItem>>>
  xYGraphChart?: Maybe<UploadFileEntityResponse>
  saboteurImage?: Maybe<UploadFileEntityResponse>
  characteristics?: Maybe<Array<Maybe<ComponentSubmissionsCharacteristics>>>
  sliderAnswers?: Maybe<Scalars['JSON']>
  sliderImage?: Maybe<UploadFileEntityResponse>
  gapsAnswers?: Maybe<Array<Maybe<ComponentSubmissionsValuesGaps>>>
  saboteurSkipProofs?: Maybe<Array<Maybe<ComponentSubmissionsSkipProofComponent>>>
  competencyAnswers?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyAnswer>>>
  emailSent?: Maybe<Scalars['Boolean']>
}

export type ComponentSubmissionsSubModuleSubmissionAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionGravitasScalesArgs = {
  filters?: Maybe<ComponentGravitasScaleItemsGravitasScaleItemsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionStakeholderItemsArgs = {
  filters?: Maybe<ComponentStakeholderMapItemStackholderMapItemFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionXyGraphItemsArgs = {
  filters?: Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionCharacteristicsArgs = {
  filters?: Maybe<ComponentSubmissionsCharacteristicsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionGapsAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsValuesGapsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionSaboteurSkipProofsArgs = {
  filters?: Maybe<ComponentSubmissionsSkipProofComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsSubModuleSubmissionCompetencyAnswersArgs = {
  filters?: Maybe<ComponentSubmissionsCompetencyAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentSubmissionsUploadDocumentsFiltersInput = {
  name?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsUploadDocumentsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsUploadDocumentsFiltersInput>>>
  not?: Maybe<ComponentSubmissionsUploadDocumentsFiltersInput>
}

export type ComponentSubmissionsUploadDocumentsInput = {
  id?: Maybe<Scalars['ID']>
  saboteurProofValue?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsUploadDocuments = {
  __typename?: 'ComponentSubmissionsUploadDocuments'
  id: Scalars['ID']
  saboteurProofValue?: Maybe<UploadFileEntityResponse>
  name?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsValuesGapsFiltersInput = {
  gap?: Maybe<StringFilterInput>
  commitment?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentSubmissionsValuesGapsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentSubmissionsValuesGapsFiltersInput>>>
  not?: Maybe<ComponentSubmissionsValuesGapsFiltersInput>
}

export type ComponentSubmissionsValuesGapsInput = {
  id?: Maybe<Scalars['ID']>
  gap?: Maybe<Scalars['String']>
  commitment?: Maybe<Scalars['String']>
}

export type ComponentSubmissionsValuesGaps = {
  __typename?: 'ComponentSubmissionsValuesGaps'
  id: Scalars['ID']
  gap?: Maybe<Scalars['String']>
  commitment?: Maybe<Scalars['String']>
}

export type ComponentTranslationsAboutUsFiltersInput = {
  textEnglish?: Maybe<StringFilterInput>
  textFrench?: Maybe<StringFilterInput>
  textGerman?: Maybe<StringFilterInput>
  userGuidelinesTextEnglish?: Maybe<StringFilterInput>
  userGuidelinesTextFrench?: Maybe<StringFilterInput>
  userGuidelinesTextGerman?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsAboutUsFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsAboutUsFiltersInput>>>
  not?: Maybe<ComponentTranslationsAboutUsFiltersInput>
}

export type ComponentTranslationsAboutUsInput = {
  id?: Maybe<Scalars['ID']>
  textEnglish?: Maybe<Scalars['String']>
  textFrench?: Maybe<Scalars['String']>
  textGerman?: Maybe<Scalars['String']>
  userGuidelinesTextEnglish?: Maybe<Scalars['String']>
  userGuidelinesTextFrench?: Maybe<Scalars['String']>
  userGuidelinesTextGerman?: Maybe<Scalars['String']>
  userGuidelinesEnglish?: Maybe<Array<Maybe<Scalars['ID']>>>
  userGuidelinesFrench?: Maybe<Array<Maybe<Scalars['ID']>>>
  userGuidelinesGerman?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type ComponentTranslationsAboutUs = {
  __typename?: 'ComponentTranslationsAboutUs'
  id: Scalars['ID']
  textEnglish?: Maybe<Scalars['String']>
  textFrench?: Maybe<Scalars['String']>
  textGerman?: Maybe<Scalars['String']>
  userGuidelinesTextEnglish?: Maybe<Scalars['String']>
  userGuidelinesTextFrench?: Maybe<Scalars['String']>
  userGuidelinesTextGerman?: Maybe<Scalars['String']>
  userGuidelinesEnglish?: Maybe<UploadFileRelationResponseCollection>
  userGuidelinesFrench?: Maybe<UploadFileRelationResponseCollection>
  userGuidelinesGerman?: Maybe<UploadFileRelationResponseCollection>
}

export type ComponentTranslationsAboutUsUserGuidelinesEnglishArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsAboutUsUserGuidelinesFrenchArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsAboutUsUserGuidelinesGermanArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsButtonsInput = {
  id?: Maybe<Scalars['ID']>
  backBtnsEnglish?: Maybe<Scalars['String']>
  backBtnsFrench?: Maybe<Scalars['String']>
  backBtnsGerman?: Maybe<Scalars['String']>
  nextBtnsEnglish?: Maybe<Scalars['String']>
  nextBtnsFrench?: Maybe<Scalars['String']>
  nextBtnsGerman?: Maybe<Scalars['String']>
  addMoreBtnsEnglish?: Maybe<Scalars['String']>
  addMoreBtnsFrench?: Maybe<Scalars['String']>
  addMoreBtnsGerman?: Maybe<Scalars['String']>
}

export type ComponentTranslationsButtons = {
  __typename?: 'ComponentTranslationsButtons'
  id: Scalars['ID']
  backBtnsEnglish?: Maybe<Scalars['String']>
  backBtnsFrench?: Maybe<Scalars['String']>
  backBtnsGerman?: Maybe<Scalars['String']>
  nextBtnsEnglish?: Maybe<Scalars['String']>
  nextBtnsFrench?: Maybe<Scalars['String']>
  nextBtnsGerman?: Maybe<Scalars['String']>
  addMoreBtnsEnglish?: Maybe<Scalars['String']>
  addMoreBtnsFrench?: Maybe<Scalars['String']>
  addMoreBtnsGerman?: Maybe<Scalars['String']>
}

export type ComponentTranslationsDashboardScreenInput = {
  id?: Maybe<Scalars['ID']>
  myProgrammesEnglish?: Maybe<Scalars['String']>
  myProgrammesFrench?: Maybe<Scalars['String']>
  myProgrammesGerman?: Maybe<Scalars['String']>
  myProgrammesSpanish?: Maybe<Scalars['String']>
  myProgrammesMandarin?: Maybe<Scalars['String']>
  suggestedTitleEnglish?: Maybe<Scalars['String']>
  suggestedTitleFrench?: Maybe<Scalars['String']>
  suggestedTitleSpanish?: Maybe<Scalars['String']>
  suggestedTitleGerman?: Maybe<Scalars['String']>
  suggestedTitleMandarin?: Maybe<Scalars['String']>
  uploadTitleEnglish?: Maybe<Scalars['String']>
  uploadTitleFrench?: Maybe<Scalars['String']>
  uploadTitleSpanish?: Maybe<Scalars['String']>
  uploadTitleGerman?: Maybe<Scalars['String']>
  uploadTitleMandarin?: Maybe<Scalars['String']>
  viewAllEnglish?: Maybe<Scalars['String']>
  viewAllFrench?: Maybe<Scalars['String']>
  viewAllMandarin?: Maybe<Scalars['String']>
  viewAllSpanish?: Maybe<Scalars['String']>
  viewAllGerman?: Maybe<Scalars['String']>
  logOutDashboardEnglish?: Maybe<Scalars['String']>
  logOutDashboardFrench?: Maybe<Scalars['String']>
  logOutDashboardMandarin?: Maybe<Scalars['String']>
  logOutDashboardSpanish?: Maybe<Scalars['String']>
  logOutDashboardGerman?: Maybe<Scalars['String']>
  notepadTextEnglish?: Maybe<Scalars['String']>
  notepadTextFrench?: Maybe<Scalars['String']>
  notepadTextMandarin?: Maybe<Scalars['String']>
  notepadTextSpanish?: Maybe<Scalars['String']>
  notepadTextGerman?: Maybe<Scalars['String']>
}

export type ComponentTranslationsDashboardScreen = {
  __typename?: 'ComponentTranslationsDashboardScreen'
  id: Scalars['ID']
  myProgrammesEnglish?: Maybe<Scalars['String']>
  myProgrammesFrench?: Maybe<Scalars['String']>
  myProgrammesGerman?: Maybe<Scalars['String']>
  myProgrammesSpanish?: Maybe<Scalars['String']>
  myProgrammesMandarin?: Maybe<Scalars['String']>
  suggestedTitleEnglish?: Maybe<Scalars['String']>
  suggestedTitleFrench?: Maybe<Scalars['String']>
  suggestedTitleSpanish?: Maybe<Scalars['String']>
  suggestedTitleGerman?: Maybe<Scalars['String']>
  suggestedTitleMandarin?: Maybe<Scalars['String']>
  uploadTitleEnglish?: Maybe<Scalars['String']>
  uploadTitleFrench?: Maybe<Scalars['String']>
  uploadTitleSpanish?: Maybe<Scalars['String']>
  uploadTitleGerman?: Maybe<Scalars['String']>
  uploadTitleMandarin?: Maybe<Scalars['String']>
  viewAllEnglish?: Maybe<Scalars['String']>
  viewAllFrench?: Maybe<Scalars['String']>
  viewAllMandarin?: Maybe<Scalars['String']>
  viewAllSpanish?: Maybe<Scalars['String']>
  viewAllGerman?: Maybe<Scalars['String']>
  logOutDashboardEnglish?: Maybe<Scalars['String']>
  logOutDashboardFrench?: Maybe<Scalars['String']>
  logOutDashboardMandarin?: Maybe<Scalars['String']>
  logOutDashboardSpanish?: Maybe<Scalars['String']>
  logOutDashboardGerman?: Maybe<Scalars['String']>
  notepadTextEnglish?: Maybe<Scalars['String']>
  notepadTextFrench?: Maybe<Scalars['String']>
  notepadTextMandarin?: Maybe<Scalars['String']>
  notepadTextSpanish?: Maybe<Scalars['String']>
  notepadTextGerman?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsFrenchFiltersInput = {
  question?: Maybe<StringFilterInput>
  answer?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsFaqsFrenchFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsFaqsFrenchFiltersInput>>>
  not?: Maybe<ComponentTranslationsFaqsFrenchFiltersInput>
}

export type ComponentTranslationsFaqsFrenchInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsFrench = {
  __typename?: 'ComponentTranslationsFaqsFrench'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsGermanFiltersInput = {
  question?: Maybe<StringFilterInput>
  answer?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsFaqsGermanFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsFaqsGermanFiltersInput>>>
  not?: Maybe<ComponentTranslationsFaqsGermanFiltersInput>
}

export type ComponentTranslationsFaqsGermanInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsGerman = {
  __typename?: 'ComponentTranslationsFaqsGerman'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsMandarinFiltersInput = {
  question?: Maybe<StringFilterInput>
  answer?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsFaqsMandarinFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsFaqsMandarinFiltersInput>>>
  not?: Maybe<ComponentTranslationsFaqsMandarinFiltersInput>
}

export type ComponentTranslationsFaqsMandarinInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsMandarin = {
  __typename?: 'ComponentTranslationsFaqsMandarin'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsQuestionAnswerFiltersInput = {
  question?: Maybe<StringFilterInput>
  answer?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsFaqsQuestionAnswerFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsFaqsQuestionAnswerFiltersInput>>>
  not?: Maybe<ComponentTranslationsFaqsQuestionAnswerFiltersInput>
}

export type ComponentTranslationsFaqsQuestionAnswerInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsQuestionAnswer = {
  __typename?: 'ComponentTranslationsFaqsQuestionAnswer'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsSpanishFiltersInput = {
  question?: Maybe<StringFilterInput>
  answer?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsFaqsSpanishFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsFaqsSpanishFiltersInput>>>
  not?: Maybe<ComponentTranslationsFaqsSpanishFiltersInput>
}

export type ComponentTranslationsFaqsSpanishInput = {
  id?: Maybe<Scalars['ID']>
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsSpanish = {
  __typename?: 'ComponentTranslationsFaqsSpanish'
  id: Scalars['ID']
  question?: Maybe<Scalars['String']>
  answer?: Maybe<Scalars['String']>
}

export type ComponentTranslationsFaqsInput = {
  id?: Maybe<Scalars['ID']>
  faqsEnglish?: Maybe<Array<Maybe<ComponentTranslationsFaqsQuestionAnswerInput>>>
  faqsFrench?: Maybe<Array<Maybe<ComponentTranslationsFaqsFrenchInput>>>
  faqsGerman?: Maybe<Array<Maybe<ComponentTranslationsFaqsGermanInput>>>
  faqsMandarin?: Maybe<Array<Maybe<ComponentTranslationsFaqsMandarinInput>>>
  faqsSpanish?: Maybe<Array<Maybe<ComponentTranslationsFaqsSpanishInput>>>
}

export type ComponentTranslationsFaqs = {
  __typename?: 'ComponentTranslationsFaqs'
  id: Scalars['ID']
  faqsEnglish?: Maybe<Array<Maybe<ComponentTranslationsFaqsQuestionAnswer>>>
  faqsFrench?: Maybe<Array<Maybe<ComponentTranslationsFaqsFrench>>>
  faqsGerman?: Maybe<Array<Maybe<ComponentTranslationsFaqsGerman>>>
  faqsMandarin?: Maybe<Array<Maybe<ComponentTranslationsFaqsMandarin>>>
  faqsSpanish?: Maybe<Array<Maybe<ComponentTranslationsFaqsSpanish>>>
}

export type ComponentTranslationsFaqsFaqsEnglishArgs = {
  filters?: Maybe<ComponentTranslationsFaqsQuestionAnswerFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsFaqsFaqsFrenchArgs = {
  filters?: Maybe<ComponentTranslationsFaqsFrenchFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsFaqsFaqsGermanArgs = {
  filters?: Maybe<ComponentTranslationsFaqsGermanFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsFaqsFaqsMandarinArgs = {
  filters?: Maybe<ComponentTranslationsFaqsMandarinFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsFaqsFaqsSpanishArgs = {
  filters?: Maybe<ComponentTranslationsFaqsSpanishFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ComponentTranslationsHelpAndSupportInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  knowledgeBaseEnglish?: Maybe<Scalars['String']>
  liveChatEnglish?: Maybe<Scalars['String']>
  myTicketsEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  knowledgeBaseFrench?: Maybe<Scalars['String']>
  liveChatFrench?: Maybe<Scalars['String']>
  myTicketsFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  knowledgeBaseGerman?: Maybe<Scalars['String']>
  liveChatGerman?: Maybe<Scalars['String']>
  myTicketsGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  knowledgeBaseSpanish?: Maybe<Scalars['String']>
  liveChatSpanish?: Maybe<Scalars['String']>
  myTicketsSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  knowledgeBaseMandarin?: Maybe<Scalars['String']>
  liveChatMandarin?: Maybe<Scalars['String']>
  myTicketsMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsHelpAndSupport = {
  __typename?: 'ComponentTranslationsHelpAndSupport'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  knowledgeBaseEnglish?: Maybe<Scalars['String']>
  liveChatEnglish?: Maybe<Scalars['String']>
  myTicketsEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  knowledgeBaseFrench?: Maybe<Scalars['String']>
  liveChatFrench?: Maybe<Scalars['String']>
  myTicketsFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  knowledgeBaseGerman?: Maybe<Scalars['String']>
  liveChatGerman?: Maybe<Scalars['String']>
  myTicketsGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  knowledgeBaseSpanish?: Maybe<Scalars['String']>
  liveChatSpanish?: Maybe<Scalars['String']>
  myTicketsSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  knowledgeBaseMandarin?: Maybe<Scalars['String']>
  liveChatMandarin?: Maybe<Scalars['String']>
  myTicketsMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsHomeScreenInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  myProgrammeEnglish?: Maybe<Scalars['String']>
  completedModulesEnglish?: Maybe<Scalars['String']>
  noChangesEnglish?: Maybe<Scalars['String']>
  changesUpEnglish?: Maybe<Scalars['String']>
  changesDownEnglish?: Maybe<Scalars['String']>
  viewModulesEnglish?: Maybe<Scalars['String']>
  myLibraryEnglish?: Maybe<Scalars['String']>
  viewedArticlesEnglish?: Maybe<Scalars['String']>
  viewLibraryEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  myProgrammeFrench?: Maybe<Scalars['String']>
  completedModulesFrench?: Maybe<Scalars['String']>
  noChangesFrench?: Maybe<Scalars['String']>
  changesUpFrench?: Maybe<Scalars['String']>
  changesDownFrench?: Maybe<Scalars['String']>
  viewModulesFrench?: Maybe<Scalars['String']>
  myLibraryFrench?: Maybe<Scalars['String']>
  viewedArticlesFrench?: Maybe<Scalars['String']>
  viewLibraryFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  myProgrammeGerman?: Maybe<Scalars['String']>
  completedModulesGerman?: Maybe<Scalars['String']>
  noChangesGerman?: Maybe<Scalars['String']>
  changesUpGerman?: Maybe<Scalars['String']>
  changesDownGerman?: Maybe<Scalars['String']>
  viewModulesGerman?: Maybe<Scalars['String']>
  myLibraryGerman?: Maybe<Scalars['String']>
  viewedArticlesGerman?: Maybe<Scalars['String']>
  viewLibraryGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  myProgrammeSpanish?: Maybe<Scalars['String']>
  completedModulesSpanish?: Maybe<Scalars['String']>
  noChangesSpanish?: Maybe<Scalars['String']>
  changesUpSpanish?: Maybe<Scalars['String']>
  changesDownSpanish?: Maybe<Scalars['String']>
  viewModulesSpanish?: Maybe<Scalars['String']>
  myLibrarySpanish?: Maybe<Scalars['String']>
  viewedArticlesSpanish?: Maybe<Scalars['String']>
  viewLibrarySpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  myProgrammeMandarin?: Maybe<Scalars['String']>
  completedModulesMandarin?: Maybe<Scalars['String']>
  noChangesMandarin?: Maybe<Scalars['String']>
  changesUpMandarin?: Maybe<Scalars['String']>
  changesDownMandarin?: Maybe<Scalars['String']>
  viewModulesMandarin?: Maybe<Scalars['String']>
  myLibraryMandarin?: Maybe<Scalars['String']>
  viewedArticlesMandarin?: Maybe<Scalars['String']>
  viewLibraryMandarin?: Maybe<Scalars['String']>
  welcomeEnglish?: Maybe<Scalars['String']>
  welcomeFrench?: Maybe<Scalars['String']>
  welcomeGerman?: Maybe<Scalars['String']>
  welcomeSpanish?: Maybe<Scalars['String']>
  welcomeMandarin?: Maybe<Scalars['String']>
  programmeProgressEnglish?: Maybe<Scalars['String']>
  programmeProgressFrench?: Maybe<Scalars['String']>
  programmeProgressGerman?: Maybe<Scalars['String']>
  programmeProgressSpanish?: Maybe<Scalars['String']>
  programmeProgressMandarin?: Maybe<Scalars['String']>
  whatsNextEnglish?: Maybe<Scalars['String']>
  whatsNextFrench?: Maybe<Scalars['String']>
  whatsNextGerman?: Maybe<Scalars['String']>
  whatsNextSpanish?: Maybe<Scalars['String']>
  whatsNextMandarin?: Maybe<Scalars['String']>
  whatToExpectEnglish?: Maybe<Scalars['String']>
  whatToExpectFrench?: Maybe<Scalars['String']>
  whatToExpectGerman?: Maybe<Scalars['String']>
  whatToExpectSpanish?: Maybe<Scalars['String']>
  whatToExpectMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsHomeScreen = {
  __typename?: 'ComponentTranslationsHomeScreen'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  myProgrammeEnglish?: Maybe<Scalars['String']>
  completedModulesEnglish?: Maybe<Scalars['String']>
  noChangesEnglish?: Maybe<Scalars['String']>
  changesUpEnglish?: Maybe<Scalars['String']>
  changesDownEnglish?: Maybe<Scalars['String']>
  viewModulesEnglish?: Maybe<Scalars['String']>
  myLibraryEnglish?: Maybe<Scalars['String']>
  viewedArticlesEnglish?: Maybe<Scalars['String']>
  viewLibraryEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  myProgrammeFrench?: Maybe<Scalars['String']>
  completedModulesFrench?: Maybe<Scalars['String']>
  noChangesFrench?: Maybe<Scalars['String']>
  changesUpFrench?: Maybe<Scalars['String']>
  changesDownFrench?: Maybe<Scalars['String']>
  viewModulesFrench?: Maybe<Scalars['String']>
  myLibraryFrench?: Maybe<Scalars['String']>
  viewedArticlesFrench?: Maybe<Scalars['String']>
  viewLibraryFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  myProgrammeGerman?: Maybe<Scalars['String']>
  completedModulesGerman?: Maybe<Scalars['String']>
  noChangesGerman?: Maybe<Scalars['String']>
  changesUpGerman?: Maybe<Scalars['String']>
  changesDownGerman?: Maybe<Scalars['String']>
  viewModulesGerman?: Maybe<Scalars['String']>
  myLibraryGerman?: Maybe<Scalars['String']>
  viewedArticlesGerman?: Maybe<Scalars['String']>
  viewLibraryGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  myProgrammeSpanish?: Maybe<Scalars['String']>
  completedModulesSpanish?: Maybe<Scalars['String']>
  noChangesSpanish?: Maybe<Scalars['String']>
  changesUpSpanish?: Maybe<Scalars['String']>
  changesDownSpanish?: Maybe<Scalars['String']>
  viewModulesSpanish?: Maybe<Scalars['String']>
  myLibrarySpanish?: Maybe<Scalars['String']>
  viewedArticlesSpanish?: Maybe<Scalars['String']>
  viewLibrarySpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  myProgrammeMandarin?: Maybe<Scalars['String']>
  completedModulesMandarin?: Maybe<Scalars['String']>
  noChangesMandarin?: Maybe<Scalars['String']>
  changesUpMandarin?: Maybe<Scalars['String']>
  changesDownMandarin?: Maybe<Scalars['String']>
  viewModulesMandarin?: Maybe<Scalars['String']>
  myLibraryMandarin?: Maybe<Scalars['String']>
  viewedArticlesMandarin?: Maybe<Scalars['String']>
  viewLibraryMandarin?: Maybe<Scalars['String']>
  welcomeEnglish?: Maybe<Scalars['String']>
  welcomeFrench?: Maybe<Scalars['String']>
  welcomeGerman?: Maybe<Scalars['String']>
  welcomeSpanish?: Maybe<Scalars['String']>
  welcomeMandarin?: Maybe<Scalars['String']>
  programmeProgressEnglish?: Maybe<Scalars['String']>
  programmeProgressFrench?: Maybe<Scalars['String']>
  programmeProgressGerman?: Maybe<Scalars['String']>
  programmeProgressSpanish?: Maybe<Scalars['String']>
  programmeProgressMandarin?: Maybe<Scalars['String']>
  whatsNextEnglish?: Maybe<Scalars['String']>
  whatsNextFrench?: Maybe<Scalars['String']>
  whatsNextGerman?: Maybe<Scalars['String']>
  whatsNextSpanish?: Maybe<Scalars['String']>
  whatsNextMandarin?: Maybe<Scalars['String']>
  whatToExpectEnglish?: Maybe<Scalars['String']>
  whatToExpectFrench?: Maybe<Scalars['String']>
  whatToExpectGerman?: Maybe<Scalars['String']>
  whatToExpectSpanish?: Maybe<Scalars['String']>
  whatToExpectMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsLanguageSelectionFiltersInput = {
  titleEnglish?: Maybe<StringFilterInput>
  nextButtonEnglish?: Maybe<StringFilterInput>
  bodyEnglish?: Maybe<StringFilterInput>
  titleFrench?: Maybe<StringFilterInput>
  nextButtonFrench?: Maybe<StringFilterInput>
  bodyFrench?: Maybe<StringFilterInput>
  titleGerman?: Maybe<StringFilterInput>
  nextButtonGerman?: Maybe<StringFilterInput>
  bodyGerman?: Maybe<StringFilterInput>
  titleSpanish?: Maybe<StringFilterInput>
  nextButtonSpanish?: Maybe<StringFilterInput>
  bodySpanish?: Maybe<StringFilterInput>
  titleMandarin?: Maybe<StringFilterInput>
  nextButtonMandarin?: Maybe<StringFilterInput>
  bodyMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsLanguageSelectionFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsLanguageSelectionFiltersInput>>>
  not?: Maybe<ComponentTranslationsLanguageSelectionFiltersInput>
}

export type ComponentTranslationsLanguageSelectionInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  nextButtonEnglish?: Maybe<Scalars['String']>
  bodyEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  nextButtonFrench?: Maybe<Scalars['String']>
  bodyFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  nextButtonGerman?: Maybe<Scalars['String']>
  bodyGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  nextButtonSpanish?: Maybe<Scalars['String']>
  bodySpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  nextButtonMandarin?: Maybe<Scalars['String']>
  bodyMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsLanguageSelection = {
  __typename?: 'ComponentTranslationsLanguageSelection'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  nextButtonEnglish?: Maybe<Scalars['String']>
  bodyEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  nextButtonFrench?: Maybe<Scalars['String']>
  bodyFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  nextButtonGerman?: Maybe<Scalars['String']>
  bodyGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  nextButtonSpanish?: Maybe<Scalars['String']>
  bodySpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  nextButtonMandarin?: Maybe<Scalars['String']>
  bodyMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsLetsGetStartedScreenFiltersInput = {
  titleEnglish?: Maybe<StringFilterInput>
  subTitleEnglish?: Maybe<StringFilterInput>
  firstNameLabelEnglish?: Maybe<StringFilterInput>
  firstNameErrorEnglish?: Maybe<StringFilterInput>
  lastNameLabelEnglish?: Maybe<StringFilterInput>
  lastNameErrorEnglish?: Maybe<StringFilterInput>
  jobTitleLabelEnglish?: Maybe<StringFilterInput>
  jobTitleErrorEnglish?: Maybe<StringFilterInput>
  divisionLabelEnglish?: Maybe<StringFilterInput>
  divisionErrorEnglish?: Maybe<StringFilterInput>
  nextButtonEnglish?: Maybe<StringFilterInput>
  titleFrench?: Maybe<StringFilterInput>
  subTitleFrench?: Maybe<StringFilterInput>
  firstNameLabelFrench?: Maybe<StringFilterInput>
  firstNameErrorFrench?: Maybe<StringFilterInput>
  lastNameLabelFrench?: Maybe<StringFilterInput>
  lastNameErrorFrench?: Maybe<StringFilterInput>
  jobTitleLabelFrench?: Maybe<StringFilterInput>
  jobTitleErrorFrench?: Maybe<StringFilterInput>
  divisionLabelFrench?: Maybe<StringFilterInput>
  divisionErrorFrench?: Maybe<StringFilterInput>
  nextButtonFrench?: Maybe<StringFilterInput>
  titleGerman?: Maybe<StringFilterInput>
  subTitleGerman?: Maybe<StringFilterInput>
  firstNameLabelGerman?: Maybe<StringFilterInput>
  firstNameErrorGerman?: Maybe<StringFilterInput>
  lastNameLabelGerman?: Maybe<StringFilterInput>
  lastNameErrorGerman?: Maybe<StringFilterInput>
  jobTitleLabelGerman?: Maybe<StringFilterInput>
  jobTitleErrorGerman?: Maybe<StringFilterInput>
  divisionLabelGerman?: Maybe<StringFilterInput>
  divisionErrorGerman?: Maybe<StringFilterInput>
  nextButtonGerman?: Maybe<StringFilterInput>
  titleSpanish?: Maybe<StringFilterInput>
  subTitleSpanish?: Maybe<StringFilterInput>
  firstNameLabelSpanish?: Maybe<StringFilterInput>
  firstNameErrorSpanish?: Maybe<StringFilterInput>
  lastNameLabelSpanish?: Maybe<StringFilterInput>
  lastNameErrorSpanish?: Maybe<StringFilterInput>
  jobTitleLabelSpanish?: Maybe<StringFilterInput>
  jobTitleErrorSpanish?: Maybe<StringFilterInput>
  divisionLabelSpanish?: Maybe<StringFilterInput>
  divisionErrorSpanish?: Maybe<StringFilterInput>
  nextButtonSpanish?: Maybe<StringFilterInput>
  titleMandarin?: Maybe<StringFilterInput>
  subTitleMandarin?: Maybe<StringFilterInput>
  firstNameLabelMandarin?: Maybe<StringFilterInput>
  firstNameErrorMandarin?: Maybe<StringFilterInput>
  lastNameLabelMandarin?: Maybe<StringFilterInput>
  lastNameErrorMandarin?: Maybe<StringFilterInput>
  jobTitleLabelMandarin?: Maybe<StringFilterInput>
  jobTitleErrorMandarin?: Maybe<StringFilterInput>
  divisionLabelMandarin?: Maybe<StringFilterInput>
  divisionErrorMandarin?: Maybe<StringFilterInput>
  nextButtonMandarin?: Maybe<StringFilterInput>
  timezoneErrorEnglish?: Maybe<StringFilterInput>
  timezoneLabelSpanish?: Maybe<StringFilterInput>
  timezoneLabelFrench?: Maybe<StringFilterInput>
  timezoneLabelGerman?: Maybe<StringFilterInput>
  timezoneLabelMandarin?: Maybe<StringFilterInput>
  timezoneLabelEnglish?: Maybe<StringFilterInput>
  timezoneErrorFrench?: Maybe<StringFilterInput>
  timezoneErrorSpanish?: Maybe<StringFilterInput>
  timezoneErrorGerman?: Maybe<StringFilterInput>
  timezoneErrorMandarin?: Maybe<StringFilterInput>
  regionLabelEnglish?: Maybe<StringFilterInput>
  regionErrorEnglish?: Maybe<StringFilterInput>
  regionLabelFrench?: Maybe<StringFilterInput>
  regionErrorFrench?: Maybe<StringFilterInput>
  regionLabelSpanish?: Maybe<StringFilterInput>
  regionErrorSpanish?: Maybe<StringFilterInput>
  regionLabelGerman?: Maybe<StringFilterInput>
  regionErrorGerman?: Maybe<StringFilterInput>
  regionLabelMandarin?: Maybe<StringFilterInput>
  regionErrorMandarin?: Maybe<StringFilterInput>
  leftTitleEnglish?: Maybe<StringFilterInput>
  leftSubtitleEnglish?: Maybe<StringFilterInput>
  leftTitleSpanish?: Maybe<StringFilterInput>
  leftSubtitleSpanish?: Maybe<StringFilterInput>
  leftTitleMandarin?: Maybe<StringFilterInput>
  leftSubTitleMandarin?: Maybe<StringFilterInput>
  leftTitleFrench?: Maybe<StringFilterInput>
  leftSubtitleFrench?: Maybe<StringFilterInput>
  leftSubtitleGerman?: Maybe<StringFilterInput>
  leftTitleGerman?: Maybe<StringFilterInput>
  leftTitleCzech?: Maybe<StringFilterInput>
  havingTroubleEnglish?: Maybe<StringFilterInput>
  havingTroubleSpanish?: Maybe<StringFilterInput>
  havingTroubleFrench?: Maybe<StringFilterInput>
  havingTroubleMandarin?: Maybe<StringFilterInput>
  havingTroubleGerman?: Maybe<StringFilterInput>
  getHelpButtonEnglish?: Maybe<StringFilterInput>
  getHelpButtonSpanish?: Maybe<StringFilterInput>
  getHelpButtonFrench?: Maybe<StringFilterInput>
  getHelpButtonGerman?: Maybe<StringFilterInput>
  getHelpButtonMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsLetsGetStartedScreenFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsLetsGetStartedScreenFiltersInput>>>
  not?: Maybe<ComponentTranslationsLetsGetStartedScreenFiltersInput>
}

export type ComponentTranslationsLetsGetStartedScreenInput = {
  id?: Maybe<Scalars['ID']>
  image?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  firstNameLabelEnglish?: Maybe<Scalars['String']>
  firstNameErrorEnglish?: Maybe<Scalars['String']>
  lastNameLabelEnglish?: Maybe<Scalars['String']>
  lastNameErrorEnglish?: Maybe<Scalars['String']>
  jobTitleLabelEnglish?: Maybe<Scalars['String']>
  jobTitleErrorEnglish?: Maybe<Scalars['String']>
  divisionLabelEnglish?: Maybe<Scalars['String']>
  divisionErrorEnglish?: Maybe<Scalars['String']>
  nextButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  firstNameLabelFrench?: Maybe<Scalars['String']>
  firstNameErrorFrench?: Maybe<Scalars['String']>
  lastNameLabelFrench?: Maybe<Scalars['String']>
  lastNameErrorFrench?: Maybe<Scalars['String']>
  jobTitleLabelFrench?: Maybe<Scalars['String']>
  jobTitleErrorFrench?: Maybe<Scalars['String']>
  divisionLabelFrench?: Maybe<Scalars['String']>
  divisionErrorFrench?: Maybe<Scalars['String']>
  nextButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  firstNameLabelGerman?: Maybe<Scalars['String']>
  firstNameErrorGerman?: Maybe<Scalars['String']>
  lastNameLabelGerman?: Maybe<Scalars['String']>
  lastNameErrorGerman?: Maybe<Scalars['String']>
  jobTitleLabelGerman?: Maybe<Scalars['String']>
  jobTitleErrorGerman?: Maybe<Scalars['String']>
  divisionLabelGerman?: Maybe<Scalars['String']>
  divisionErrorGerman?: Maybe<Scalars['String']>
  nextButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  firstNameLabelSpanish?: Maybe<Scalars['String']>
  firstNameErrorSpanish?: Maybe<Scalars['String']>
  lastNameLabelSpanish?: Maybe<Scalars['String']>
  lastNameErrorSpanish?: Maybe<Scalars['String']>
  jobTitleLabelSpanish?: Maybe<Scalars['String']>
  jobTitleErrorSpanish?: Maybe<Scalars['String']>
  divisionLabelSpanish?: Maybe<Scalars['String']>
  divisionErrorSpanish?: Maybe<Scalars['String']>
  nextButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  firstNameLabelMandarin?: Maybe<Scalars['String']>
  firstNameErrorMandarin?: Maybe<Scalars['String']>
  lastNameLabelMandarin?: Maybe<Scalars['String']>
  lastNameErrorMandarin?: Maybe<Scalars['String']>
  jobTitleLabelMandarin?: Maybe<Scalars['String']>
  jobTitleErrorMandarin?: Maybe<Scalars['String']>
  divisionLabelMandarin?: Maybe<Scalars['String']>
  divisionErrorMandarin?: Maybe<Scalars['String']>
  nextButtonMandarin?: Maybe<Scalars['String']>
  timezoneErrorEnglish?: Maybe<Scalars['String']>
  timezoneLabelSpanish?: Maybe<Scalars['String']>
  timezoneLabelFrench?: Maybe<Scalars['String']>
  timezoneLabelGerman?: Maybe<Scalars['String']>
  timezoneLabelMandarin?: Maybe<Scalars['String']>
  timezoneLabelEnglish?: Maybe<Scalars['String']>
  timezoneErrorFrench?: Maybe<Scalars['String']>
  timezoneErrorSpanish?: Maybe<Scalars['String']>
  timezoneErrorGerman?: Maybe<Scalars['String']>
  timezoneErrorMandarin?: Maybe<Scalars['String']>
  regionLabelEnglish?: Maybe<Scalars['String']>
  regionErrorEnglish?: Maybe<Scalars['String']>
  regionLabelFrench?: Maybe<Scalars['String']>
  regionErrorFrench?: Maybe<Scalars['String']>
  regionLabelSpanish?: Maybe<Scalars['String']>
  regionErrorSpanish?: Maybe<Scalars['String']>
  regionLabelGerman?: Maybe<Scalars['String']>
  regionErrorGerman?: Maybe<Scalars['String']>
  regionLabelMandarin?: Maybe<Scalars['String']>
  regionErrorMandarin?: Maybe<Scalars['String']>
  leftTitleEnglish?: Maybe<Scalars['String']>
  leftSubtitleEnglish?: Maybe<Scalars['String']>
  leftTitleSpanish?: Maybe<Scalars['String']>
  leftSubtitleSpanish?: Maybe<Scalars['String']>
  leftTitleMandarin?: Maybe<Scalars['String']>
  leftSubTitleMandarin?: Maybe<Scalars['String']>
  leftTitleFrench?: Maybe<Scalars['String']>
  leftSubtitleFrench?: Maybe<Scalars['String']>
  leftSubtitleGerman?: Maybe<Scalars['String']>
  leftTitleGerman?: Maybe<Scalars['String']>
  leftTitleCzech?: Maybe<Scalars['String']>
  havingTroubleEnglish?: Maybe<Scalars['String']>
  havingTroubleSpanish?: Maybe<Scalars['String']>
  havingTroubleFrench?: Maybe<Scalars['String']>
  havingTroubleMandarin?: Maybe<Scalars['String']>
  havingTroubleGerman?: Maybe<Scalars['String']>
  getHelpButtonEnglish?: Maybe<Scalars['String']>
  getHelpButtonSpanish?: Maybe<Scalars['String']>
  getHelpButtonFrench?: Maybe<Scalars['String']>
  getHelpButtonGerman?: Maybe<Scalars['String']>
  getHelpButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsLetsGetStartedScreen = {
  __typename?: 'ComponentTranslationsLetsGetStartedScreen'
  id: Scalars['ID']
  image?: Maybe<UploadFileEntityResponse>
  titleEnglish?: Maybe<Scalars['String']>
  subTitleEnglish?: Maybe<Scalars['String']>
  firstNameLabelEnglish?: Maybe<Scalars['String']>
  firstNameErrorEnglish?: Maybe<Scalars['String']>
  lastNameLabelEnglish?: Maybe<Scalars['String']>
  lastNameErrorEnglish?: Maybe<Scalars['String']>
  jobTitleLabelEnglish?: Maybe<Scalars['String']>
  jobTitleErrorEnglish?: Maybe<Scalars['String']>
  divisionLabelEnglish?: Maybe<Scalars['String']>
  divisionErrorEnglish?: Maybe<Scalars['String']>
  nextButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  subTitleFrench?: Maybe<Scalars['String']>
  firstNameLabelFrench?: Maybe<Scalars['String']>
  firstNameErrorFrench?: Maybe<Scalars['String']>
  lastNameLabelFrench?: Maybe<Scalars['String']>
  lastNameErrorFrench?: Maybe<Scalars['String']>
  jobTitleLabelFrench?: Maybe<Scalars['String']>
  jobTitleErrorFrench?: Maybe<Scalars['String']>
  divisionLabelFrench?: Maybe<Scalars['String']>
  divisionErrorFrench?: Maybe<Scalars['String']>
  nextButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  subTitleGerman?: Maybe<Scalars['String']>
  firstNameLabelGerman?: Maybe<Scalars['String']>
  firstNameErrorGerman?: Maybe<Scalars['String']>
  lastNameLabelGerman?: Maybe<Scalars['String']>
  lastNameErrorGerman?: Maybe<Scalars['String']>
  jobTitleLabelGerman?: Maybe<Scalars['String']>
  jobTitleErrorGerman?: Maybe<Scalars['String']>
  divisionLabelGerman?: Maybe<Scalars['String']>
  divisionErrorGerman?: Maybe<Scalars['String']>
  nextButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  subTitleSpanish?: Maybe<Scalars['String']>
  firstNameLabelSpanish?: Maybe<Scalars['String']>
  firstNameErrorSpanish?: Maybe<Scalars['String']>
  lastNameLabelSpanish?: Maybe<Scalars['String']>
  lastNameErrorSpanish?: Maybe<Scalars['String']>
  jobTitleLabelSpanish?: Maybe<Scalars['String']>
  jobTitleErrorSpanish?: Maybe<Scalars['String']>
  divisionLabelSpanish?: Maybe<Scalars['String']>
  divisionErrorSpanish?: Maybe<Scalars['String']>
  nextButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  subTitleMandarin?: Maybe<Scalars['String']>
  firstNameLabelMandarin?: Maybe<Scalars['String']>
  firstNameErrorMandarin?: Maybe<Scalars['String']>
  lastNameLabelMandarin?: Maybe<Scalars['String']>
  lastNameErrorMandarin?: Maybe<Scalars['String']>
  jobTitleLabelMandarin?: Maybe<Scalars['String']>
  jobTitleErrorMandarin?: Maybe<Scalars['String']>
  divisionLabelMandarin?: Maybe<Scalars['String']>
  divisionErrorMandarin?: Maybe<Scalars['String']>
  nextButtonMandarin?: Maybe<Scalars['String']>
  timezoneErrorEnglish?: Maybe<Scalars['String']>
  timezoneLabelSpanish?: Maybe<Scalars['String']>
  timezoneLabelFrench?: Maybe<Scalars['String']>
  timezoneLabelGerman?: Maybe<Scalars['String']>
  timezoneLabelMandarin?: Maybe<Scalars['String']>
  timezoneLabelEnglish?: Maybe<Scalars['String']>
  timezoneErrorFrench?: Maybe<Scalars['String']>
  timezoneErrorSpanish?: Maybe<Scalars['String']>
  timezoneErrorGerman?: Maybe<Scalars['String']>
  timezoneErrorMandarin?: Maybe<Scalars['String']>
  regionLabelEnglish?: Maybe<Scalars['String']>
  regionErrorEnglish?: Maybe<Scalars['String']>
  regionLabelFrench?: Maybe<Scalars['String']>
  regionErrorFrench?: Maybe<Scalars['String']>
  regionLabelSpanish?: Maybe<Scalars['String']>
  regionErrorSpanish?: Maybe<Scalars['String']>
  regionLabelGerman?: Maybe<Scalars['String']>
  regionErrorGerman?: Maybe<Scalars['String']>
  regionLabelMandarin?: Maybe<Scalars['String']>
  regionErrorMandarin?: Maybe<Scalars['String']>
  leftTitleEnglish?: Maybe<Scalars['String']>
  leftSubtitleEnglish?: Maybe<Scalars['String']>
  leftTitleSpanish?: Maybe<Scalars['String']>
  leftSubtitleSpanish?: Maybe<Scalars['String']>
  leftTitleMandarin?: Maybe<Scalars['String']>
  leftSubTitleMandarin?: Maybe<Scalars['String']>
  leftTitleFrench?: Maybe<Scalars['String']>
  leftSubtitleFrench?: Maybe<Scalars['String']>
  leftSubtitleGerman?: Maybe<Scalars['String']>
  leftTitleGerman?: Maybe<Scalars['String']>
  leftTitleCzech?: Maybe<Scalars['String']>
  havingTroubleEnglish?: Maybe<Scalars['String']>
  havingTroubleSpanish?: Maybe<Scalars['String']>
  havingTroubleFrench?: Maybe<Scalars['String']>
  havingTroubleMandarin?: Maybe<Scalars['String']>
  havingTroubleGerman?: Maybe<Scalars['String']>
  getHelpButtonEnglish?: Maybe<Scalars['String']>
  getHelpButtonSpanish?: Maybe<Scalars['String']>
  getHelpButtonFrench?: Maybe<Scalars['String']>
  getHelpButtonGerman?: Maybe<Scalars['String']>
  getHelpButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMenuInput = {
  id?: Maybe<Scalars['ID']>
  homeEnglish?: Maybe<Scalars['String']>
  myProgrammeEnglish?: Maybe<Scalars['String']>
  myWorkEnglish?: Maybe<Scalars['String']>
  myLibraryEnglish?: Maybe<Scalars['String']>
  myProfileEnglish?: Maybe<Scalars['String']>
  helpAndSupportEnglish?: Maybe<Scalars['String']>
  signOutEnglish?: Maybe<Scalars['String']>
  homeFrench?: Maybe<Scalars['String']>
  myProgrammeFrench?: Maybe<Scalars['String']>
  myWorkFrench?: Maybe<Scalars['String']>
  myLibraryFrench?: Maybe<Scalars['String']>
  myProfileFrench?: Maybe<Scalars['String']>
  helpAndSupportFrench?: Maybe<Scalars['String']>
  signOutFrench?: Maybe<Scalars['String']>
  homeGerman?: Maybe<Scalars['String']>
  myProgrammeGerman?: Maybe<Scalars['String']>
  myWorkGerman?: Maybe<Scalars['String']>
  myLibraryGerman?: Maybe<Scalars['String']>
  myProfileGerman?: Maybe<Scalars['String']>
  helpAndSupportGerman?: Maybe<Scalars['String']>
  signOutGerman?: Maybe<Scalars['String']>
  homeSpanish?: Maybe<Scalars['String']>
  myProgrammeSpanish?: Maybe<Scalars['String']>
  myWorkSpanish?: Maybe<Scalars['String']>
  myLibrarySpanish?: Maybe<Scalars['String']>
  myProfileSpanish?: Maybe<Scalars['String']>
  helpAndSupportSpanish?: Maybe<Scalars['String']>
  signOutSpanish?: Maybe<Scalars['String']>
  homeMandarin?: Maybe<Scalars['String']>
  myProgrammeMandarin?: Maybe<Scalars['String']>
  myWorkMandarin?: Maybe<Scalars['String']>
  myLibraryMandarin?: Maybe<Scalars['String']>
  myProfileMandarin?: Maybe<Scalars['String']>
  helpAndSupportMandarin?: Maybe<Scalars['String']>
  signOutMandarin?: Maybe<Scalars['String']>
  aboutUsEnglish?: Maybe<Scalars['String']>
  aboutUsFrench?: Maybe<Scalars['String']>
  aboutUsGerman?: Maybe<Scalars['String']>
  myNotesMandarin?: Maybe<Scalars['String']>
  myNotesGerman?: Maybe<Scalars['String']>
  myNotesSpanish?: Maybe<Scalars['String']>
  myNotesEnglish?: Maybe<Scalars['String']>
  myNotesFrench?: Maybe<Scalars['String']>
  notificationsEnglish?: Maybe<Scalars['String']>
  notificationsFrench?: Maybe<Scalars['String']>
  notificationsGerman?: Maybe<Scalars['String']>
  notificationsSpanish?: Maybe<Scalars['String']>
  notificationsMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMenu = {
  __typename?: 'ComponentTranslationsMenu'
  id: Scalars['ID']
  homeEnglish?: Maybe<Scalars['String']>
  myProgrammeEnglish?: Maybe<Scalars['String']>
  myWorkEnglish?: Maybe<Scalars['String']>
  myLibraryEnglish?: Maybe<Scalars['String']>
  myProfileEnglish?: Maybe<Scalars['String']>
  helpAndSupportEnglish?: Maybe<Scalars['String']>
  signOutEnglish?: Maybe<Scalars['String']>
  homeFrench?: Maybe<Scalars['String']>
  myProgrammeFrench?: Maybe<Scalars['String']>
  myWorkFrench?: Maybe<Scalars['String']>
  myLibraryFrench?: Maybe<Scalars['String']>
  myProfileFrench?: Maybe<Scalars['String']>
  helpAndSupportFrench?: Maybe<Scalars['String']>
  signOutFrench?: Maybe<Scalars['String']>
  homeGerman?: Maybe<Scalars['String']>
  myProgrammeGerman?: Maybe<Scalars['String']>
  myWorkGerman?: Maybe<Scalars['String']>
  myLibraryGerman?: Maybe<Scalars['String']>
  myProfileGerman?: Maybe<Scalars['String']>
  helpAndSupportGerman?: Maybe<Scalars['String']>
  signOutGerman?: Maybe<Scalars['String']>
  homeSpanish?: Maybe<Scalars['String']>
  myProgrammeSpanish?: Maybe<Scalars['String']>
  myWorkSpanish?: Maybe<Scalars['String']>
  myLibrarySpanish?: Maybe<Scalars['String']>
  myProfileSpanish?: Maybe<Scalars['String']>
  helpAndSupportSpanish?: Maybe<Scalars['String']>
  signOutSpanish?: Maybe<Scalars['String']>
  homeMandarin?: Maybe<Scalars['String']>
  myProgrammeMandarin?: Maybe<Scalars['String']>
  myWorkMandarin?: Maybe<Scalars['String']>
  myLibraryMandarin?: Maybe<Scalars['String']>
  myProfileMandarin?: Maybe<Scalars['String']>
  helpAndSupportMandarin?: Maybe<Scalars['String']>
  signOutMandarin?: Maybe<Scalars['String']>
  aboutUsEnglish?: Maybe<Scalars['String']>
  aboutUsFrench?: Maybe<Scalars['String']>
  aboutUsGerman?: Maybe<Scalars['String']>
  myNotesMandarin?: Maybe<Scalars['String']>
  myNotesGerman?: Maybe<Scalars['String']>
  myNotesSpanish?: Maybe<Scalars['String']>
  myNotesEnglish?: Maybe<Scalars['String']>
  myNotesFrench?: Maybe<Scalars['String']>
  notificationsEnglish?: Maybe<Scalars['String']>
  notificationsFrench?: Maybe<Scalars['String']>
  notificationsGerman?: Maybe<Scalars['String']>
  notificationsSpanish?: Maybe<Scalars['String']>
  notificationsMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMyLibraryScreenInput = {
  id?: Maybe<Scalars['ID']>
  allArticlesEnglish?: Maybe<Scalars['String']>
  myBookmarksEnglish?: Maybe<Scalars['String']>
  allArticlesFrench?: Maybe<Scalars['String']>
  myBookmarksFrench?: Maybe<Scalars['String']>
  allArticlesGerman?: Maybe<Scalars['String']>
  myBookmarksGerman?: Maybe<Scalars['String']>
  allArticlesSpanish?: Maybe<Scalars['String']>
  myBookmarksSpanish?: Maybe<Scalars['String']>
  allArticlesMandarin?: Maybe<Scalars['String']>
  myBookmarksMandarin?: Maybe<Scalars['String']>
  viewArticleEnglish?: Maybe<Scalars['String']>
  viewArticleFrench?: Maybe<Scalars['String']>
  viewArticleSpanish?: Maybe<Scalars['String']>
  viewArticleGerman?: Maybe<Scalars['String']>
  viewArticleMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMyLibraryScreen = {
  __typename?: 'ComponentTranslationsMyLibraryScreen'
  id: Scalars['ID']
  allArticlesEnglish?: Maybe<Scalars['String']>
  myBookmarksEnglish?: Maybe<Scalars['String']>
  allArticlesFrench?: Maybe<Scalars['String']>
  myBookmarksFrench?: Maybe<Scalars['String']>
  allArticlesGerman?: Maybe<Scalars['String']>
  myBookmarksGerman?: Maybe<Scalars['String']>
  allArticlesSpanish?: Maybe<Scalars['String']>
  myBookmarksSpanish?: Maybe<Scalars['String']>
  allArticlesMandarin?: Maybe<Scalars['String']>
  myBookmarksMandarin?: Maybe<Scalars['String']>
  viewArticleEnglish?: Maybe<Scalars['String']>
  viewArticleFrench?: Maybe<Scalars['String']>
  viewArticleSpanish?: Maybe<Scalars['String']>
  viewArticleGerman?: Maybe<Scalars['String']>
  viewArticleMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMyProfileScreenInput = {
  id?: Maybe<Scalars['ID']>
  detailsEnglish?: Maybe<Scalars['String']>
  settingsEnglish?: Maybe<Scalars['String']>
  logoutEnglish?: Maybe<Scalars['String']>
  joinEnglish?: Maybe<Scalars['String']>
  detailsFrench?: Maybe<Scalars['String']>
  settingsFrench?: Maybe<Scalars['String']>
  logoutFrench?: Maybe<Scalars['String']>
  joinFrench?: Maybe<Scalars['String']>
  detailsGerman?: Maybe<Scalars['String']>
  settingsGerman?: Maybe<Scalars['String']>
  logoutGerman?: Maybe<Scalars['String']>
  joinGerman?: Maybe<Scalars['String']>
  detailsSpanish?: Maybe<Scalars['String']>
  settingsSpanish?: Maybe<Scalars['String']>
  logoutSpanish?: Maybe<Scalars['String']>
  joinSpanish?: Maybe<Scalars['String']>
  detailsMandarin?: Maybe<Scalars['String']>
  settingsMandarin?: Maybe<Scalars['String']>
  logoutMandarin?: Maybe<Scalars['String']>
  joinMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMyProfileScreen = {
  __typename?: 'ComponentTranslationsMyProfileScreen'
  id: Scalars['ID']
  detailsEnglish?: Maybe<Scalars['String']>
  settingsEnglish?: Maybe<Scalars['String']>
  logoutEnglish?: Maybe<Scalars['String']>
  joinEnglish?: Maybe<Scalars['String']>
  detailsFrench?: Maybe<Scalars['String']>
  settingsFrench?: Maybe<Scalars['String']>
  logoutFrench?: Maybe<Scalars['String']>
  joinFrench?: Maybe<Scalars['String']>
  detailsGerman?: Maybe<Scalars['String']>
  settingsGerman?: Maybe<Scalars['String']>
  logoutGerman?: Maybe<Scalars['String']>
  joinGerman?: Maybe<Scalars['String']>
  detailsSpanish?: Maybe<Scalars['String']>
  settingsSpanish?: Maybe<Scalars['String']>
  logoutSpanish?: Maybe<Scalars['String']>
  joinSpanish?: Maybe<Scalars['String']>
  detailsMandarin?: Maybe<Scalars['String']>
  settingsMandarin?: Maybe<Scalars['String']>
  logoutMandarin?: Maybe<Scalars['String']>
  joinMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMyWorkScreenInput = {
  id?: Maybe<Scalars['ID']>
  emptyStateTitleEnglish?: Maybe<Scalars['String']>
  emptyStateDescEnglish?: Maybe<Scalars['String']>
  subtitleEnglish?: Maybe<Scalars['String']>
  feedbackEnglish?: Maybe<Scalars['String']>
  feedbackNotEligibleEnglish?: Maybe<Scalars['String']>
  feedbackNotReceivedEnglish?: Maybe<Scalars['String']>
  notStartedEnglish?: Maybe<Scalars['String']>
  emptyStateTitleFrench?: Maybe<Scalars['String']>
  emptyStateDescFrench?: Maybe<Scalars['String']>
  subtitleFrench?: Maybe<Scalars['String']>
  feedbackFrench?: Maybe<Scalars['String']>
  feedbackNotEligibleFrench?: Maybe<Scalars['String']>
  feedbackNotReceivedFrench?: Maybe<Scalars['String']>
  notStartedFrench?: Maybe<Scalars['String']>
  emptyStateTitleGerman?: Maybe<Scalars['String']>
  emptyStateDescGerman?: Maybe<Scalars['String']>
  subtitleGerman?: Maybe<Scalars['String']>
  feedbackGerman?: Maybe<Scalars['String']>
  feedbackNotEligibleGerman?: Maybe<Scalars['String']>
  feedbackNotReceivedGerman?: Maybe<Scalars['String']>
  notStartedGerman?: Maybe<Scalars['String']>
  emptyStateTitleSpanish?: Maybe<Scalars['String']>
  emptyStateDescSpanish?: Maybe<Scalars['String']>
  subtitleSpanish?: Maybe<Scalars['String']>
  feedbackSpanish?: Maybe<Scalars['String']>
  feedbackNotEligibleSpanish?: Maybe<Scalars['String']>
  feedbackNotReceivedSpanish?: Maybe<Scalars['String']>
  notStartedSpanish?: Maybe<Scalars['String']>
  emptyStateTitleMandarin?: Maybe<Scalars['String']>
  emptyStateDescMandarin?: Maybe<Scalars['String']>
  subtitleMandarin?: Maybe<Scalars['String']>
  feedbackMandarin?: Maybe<Scalars['String']>
  feedbackNotEligibleMandarin?: Maybe<Scalars['String']>
  feedbackNotReceivedMandarin?: Maybe<Scalars['String']>
  notStartedMandarin?: Maybe<Scalars['String']>
  downloadContentTextEnglish?: Maybe<Scalars['String']>
  downloadContentTextFrench?: Maybe<Scalars['String']>
  downloadContentTextGerman?: Maybe<Scalars['String']>
  downloadContentTextSpanish?: Maybe<Scalars['String']>
  downloadContentTextMandarin?: Maybe<Scalars['String']>
  myNotesEnglish?: Maybe<Scalars['String']>
  myNotesSpanish?: Maybe<Scalars['String']>
  myNotesMandarin?: Maybe<Scalars['String']>
  myNotesFrench?: Maybe<Scalars['String']>
  myNotesGerman?: Maybe<Scalars['String']>
  moduleReportsEnglish?: Maybe<Scalars['String']>
  moduleReportsMandarin?: Maybe<Scalars['String']>
  moduleReportsSpanish?: Maybe<Scalars['String']>
  moduleReportsFrench?: Maybe<Scalars['String']>
  moduleReportsGerman?: Maybe<Scalars['String']>
  documentsEnglish?: Maybe<Scalars['String']>
  documentsSpanish?: Maybe<Scalars['String']>
  documentsFrench?: Maybe<Scalars['String']>
  documentsMandarin?: Maybe<Scalars['String']>
  documentsGerman?: Maybe<Scalars['String']>
  deleteHeadingEnglish?: Maybe<Scalars['String']>
  deleteHeadingSpanish?: Maybe<Scalars['String']>
  deleteHeadingGerman?: Maybe<Scalars['String']>
  deleteHeadingMandarin?: Maybe<Scalars['String']>
  deleteHeadingFrench?: Maybe<Scalars['String']>
  deleteMessageEnglish?: Maybe<Scalars['String']>
  deleteMessageSpanish?: Maybe<Scalars['String']>
  deleteMessageGerman?: Maybe<Scalars['String']>
  deleteMessageFrench?: Maybe<Scalars['String']>
  deleteMessageMandarin?: Maybe<Scalars['String']>
  recentNotesEnglish?: Maybe<Scalars['String']>
  recentNotesSpanish?: Maybe<Scalars['String']>
  recentNotesGerman?: Maybe<Scalars['String']>
  recentNotesFrench?: Maybe<Scalars['String']>
  recentNotesMandarin?: Maybe<Scalars['String']>
  programmeHeadingEnglish?: Maybe<Scalars['String']>
  programmeHeadingSpanish?: Maybe<Scalars['String']>
  programmeHeadingFrench?: Maybe<Scalars['String']>
  programmeHeadingMandarin?: Maybe<Scalars['String']>
  programmeHeadingGerman?: Maybe<Scalars['String']>
  noNotesHeadingEnglish?: Maybe<Scalars['String']>
  noNotesHeadingSpanish?: Maybe<Scalars['String']>
  noNotesHeadingFrench?: Maybe<Scalars['String']>
  noNotesHeadingMandarin?: Maybe<Scalars['String']>
  noNotesHeadingGerman?: Maybe<Scalars['String']>
  noNotesSubHeadingEnglish?: Maybe<Scalars['String']>
  noNotesSubHeadingFrench?: Maybe<Scalars['String']>
  noNotesSubHeadingSpanish?: Maybe<Scalars['String']>
  noNotesSubHeadingGerman?: Maybe<Scalars['String']>
  noNotesSubHeadingMandarin?: Maybe<Scalars['String']>
  noModuleReportsHeadingEnglish?: Maybe<Scalars['String']>
  noModuleReportsHeadingSpanish?: Maybe<Scalars['String']>
  noModuleReportsHeadingMandarin?: Maybe<Scalars['String']>
  noModuleReportsHeadingFrench?: Maybe<Scalars['String']>
  noModuleReportsHeadingGerman?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingEnglish?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingFrench?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingMandarin?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingGerman?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingSpanish?: Maybe<Scalars['String']>
  noDocumentsHeadingEnglish?: Maybe<Scalars['String']>
  noDocumentsHeadingSpanish?: Maybe<Scalars['String']>
  noDocumentsHeadingGerman?: Maybe<Scalars['String']>
  noDocumentsHeadingMandarin?: Maybe<Scalars['String']>
  noDocumentsHeadingFrench?: Maybe<Scalars['String']>
  noDocumentsSubHeadingEnglish?: Maybe<Scalars['String']>
  noDocumentsSubHeadingFrench?: Maybe<Scalars['String']>
  noDocumentsSubHeadingMandarin?: Maybe<Scalars['String']>
  noDocumentsSubHeadingGerman?: Maybe<Scalars['String']>
  noDocumentsSubHeadingSpanish?: Maybe<Scalars['String']>
  sectionCompletedEnglish?: Maybe<Scalars['String']>
  sectionCompletedSpanish?: Maybe<Scalars['String']>
  sectionCompletedGerman?: Maybe<Scalars['String']>
  sectionCompletedFrench?: Maybe<Scalars['String']>
  sectionCompletedMandarin?: Maybe<Scalars['String']>
  documentsUploadedEnglish?: Maybe<Scalars['String']>
  documentsUploadedSpanish?: Maybe<Scalars['String']>
  documentsUploadedFrench?: Maybe<Scalars['String']>
  documentsUploadedMandarin?: Maybe<Scalars['String']>
  documentsUploadedGerman?: Maybe<Scalars['String']>
  uploadButtonEnglish?: Maybe<Scalars['String']>
  uploadButtonFrench?: Maybe<Scalars['String']>
  uploadButtonMandarin?: Maybe<Scalars['String']>
  uploadButtonSpanish?: Maybe<Scalars['String']>
  uploadButtonGerman?: Maybe<Scalars['String']>
}

export type ComponentTranslationsMyWorkScreen = {
  __typename?: 'ComponentTranslationsMyWorkScreen'
  id: Scalars['ID']
  emptyStateTitleEnglish?: Maybe<Scalars['String']>
  emptyStateDescEnglish?: Maybe<Scalars['String']>
  subtitleEnglish?: Maybe<Scalars['String']>
  feedbackEnglish?: Maybe<Scalars['String']>
  feedbackNotEligibleEnglish?: Maybe<Scalars['String']>
  feedbackNotReceivedEnglish?: Maybe<Scalars['String']>
  notStartedEnglish?: Maybe<Scalars['String']>
  emptyStateTitleFrench?: Maybe<Scalars['String']>
  emptyStateDescFrench?: Maybe<Scalars['String']>
  subtitleFrench?: Maybe<Scalars['String']>
  feedbackFrench?: Maybe<Scalars['String']>
  feedbackNotEligibleFrench?: Maybe<Scalars['String']>
  feedbackNotReceivedFrench?: Maybe<Scalars['String']>
  notStartedFrench?: Maybe<Scalars['String']>
  emptyStateTitleGerman?: Maybe<Scalars['String']>
  emptyStateDescGerman?: Maybe<Scalars['String']>
  subtitleGerman?: Maybe<Scalars['String']>
  feedbackGerman?: Maybe<Scalars['String']>
  feedbackNotEligibleGerman?: Maybe<Scalars['String']>
  feedbackNotReceivedGerman?: Maybe<Scalars['String']>
  notStartedGerman?: Maybe<Scalars['String']>
  emptyStateTitleSpanish?: Maybe<Scalars['String']>
  emptyStateDescSpanish?: Maybe<Scalars['String']>
  subtitleSpanish?: Maybe<Scalars['String']>
  feedbackSpanish?: Maybe<Scalars['String']>
  feedbackNotEligibleSpanish?: Maybe<Scalars['String']>
  feedbackNotReceivedSpanish?: Maybe<Scalars['String']>
  notStartedSpanish?: Maybe<Scalars['String']>
  emptyStateTitleMandarin?: Maybe<Scalars['String']>
  emptyStateDescMandarin?: Maybe<Scalars['String']>
  subtitleMandarin?: Maybe<Scalars['String']>
  feedbackMandarin?: Maybe<Scalars['String']>
  feedbackNotEligibleMandarin?: Maybe<Scalars['String']>
  feedbackNotReceivedMandarin?: Maybe<Scalars['String']>
  notStartedMandarin?: Maybe<Scalars['String']>
  downloadContentTextEnglish?: Maybe<Scalars['String']>
  downloadContentTextFrench?: Maybe<Scalars['String']>
  downloadContentTextGerman?: Maybe<Scalars['String']>
  downloadContentTextSpanish?: Maybe<Scalars['String']>
  downloadContentTextMandarin?: Maybe<Scalars['String']>
  myNotesEnglish?: Maybe<Scalars['String']>
  myNotesSpanish?: Maybe<Scalars['String']>
  myNotesMandarin?: Maybe<Scalars['String']>
  myNotesFrench?: Maybe<Scalars['String']>
  myNotesGerman?: Maybe<Scalars['String']>
  moduleReportsEnglish?: Maybe<Scalars['String']>
  moduleReportsMandarin?: Maybe<Scalars['String']>
  moduleReportsSpanish?: Maybe<Scalars['String']>
  moduleReportsFrench?: Maybe<Scalars['String']>
  moduleReportsGerman?: Maybe<Scalars['String']>
  documentsEnglish?: Maybe<Scalars['String']>
  documentsSpanish?: Maybe<Scalars['String']>
  documentsFrench?: Maybe<Scalars['String']>
  documentsMandarin?: Maybe<Scalars['String']>
  documentsGerman?: Maybe<Scalars['String']>
  deleteHeadingEnglish?: Maybe<Scalars['String']>
  deleteHeadingSpanish?: Maybe<Scalars['String']>
  deleteHeadingGerman?: Maybe<Scalars['String']>
  deleteHeadingMandarin?: Maybe<Scalars['String']>
  deleteHeadingFrench?: Maybe<Scalars['String']>
  deleteMessageEnglish?: Maybe<Scalars['String']>
  deleteMessageSpanish?: Maybe<Scalars['String']>
  deleteMessageGerman?: Maybe<Scalars['String']>
  deleteMessageFrench?: Maybe<Scalars['String']>
  deleteMessageMandarin?: Maybe<Scalars['String']>
  recentNotesEnglish?: Maybe<Scalars['String']>
  recentNotesSpanish?: Maybe<Scalars['String']>
  recentNotesGerman?: Maybe<Scalars['String']>
  recentNotesFrench?: Maybe<Scalars['String']>
  recentNotesMandarin?: Maybe<Scalars['String']>
  programmeHeadingEnglish?: Maybe<Scalars['String']>
  programmeHeadingSpanish?: Maybe<Scalars['String']>
  programmeHeadingFrench?: Maybe<Scalars['String']>
  programmeHeadingMandarin?: Maybe<Scalars['String']>
  programmeHeadingGerman?: Maybe<Scalars['String']>
  noNotesHeadingEnglish?: Maybe<Scalars['String']>
  noNotesHeadingSpanish?: Maybe<Scalars['String']>
  noNotesHeadingFrench?: Maybe<Scalars['String']>
  noNotesHeadingMandarin?: Maybe<Scalars['String']>
  noNotesHeadingGerman?: Maybe<Scalars['String']>
  noNotesSubHeadingEnglish?: Maybe<Scalars['String']>
  noNotesSubHeadingFrench?: Maybe<Scalars['String']>
  noNotesSubHeadingSpanish?: Maybe<Scalars['String']>
  noNotesSubHeadingGerman?: Maybe<Scalars['String']>
  noNotesSubHeadingMandarin?: Maybe<Scalars['String']>
  noModuleReportsHeadingEnglish?: Maybe<Scalars['String']>
  noModuleReportsHeadingSpanish?: Maybe<Scalars['String']>
  noModuleReportsHeadingMandarin?: Maybe<Scalars['String']>
  noModuleReportsHeadingFrench?: Maybe<Scalars['String']>
  noModuleReportsHeadingGerman?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingEnglish?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingFrench?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingMandarin?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingGerman?: Maybe<Scalars['String']>
  noModuleReportsSubHeadingSpanish?: Maybe<Scalars['String']>
  noDocumentsHeadingEnglish?: Maybe<Scalars['String']>
  noDocumentsHeadingSpanish?: Maybe<Scalars['String']>
  noDocumentsHeadingGerman?: Maybe<Scalars['String']>
  noDocumentsHeadingMandarin?: Maybe<Scalars['String']>
  noDocumentsHeadingFrench?: Maybe<Scalars['String']>
  noDocumentsSubHeadingEnglish?: Maybe<Scalars['String']>
  noDocumentsSubHeadingFrench?: Maybe<Scalars['String']>
  noDocumentsSubHeadingMandarin?: Maybe<Scalars['String']>
  noDocumentsSubHeadingGerman?: Maybe<Scalars['String']>
  noDocumentsSubHeadingSpanish?: Maybe<Scalars['String']>
  sectionCompletedEnglish?: Maybe<Scalars['String']>
  sectionCompletedSpanish?: Maybe<Scalars['String']>
  sectionCompletedGerman?: Maybe<Scalars['String']>
  sectionCompletedFrench?: Maybe<Scalars['String']>
  sectionCompletedMandarin?: Maybe<Scalars['String']>
  documentsUploadedEnglish?: Maybe<Scalars['String']>
  documentsUploadedSpanish?: Maybe<Scalars['String']>
  documentsUploadedFrench?: Maybe<Scalars['String']>
  documentsUploadedMandarin?: Maybe<Scalars['String']>
  documentsUploadedGerman?: Maybe<Scalars['String']>
  uploadButtonEnglish?: Maybe<Scalars['String']>
  uploadButtonFrench?: Maybe<Scalars['String']>
  uploadButtonMandarin?: Maybe<Scalars['String']>
  uploadButtonSpanish?: Maybe<Scalars['String']>
  uploadButtonGerman?: Maybe<Scalars['String']>
}

export type ComponentTranslationsPersonalScreenInput = {
  id?: Maybe<Scalars['ID']>
  changePhotoEnglish?: Maybe<Scalars['String']>
  firstNameLabelEnglish?: Maybe<Scalars['String']>
  lastNameLabelEnglish?: Maybe<Scalars['String']>
  emailLabelEnglish?: Maybe<Scalars['String']>
  jobTitleLabelEnglish?: Maybe<Scalars['String']>
  divisionLabelEnglish?: Maybe<Scalars['String']>
  editInfoButtonEnglish?: Maybe<Scalars['String']>
  changePhotoFrench?: Maybe<Scalars['String']>
  firstNameLabelFrench?: Maybe<Scalars['String']>
  lastNameLabelFrench?: Maybe<Scalars['String']>
  emailLabelFrench?: Maybe<Scalars['String']>
  jobTitleLabelFrench?: Maybe<Scalars['String']>
  divisionLabelFrench?: Maybe<Scalars['String']>
  editInfoButtonFrench?: Maybe<Scalars['String']>
  changePhotoGerman?: Maybe<Scalars['String']>
  firstNameLabelGerman?: Maybe<Scalars['String']>
  lastNameLabelGerman?: Maybe<Scalars['String']>
  emailLabelGerman?: Maybe<Scalars['String']>
  jobTitleLabelGerman?: Maybe<Scalars['String']>
  divisionLabelGerman?: Maybe<Scalars['String']>
  editInfoButtonGerman?: Maybe<Scalars['String']>
  changePhotoSpanish?: Maybe<Scalars['String']>
  firstNameLabelSpanish?: Maybe<Scalars['String']>
  lastNameLabelSpanish?: Maybe<Scalars['String']>
  emailLabelSpanish?: Maybe<Scalars['String']>
  jobTitleLabelSpanish?: Maybe<Scalars['String']>
  divisionLabelSpanish?: Maybe<Scalars['String']>
  editInfoButtonSpanish?: Maybe<Scalars['String']>
  changePhotoMandarin?: Maybe<Scalars['String']>
  firstNameLabelMandarin?: Maybe<Scalars['String']>
  lastNameLabelMandarin?: Maybe<Scalars['String']>
  emailLabelMandarin?: Maybe<Scalars['String']>
  jobTitleLabelMandarin?: Maybe<Scalars['String']>
  divisionLabelMandarin?: Maybe<Scalars['String']>
  editInfoButtonMandarin?: Maybe<Scalars['String']>
  resetPasswordEnglish?: Maybe<Scalars['String']>
  resetPasswordFrench?: Maybe<Scalars['String']>
  resetPasswordSpanish?: Maybe<Scalars['String']>
  resetPasswordGerman?: Maybe<Scalars['String']>
  resetPasswordMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsPersonalScreen = {
  __typename?: 'ComponentTranslationsPersonalScreen'
  id: Scalars['ID']
  changePhotoEnglish?: Maybe<Scalars['String']>
  firstNameLabelEnglish?: Maybe<Scalars['String']>
  lastNameLabelEnglish?: Maybe<Scalars['String']>
  emailLabelEnglish?: Maybe<Scalars['String']>
  jobTitleLabelEnglish?: Maybe<Scalars['String']>
  divisionLabelEnglish?: Maybe<Scalars['String']>
  editInfoButtonEnglish?: Maybe<Scalars['String']>
  changePhotoFrench?: Maybe<Scalars['String']>
  firstNameLabelFrench?: Maybe<Scalars['String']>
  lastNameLabelFrench?: Maybe<Scalars['String']>
  emailLabelFrench?: Maybe<Scalars['String']>
  jobTitleLabelFrench?: Maybe<Scalars['String']>
  divisionLabelFrench?: Maybe<Scalars['String']>
  editInfoButtonFrench?: Maybe<Scalars['String']>
  changePhotoGerman?: Maybe<Scalars['String']>
  firstNameLabelGerman?: Maybe<Scalars['String']>
  lastNameLabelGerman?: Maybe<Scalars['String']>
  emailLabelGerman?: Maybe<Scalars['String']>
  jobTitleLabelGerman?: Maybe<Scalars['String']>
  divisionLabelGerman?: Maybe<Scalars['String']>
  editInfoButtonGerman?: Maybe<Scalars['String']>
  changePhotoSpanish?: Maybe<Scalars['String']>
  firstNameLabelSpanish?: Maybe<Scalars['String']>
  lastNameLabelSpanish?: Maybe<Scalars['String']>
  emailLabelSpanish?: Maybe<Scalars['String']>
  jobTitleLabelSpanish?: Maybe<Scalars['String']>
  divisionLabelSpanish?: Maybe<Scalars['String']>
  editInfoButtonSpanish?: Maybe<Scalars['String']>
  changePhotoMandarin?: Maybe<Scalars['String']>
  firstNameLabelMandarin?: Maybe<Scalars['String']>
  lastNameLabelMandarin?: Maybe<Scalars['String']>
  emailLabelMandarin?: Maybe<Scalars['String']>
  jobTitleLabelMandarin?: Maybe<Scalars['String']>
  divisionLabelMandarin?: Maybe<Scalars['String']>
  editInfoButtonMandarin?: Maybe<Scalars['String']>
  resetPasswordEnglish?: Maybe<Scalars['String']>
  resetPasswordFrench?: Maybe<Scalars['String']>
  resetPasswordSpanish?: Maybe<Scalars['String']>
  resetPasswordGerman?: Maybe<Scalars['String']>
  resetPasswordMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsPrivacyPolicyInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  textEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  textFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  textGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  textSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  textMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsPrivacyPolicy = {
  __typename?: 'ComponentTranslationsPrivacyPolicy'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  textEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  textFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  textGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  textSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  textMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsProgrammeScreenInput = {
  id?: Maybe<Scalars['ID']>
  changeButtonEnglish?: Maybe<Scalars['String']>
  changeButtonFrench?: Maybe<Scalars['String']>
  changeButtonGerman?: Maybe<Scalars['String']>
  changeButtonSpanish?: Maybe<Scalars['String']>
  changeButtonMandarin?: Maybe<Scalars['String']>
  programmeOverviewEnglish?: Maybe<Scalars['String']>
  programmeOverviewFrench?: Maybe<Scalars['String']>
  programmeOverviewSpanish?: Maybe<Scalars['String']>
  programmeOverviewGerman?: Maybe<Scalars['String']>
  programmeOverviewMandarin?: Maybe<Scalars['String']>
  programmeTextEnglish?: Maybe<Scalars['String']>
  programmeTextFrench?: Maybe<Scalars['String']>
  programmeTextSpanish?: Maybe<Scalars['String']>
  programmeTextGerman?: Maybe<Scalars['String']>
  programmeTextMandarin?: Maybe<Scalars['String']>
  informationEnglish?: Maybe<Scalars['String']>
  informationFrench?: Maybe<Scalars['String']>
  informationGerman?: Maybe<Scalars['String']>
  informationSpanish?: Maybe<Scalars['String']>
  informationMandarin?: Maybe<Scalars['String']>
  selectProgrammesTitleEnglish?: Maybe<Scalars['String']>
  selectProgrammesTitleFrench?: Maybe<Scalars['String']>
  selectProgrammesTitleGerman?: Maybe<Scalars['String']>
  selectProgrammesTitleSpanish?: Maybe<Scalars['String']>
  selectProgrammesTitleMandarin?: Maybe<Scalars['String']>
  selectProgrammesSubTitleEnglish?: Maybe<Scalars['String']>
  selectProgrammesSubTitleFrench?: Maybe<Scalars['String']>
  selectProgrammesSubTitleGerman?: Maybe<Scalars['String']>
  selectProgrammesSubTitleSpanish?: Maybe<Scalars['String']>
  selectProgrammesSubTitleMandarin?: Maybe<Scalars['String']>
  selectButtonEnglish?: Maybe<Scalars['String']>
  selectButtonFrench?: Maybe<Scalars['String']>
  selectButtonGerman?: Maybe<Scalars['String']>
  selectButtonSpanish?: Maybe<Scalars['String']>
  selectButtonMandarin?: Maybe<Scalars['String']>
  changeProgrammeTypeEnglish?: Maybe<Scalars['String']>
  changeProgrammeTypeFrench?: Maybe<Scalars['String']>
  changeProgrammeTypeGerman?: Maybe<Scalars['String']>
  changeProgrammeTypeSpanish?: Maybe<Scalars['String']>
  changeProgrammeTypeMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsProgrammeScreen = {
  __typename?: 'ComponentTranslationsProgrammeScreen'
  id: Scalars['ID']
  changeButtonEnglish?: Maybe<Scalars['String']>
  changeButtonFrench?: Maybe<Scalars['String']>
  changeButtonGerman?: Maybe<Scalars['String']>
  changeButtonSpanish?: Maybe<Scalars['String']>
  changeButtonMandarin?: Maybe<Scalars['String']>
  programmeOverviewEnglish?: Maybe<Scalars['String']>
  programmeOverviewFrench?: Maybe<Scalars['String']>
  programmeOverviewSpanish?: Maybe<Scalars['String']>
  programmeOverviewGerman?: Maybe<Scalars['String']>
  programmeOverviewMandarin?: Maybe<Scalars['String']>
  programmeTextEnglish?: Maybe<Scalars['String']>
  programmeTextFrench?: Maybe<Scalars['String']>
  programmeTextSpanish?: Maybe<Scalars['String']>
  programmeTextGerman?: Maybe<Scalars['String']>
  programmeTextMandarin?: Maybe<Scalars['String']>
  informationEnglish?: Maybe<Scalars['String']>
  informationFrench?: Maybe<Scalars['String']>
  informationGerman?: Maybe<Scalars['String']>
  informationSpanish?: Maybe<Scalars['String']>
  informationMandarin?: Maybe<Scalars['String']>
  selectProgrammesTitleEnglish?: Maybe<Scalars['String']>
  selectProgrammesTitleFrench?: Maybe<Scalars['String']>
  selectProgrammesTitleGerman?: Maybe<Scalars['String']>
  selectProgrammesTitleSpanish?: Maybe<Scalars['String']>
  selectProgrammesTitleMandarin?: Maybe<Scalars['String']>
  selectProgrammesSubTitleEnglish?: Maybe<Scalars['String']>
  selectProgrammesSubTitleFrench?: Maybe<Scalars['String']>
  selectProgrammesSubTitleGerman?: Maybe<Scalars['String']>
  selectProgrammesSubTitleSpanish?: Maybe<Scalars['String']>
  selectProgrammesSubTitleMandarin?: Maybe<Scalars['String']>
  selectButtonEnglish?: Maybe<Scalars['String']>
  selectButtonFrench?: Maybe<Scalars['String']>
  selectButtonGerman?: Maybe<Scalars['String']>
  selectButtonSpanish?: Maybe<Scalars['String']>
  selectButtonMandarin?: Maybe<Scalars['String']>
  changeProgrammeTypeEnglish?: Maybe<Scalars['String']>
  changeProgrammeTypeFrench?: Maybe<Scalars['String']>
  changeProgrammeTypeGerman?: Maybe<Scalars['String']>
  changeProgrammeTypeSpanish?: Maybe<Scalars['String']>
  changeProgrammeTypeMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsSettingsScreenInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  saveButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  saveButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  saveButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  saveButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  saveButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsSettingsScreen = {
  __typename?: 'ComponentTranslationsSettingsScreen'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  saveButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  saveButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  saveButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  saveButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  saveButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsTermsOfServiceInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  textEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  textFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  textGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  textSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  textMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsTermsOfService = {
  __typename?: 'ComponentTranslationsTermsOfService'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  textEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  textFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  textGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  textSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  textMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsWelcomeScreenFiltersInput = {
  titleEnglish?: Maybe<StringFilterInput>
  bodyEnglish?: Maybe<StringFilterInput>
  nextButtonEnglish?: Maybe<StringFilterInput>
  titleFrench?: Maybe<StringFilterInput>
  bodyFrench?: Maybe<StringFilterInput>
  nextButtonFrench?: Maybe<StringFilterInput>
  titleGerman?: Maybe<StringFilterInput>
  bodyGerman?: Maybe<StringFilterInput>
  nextButtonGerman?: Maybe<StringFilterInput>
  titleSpanish?: Maybe<StringFilterInput>
  bodySpanish?: Maybe<StringFilterInput>
  nextButtonSpanish?: Maybe<StringFilterInput>
  titleMandarin?: Maybe<StringFilterInput>
  bodyMandarin?: Maybe<StringFilterInput>
  nextButtonMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsWelcomeScreenFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsWelcomeScreenFiltersInput>>>
  not?: Maybe<ComponentTranslationsWelcomeScreenFiltersInput>
}

export type ComponentTranslationsWelcomeScreenInput = {
  id?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  bodyEnglish?: Maybe<Scalars['String']>
  videoEnglish?: Maybe<Scalars['ID']>
  nextButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  bodyFrench?: Maybe<Scalars['String']>
  videoFrench?: Maybe<Scalars['ID']>
  nextButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  bodyGerman?: Maybe<Scalars['String']>
  videoGerman?: Maybe<Scalars['ID']>
  nextButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  bodySpanish?: Maybe<Scalars['String']>
  videoSpanish?: Maybe<Scalars['ID']>
  nextButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  bodyMandarin?: Maybe<Scalars['String']>
  videoMandarin?: Maybe<Scalars['ID']>
  nextButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsWelcomeScreen = {
  __typename?: 'ComponentTranslationsWelcomeScreen'
  id: Scalars['ID']
  titleEnglish?: Maybe<Scalars['String']>
  bodyEnglish?: Maybe<Scalars['String']>
  videoEnglish?: Maybe<UploadFileEntityResponse>
  nextButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  bodyFrench?: Maybe<Scalars['String']>
  videoFrench?: Maybe<UploadFileEntityResponse>
  nextButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  bodyGerman?: Maybe<Scalars['String']>
  videoGerman?: Maybe<UploadFileEntityResponse>
  nextButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  bodySpanish?: Maybe<Scalars['String']>
  videoSpanish?: Maybe<UploadFileEntityResponse>
  nextButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  bodyMandarin?: Maybe<Scalars['String']>
  videoMandarin?: Maybe<UploadFileEntityResponse>
  nextButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsWhatToExpectFiltersInput = {
  titleEnglish?: Maybe<StringFilterInput>
  bodyEnglish?: Maybe<StringFilterInput>
  getStartedButtonEnglish?: Maybe<StringFilterInput>
  titleFrench?: Maybe<StringFilterInput>
  bodyFrench?: Maybe<StringFilterInput>
  getStartedButtonFrench?: Maybe<StringFilterInput>
  titleGerman?: Maybe<StringFilterInput>
  bodyGerman?: Maybe<StringFilterInput>
  getStartedButtonGerman?: Maybe<StringFilterInput>
  titleSpanish?: Maybe<StringFilterInput>
  bodySpanish?: Maybe<StringFilterInput>
  getStartedButtonSpanish?: Maybe<StringFilterInput>
  titleMandarin?: Maybe<StringFilterInput>
  bodyMandarin?: Maybe<StringFilterInput>
  getStartedButtonMandarin?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentTranslationsWhatToExpectFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentTranslationsWhatToExpectFiltersInput>>>
  not?: Maybe<ComponentTranslationsWhatToExpectFiltersInput>
}

export type ComponentTranslationsWhatToExpectInput = {
  id?: Maybe<Scalars['ID']>
  image?: Maybe<Scalars['ID']>
  titleEnglish?: Maybe<Scalars['String']>
  bodyEnglish?: Maybe<Scalars['String']>
  getStartedButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  bodyFrench?: Maybe<Scalars['String']>
  getStartedButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  bodyGerman?: Maybe<Scalars['String']>
  getStartedButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  bodySpanish?: Maybe<Scalars['String']>
  getStartedButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  bodyMandarin?: Maybe<Scalars['String']>
  getStartedButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentTranslationsWhatToExpect = {
  __typename?: 'ComponentTranslationsWhatToExpect'
  id: Scalars['ID']
  image?: Maybe<UploadFileEntityResponse>
  titleEnglish?: Maybe<Scalars['String']>
  bodyEnglish?: Maybe<Scalars['String']>
  getStartedButtonEnglish?: Maybe<Scalars['String']>
  titleFrench?: Maybe<Scalars['String']>
  bodyFrench?: Maybe<Scalars['String']>
  getStartedButtonFrench?: Maybe<Scalars['String']>
  titleGerman?: Maybe<Scalars['String']>
  bodyGerman?: Maybe<Scalars['String']>
  getStartedButtonGerman?: Maybe<Scalars['String']>
  titleSpanish?: Maybe<Scalars['String']>
  bodySpanish?: Maybe<Scalars['String']>
  getStartedButtonSpanish?: Maybe<Scalars['String']>
  titleMandarin?: Maybe<Scalars['String']>
  bodyMandarin?: Maybe<Scalars['String']>
  getStartedButtonMandarin?: Maybe<Scalars['String']>
}

export type ComponentXyGraphItemXyGraphItemFiltersInput = {
  valueX?: Maybe<IntFilterInput>
  valueY?: Maybe<IntFilterInput>
  color?: Maybe<StringFilterInput>
  and?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>>>
  or?: Maybe<Array<Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>>>
  not?: Maybe<ComponentXyGraphItemXyGraphItemFiltersInput>
}

export type ComponentXyGraphItemXyGraphItemInput = {
  id?: Maybe<Scalars['ID']>
  valueX?: Maybe<Scalars['Int']>
  valueY?: Maybe<Scalars['Int']>
  image?: Maybe<Scalars['ID']>
  color?: Maybe<Scalars['String']>
}

export type ComponentXyGraphItemXyGraphItem = {
  __typename?: 'ComponentXYGraphItemXYGraphItem'
  id: Scalars['ID']
  valueX?: Maybe<Scalars['Int']>
  valueY?: Maybe<Scalars['Int']>
  image?: Maybe<UploadFileEntityResponse>
  color?: Maybe<Scalars['String']>
}

export type UploadFileFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  alternativeText?: Maybe<StringFilterInput>
  caption?: Maybe<StringFilterInput>
  width?: Maybe<IntFilterInput>
  height?: Maybe<IntFilterInput>
  formats?: Maybe<JsonFilterInput>
  hash?: Maybe<StringFilterInput>
  ext?: Maybe<StringFilterInput>
  mime?: Maybe<StringFilterInput>
  size?: Maybe<FloatFilterInput>
  url?: Maybe<StringFilterInput>
  previewUrl?: Maybe<StringFilterInput>
  provider?: Maybe<StringFilterInput>
  provider_metadata?: Maybe<JsonFilterInput>
  folder?: Maybe<UploadFolderFiltersInput>
  folderPath?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UploadFileFiltersInput>>>
  or?: Maybe<Array<Maybe<UploadFileFiltersInput>>>
  not?: Maybe<UploadFileFiltersInput>
}

export type UploadFileInput = {
  name?: Maybe<Scalars['String']>
  alternativeText?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  formats?: Maybe<Scalars['JSON']>
  hash?: Maybe<Scalars['String']>
  ext?: Maybe<Scalars['String']>
  mime?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Float']>
  url?: Maybe<Scalars['String']>
  previewUrl?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  provider_metadata?: Maybe<Scalars['JSON']>
  folder?: Maybe<Scalars['ID']>
  folderPath?: Maybe<Scalars['String']>
}

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UploadFile>
}

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse'
  data?: Maybe<UploadFileEntity>
}

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection'
  data: Array<UploadFileEntity>
  meta: ResponseCollectionMeta
}

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection'
  data: Array<UploadFileEntity>
}

export type UploadFolderFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  pathId?: Maybe<IntFilterInput>
  parent?: Maybe<UploadFolderFiltersInput>
  children?: Maybe<UploadFolderFiltersInput>
  files?: Maybe<UploadFileFiltersInput>
  path?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>
  or?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>
  not?: Maybe<UploadFolderFiltersInput>
}

export type UploadFolderInput = {
  name?: Maybe<Scalars['String']>
  pathId?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['ID']>
  children?: Maybe<Array<Maybe<Scalars['ID']>>>
  files?: Maybe<Array<Maybe<Scalars['ID']>>>
  path?: Maybe<Scalars['String']>
}

export type UploadFolder = {
  __typename?: 'UploadFolder'
  name: Scalars['String']
  pathId: Scalars['Int']
  parent?: Maybe<UploadFolderEntityResponse>
  children?: Maybe<UploadFolderRelationResponseCollection>
  files?: Maybe<UploadFileRelationResponseCollection>
  path: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UploadFolderChildrenArgs = {
  filters?: Maybe<UploadFolderFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UploadFolderFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UploadFolder>
}

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse'
  data?: Maybe<UploadFolderEntity>
}

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection'
  data: Array<UploadFolderEntity>
  meta: ResponseCollectionMeta
}

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection'
  data: Array<UploadFolderEntity>
}

export type I18NLocaleFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  code?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>
  or?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>
  not?: Maybe<I18NLocaleFiltersInput>
}

export type I18NLocale = {
  __typename?: 'I18NLocale'
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<I18NLocale>
}

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse'
  data?: Maybe<I18NLocaleEntity>
}

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection'
  data: Array<I18NLocaleEntity>
  meta: ResponseCollectionMeta
}

export type UsersPermissionsPermissionFiltersInput = {
  id?: Maybe<IdFilterInput>
  action?: Maybe<StringFilterInput>
  role?: Maybe<UsersPermissionsRoleFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>
  or?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>
  not?: Maybe<UsersPermissionsPermissionFiltersInput>
}

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission'
  action: Scalars['String']
  role?: Maybe<UsersPermissionsRoleEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UsersPermissionsPermission>
}

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection'
  data: Array<UsersPermissionsPermissionEntity>
}

export type UsersPermissionsRoleFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  description?: Maybe<StringFilterInput>
  type?: Maybe<StringFilterInput>
  permissions?: Maybe<UsersPermissionsPermissionFiltersInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  companies?: Maybe<CompanyFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>
  or?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>
  not?: Maybe<UsersPermissionsRoleFiltersInput>
}

export type UsersPermissionsRoleInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  companies?: Maybe<CompanyRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UsersPermissionsRolePermissionsArgs = {
  filters?: Maybe<UsersPermissionsPermissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsRoleUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsRoleCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UsersPermissionsRole>
}

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse'
  data?: Maybe<UsersPermissionsRoleEntity>
}

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection'
  data: Array<UsersPermissionsRoleEntity>
  meta: ResponseCollectionMeta
}

export type UsersPermissionsRoleRelationResponseCollection = {
  __typename?: 'UsersPermissionsRoleRelationResponseCollection'
  data: Array<UsersPermissionsRoleEntity>
}

export type UsersPermissionsUserFiltersInput = {
  id?: Maybe<IdFilterInput>
  username?: Maybe<StringFilterInput>
  email?: Maybe<StringFilterInput>
  provider?: Maybe<StringFilterInput>
  password?: Maybe<StringFilterInput>
  resetPasswordToken?: Maybe<StringFilterInput>
  confirmed?: Maybe<BooleanFilterInput>
  blocked?: Maybe<BooleanFilterInput>
  role?: Maybe<UsersPermissionsRoleFiltersInput>
  phoneNumber?: Maybe<StringFilterInput>
  language?: Maybe<LanguageFiltersInput>
  firstName?: Maybe<StringFilterInput>
  lastName?: Maybe<StringFilterInput>
  jobTitle?: Maybe<StringFilterInput>
  modulesRead?: Maybe<JsonFilterInput>
  homePromptsRead?: Maybe<BooleanFilterInput>
  programmePromptsRead?: Maybe<BooleanFilterInput>
  modulePromptsRead?: Maybe<BooleanFilterInput>
  bookmarks?: Maybe<JsonFilterInput>
  submissions?: Maybe<SubmissionFiltersInput>
  company?: Maybe<CompanyFiltersInput>
  articlesViewed?: Maybe<JsonFilterInput>
  postCard?: Maybe<PostCardFiltersInput>
  timezone?: Maybe<ComponentOptionTimezoneFiltersInput>
  loginAttempts?: Maybe<IntFilterInput>
  notifications?: Maybe<NotificationFiltersInput>
  programme_submissions?: Maybe<ProgrammeSubmissionFiltersInput>
  feedForwardUrl?: Maybe<StringFilterInput>
  division?: Maybe<DivisionFiltersInput>
  region?: Maybe<RegionFiltersInput>
  sub_module_submissions?: Maybe<SubModuleSubmissionFiltersInput>
  programmes?: Maybe<ProgrammeFiltersInput>
  coach_submitted_reports?: Maybe<CoachSubmittedReportFiltersInput>
  loginWithSSO?: Maybe<BooleanFilterInput>
  programmeEvalutionEmailSent?: Maybe<BooleanFilterInput>
  updatedDetails?: Maybe<BooleanFilterInput>
  account_changes?: Maybe<AccountChangeFiltersInput>
  notes?: Maybe<NoteFiltersInput>
  user_uploaded_documents?: Maybe<UserUploadedDocumentFiltersInput>
  onboarding?: Maybe<BooleanFilterInput>
  triggerReport?: Maybe<BooleanFilterInput>
  bookings?: Maybe<BookingFiltersInput>
  clientId?: Maybe<LongFilterInput>
  respondents_answers?: Maybe<RespondentsAnswerFiltersInput>
  current_survey?: Maybe<JsonFilterInput>
  coach?: Maybe<CoachFiltersInput>
  three_60_form_id?: Maybe<StringFilterInput>
  submodule_reports?: Maybe<SubmoduleReportFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>
  or?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>
  not?: Maybe<UsersPermissionsUserFiltersInput>
}

export type UsersPermissionsUserInput = {
  username?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  resetPasswordToken?: Maybe<Scalars['String']>
  confirmed?: Maybe<Scalars['Boolean']>
  blocked?: Maybe<Scalars['Boolean']>
  role?: Maybe<Scalars['ID']>
  phoneNumber?: Maybe<Scalars['String']>
  profilePicture?: Maybe<Scalars['ID']>
  language?: Maybe<Scalars['ID']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  modulesRead?: Maybe<Scalars['JSON']>
  homePromptsRead?: Maybe<Scalars['Boolean']>
  programmePromptsRead?: Maybe<Scalars['Boolean']>
  modulePromptsRead?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['JSON']>
  submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  report?: Maybe<Scalars['ID']>
  company?: Maybe<Scalars['ID']>
  articlesViewed?: Maybe<Scalars['JSON']>
  postCard?: Maybe<Scalars['ID']>
  timezone?: Maybe<ComponentOptionTimezoneInput>
  certificate?: Maybe<Scalars['ID']>
  loginAttempts?: Maybe<Scalars['Int']>
  notifications?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  feedForwardUrl?: Maybe<Scalars['String']>
  division?: Maybe<Scalars['ID']>
  region?: Maybe<Scalars['ID']>
  sub_module_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  programmes?: Maybe<Array<Maybe<Scalars['ID']>>>
  coach_submitted_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  loginWithSSO?: Maybe<Scalars['Boolean']>
  programmeEvalutionEmailSent?: Maybe<Scalars['Boolean']>
  updatedDetails?: Maybe<Scalars['Boolean']>
  account_changes?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  user_uploaded_documents?: Maybe<Array<Maybe<Scalars['ID']>>>
  onboarding?: Maybe<Scalars['Boolean']>
  triggerReport?: Maybe<Scalars['Boolean']>
  bookings?: Maybe<Array<Maybe<Scalars['ID']>>>
  clientId?: Maybe<Scalars['Long']>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  current_survey?: Maybe<Scalars['JSON']>
  coach?: Maybe<Scalars['ID']>
  three_60_form_id?: Maybe<Scalars['String']>
  submodule_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UsersPermissionsUser>
}

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse'
  data?: Maybe<UsersPermissionsUserEntity>
}

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection'
  data: Array<UsersPermissionsUserEntity>
  meta: ResponseCollectionMeta
}

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection'
  data: Array<UsersPermissionsUserEntity>
}

export type AccountChangeFiltersInput = {
  id?: Maybe<IdFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  old_division?: Maybe<DivisionFiltersInput>
  old_region?: Maybe<RegionFiltersInput>
  new_division?: Maybe<DivisionFiltersInput>
  new_region?: Maybe<RegionFiltersInput>
  accept?: Maybe<BooleanFilterInput>
  user_updated?: Maybe<BooleanFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<AccountChangeFiltersInput>>>
  or?: Maybe<Array<Maybe<AccountChangeFiltersInput>>>
  not?: Maybe<AccountChangeFiltersInput>
}

export type AccountChange = {
  __typename?: 'AccountChange'
  user?: Maybe<UsersPermissionsUserEntityResponse>
  old_division?: Maybe<DivisionEntityResponse>
  old_region?: Maybe<RegionEntityResponse>
  new_division?: Maybe<DivisionEntityResponse>
  new_region?: Maybe<RegionEntityResponse>
  accept?: Maybe<Scalars['Boolean']>
  user_updated?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type AccountChangeEntity = {
  __typename?: 'AccountChangeEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<AccountChange>
}

export type AccountChangeEntityResponse = {
  __typename?: 'AccountChangeEntityResponse'
  data?: Maybe<AccountChangeEntity>
}

export type AccountChangeEntityResponseCollection = {
  __typename?: 'AccountChangeEntityResponseCollection'
  data: Array<AccountChangeEntity>
  meta: ResponseCollectionMeta
}

export type AccountChangeRelationResponseCollection = {
  __typename?: 'AccountChangeRelationResponseCollection'
  data: Array<AccountChangeEntity>
}

export type AppointmentFiltersInput = {
  id?: Maybe<IdFilterInput>
  calendar_id?: Maybe<LongFilterInput>
  business_id?: Maybe<LongFilterInput>
  appointment_title?: Maybe<StringFilterInput>
  client_name?: Maybe<StringFilterInput>
  client_email?: Maybe<StringFilterInput>
  appointment_schedule_id?: Maybe<LongFilterInput>
  appointment_url?: Maybe<StringFilterInput>
  appointment_start_date?: Maybe<DateTimeFilterInput>
  appointment_end_date?: Maybe<DateTimeFilterInput>
  coach_session?: Maybe<CoachSessionFiltersInput>
  status?: Maybe<BooleanFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<AppointmentFiltersInput>>>
  or?: Maybe<Array<Maybe<AppointmentFiltersInput>>>
  not?: Maybe<AppointmentFiltersInput>
}

export type AppointmentInput = {
  calendar_id?: Maybe<Scalars['Long']>
  business_id?: Maybe<Scalars['Long']>
  appointment_title?: Maybe<Scalars['String']>
  client_name?: Maybe<Scalars['String']>
  client_email?: Maybe<Scalars['String']>
  appointment_schedule_id?: Maybe<Scalars['Long']>
  appointment_url?: Maybe<Scalars['String']>
  appointment_start_date?: Maybe<Scalars['DateTime']>
  appointment_end_date?: Maybe<Scalars['DateTime']>
  coach_session?: Maybe<Scalars['ID']>
  status?: Maybe<Scalars['Boolean']>
}

export type Appointment = {
  __typename?: 'Appointment'
  calendar_id?: Maybe<Scalars['Long']>
  business_id?: Maybe<Scalars['Long']>
  appointment_title?: Maybe<Scalars['String']>
  client_name?: Maybe<Scalars['String']>
  client_email?: Maybe<Scalars['String']>
  appointment_schedule_id?: Maybe<Scalars['Long']>
  appointment_url?: Maybe<Scalars['String']>
  appointment_start_date?: Maybe<Scalars['DateTime']>
  appointment_end_date?: Maybe<Scalars['DateTime']>
  coach_session?: Maybe<CoachSessionEntityResponse>
  status?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type AppointmentEntity = {
  __typename?: 'AppointmentEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Appointment>
}

export type AppointmentEntityResponse = {
  __typename?: 'AppointmentEntityResponse'
  data?: Maybe<AppointmentEntity>
}

export type AppointmentEntityResponseCollection = {
  __typename?: 'AppointmentEntityResponseCollection'
  data: Array<AppointmentEntity>
  meta: ResponseCollectionMeta
}

export type ArticleFiltersInput = {
  id?: Maybe<IdFilterInput>
  title_english?: Maybe<StringFilterInput>
  content_english?: Maybe<StringFilterInput>
  date_published?: Maybe<DateFilterInput>
  read_time?: Maybe<IntFilterInput>
  title_french?: Maybe<StringFilterInput>
  title_german?: Maybe<StringFilterInput>
  title_spanish?: Maybe<StringFilterInput>
  title_mandarin?: Maybe<StringFilterInput>
  content_french?: Maybe<StringFilterInput>
  content_german?: Maybe<StringFilterInput>
  content_spanish?: Maybe<StringFilterInput>
  content_mandarin?: Maybe<StringFilterInput>
  link?: Maybe<StringFilterInput>
  companies?: Maybe<CompanyFiltersInput>
  programme_types?: Maybe<ProgrammeTypeFiltersInput>
  position?: Maybe<LongFilterInput>
  programmes?: Maybe<ProgrammeFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ArticleFiltersInput>>>
  or?: Maybe<Array<Maybe<ArticleFiltersInput>>>
  not?: Maybe<ArticleFiltersInput>
}

export type ArticleInput = {
  title_english?: Maybe<Scalars['String']>
  content_english?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['ID']>
  date_published?: Maybe<Scalars['Date']>
  read_time?: Maybe<Scalars['Int']>
  title_french?: Maybe<Scalars['String']>
  title_german?: Maybe<Scalars['String']>
  title_spanish?: Maybe<Scalars['String']>
  title_mandarin?: Maybe<Scalars['String']>
  content_french?: Maybe<Scalars['String']>
  content_german?: Maybe<Scalars['String']>
  content_spanish?: Maybe<Scalars['String']>
  content_mandarin?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_types?: Maybe<Array<Maybe<Scalars['ID']>>>
  position?: Maybe<Scalars['Long']>
  programmes?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type ArticleEntity = {
  __typename?: 'ArticleEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Article>
}

export type ArticleEntityResponse = {
  __typename?: 'ArticleEntityResponse'
  data?: Maybe<ArticleEntity>
}

export type ArticleEntityResponseCollection = {
  __typename?: 'ArticleEntityResponseCollection'
  data: Array<ArticleEntity>
  meta: ResponseCollectionMeta
}

export type ArticleRelationResponseCollection = {
  __typename?: 'ArticleRelationResponseCollection'
  data: Array<ArticleEntity>
}

export type AuditTrailFiltersInput = {
  id?: Maybe<IdFilterInput>
  details?: Maybe<JsonFilterInput>
  action?: Maybe<StringFilterInput>
  section?: Maybe<StringFilterInput>
  by?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<AuditTrailFiltersInput>>>
  or?: Maybe<Array<Maybe<AuditTrailFiltersInput>>>
  not?: Maybe<AuditTrailFiltersInput>
}

export type AuditTrailInput = {
  details?: Maybe<Scalars['JSON']>
  action?: Maybe<Scalars['String']>
  section?: Maybe<Scalars['String']>
  by?: Maybe<Scalars['String']>
}

export type AuditTrail = {
  __typename?: 'AuditTrail'
  details?: Maybe<Scalars['JSON']>
  action?: Maybe<Scalars['String']>
  section?: Maybe<Scalars['String']>
  by?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type AuditTrailEntity = {
  __typename?: 'AuditTrailEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<AuditTrail>
}

export type AuditTrailEntityResponse = {
  __typename?: 'AuditTrailEntityResponse'
  data?: Maybe<AuditTrailEntity>
}

export type AuditTrailEntityResponseCollection = {
  __typename?: 'AuditTrailEntityResponseCollection'
  data: Array<AuditTrailEntity>
  meta: ResponseCollectionMeta
}

export type BookingFiltersInput = {
  id?: Maybe<IdFilterInput>
  title?: Maybe<StringFilterInput>
  description?: Maybe<StringFilterInput>
  duration?: Maybe<IntFilterInput>
  date?: Maybe<DateTimeFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  confirmed?: Maybe<BooleanFilterInput>
  event_id?: Maybe<IntFilterInput>
  cancelled?: Maybe<BooleanFilterInput>
  is_updated?: Maybe<BooleanFilterInput>
  end?: Maybe<DateTimeFilterInput>
  location?: Maybe<StringFilterInput>
  organiser?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<BookingFiltersInput>>>
  or?: Maybe<Array<Maybe<BookingFiltersInput>>>
  not?: Maybe<BookingFiltersInput>
}

export type BookingInput = {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Int']>
  date?: Maybe<Scalars['DateTime']>
  user?: Maybe<Scalars['ID']>
  confirmed?: Maybe<Scalars['Boolean']>
  event_id?: Maybe<Scalars['Int']>
  cancelled?: Maybe<Scalars['Boolean']>
  is_updated?: Maybe<Scalars['Boolean']>
  end?: Maybe<Scalars['DateTime']>
  location?: Maybe<Scalars['String']>
  organiser?: Maybe<Scalars['String']>
}

export type Booking = {
  __typename?: 'Booking'
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Int']>
  date?: Maybe<Scalars['DateTime']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  confirmed?: Maybe<Scalars['Boolean']>
  event_id?: Maybe<Scalars['Int']>
  cancelled?: Maybe<Scalars['Boolean']>
  is_updated?: Maybe<Scalars['Boolean']>
  end?: Maybe<Scalars['DateTime']>
  location?: Maybe<Scalars['String']>
  organiser?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type BookingEntity = {
  __typename?: 'BookingEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Booking>
}

export type BookingEntityResponse = {
  __typename?: 'BookingEntityResponse'
  data?: Maybe<BookingEntity>
}

export type BookingEntityResponseCollection = {
  __typename?: 'BookingEntityResponseCollection'
  data: Array<BookingEntity>
  meta: ResponseCollectionMeta
}

export type BookingRelationResponseCollection = {
  __typename?: 'BookingRelationResponseCollection'
  data: Array<BookingEntity>
}

export type CoachFiltersInput = {
  id?: Maybe<IdFilterInput>
  email?: Maybe<StringFilterInput>
  first_name?: Maybe<StringFilterInput>
  last_name?: Maybe<StringFilterInput>
  is_coach_added_by_user?: Maybe<BooleanFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<CoachFiltersInput>>>
  or?: Maybe<Array<Maybe<CoachFiltersInput>>>
  not?: Maybe<CoachFiltersInput>
}

export type CoachInput = {
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  is_coach_added_by_user?: Maybe<Scalars['Boolean']>
}

export type Coach = {
  __typename?: 'Coach'
  email: Scalars['String']
  first_name: Scalars['String']
  last_name: Scalars['String']
  is_coach_added_by_user?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CoachEntity = {
  __typename?: 'CoachEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Coach>
}

export type CoachEntityResponse = {
  __typename?: 'CoachEntityResponse'
  data?: Maybe<CoachEntity>
}

export type CoachEntityResponseCollection = {
  __typename?: 'CoachEntityResponseCollection'
  data: Array<CoachEntity>
  meta: ResponseCollectionMeta
}

export type CoachSessionFiltersInput = {
  id?: Maybe<IdFilterInput>
  title?: Maybe<StringFilterInput>
  status?: Maybe<BooleanFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  appointment?: Maybe<AppointmentFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<CoachSessionFiltersInput>>>
  or?: Maybe<Array<Maybe<CoachSessionFiltersInput>>>
  not?: Maybe<CoachSessionFiltersInput>
}

export type CoachSessionInput = {
  title?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Boolean']>
  user?: Maybe<Scalars['ID']>
  programme_submission?: Maybe<Scalars['ID']>
  sub_module_submission?: Maybe<Scalars['ID']>
  appointment?: Maybe<Scalars['ID']>
}

export type CoachSession = {
  __typename?: 'CoachSession'
  title?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Boolean']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  appointment?: Maybe<AppointmentEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CoachSessionEntity = {
  __typename?: 'CoachSessionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<CoachSession>
}

export type CoachSessionEntityResponse = {
  __typename?: 'CoachSessionEntityResponse'
  data?: Maybe<CoachSessionEntity>
}

export type CoachSessionEntityResponseCollection = {
  __typename?: 'CoachSessionEntityResponseCollection'
  data: Array<CoachSessionEntity>
  meta: ResponseCollectionMeta
}

export type CoachSessionRelationResponseCollection = {
  __typename?: 'CoachSessionRelationResponseCollection'
  data: Array<CoachSessionEntity>
}

export type CoachSubmittedReportFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  notes_id?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<CoachSubmittedReportFiltersInput>>>
  or?: Maybe<Array<Maybe<CoachSubmittedReportFiltersInput>>>
  not?: Maybe<CoachSubmittedReportFiltersInput>
}

export type CoachSubmittedReportInput = {
  name?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['ID']>
  notes_id?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponentInput>>>
}

export type CoachSubmittedReport = {
  __typename?: 'CoachSubmittedReport'
  name?: Maybe<Scalars['String']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  notes_id?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponent>>>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CoachSubmittedReportNotes_IdArgs = {
  filters?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CoachSubmittedReportEntity = {
  __typename?: 'CoachSubmittedReportEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<CoachSubmittedReport>
}

export type CoachSubmittedReportEntityResponse = {
  __typename?: 'CoachSubmittedReportEntityResponse'
  data?: Maybe<CoachSubmittedReportEntity>
}

export type CoachSubmittedReportEntityResponseCollection = {
  __typename?: 'CoachSubmittedReportEntityResponseCollection'
  data: Array<CoachSubmittedReportEntity>
  meta: ResponseCollectionMeta
}

export type CoachSubmittedReportRelationResponseCollection = {
  __typename?: 'CoachSubmittedReportRelationResponseCollection'
  data: Array<CoachSubmittedReportEntity>
}

export type CompanyFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  modules?: Maybe<ModuleFiltersInput>
  programmes?: Maybe<ProgrammeFiltersInput>
  roles?: Maybe<UsersPermissionsRoleFiltersInput>
  articles?: Maybe<ArticleFiltersInput>
  welcome_screen?: Maybe<ComponentTranslationsWelcomeScreenFiltersInput>
  language_selection?: Maybe<ComponentTranslationsLanguageSelectionFiltersInput>
  lets_get_started_screen?: Maybe<ComponentTranslationsLetsGetStartedScreenFiltersInput>
  what_to_expect?: Maybe<ComponentTranslationsWhatToExpectFiltersInput>
  divisions?: Maybe<DivisionFiltersInput>
  regions?: Maybe<RegionFiltersInput>
  is_template?: Maybe<BooleanFilterInput>
  programmes_title?: Maybe<StringFilterInput>
  programmes_subTitle?: Maybe<StringFilterInput>
  languages?: Maybe<LanguageFiltersInput>
  about_us?: Maybe<ComponentTranslationsAboutUsFiltersInput>
  email_logs?: Maybe<EmailLogFiltersInput>
  users_confirm_details?: Maybe<BooleanFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<CompanyFiltersInput>>>
  or?: Maybe<Array<Maybe<CompanyFiltersInput>>>
  not?: Maybe<CompanyFiltersInput>
}

export type CompanyInput = {
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['ID']>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  modules?: Maybe<Array<Maybe<Scalars['ID']>>>
  programmes?: Maybe<Array<Maybe<Scalars['ID']>>>
  roles?: Maybe<Array<Maybe<Scalars['ID']>>>
  certificate_template?: Maybe<Scalars['ID']>
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>
  welcome_screen?: Maybe<ComponentTranslationsWelcomeScreenInput>
  language_selection?: Maybe<ComponentTranslationsLanguageSelectionInput>
  lets_get_started_screen?: Maybe<ComponentTranslationsLetsGetStartedScreenInput>
  what_to_expect?: Maybe<ComponentTranslationsWhatToExpectInput>
  divisions?: Maybe<Array<Maybe<Scalars['ID']>>>
  regions?: Maybe<Array<Maybe<Scalars['ID']>>>
  is_template?: Maybe<Scalars['Boolean']>
  programmes_title?: Maybe<Scalars['String']>
  programmes_subTitle?: Maybe<Scalars['String']>
  languages?: Maybe<Array<Maybe<Scalars['ID']>>>
  about_us?: Maybe<ComponentTranslationsAboutUsInput>
  email_logs?: Maybe<Array<Maybe<Scalars['ID']>>>
  users_confirm_details?: Maybe<Scalars['Boolean']>
}

export type Company = {
  __typename?: 'Company'
  name?: Maybe<Scalars['String']>
  logo?: Maybe<UploadFileEntityResponse>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  modules?: Maybe<ModuleRelationResponseCollection>
  programmes?: Maybe<ProgrammeRelationResponseCollection>
  roles?: Maybe<UsersPermissionsRoleRelationResponseCollection>
  certificate_template?: Maybe<UploadFileEntityResponse>
  articles?: Maybe<ArticleRelationResponseCollection>
  welcome_screen?: Maybe<ComponentTranslationsWelcomeScreen>
  language_selection?: Maybe<ComponentTranslationsLanguageSelection>
  lets_get_started_screen?: Maybe<ComponentTranslationsLetsGetStartedScreen>
  what_to_expect?: Maybe<ComponentTranslationsWhatToExpect>
  divisions?: Maybe<DivisionRelationResponseCollection>
  regions?: Maybe<RegionRelationResponseCollection>
  is_template?: Maybe<Scalars['Boolean']>
  programmes_title?: Maybe<Scalars['String']>
  programmes_subTitle?: Maybe<Scalars['String']>
  languages?: Maybe<LanguageRelationResponseCollection>
  about_us?: Maybe<ComponentTranslationsAboutUs>
  email_logs?: Maybe<EmailLogRelationResponseCollection>
  users_confirm_details?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CompanyUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyModulesArgs = {
  filters?: Maybe<ModuleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyProgrammesArgs = {
  filters?: Maybe<ProgrammeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyRolesArgs = {
  filters?: Maybe<UsersPermissionsRoleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyArticlesArgs = {
  filters?: Maybe<ArticleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyDivisionsArgs = {
  filters?: Maybe<DivisionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyRegionsArgs = {
  filters?: Maybe<RegionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyLanguagesArgs = {
  filters?: Maybe<LanguageFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyEmail_LogsArgs = {
  filters?: Maybe<EmailLogFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CompanyEntity = {
  __typename?: 'CompanyEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Company>
}

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse'
  data?: Maybe<CompanyEntity>
}

export type CompanyEntityResponseCollection = {
  __typename?: 'CompanyEntityResponseCollection'
  data: Array<CompanyEntity>
  meta: ResponseCollectionMeta
}

export type CompanyRelationResponseCollection = {
  __typename?: 'CompanyRelationResponseCollection'
  data: Array<CompanyEntity>
}

export type CompetencyUrlFiltersInput = {
  id?: Maybe<IdFilterInput>
  url?: Maybe<StringFilterInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  submission?: Maybe<SubmissionFiltersInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  coach_email?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<CompetencyUrlFiltersInput>>>
  or?: Maybe<Array<Maybe<CompetencyUrlFiltersInput>>>
  not?: Maybe<CompetencyUrlFiltersInput>
}

export type CompetencyUrlInput = {
  url?: Maybe<Scalars['String']>
  programme_submission?: Maybe<Scalars['ID']>
  submission?: Maybe<Scalars['ID']>
  sub_module_submission?: Maybe<Scalars['ID']>
  coach_email?: Maybe<Scalars['String']>
}

export type CompetencyUrl = {
  __typename?: 'CompetencyUrl'
  url?: Maybe<Scalars['String']>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  submission?: Maybe<SubmissionEntityResponse>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  coach_email?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CompetencyUrlEntity = {
  __typename?: 'CompetencyUrlEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<CompetencyUrl>
}

export type CompetencyUrlEntityResponse = {
  __typename?: 'CompetencyUrlEntityResponse'
  data?: Maybe<CompetencyUrlEntity>
}

export type CompetencyUrlEntityResponseCollection = {
  __typename?: 'CompetencyUrlEntityResponseCollection'
  data: Array<CompetencyUrlEntity>
  meta: ResponseCollectionMeta
}

export type DivisionFiltersInput = {
  id?: Maybe<IdFilterInput>
  title_english?: Maybe<StringFilterInput>
  title_french?: Maybe<StringFilterInput>
  title_german?: Maybe<StringFilterInput>
  title_spanish?: Maybe<StringFilterInput>
  title_mandarin?: Maybe<StringFilterInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  companies?: Maybe<CompanyFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<DivisionFiltersInput>>>
  or?: Maybe<Array<Maybe<DivisionFiltersInput>>>
  not?: Maybe<DivisionFiltersInput>
}

export type DivisionInput = {
  title_english?: Maybe<Scalars['String']>
  title_french?: Maybe<Scalars['String']>
  title_german?: Maybe<Scalars['String']>
  title_spanish?: Maybe<Scalars['String']>
  title_mandarin?: Maybe<Scalars['String']>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type Division = {
  __typename?: 'Division'
  title_english?: Maybe<Scalars['String']>
  title_french?: Maybe<Scalars['String']>
  title_german?: Maybe<Scalars['String']>
  title_spanish?: Maybe<Scalars['String']>
  title_mandarin?: Maybe<Scalars['String']>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  companies?: Maybe<CompanyRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type DivisionUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DivisionCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DivisionEntity = {
  __typename?: 'DivisionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Division>
}

export type DivisionEntityResponse = {
  __typename?: 'DivisionEntityResponse'
  data?: Maybe<DivisionEntity>
}

export type DivisionEntityResponseCollection = {
  __typename?: 'DivisionEntityResponseCollection'
  data: Array<DivisionEntity>
  meta: ResponseCollectionMeta
}

export type DivisionRelationResponseCollection = {
  __typename?: 'DivisionRelationResponseCollection'
  data: Array<DivisionEntity>
}

export type EmailLogFiltersInput = {
  id?: Maybe<IdFilterInput>
  to?: Maybe<StringFilterInput>
  subject?: Maybe<StringFilterInput>
  body?: Maybe<StringFilterInput>
  timestamp?: Maybe<DateTimeFilterInput>
  programme?: Maybe<ProgrammeFiltersInput>
  company?: Maybe<CompanyFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<EmailLogFiltersInput>>>
  or?: Maybe<Array<Maybe<EmailLogFiltersInput>>>
  not?: Maybe<EmailLogFiltersInput>
}

export type EmailLogInput = {
  to?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['DateTime']>
  programme?: Maybe<Scalars['ID']>
  company?: Maybe<Scalars['ID']>
}

export type EmailLog = {
  __typename?: 'EmailLog'
  to?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['DateTime']>
  programme?: Maybe<ProgrammeEntityResponse>
  company?: Maybe<CompanyEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type EmailLogEntity = {
  __typename?: 'EmailLogEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<EmailLog>
}

export type EmailLogEntityResponse = {
  __typename?: 'EmailLogEntityResponse'
  data?: Maybe<EmailLogEntity>
}

export type EmailLogEntityResponseCollection = {
  __typename?: 'EmailLogEntityResponseCollection'
  data: Array<EmailLogEntity>
  meta: ResponseCollectionMeta
}

export type EmailLogRelationResponseCollection = {
  __typename?: 'EmailLogRelationResponseCollection'
  data: Array<EmailLogEntity>
}

export type EmailTemplateFiltersInput = {
  id?: Maybe<IdFilterInput>
  subject?: Maybe<StringFilterInput>
  body?: Maybe<StringFilterInput>
  programme_types?: Maybe<ProgrammeTypeFiltersInput>
  sections?: Maybe<SectionFiltersInput>
  programme?: Maybe<ProgrammeFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<EmailTemplateFiltersInput>>>
  or?: Maybe<Array<Maybe<EmailTemplateFiltersInput>>>
  not?: Maybe<EmailTemplateFiltersInput>
}

export type EmailTemplateInput = {
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  programme_types?: Maybe<Array<Maybe<Scalars['ID']>>>
  sections?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme?: Maybe<Scalars['ID']>
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate'
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  programme_types?: Maybe<ProgrammeTypeRelationResponseCollection>
  sections?: Maybe<SectionRelationResponseCollection>
  programme?: Maybe<ProgrammeEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type EmailTemplateProgramme_TypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EmailTemplateSectionsArgs = {
  filters?: Maybe<SectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EmailTemplateEntity = {
  __typename?: 'EmailTemplateEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<EmailTemplate>
}

export type EmailTemplateEntityResponse = {
  __typename?: 'EmailTemplateEntityResponse'
  data?: Maybe<EmailTemplateEntity>
}

export type EmailTemplateEntityResponseCollection = {
  __typename?: 'EmailTemplateEntityResponseCollection'
  data: Array<EmailTemplateEntity>
  meta: ResponseCollectionMeta
}

export type EmailTemplateRelationResponseCollection = {
  __typename?: 'EmailTemplateRelationResponseCollection'
  data: Array<EmailTemplateEntity>
}

export type EventFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  link?: Maybe<StringFilterInput>
  programme_types?: Maybe<ProgrammeTypeFiltersInput>
  sections?: Maybe<SectionFiltersInput>
  session_id?: Maybe<LongFilterInput>
  description?: Maybe<StringFilterInput>
  service_id?: Maybe<LongFilterInput>
  class_id?: Maybe<LongFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<EventFiltersInput>>>
  or?: Maybe<Array<Maybe<EventFiltersInput>>>
  not?: Maybe<EventFiltersInput>
}

export type EventInput = {
  name?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  programme_types?: Maybe<Array<Maybe<Scalars['ID']>>>
  sections?: Maybe<Array<Maybe<Scalars['ID']>>>
  session_id?: Maybe<Scalars['Long']>
  description?: Maybe<Scalars['String']>
  service_id?: Maybe<Scalars['Long']>
  class_id?: Maybe<Scalars['Long']>
}

export type Event = {
  __typename?: 'Event'
  name?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  programme_types?: Maybe<ProgrammeTypeRelationResponseCollection>
  sections?: Maybe<SectionRelationResponseCollection>
  session_id?: Maybe<Scalars['Long']>
  description?: Maybe<Scalars['String']>
  service_id?: Maybe<Scalars['Long']>
  class_id?: Maybe<Scalars['Long']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type EventProgramme_TypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EventSectionsArgs = {
  filters?: Maybe<SectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EventEntity = {
  __typename?: 'EventEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Event>
}

export type EventEntityResponse = {
  __typename?: 'EventEntityResponse'
  data?: Maybe<EventEntity>
}

export type EventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection'
  data: Array<EventEntity>
  meta: ResponseCollectionMeta
}

export type EventRelationResponseCollection = {
  __typename?: 'EventRelationResponseCollection'
  data: Array<EventEntity>
}

export type FeedfowardUrlFiltersInput = {
  id?: Maybe<IdFilterInput>
  url?: Maybe<StringFilterInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  coach_email?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<FeedfowardUrlFiltersInput>>>
  or?: Maybe<Array<Maybe<FeedfowardUrlFiltersInput>>>
  not?: Maybe<FeedfowardUrlFiltersInput>
}

export type FeedfowardUrlInput = {
  url?: Maybe<Scalars['String']>
  programme_submission?: Maybe<Scalars['ID']>
  sub_module_submission?: Maybe<Scalars['ID']>
  coach_email?: Maybe<Scalars['String']>
}

export type FeedfowardUrl = {
  __typename?: 'FeedfowardUrl'
  url?: Maybe<Scalars['String']>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  coach_email?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type FeedfowardUrlEntity = {
  __typename?: 'FeedfowardUrlEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<FeedfowardUrl>
}

export type FeedfowardUrlEntityResponse = {
  __typename?: 'FeedfowardUrlEntityResponse'
  data?: Maybe<FeedfowardUrlEntity>
}

export type FeedfowardUrlEntityResponseCollection = {
  __typename?: 'FeedfowardUrlEntityResponseCollection'
  data: Array<FeedfowardUrlEntity>
  meta: ResponseCollectionMeta
}

export type ForwardedCardFiltersInput = {
  id?: Maybe<IdFilterInput>
  post_card?: Maybe<PostCardFiltersInput>
  p1?: Maybe<StringFilterInput>
  p2?: Maybe<StringFilterInput>
  p3?: Maybe<StringFilterInput>
  p1_sentence?: Maybe<StringFilterInput>
  p2_sentence?: Maybe<StringFilterInput>
  p3_sentence?: Maybe<StringFilterInput>
  signature?: Maybe<StringFilterInput>
  from?: Maybe<StringFilterInput>
  to?: Maybe<StringFilterInput>
  general_text?: Maybe<StringFilterInput>
  dear?: Maybe<StringFilterInput>
  promotion_link?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ForwardedCardFiltersInput>>>
  or?: Maybe<Array<Maybe<ForwardedCardFiltersInput>>>
  not?: Maybe<ForwardedCardFiltersInput>
}

export type ForwardedCardInput = {
  post_card?: Maybe<Scalars['ID']>
  p1?: Maybe<Scalars['String']>
  p2?: Maybe<Scalars['String']>
  p3?: Maybe<Scalars['String']>
  p1_sentence?: Maybe<Scalars['String']>
  p2_sentence?: Maybe<Scalars['String']>
  p3_sentence?: Maybe<Scalars['String']>
  signature?: Maybe<Scalars['String']>
  from?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
  general_text?: Maybe<Scalars['String']>
  dear?: Maybe<Scalars['String']>
  promotion_link?: Maybe<Scalars['String']>
}

export type ForwardedCard = {
  __typename?: 'ForwardedCard'
  post_card?: Maybe<PostCardEntityResponse>
  p1: Scalars['String']
  p2: Scalars['String']
  p3: Scalars['String']
  p1_sentence: Scalars['String']
  p2_sentence: Scalars['String']
  p3_sentence: Scalars['String']
  signature: Scalars['String']
  from: Scalars['String']
  to: Scalars['String']
  general_text?: Maybe<Scalars['String']>
  dear: Scalars['String']
  promotion_link?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ForwardedCardEntity = {
  __typename?: 'ForwardedCardEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<ForwardedCard>
}

export type ForwardedCardEntityResponse = {
  __typename?: 'ForwardedCardEntityResponse'
  data?: Maybe<ForwardedCardEntity>
}

export type ForwardedCardEntityResponseCollection = {
  __typename?: 'ForwardedCardEntityResponseCollection'
  data: Array<ForwardedCardEntity>
  meta: ResponseCollectionMeta
}

export type ForwardedCardRelationResponseCollection = {
  __typename?: 'ForwardedCardRelationResponseCollection'
  data: Array<ForwardedCardEntity>
}

export type InviteFiltersInput = {
  id?: Maybe<IdFilterInput>
  email?: Maybe<StringFilterInput>
  company?: Maybe<CompanyFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<InviteFiltersInput>>>
  or?: Maybe<Array<Maybe<InviteFiltersInput>>>
  not?: Maybe<InviteFiltersInput>
}

export type InviteInput = {
  email?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['ID']>
}

export type Invite = {
  __typename?: 'Invite'
  email?: Maybe<Scalars['String']>
  company?: Maybe<CompanyEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type InviteEntity = {
  __typename?: 'InviteEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Invite>
}

export type InviteEntityResponse = {
  __typename?: 'InviteEntityResponse'
  data?: Maybe<InviteEntity>
}

export type InviteEntityResponseCollection = {
  __typename?: 'InviteEntityResponseCollection'
  data: Array<InviteEntity>
  meta: ResponseCollectionMeta
}

export enum Enum_Language_Name {
  English = 'English',
  French = 'French',
  German = 'German',
  Spanish = 'Spanish',
  Mandarin = 'Mandarin'
}

export type LanguageFiltersInput = {
  id?: Maybe<IdFilterInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  name?: Maybe<StringFilterInput>
  programme_submissions?: Maybe<ProgrammeSubmissionFiltersInput>
  companies?: Maybe<CompanyFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<LanguageFiltersInput>>>
  or?: Maybe<Array<Maybe<LanguageFiltersInput>>>
  not?: Maybe<LanguageFiltersInput>
}

export type LanguageInput = {
  image?: Maybe<Scalars['ID']>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  name?: Maybe<Enum_Language_Name>
  programme_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type Language = {
  __typename?: 'Language'
  image?: Maybe<UploadFileEntityResponse>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  name?: Maybe<Enum_Language_Name>
  programme_submissions?: Maybe<ProgrammeSubmissionRelationResponseCollection>
  companies?: Maybe<CompanyRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type LanguageUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type LanguageProgramme_SubmissionsArgs = {
  filters?: Maybe<ProgrammeSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type LanguageCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type LanguageEntity = {
  __typename?: 'LanguageEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Language>
}

export type LanguageEntityResponse = {
  __typename?: 'LanguageEntityResponse'
  data?: Maybe<LanguageEntity>
}

export type LanguageEntityResponseCollection = {
  __typename?: 'LanguageEntityResponseCollection'
  data: Array<LanguageEntity>
  meta: ResponseCollectionMeta
}

export type LanguageRelationResponseCollection = {
  __typename?: 'LanguageRelationResponseCollection'
  data: Array<LanguageEntity>
}

export type LogoFiltersInput = {
  id?: Maybe<IdFilterInput>
  post_cards?: Maybe<PostCardFiltersInput>
  name?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<LogoFiltersInput>>>
  or?: Maybe<Array<Maybe<LogoFiltersInput>>>
  not?: Maybe<LogoFiltersInput>
}

export type LogoInput = {
  logo?: Maybe<Scalars['ID']>
  post_cards?: Maybe<Array<Maybe<Scalars['ID']>>>
  name?: Maybe<Scalars['String']>
}

export type Logo = {
  __typename?: 'Logo'
  logo?: Maybe<UploadFileEntityResponse>
  post_cards?: Maybe<PostCardRelationResponseCollection>
  name?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type LogoPost_CardsArgs = {
  filters?: Maybe<PostCardFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type LogoEntity = {
  __typename?: 'LogoEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Logo>
}

export type LogoEntityResponse = {
  __typename?: 'LogoEntityResponse'
  data?: Maybe<LogoEntity>
}

export type LogoEntityResponseCollection = {
  __typename?: 'LogoEntityResponseCollection'
  data: Array<LogoEntity>
  meta: ResponseCollectionMeta
}

export type ModalFiltersInput = {
  id?: Maybe<IdFilterInput>
  title?: Maybe<StringFilterInput>
  subtitle?: Maybe<StringFilterInput>
  message?: Maybe<StringFilterInput>
  programme_types?: Maybe<ProgrammeTypeFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ModalFiltersInput>>>
  or?: Maybe<Array<Maybe<ModalFiltersInput>>>
  not?: Maybe<ModalFiltersInput>
}

export type ModalInput = {
  title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['ID']>
  programme_types?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type Modal = {
  __typename?: 'Modal'
  title?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  image?: Maybe<UploadFileEntityResponse>
  programme_types?: Maybe<ProgrammeTypeRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ModalProgramme_TypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ModalEntity = {
  __typename?: 'ModalEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Modal>
}

export type ModalEntityResponse = {
  __typename?: 'ModalEntityResponse'
  data?: Maybe<ModalEntity>
}

export type ModalEntityResponseCollection = {
  __typename?: 'ModalEntityResponseCollection'
  data: Array<ModalEntity>
  meta: ResponseCollectionMeta
}

export type ModuleFiltersInput = {
  id?: Maybe<IdFilterInput>
  name_english?: Maybe<StringFilterInput>
  name_french?: Maybe<StringFilterInput>
  name_german?: Maybe<StringFilterInput>
  name_spanish?: Maybe<StringFilterInput>
  name_mandarin?: Maybe<StringFilterInput>
  sections?: Maybe<SectionFiltersInput>
  submissions?: Maybe<SubmissionFiltersInput>
  description_english?: Maybe<StringFilterInput>
  description_french?: Maybe<StringFilterInput>
  description_german?: Maybe<StringFilterInput>
  description_mandarin?: Maybe<StringFilterInput>
  description_spanish?: Maybe<StringFilterInput>
  companies?: Maybe<CompanyFiltersInput>
  position?: Maybe<IntFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ModuleFiltersInput>>>
  or?: Maybe<Array<Maybe<ModuleFiltersInput>>>
  not?: Maybe<ModuleFiltersInput>
}

export type ModuleInput = {
  name_english?: Maybe<Scalars['String']>
  name_french?: Maybe<Scalars['String']>
  name_german?: Maybe<Scalars['String']>
  name_spanish?: Maybe<Scalars['String']>
  name_mandarin?: Maybe<Scalars['String']>
  sections?: Maybe<Array<Maybe<Scalars['ID']>>>
  cover?: Maybe<Scalars['ID']>
  submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  description_english?: Maybe<Scalars['String']>
  description_french?: Maybe<Scalars['String']>
  description_german?: Maybe<Scalars['String']>
  description_mandarin?: Maybe<Scalars['String']>
  description_spanish?: Maybe<Scalars['String']>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
  position?: Maybe<Scalars['Int']>
}

export type Module = {
  __typename?: 'Module'
  name_english?: Maybe<Scalars['String']>
  name_french?: Maybe<Scalars['String']>
  name_german?: Maybe<Scalars['String']>
  name_spanish?: Maybe<Scalars['String']>
  name_mandarin?: Maybe<Scalars['String']>
  sections?: Maybe<SectionRelationResponseCollection>
  cover?: Maybe<UploadFileEntityResponse>
  submissions?: Maybe<SubmissionRelationResponseCollection>
  description_english?: Maybe<Scalars['String']>
  description_french?: Maybe<Scalars['String']>
  description_german?: Maybe<Scalars['String']>
  description_mandarin?: Maybe<Scalars['String']>
  description_spanish?: Maybe<Scalars['String']>
  companies?: Maybe<CompanyRelationResponseCollection>
  position?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ModuleSectionsArgs = {
  filters?: Maybe<SectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ModuleSubmissionsArgs = {
  filters?: Maybe<SubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ModuleCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ModuleEntity = {
  __typename?: 'ModuleEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Module>
}

export type ModuleEntityResponse = {
  __typename?: 'ModuleEntityResponse'
  data?: Maybe<ModuleEntity>
}

export type ModuleEntityResponseCollection = {
  __typename?: 'ModuleEntityResponseCollection'
  data: Array<ModuleEntity>
  meta: ResponseCollectionMeta
}

export type ModuleRelationResponseCollection = {
  __typename?: 'ModuleRelationResponseCollection'
  data: Array<ModuleEntity>
}

export type NoteFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  note?: Maybe<StringFilterInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  section_or_submission_id?: Maybe<StringFilterInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  notes_id?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  programme?: Maybe<ProgrammeFiltersInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<NoteFiltersInput>>>
  or?: Maybe<Array<Maybe<NoteFiltersInput>>>
  not?: Maybe<NoteFiltersInput>
}

export type NoteInput = {
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  sub_module_submission?: Maybe<Scalars['ID']>
  section_or_submission_id?: Maybe<Scalars['String']>
  programme_submission?: Maybe<Scalars['ID']>
  document?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes_id?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponentInput>>>
  programme?: Maybe<Scalars['ID']>
  user?: Maybe<Scalars['ID']>
}

export type Note = {
  __typename?: 'Note'
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  section_or_submission_id?: Maybe<Scalars['String']>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  document?: Maybe<UploadFileRelationResponseCollection>
  notes_id?: Maybe<Array<Maybe<ComponentSubmissionsSaboteurComponent>>>
  programme?: Maybe<ProgrammeEntityResponse>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type NoteDocumentArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NoteNotes_IdArgs = {
  filters?: Maybe<ComponentSubmissionsSaboteurComponentFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NoteEntity = {
  __typename?: 'NoteEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Note>
}

export type NoteEntityResponse = {
  __typename?: 'NoteEntityResponse'
  data?: Maybe<NoteEntity>
}

export type NoteEntityResponseCollection = {
  __typename?: 'NoteEntityResponseCollection'
  data: Array<NoteEntity>
  meta: ResponseCollectionMeta
}

export type NoteRelationResponseCollection = {
  __typename?: 'NoteRelationResponseCollection'
  data: Array<NoteEntity>
}

export type NotificationFiltersInput = {
  id?: Maybe<IdFilterInput>
  title?: Maybe<StringFilterInput>
  time_stamp?: Maybe<DateTimeFilterInput>
  path?: Maybe<StringFilterInput>
  read?: Maybe<BooleanFilterInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  report?: Maybe<ReportFiltersInput>
  programme_report?: Maybe<ProgrammeReportFiltersInput>
  submodule_report?: Maybe<SubmoduleReportFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<NotificationFiltersInput>>>
  or?: Maybe<Array<Maybe<NotificationFiltersInput>>>
  not?: Maybe<NotificationFiltersInput>
}

export type NotificationInput = {
  title?: Maybe<Scalars['String']>
  time_stamp?: Maybe<Scalars['DateTime']>
  path?: Maybe<Scalars['String']>
  read?: Maybe<Scalars['Boolean']>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  report?: Maybe<Scalars['ID']>
  programme_report?: Maybe<Scalars['ID']>
  submodule_report?: Maybe<Scalars['ID']>
}

export type Notification = {
  __typename?: 'Notification'
  title?: Maybe<Scalars['String']>
  time_stamp?: Maybe<Scalars['DateTime']>
  path?: Maybe<Scalars['String']>
  read?: Maybe<Scalars['Boolean']>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  report?: Maybe<ReportEntityResponse>
  programme_report?: Maybe<ProgrammeReportEntityResponse>
  submodule_report?: Maybe<SubmoduleReportEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type NotificationUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NotificationEntity = {
  __typename?: 'NotificationEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Notification>
}

export type NotificationEntityResponse = {
  __typename?: 'NotificationEntityResponse'
  data?: Maybe<NotificationEntity>
}

export type NotificationEntityResponseCollection = {
  __typename?: 'NotificationEntityResponseCollection'
  data: Array<NotificationEntity>
  meta: ResponseCollectionMeta
}

export type NotificationRelationResponseCollection = {
  __typename?: 'NotificationRelationResponseCollection'
  data: Array<NotificationEntity>
}

export enum Enum_Participant_Type {
  Feedforward = 'Feedforward',
  Competency = 'Competency'
}

export type ParticipantFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  email?: Maybe<StringFilterInput>
  type?: Maybe<StringFilterInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  coach_email?: Maybe<StringFilterInput>
  survey_sent_date?: Maybe<DateTimeFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ParticipantFiltersInput>>>
  or?: Maybe<Array<Maybe<ParticipantFiltersInput>>>
  not?: Maybe<ParticipantFiltersInput>
}

export type ParticipantInput = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  type?: Maybe<Enum_Participant_Type>
  programme_submission?: Maybe<Scalars['ID']>
  sub_module_submission?: Maybe<Scalars['ID']>
  coach_email?: Maybe<Scalars['String']>
  survey_sent_date?: Maybe<Scalars['DateTime']>
}

export type Participant = {
  __typename?: 'Participant'
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  type?: Maybe<Enum_Participant_Type>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  coach_email?: Maybe<Scalars['String']>
  survey_sent_date?: Maybe<Scalars['DateTime']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ParticipantEntity = {
  __typename?: 'ParticipantEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Participant>
}

export type ParticipantEntityResponse = {
  __typename?: 'ParticipantEntityResponse'
  data?: Maybe<ParticipantEntity>
}

export type ParticipantEntityResponseCollection = {
  __typename?: 'ParticipantEntityResponseCollection'
  data: Array<ParticipantEntity>
  meta: ResponseCollectionMeta
}

export type ParticipantRelationResponseCollection = {
  __typename?: 'ParticipantRelationResponseCollection'
  data: Array<ParticipantEntity>
}

export type PostCardFiltersInput = {
  id?: Maybe<IdFilterInput>
  front_image?: Maybe<StringFilterInput>
  token?: Maybe<StringFilterInput>
  post_card_image?: Maybe<PostCardImageFiltersInput>
  forwarded_cards?: Maybe<ForwardedCardFiltersInput>
  front_text?: Maybe<StringFilterInput>
  company_logo?: Maybe<LogoFiltersInput>
  expiration_time?: Maybe<LongFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<PostCardFiltersInput>>>
  or?: Maybe<Array<Maybe<PostCardFiltersInput>>>
  not?: Maybe<PostCardFiltersInput>
}

export type PostCardInput = {
  front_image?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  post_card_image?: Maybe<Scalars['ID']>
  forwarded_cards?: Maybe<Array<Maybe<Scalars['ID']>>>
  front_text?: Maybe<Scalars['String']>
  company_logo?: Maybe<Scalars['ID']>
  expiration_time?: Maybe<Scalars['Long']>
  user?: Maybe<Scalars['ID']>
}

export type PostCard = {
  __typename?: 'PostCard'
  front_image?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  post_card_image?: Maybe<PostCardImageEntityResponse>
  forwarded_cards?: Maybe<ForwardedCardRelationResponseCollection>
  front_text?: Maybe<Scalars['String']>
  company_logo?: Maybe<LogoEntityResponse>
  expiration_time?: Maybe<Scalars['Long']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PostCardForwarded_CardsArgs = {
  filters?: Maybe<ForwardedCardFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PostCardEntity = {
  __typename?: 'PostCardEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<PostCard>
}

export type PostCardEntityResponse = {
  __typename?: 'PostCardEntityResponse'
  data?: Maybe<PostCardEntity>
}

export type PostCardEntityResponseCollection = {
  __typename?: 'PostCardEntityResponseCollection'
  data: Array<PostCardEntity>
  meta: ResponseCollectionMeta
}

export type PostCardRelationResponseCollection = {
  __typename?: 'PostCardRelationResponseCollection'
  data: Array<PostCardEntity>
}

export type PostCardImageFiltersInput = {
  id?: Maybe<IdFilterInput>
  post_cards?: Maybe<PostCardFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<PostCardImageFiltersInput>>>
  or?: Maybe<Array<Maybe<PostCardImageFiltersInput>>>
  not?: Maybe<PostCardImageFiltersInput>
}

export type PostCardImageInput = {
  image?: Maybe<Scalars['ID']>
  post_cards?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type PostCardImage = {
  __typename?: 'PostCardImage'
  image?: Maybe<UploadFileEntityResponse>
  post_cards?: Maybe<PostCardRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PostCardImagePost_CardsArgs = {
  filters?: Maybe<PostCardFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PostCardImageEntity = {
  __typename?: 'PostCardImageEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<PostCardImage>
}

export type PostCardImageEntityResponse = {
  __typename?: 'PostCardImageEntityResponse'
  data?: Maybe<PostCardImageEntity>
}

export type PostCardImageEntityResponseCollection = {
  __typename?: 'PostCardImageEntityResponseCollection'
  data: Array<PostCardImageEntity>
  meta: ResponseCollectionMeta
}

export type PostCardSentenceFiltersInput = {
  id?: Maybe<IdFilterInput>
  post_card_sentence?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<PostCardSentenceFiltersInput>>>
  or?: Maybe<Array<Maybe<PostCardSentenceFiltersInput>>>
  not?: Maybe<PostCardSentenceFiltersInput>
}

export type PostCardSentenceInput = {
  post_card_sentence?: Maybe<Scalars['String']>
}

export type PostCardSentence = {
  __typename?: 'PostCardSentence'
  post_card_sentence?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PostCardSentenceEntity = {
  __typename?: 'PostCardSentenceEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<PostCardSentence>
}

export type PostCardSentenceEntityResponse = {
  __typename?: 'PostCardSentenceEntityResponse'
  data?: Maybe<PostCardSentenceEntity>
}

export type PostCardSentenceEntityResponseCollection = {
  __typename?: 'PostCardSentenceEntityResponseCollection'
  data: Array<PostCardSentenceEntity>
  meta: ResponseCollectionMeta
}

export type ProgrammeFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  description?: Maybe<StringFilterInput>
  companies?: Maybe<CompanyFiltersInput>
  programme_types?: Maybe<ProgrammeTypeFiltersInput>
  programme_submissions?: Maybe<ProgrammeSubmissionFiltersInput>
  start?: Maybe<DateTimeFilterInput>
  is_custom_journey?: Maybe<BooleanFilterInput>
  show_timeline?: Maybe<BooleanFilterInput>
  programme_title?: Maybe<StringFilterInput>
  position?: Maybe<IntFilterInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  articles?: Maybe<ArticleFiltersInput>
  send_type_form?: Maybe<BooleanFilterInput>
  type_form_collection?: Maybe<TypeFormCollectionFiltersInput>
  email_logs?: Maybe<EmailLogFiltersInput>
  email_templates?: Maybe<EmailTemplateFiltersInput>
  what_to_expect?: Maybe<StringFilterInput>
  completion?: Maybe<DateTimeFilterInput>
  notes?: Maybe<NoteFiltersInput>
  is_template?: Maybe<BooleanFilterInput>
  three_60_form_id?: Maybe<StringFilterInput>
  sub_module_submissions?: Maybe<SubModuleSubmissionFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ProgrammeFiltersInput>>>
  or?: Maybe<Array<Maybe<ProgrammeFiltersInput>>>
  not?: Maybe<ProgrammeFiltersInput>
}

export type ProgrammeInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_types?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  start?: Maybe<Scalars['DateTime']>
  is_custom_journey?: Maybe<Scalars['Boolean']>
  show_timeline?: Maybe<Scalars['Boolean']>
  programme_title?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  logo?: Maybe<Scalars['ID']>
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>
  send_type_form?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<Scalars['ID']>
  email_logs?: Maybe<Array<Maybe<Scalars['ID']>>>
  email_templates?: Maybe<Array<Maybe<Scalars['ID']>>>
  what_to_expect?: Maybe<Scalars['String']>
  completion?: Maybe<Scalars['DateTime']>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  is_template?: Maybe<Scalars['Boolean']>
  three_60_form_id?: Maybe<Scalars['String']>
  sub_module_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type Programme = {
  __typename?: 'Programme'
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  companies?: Maybe<CompanyRelationResponseCollection>
  programme_types?: Maybe<ProgrammeTypeRelationResponseCollection>
  programme_submissions?: Maybe<ProgrammeSubmissionRelationResponseCollection>
  start?: Maybe<Scalars['DateTime']>
  is_custom_journey?: Maybe<Scalars['Boolean']>
  show_timeline?: Maybe<Scalars['Boolean']>
  programme_title?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  logo?: Maybe<UploadFileEntityResponse>
  articles?: Maybe<ArticleRelationResponseCollection>
  send_type_form?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<TypeFormCollectionEntityResponse>
  email_logs?: Maybe<EmailLogRelationResponseCollection>
  email_templates?: Maybe<EmailTemplateRelationResponseCollection>
  what_to_expect?: Maybe<Scalars['String']>
  completion?: Maybe<Scalars['DateTime']>
  notes?: Maybe<NoteRelationResponseCollection>
  is_template?: Maybe<Scalars['Boolean']>
  three_60_form_id?: Maybe<Scalars['String']>
  sub_module_submissions?: Maybe<SubModuleSubmissionRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ProgrammeCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeProgramme_TypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeProgramme_SubmissionsArgs = {
  filters?: Maybe<ProgrammeSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeArticlesArgs = {
  filters?: Maybe<ArticleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeEmail_LogsArgs = {
  filters?: Maybe<EmailLogFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeEmail_TemplatesArgs = {
  filters?: Maybe<EmailTemplateFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeNotesArgs = {
  filters?: Maybe<NoteFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeSub_Module_SubmissionsArgs = {
  filters?: Maybe<SubModuleSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeEntity = {
  __typename?: 'ProgrammeEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Programme>
}

export type ProgrammeEntityResponse = {
  __typename?: 'ProgrammeEntityResponse'
  data?: Maybe<ProgrammeEntity>
}

export type ProgrammeEntityResponseCollection = {
  __typename?: 'ProgrammeEntityResponseCollection'
  data: Array<ProgrammeEntity>
  meta: ResponseCollectionMeta
}

export type ProgrammeRelationResponseCollection = {
  __typename?: 'ProgrammeRelationResponseCollection'
  data: Array<ProgrammeEntity>
}

export type ProgrammeReportFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  notification?: Maybe<NotificationFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ProgrammeReportFiltersInput>>>
  or?: Maybe<Array<Maybe<ProgrammeReportFiltersInput>>>
  not?: Maybe<ProgrammeReportFiltersInput>
}

export type ProgrammeReportInput = {
  name?: Maybe<Scalars['String']>
  report?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_submission?: Maybe<Scalars['ID']>
  notification?: Maybe<Scalars['ID']>
}

export type ProgrammeReport = {
  __typename?: 'ProgrammeReport'
  name?: Maybe<Scalars['String']>
  report?: Maybe<UploadFileRelationResponseCollection>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  notification?: Maybe<NotificationEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ProgrammeReportReportArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeReportEntity = {
  __typename?: 'ProgrammeReportEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<ProgrammeReport>
}

export type ProgrammeReportEntityResponse = {
  __typename?: 'ProgrammeReportEntityResponse'
  data?: Maybe<ProgrammeReportEntity>
}

export type ProgrammeReportEntityResponseCollection = {
  __typename?: 'ProgrammeReportEntityResponseCollection'
  data: Array<ProgrammeReportEntity>
  meta: ResponseCollectionMeta
}

export enum Enum_Programmesubmission_Status {
  NotStartedYet = 'not_started_yet',
  InProgress = 'in_progress',
  Completed = 'completed',
  UnderReview = 'under_review',
  Resubmission = 'resubmission'
}

export type ProgrammeSubmissionFiltersInput = {
  id?: Maybe<IdFilterInput>
  status?: Maybe<StringFilterInput>
  progress?: Maybe<IntFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  language?: Maybe<LanguageFiltersInput>
  programme_type?: Maybe<ProgrammeTypeFiltersInput>
  coach_sessions?: Maybe<CoachSessionFiltersInput>
  programme_responses?: Maybe<ComponentSubmissionsProgrammeSubmissionsFiltersInput>
  my_notes?: Maybe<ComponentSubmissionsNotesFiltersInput>
  submission_participants?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
  programme_report?: Maybe<ProgrammeReportFiltersInput>
  programme?: Maybe<ProgrammeFiltersInput>
  respondents_answers?: Maybe<RespondentsAnswerFiltersInput>
  competency_url?: Maybe<CompetencyUrlFiltersInput>
  feedfoward_url?: Maybe<FeedfowardUrlFiltersInput>
  selected?: Maybe<BooleanFilterInput>
  participants?: Maybe<ParticipantFiltersInput>
  uploaded_reports?: Maybe<UploadedReportFiltersInput>
  notes?: Maybe<NoteFiltersInput>
  email_sent?: Maybe<BooleanFilterInput>
  is_updated_by_stakeholder?: Maybe<BooleanFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ProgrammeSubmissionFiltersInput>>>
  or?: Maybe<Array<Maybe<ProgrammeSubmissionFiltersInput>>>
  not?: Maybe<ProgrammeSubmissionFiltersInput>
}

export type ProgrammeSubmissionInput = {
  status?: Maybe<Enum_Programmesubmission_Status>
  progress?: Maybe<Scalars['Int']>
  user?: Maybe<Scalars['ID']>
  language?: Maybe<Scalars['ID']>
  programme_type?: Maybe<Scalars['ID']>
  coach_sessions?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_responses?: Maybe<Array<Maybe<ComponentSubmissionsProgrammeSubmissionsInput>>>
  my_notes?: Maybe<Array<Maybe<ComponentSubmissionsNotesInput>>>
  submission_participants?: Maybe<Array<Maybe<ComponentSubmissionsParticipantsInput>>>
  programme_report?: Maybe<Scalars['ID']>
  programme?: Maybe<Scalars['ID']>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  competency_url?: Maybe<Scalars['ID']>
  feedfoward_url?: Maybe<Scalars['ID']>
  selected?: Maybe<Scalars['Boolean']>
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>
  uploaded_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  email_sent?: Maybe<Scalars['Boolean']>
  is_updated_by_stakeholder?: Maybe<Scalars['Boolean']>
}

export type ProgrammeSubmissionEntity = {
  __typename?: 'ProgrammeSubmissionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<ProgrammeSubmission>
}

export type ProgrammeSubmissionEntityResponse = {
  __typename?: 'ProgrammeSubmissionEntityResponse'
  data?: Maybe<ProgrammeSubmissionEntity>
}

export type ProgrammeSubmissionEntityResponseCollection = {
  __typename?: 'ProgrammeSubmissionEntityResponseCollection'
  data: Array<ProgrammeSubmissionEntity>
  meta: ResponseCollectionMeta
}

export type ProgrammeSubmissionRelationResponseCollection = {
  __typename?: 'ProgrammeSubmissionRelationResponseCollection'
  data: Array<ProgrammeSubmissionEntity>
}

export type ProgrammeTypeProgrammeQuestionsDynamicZone =
  | ComponentQuestionTypesValuesNested
  | ComponentQuestionTypesValuesBubble
  | ComponentQuestionTypesValuesCharacters
  | ComponentQuestionTypesValuesSliders
  | ComponentQuestionTypesValuesGaps
  | ComponentQuestionTypesSaboteurVideos
  | ComponentQuestionTypesMultipleChoice
  | ComponentQuestionTypesSaboteur
  | ComponentQuestionTypesSimpleQuestion
  | ComponentQuestionTypesXyGraph
  | ComponentQuestionTypesNestedQuestions
  | ComponentQuestionTypesUploadQuestion
  | ComponentQuestionTypesGravitasScale
  | ComponentQuestionTypesParticipant
  | ComponentQuestionTypesStakeholderMap
  | ComponentQuestionTypesFeedForward1
  | ComponentQuestionTypesFeedForward2
  | ComponentQuestionTypesSimpleReadOnly
  | ComponentQuestionTypesCompetency2
  | ComponentQuestionTypesCompetency
  | ComponentQuestionTypesGravitasGap
  | ComponentQuestionTypesSliderQuestions
  | ComponentQuestionTypesGravitasSliders
  | Error

export type ProgrammeTypeFiltersInput = {
  id?: Maybe<IdFilterInput>
  title?: Maybe<StringFilterInput>
  description?: Maybe<StringFilterInput>
  is_coaching?: Maybe<BooleanFilterInput>
  show_participants?: Maybe<BooleanFilterInput>
  competency_survey?: Maybe<BooleanFilterInput>
  feedForward_survey?: Maybe<BooleanFilterInput>
  show_session?: Maybe<BooleanFilterInput>
  notes?: Maybe<BooleanFilterInput>
  show_article?: Maybe<BooleanFilterInput>
  deadline?: Maybe<DateTimeFilterInput>
  programme_submissions?: Maybe<ProgrammeSubmissionFiltersInput>
  position?: Maybe<IntFilterInput>
  programmes?: Maybe<ProgrammeFiltersInput>
  modal?: Maybe<ModalFiltersInput>
  module?: Maybe<ModuleFiltersInput>
  is_sub_module?: Maybe<BooleanFilterInput>
  events?: Maybe<EventFiltersInput>
  sub_modules?: Maybe<SubModuleFiltersInput>
  articles?: Maybe<ArticleFiltersInput>
  is_template?: Maybe<BooleanFilterInput>
  competency_email_title?: Maybe<StringFilterInput>
  email_template?: Maybe<EmailTemplateFiltersInput>
  show_statistics?: Maybe<BooleanFilterInput>
  programme_type_instructions?: Maybe<StringFilterInput>
  email_send?: Maybe<BooleanFilterInput>
  type_form_collection?: Maybe<TypeFormCollectionFiltersInput>
  archive?: Maybe<BooleanFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ProgrammeTypeFiltersInput>>>
  or?: Maybe<Array<Maybe<ProgrammeTypeFiltersInput>>>
  not?: Maybe<ProgrammeTypeFiltersInput>
}

export type ProgrammeTypeInput = {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cover?: Maybe<Scalars['ID']>
  is_coaching?: Maybe<Scalars['Boolean']>
  show_participants?: Maybe<Scalars['Boolean']>
  competency_survey?: Maybe<Scalars['Boolean']>
  feedForward_survey?: Maybe<Scalars['Boolean']>
  show_session?: Maybe<Scalars['Boolean']>
  notes?: Maybe<Scalars['Boolean']>
  show_article?: Maybe<Scalars['Boolean']>
  deadline?: Maybe<Scalars['DateTime']>
  programme_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_questions?: Maybe<Array<Scalars['ProgrammeTypeProgrammeQuestionsDynamicZoneInput']>>
  position?: Maybe<Scalars['Int']>
  programmes?: Maybe<Array<Maybe<Scalars['ID']>>>
  modal?: Maybe<Scalars['ID']>
  module?: Maybe<Scalars['ID']>
  is_sub_module?: Maybe<Scalars['Boolean']>
  events?: Maybe<Array<Maybe<Scalars['ID']>>>
  sub_modules?: Maybe<Array<Maybe<Scalars['ID']>>>
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>
  is_template?: Maybe<Scalars['Boolean']>
  competency_email_title?: Maybe<Scalars['String']>
  email_template?: Maybe<Scalars['ID']>
  show_statistics?: Maybe<Scalars['Boolean']>
  programme_type_instructions?: Maybe<Scalars['String']>
  email_send?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<Scalars['ID']>
  archive?: Maybe<Scalars['Boolean']>
}

export type ProgrammeType = {
  __typename?: 'ProgrammeType'
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cover?: Maybe<UploadFileEntityResponse>
  is_coaching?: Maybe<Scalars['Boolean']>
  show_participants?: Maybe<Scalars['Boolean']>
  competency_survey?: Maybe<Scalars['Boolean']>
  feedForward_survey?: Maybe<Scalars['Boolean']>
  show_session?: Maybe<Scalars['Boolean']>
  notes?: Maybe<Scalars['Boolean']>
  show_article?: Maybe<Scalars['Boolean']>
  deadline?: Maybe<Scalars['DateTime']>
  programme_submissions?: Maybe<ProgrammeSubmissionRelationResponseCollection>
  programme_questions?: Maybe<Array<Maybe<ProgrammeTypeProgrammeQuestionsDynamicZone>>>
  position?: Maybe<Scalars['Int']>
  programmes?: Maybe<ProgrammeRelationResponseCollection>
  modal?: Maybe<ModalEntityResponse>
  module?: Maybe<ModuleEntityResponse>
  is_sub_module?: Maybe<Scalars['Boolean']>
  events?: Maybe<EventRelationResponseCollection>
  sub_modules?: Maybe<SubModuleRelationResponseCollection>
  articles?: Maybe<ArticleRelationResponseCollection>
  is_template?: Maybe<Scalars['Boolean']>
  competency_email_title?: Maybe<Scalars['String']>
  email_template?: Maybe<EmailTemplateEntityResponse>
  show_statistics?: Maybe<Scalars['Boolean']>
  programme_type_instructions?: Maybe<Scalars['String']>
  email_send?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<TypeFormCollectionEntityResponse>
  archive?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ProgrammeTypeProgramme_SubmissionsArgs = {
  filters?: Maybe<ProgrammeSubmissionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeTypeProgrammesArgs = {
  filters?: Maybe<ProgrammeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeTypeEventsArgs = {
  filters?: Maybe<EventFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeTypeSub_ModulesArgs = {
  filters?: Maybe<SubModuleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeTypeArticlesArgs = {
  filters?: Maybe<ArticleFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProgrammeTypeEntity = {
  __typename?: 'ProgrammeTypeEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<ProgrammeType>
}

export type ProgrammeTypeEntityResponse = {
  __typename?: 'ProgrammeTypeEntityResponse'
  data?: Maybe<ProgrammeTypeEntity>
}

export type ProgrammeTypeEntityResponseCollection = {
  __typename?: 'ProgrammeTypeEntityResponseCollection'
  data: Array<ProgrammeTypeEntity>
  meta: ResponseCollectionMeta
}

export type ProgrammeTypeRelationResponseCollection = {
  __typename?: 'ProgrammeTypeRelationResponseCollection'
  data: Array<ProgrammeTypeEntity>
}

export type RegionFiltersInput = {
  id?: Maybe<IdFilterInput>
  name_english?: Maybe<StringFilterInput>
  name_spanish?: Maybe<StringFilterInput>
  name_french?: Maybe<StringFilterInput>
  name_german?: Maybe<StringFilterInput>
  name_mandarin?: Maybe<StringFilterInput>
  users?: Maybe<UsersPermissionsUserFiltersInput>
  companies?: Maybe<CompanyFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<RegionFiltersInput>>>
  or?: Maybe<Array<Maybe<RegionFiltersInput>>>
  not?: Maybe<RegionFiltersInput>
}

export type RegionInput = {
  name_english?: Maybe<Scalars['String']>
  name_spanish?: Maybe<Scalars['String']>
  name_french?: Maybe<Scalars['String']>
  name_german?: Maybe<Scalars['String']>
  name_mandarin?: Maybe<Scalars['String']>
  users?: Maybe<Array<Maybe<Scalars['ID']>>>
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type Region = {
  __typename?: 'Region'
  name_english?: Maybe<Scalars['String']>
  name_spanish?: Maybe<Scalars['String']>
  name_french?: Maybe<Scalars['String']>
  name_german?: Maybe<Scalars['String']>
  name_mandarin?: Maybe<Scalars['String']>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
  companies?: Maybe<CompanyRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type RegionUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type RegionCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type RegionEntity = {
  __typename?: 'RegionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Region>
}

export type RegionEntityResponse = {
  __typename?: 'RegionEntityResponse'
  data?: Maybe<RegionEntity>
}

export type RegionEntityResponseCollection = {
  __typename?: 'RegionEntityResponseCollection'
  data: Array<RegionEntity>
  meta: ResponseCollectionMeta
}

export type RegionRelationResponseCollection = {
  __typename?: 'RegionRelationResponseCollection'
  data: Array<RegionEntity>
}

export enum Enum_Report_Type {
  Section = 'Section',
  Module = 'Module',
  AllModules = 'All_Modules',
  MiniSection = 'Mini_Section'
}

export type ReportFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  type?: Maybe<StringFilterInput>
  submission?: Maybe<SubmissionFiltersInput>
  section_or_module_id?: Maybe<StringFilterInput>
  notification?: Maybe<NotificationFiltersInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<ReportFiltersInput>>>
  or?: Maybe<Array<Maybe<ReportFiltersInput>>>
  not?: Maybe<ReportFiltersInput>
}

export type ReportInput = {
  name?: Maybe<Scalars['String']>
  type?: Maybe<Enum_Report_Type>
  submission?: Maybe<Scalars['ID']>
  report?: Maybe<Scalars['ID']>
  section_or_module_id?: Maybe<Scalars['String']>
  notification?: Maybe<Scalars['ID']>
  sub_module_submission?: Maybe<Scalars['ID']>
}

export type ReportEntity = {
  __typename?: 'ReportEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Report>
}

export type ReportEntityResponse = {
  __typename?: 'ReportEntityResponse'
  data?: Maybe<ReportEntity>
}

export type ReportEntityResponseCollection = {
  __typename?: 'ReportEntityResponseCollection'
  data: Array<ReportEntity>
  meta: ResponseCollectionMeta
}

export type ReportRelationResponseCollection = {
  __typename?: 'ReportRelationResponseCollection'
  data: Array<ReportEntity>
}

export type RespondentsAnswerFiltersInput = {
  id?: Maybe<IdFilterInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  email?: Maybe<StringFilterInput>
  submission?: Maybe<SubmissionFiltersInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  form_id?: Maybe<StringFilterInput>
  section_id?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<RespondentsAnswerFiltersInput>>>
  or?: Maybe<Array<Maybe<RespondentsAnswerFiltersInput>>>
  not?: Maybe<RespondentsAnswerFiltersInput>
}

export type RespondentsAnswerInput = {
  programme_submission?: Maybe<Scalars['ID']>
  email?: Maybe<Scalars['String']>
  submission?: Maybe<Scalars['ID']>
  sub_module_submission?: Maybe<Scalars['ID']>
  user?: Maybe<Scalars['ID']>
  form_id?: Maybe<Scalars['String']>
  section_id?: Maybe<Scalars['String']>
}

export type RespondentsAnswer = {
  __typename?: 'RespondentsAnswer'
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  email?: Maybe<Scalars['String']>
  submission?: Maybe<SubmissionEntityResponse>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  form_id?: Maybe<Scalars['String']>
  section_id?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type RespondentsAnswerEntity = {
  __typename?: 'RespondentsAnswerEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<RespondentsAnswer>
}

export type RespondentsAnswerEntityResponse = {
  __typename?: 'RespondentsAnswerEntityResponse'
  data?: Maybe<RespondentsAnswerEntity>
}

export type RespondentsAnswerEntityResponseCollection = {
  __typename?: 'RespondentsAnswerEntityResponseCollection'
  data: Array<RespondentsAnswerEntity>
  meta: ResponseCollectionMeta
}

export type RespondentsAnswerRelationResponseCollection = {
  __typename?: 'RespondentsAnswerRelationResponseCollection'
  data: Array<RespondentsAnswerEntity>
}

export type SectionQuestionsDynamicZone =
  | ComponentQuestionTypesMultipleChoice
  | ComponentQuestionTypesSaboteur
  | ComponentQuestionTypesSimpleQuestion
  | ComponentQuestionTypesXyGraph
  | ComponentQuestionTypesNestedQuestions
  | ComponentQuestionTypesGravitasScale
  | ComponentQuestionTypesStakeholderMap
  | ComponentQuestionTypesSaboteurVideos
  | ComponentQuestionTypesValuesSliders
  | ComponentQuestionTypesSimpleReadOnly
  | ComponentQuestionTypesValuesNested
  | ComponentQuestionTypesValuesGaps
  | ComponentQuestionTypesUploadQuestion
  | ComponentQuestionTypesParticipant
  | ComponentQuestionTypesValuesCharacters
  | ComponentQuestionTypesCompetency
  | ComponentQuestionTypesValuesBubble
  | ComponentQuestionTypesSliderQuestions
  | ComponentQuestionTypesFeedForward1
  | ComponentQuestionTypesFeedForward2
  | ComponentQuestionTypesCompetency2
  | ComponentQuestionTypesGravitasSliders
  | ComponentQuestionTypesUploadDocuments
  | ComponentQuestionTypesGravitasGap
  | Error

export type SectionFiltersInput = {
  id?: Maybe<IdFilterInput>
  title_english?: Maybe<StringFilterInput>
  sub_title_english?: Maybe<StringFilterInput>
  title_french?: Maybe<StringFilterInput>
  sub_title_french?: Maybe<StringFilterInput>
  title_german?: Maybe<StringFilterInput>
  sub_title_german?: Maybe<StringFilterInput>
  title_spanish?: Maybe<StringFilterInput>
  sub_title_spanish?: Maybe<StringFilterInput>
  title_mandarin?: Maybe<StringFilterInput>
  sub_title_mandarin?: Maybe<StringFilterInput>
  module?: Maybe<ModuleFiltersInput>
  position?: Maybe<IntFilterInput>
  sub_module?: Maybe<SubModuleFiltersInput>
  show_session?: Maybe<BooleanFilterInput>
  is_coaching?: Maybe<BooleanFilterInput>
  show_participants?: Maybe<BooleanFilterInput>
  competency_survey?: Maybe<BooleanFilterInput>
  feed_forward_survey?: Maybe<BooleanFilterInput>
  events?: Maybe<EventFiltersInput>
  is_template?: Maybe<BooleanFilterInput>
  get_started_text?: Maybe<StringFilterInput>
  notes?: Maybe<BooleanFilterInput>
  send_type_form?: Maybe<BooleanFilterInput>
  type_form_collection?: Maybe<TypeFormCollectionFiltersInput>
  email_template?: Maybe<EmailTemplateFiltersInput>
  archive?: Maybe<BooleanFilterInput>
  instructions_text?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<SectionFiltersInput>>>
  or?: Maybe<Array<Maybe<SectionFiltersInput>>>
  not?: Maybe<SectionFiltersInput>
}

export type SectionInput = {
  title_english?: Maybe<Scalars['String']>
  sub_title_english?: Maybe<Scalars['String']>
  video_english?: Maybe<Scalars['ID']>
  title_french?: Maybe<Scalars['String']>
  sub_title_french?: Maybe<Scalars['String']>
  video_french?: Maybe<Scalars['ID']>
  title_german?: Maybe<Scalars['String']>
  sub_title_german?: Maybe<Scalars['String']>
  video_german?: Maybe<Scalars['ID']>
  title_spanish?: Maybe<Scalars['String']>
  sub_title_spanish?: Maybe<Scalars['String']>
  video_spanish?: Maybe<Scalars['ID']>
  title_mandarin?: Maybe<Scalars['String']>
  sub_title_mandarin?: Maybe<Scalars['String']>
  video_mandarin?: Maybe<Scalars['ID']>
  module?: Maybe<Scalars['ID']>
  cover?: Maybe<Scalars['ID']>
  position?: Maybe<Scalars['Int']>
  questions?: Maybe<Array<Scalars['SectionQuestionsDynamicZoneInput']>>
  sub_module?: Maybe<Scalars['ID']>
  show_session?: Maybe<Scalars['Boolean']>
  is_coaching?: Maybe<Scalars['Boolean']>
  show_participants?: Maybe<Scalars['Boolean']>
  competency_survey?: Maybe<Scalars['Boolean']>
  feed_forward_survey?: Maybe<Scalars['Boolean']>
  events?: Maybe<Array<Maybe<Scalars['ID']>>>
  is_template?: Maybe<Scalars['Boolean']>
  get_started_text?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['Boolean']>
  send_type_form?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<Scalars['ID']>
  email_template?: Maybe<Scalars['ID']>
  archive?: Maybe<Scalars['Boolean']>
  instructions_text?: Maybe<Scalars['String']>
}

export type Section = {
  __typename?: 'Section'
  title_english?: Maybe<Scalars['String']>
  sub_title_english?: Maybe<Scalars['String']>
  video_english?: Maybe<UploadFileEntityResponse>
  title_french?: Maybe<Scalars['String']>
  sub_title_french?: Maybe<Scalars['String']>
  video_french?: Maybe<UploadFileEntityResponse>
  title_german?: Maybe<Scalars['String']>
  sub_title_german?: Maybe<Scalars['String']>
  video_german?: Maybe<UploadFileEntityResponse>
  title_spanish?: Maybe<Scalars['String']>
  sub_title_spanish?: Maybe<Scalars['String']>
  video_spanish?: Maybe<UploadFileEntityResponse>
  title_mandarin?: Maybe<Scalars['String']>
  sub_title_mandarin?: Maybe<Scalars['String']>
  video_mandarin?: Maybe<UploadFileEntityResponse>
  module?: Maybe<ModuleEntityResponse>
  cover?: Maybe<UploadFileEntityResponse>
  position?: Maybe<Scalars['Int']>
  questions?: Maybe<Array<Maybe<SectionQuestionsDynamicZone>>>
  sub_module?: Maybe<SubModuleEntityResponse>
  show_session?: Maybe<Scalars['Boolean']>
  is_coaching?: Maybe<Scalars['Boolean']>
  show_participants?: Maybe<Scalars['Boolean']>
  competency_survey?: Maybe<Scalars['Boolean']>
  feed_forward_survey?: Maybe<Scalars['Boolean']>
  events?: Maybe<EventRelationResponseCollection>
  is_template?: Maybe<Scalars['Boolean']>
  get_started_text?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['Boolean']>
  send_type_form?: Maybe<Scalars['Boolean']>
  type_form_collection?: Maybe<TypeFormCollectionEntityResponse>
  email_template?: Maybe<EmailTemplateEntityResponse>
  archive?: Maybe<Scalars['Boolean']>
  instructions_text?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SectionEventsArgs = {
  filters?: Maybe<EventFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SectionEntity = {
  __typename?: 'SectionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Section>
}

export type SectionEntityResponse = {
  __typename?: 'SectionEntityResponse'
  data?: Maybe<SectionEntity>
}

export type SectionEntityResponseCollection = {
  __typename?: 'SectionEntityResponseCollection'
  data: Array<SectionEntity>
  meta: ResponseCollectionMeta
}

export type SectionRelationResponseCollection = {
  __typename?: 'SectionRelationResponseCollection'
  data: Array<SectionEntity>
}

export type SubModuleFiltersInput = {
  id?: Maybe<IdFilterInput>
  name_english?: Maybe<StringFilterInput>
  name_french?: Maybe<StringFilterInput>
  name_german?: Maybe<StringFilterInput>
  name_spanish?: Maybe<StringFilterInput>
  name_mandarin?: Maybe<StringFilterInput>
  sections?: Maybe<SectionFiltersInput>
  description_english?: Maybe<StringFilterInput>
  description_french?: Maybe<StringFilterInput>
  description_german?: Maybe<StringFilterInput>
  description_mandarin?: Maybe<StringFilterInput>
  description_spanish?: Maybe<StringFilterInput>
  position?: Maybe<IntFilterInput>
  sub_module_submissions?: Maybe<SubModuleSubmissionFiltersInput>
  programme_type?: Maybe<ProgrammeTypeFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<SubModuleFiltersInput>>>
  or?: Maybe<Array<Maybe<SubModuleFiltersInput>>>
  not?: Maybe<SubModuleFiltersInput>
}

export type SubModuleInput = {
  name_english?: Maybe<Scalars['String']>
  name_french?: Maybe<Scalars['String']>
  name_german?: Maybe<Scalars['String']>
  name_spanish?: Maybe<Scalars['String']>
  name_mandarin?: Maybe<Scalars['String']>
  sections?: Maybe<Array<Maybe<Scalars['ID']>>>
  cover?: Maybe<Scalars['ID']>
  description_english?: Maybe<Scalars['String']>
  description_french?: Maybe<Scalars['String']>
  description_german?: Maybe<Scalars['String']>
  description_mandarin?: Maybe<Scalars['String']>
  description_spanish?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  sub_module_submissions?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_type?: Maybe<Scalars['ID']>
}

export type SubModuleEntity = {
  __typename?: 'SubModuleEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<SubModule>
}

export type SubModuleEntityResponse = {
  __typename?: 'SubModuleEntityResponse'
  data?: Maybe<SubModuleEntity>
}

export type SubModuleEntityResponseCollection = {
  __typename?: 'SubModuleEntityResponseCollection'
  data: Array<SubModuleEntity>
  meta: ResponseCollectionMeta
}

export type SubModuleRelationResponseCollection = {
  __typename?: 'SubModuleRelationResponseCollection'
  data: Array<SubModuleEntity>
}

export enum Enum_Submodulesubmission_Status {
  NotStarted = 'Not_Started',
  InProgress = 'In_Progress',
  UnderReview = 'Under_Review',
  Passed = 'Passed',
  Resubmission = 'Resubmission'
}

export type SubModuleSubmissionFiltersInput = {
  id?: Maybe<IdFilterInput>
  language?: Maybe<LanguageFiltersInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  view?: Maybe<IntFilterInput>
  sub_module?: Maybe<SubModuleFiltersInput>
  reports?: Maybe<ReportFiltersInput>
  section?: Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>
  progress?: Maybe<IntFilterInput>
  status?: Maybe<StringFilterInput>
  time_spent?: Maybe<StringFilterInput>
  coach_sessions?: Maybe<CoachSessionFiltersInput>
  submission_participants?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
  competency_participants?: Maybe<ComponentSubmissionsCompetencyParticipantsFiltersInput>
  respondents_answers?: Maybe<RespondentsAnswerFiltersInput>
  feedfoward_url?: Maybe<FeedfowardUrlFiltersInput>
  competency_url?: Maybe<CompetencyUrlFiltersInput>
  uploaded_reports?: Maybe<UploadedReportFiltersInput>
  participants?: Maybe<ParticipantFiltersInput>
  notes?: Maybe<NoteFiltersInput>
  section_typeform?: Maybe<BooleanFilterInput>
  programme_typeForm?: Maybe<BooleanFilterInput>
  three_60_survey_form_id?: Maybe<StringFilterInput>
  programme?: Maybe<ProgrammeFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<SubModuleSubmissionFiltersInput>>>
  or?: Maybe<Array<Maybe<SubModuleSubmissionFiltersInput>>>
  not?: Maybe<SubModuleSubmissionFiltersInput>
}

export type SubModuleSubmissionInput = {
  language?: Maybe<Scalars['ID']>
  user?: Maybe<Scalars['ID']>
  view?: Maybe<Scalars['Int']>
  sub_module?: Maybe<Scalars['ID']>
  reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  section?: Maybe<Array<Maybe<ComponentSubmissionsSectionSubmissionInput>>>
  progress?: Maybe<Scalars['Int']>
  status?: Maybe<Enum_Submodulesubmission_Status>
  time_spent?: Maybe<Scalars['String']>
  coach_sessions?: Maybe<Array<Maybe<Scalars['ID']>>>
  submission_participants?: Maybe<Array<Maybe<ComponentSubmissionsParticipantsInput>>>
  competency_participants?: Maybe<Array<Maybe<ComponentSubmissionsCompetencyParticipantsInput>>>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  feedfoward_url?: Maybe<Scalars['ID']>
  competency_url?: Maybe<Scalars['ID']>
  uploaded_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>
  notes?: Maybe<Array<Maybe<Scalars['ID']>>>
  section_typeform?: Maybe<Scalars['Boolean']>
  programme_typeForm?: Maybe<Scalars['Boolean']>
  three_60_survey_form_id?: Maybe<Scalars['String']>
  programme?: Maybe<Scalars['ID']>
}

export type SubModuleSubmissionEntity = {
  __typename?: 'SubModuleSubmissionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<SubModuleSubmission>
}

export type SubModuleSubmissionEntityResponse = {
  __typename?: 'SubModuleSubmissionEntityResponse'
  data?: Maybe<SubModuleSubmissionEntity>
}

export type SubModuleSubmissionEntityResponseCollection = {
  __typename?: 'SubModuleSubmissionEntityResponseCollection'
  data: Array<SubModuleSubmissionEntity>
  meta: ResponseCollectionMeta
}

export type SubModuleSubmissionRelationResponseCollection = {
  __typename?: 'SubModuleSubmissionRelationResponseCollection'
  data: Array<SubModuleSubmissionEntity>
}

export enum Enum_Submission_Status {
  NotStarted = 'Not_Started',
  InProgress = 'In_Progress',
  UnderReview = 'Under_Review',
  Passed = 'Passed',
  Resubmission = 'Resubmission'
}

export type SubmissionFiltersInput = {
  id?: Maybe<IdFilterInput>
  language?: Maybe<LanguageFiltersInput>
  section?: Maybe<ComponentSubmissionsSectionSubmissionFiltersInput>
  progress?: Maybe<IntFilterInput>
  status?: Maybe<StringFilterInput>
  time_spent?: Maybe<StringFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  views?: Maybe<IntFilterInput>
  module?: Maybe<ModuleFiltersInput>
  reports?: Maybe<ReportFiltersInput>
  competency_url?: Maybe<CompetencyUrlFiltersInput>
  participants?: Maybe<ComponentSubmissionsParticipantsFiltersInput>
  respondents_answers?: Maybe<RespondentsAnswerFiltersInput>
  uploaded_reports?: Maybe<UploadedReportFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<SubmissionFiltersInput>>>
  or?: Maybe<Array<Maybe<SubmissionFiltersInput>>>
  not?: Maybe<SubmissionFiltersInput>
}

export type SubmissionInput = {
  language?: Maybe<Scalars['ID']>
  section?: Maybe<Array<Maybe<ComponentSubmissionsSectionSubmissionInput>>>
  progress?: Maybe<Scalars['Int']>
  status?: Maybe<Enum_Submission_Status>
  time_spent?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['ID']>
  views?: Maybe<Scalars['Int']>
  module?: Maybe<Scalars['ID']>
  reports?: Maybe<Array<Maybe<Scalars['ID']>>>
  competency_url?: Maybe<Scalars['ID']>
  participants?: Maybe<Array<Maybe<ComponentSubmissionsParticipantsInput>>>
  respondents_answers?: Maybe<Array<Maybe<Scalars['ID']>>>
  uploaded_reports?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type SubmissionEntity = {
  __typename?: 'SubmissionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Submission>
}

export type SubmissionEntityResponse = {
  __typename?: 'SubmissionEntityResponse'
  data?: Maybe<SubmissionEntity>
}

export type SubmissionEntityResponseCollection = {
  __typename?: 'SubmissionEntityResponseCollection'
  data: Array<SubmissionEntity>
  meta: ResponseCollectionMeta
}

export type SubmissionRelationResponseCollection = {
  __typename?: 'SubmissionRelationResponseCollection'
  data: Array<SubmissionEntity>
}

export type SubmoduleReportFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  notification?: Maybe<NotificationFiltersInput>
  section_title?: Maybe<StringFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<SubmoduleReportFiltersInput>>>
  or?: Maybe<Array<Maybe<SubmoduleReportFiltersInput>>>
  not?: Maybe<SubmoduleReportFiltersInput>
}

export type SubmoduleReportInput = {
  name?: Maybe<Scalars['String']>
  report?: Maybe<Array<Maybe<Scalars['ID']>>>
  sub_module_submission?: Maybe<Scalars['ID']>
  notification?: Maybe<Scalars['ID']>
  section_title?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['ID']>
}

export type SubmoduleReport = {
  __typename?: 'SubmoduleReport'
  name?: Maybe<Scalars['String']>
  report?: Maybe<UploadFileRelationResponseCollection>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  notification?: Maybe<NotificationEntityResponse>
  section_title?: Maybe<Scalars['String']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SubmoduleReportReportArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubmoduleReportEntity = {
  __typename?: 'SubmoduleReportEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<SubmoduleReport>
}

export type SubmoduleReportEntityResponse = {
  __typename?: 'SubmoduleReportEntityResponse'
  data?: Maybe<SubmoduleReportEntity>
}

export type SubmoduleReportEntityResponseCollection = {
  __typename?: 'SubmoduleReportEntityResponseCollection'
  data: Array<SubmoduleReportEntity>
  meta: ResponseCollectionMeta
}

export type SubmoduleReportRelationResponseCollection = {
  __typename?: 'SubmoduleReportRelationResponseCollection'
  data: Array<SubmoduleReportEntity>
}

export type SurveyReportFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<SurveyReportFiltersInput>>>
  or?: Maybe<Array<Maybe<SurveyReportFiltersInput>>>
  not?: Maybe<SurveyReportFiltersInput>
}

export type SurveyReportInput = {
  name?: Maybe<Scalars['String']>
  report?: Maybe<Scalars['ID']>
}

export type SurveyReport = {
  __typename?: 'SurveyReport'
  name?: Maybe<Scalars['String']>
  report?: Maybe<UploadFileEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SurveyReportEntity = {
  __typename?: 'SurveyReportEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<SurveyReport>
}

export type SurveyReportEntityResponse = {
  __typename?: 'SurveyReportEntityResponse'
  data?: Maybe<SurveyReportEntity>
}

export type SurveyReportEntityResponseCollection = {
  __typename?: 'SurveyReportEntityResponseCollection'
  data: Array<SurveyReportEntity>
  meta: ResponseCollectionMeta
}

export type TranslationInput = {
  language_selection?: Maybe<ComponentTranslationsLanguageSelectionInput>
  welcome_screen?: Maybe<ComponentTranslationsWelcomeScreenInput>
  lets_get_started_screen?: Maybe<ComponentTranslationsLetsGetStartedScreenInput>
  what_to_expect?: Maybe<ComponentTranslationsWhatToExpectInput>
  home_screen?: Maybe<ComponentTranslationsHomeScreenInput>
  my_work_screen?: Maybe<ComponentTranslationsMyWorkScreenInput>
  my_library_screen?: Maybe<ComponentTranslationsMyLibraryScreenInput>
  my_profile_screen?: Maybe<ComponentTranslationsMyProfileScreenInput>
  personal_screen?: Maybe<ComponentTranslationsPersonalScreenInput>
  menu?: Maybe<ComponentTranslationsMenuInput>
  settings_screen?: Maybe<ComponentTranslationsSettingsScreenInput>
  help_and_support?: Maybe<ComponentTranslationsHelpAndSupportInput>
  about_us?: Maybe<ComponentTranslationsAboutUsInput>
  buttons?: Maybe<ComponentTranslationsButtonsInput>
  programmes_screen?: Maybe<ComponentTranslationsProgrammeScreenInput>
  faqs?: Maybe<ComponentTranslationsFaqsInput>
  terms_of_service?: Maybe<ComponentTranslationsTermsOfServiceInput>
  privacy_policy?: Maybe<ComponentTranslationsPrivacyPolicyInput>
  dashboard_screen?: Maybe<ComponentTranslationsDashboardScreenInput>
}

export type Translation = {
  __typename?: 'Translation'
  language_selection?: Maybe<ComponentTranslationsLanguageSelection>
  welcome_screen?: Maybe<ComponentTranslationsWelcomeScreen>
  lets_get_started_screen?: Maybe<ComponentTranslationsLetsGetStartedScreen>
  what_to_expect?: Maybe<ComponentTranslationsWhatToExpect>
  home_screen?: Maybe<ComponentTranslationsHomeScreen>
  my_work_screen?: Maybe<ComponentTranslationsMyWorkScreen>
  my_library_screen?: Maybe<ComponentTranslationsMyLibraryScreen>
  my_profile_screen?: Maybe<ComponentTranslationsMyProfileScreen>
  personal_screen?: Maybe<ComponentTranslationsPersonalScreen>
  menu?: Maybe<ComponentTranslationsMenu>
  settings_screen?: Maybe<ComponentTranslationsSettingsScreen>
  help_and_support?: Maybe<ComponentTranslationsHelpAndSupport>
  about_us?: Maybe<ComponentTranslationsAboutUs>
  buttons?: Maybe<ComponentTranslationsButtons>
  programmes_screen?: Maybe<ComponentTranslationsProgrammeScreen>
  faqs?: Maybe<ComponentTranslationsFaqs>
  terms_of_service?: Maybe<ComponentTranslationsTermsOfService>
  privacy_policy?: Maybe<ComponentTranslationsPrivacyPolicy>
  dashboard_screen?: Maybe<ComponentTranslationsDashboardScreen>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type TranslationEntity = {
  __typename?: 'TranslationEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<Translation>
}

export type TranslationEntityResponse = {
  __typename?: 'TranslationEntityResponse'
  data?: Maybe<TranslationEntity>
}

export type TypeFormCollectionFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  sections?: Maybe<SectionFiltersInput>
  type_form_url?: Maybe<StringFilterInput>
  programmes?: Maybe<ProgrammeFiltersInput>
  programme_types?: Maybe<ProgrammeTypeFiltersInput>
  submissions?: Maybe<LongFilterInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<TypeFormCollectionFiltersInput>>>
  or?: Maybe<Array<Maybe<TypeFormCollectionFiltersInput>>>
  not?: Maybe<TypeFormCollectionFiltersInput>
}

export type TypeFormCollectionInput = {
  name?: Maybe<Scalars['String']>
  sections?: Maybe<Array<Maybe<Scalars['ID']>>>
  type_form_url?: Maybe<Scalars['String']>
  programmes?: Maybe<Array<Maybe<Scalars['ID']>>>
  programme_types?: Maybe<Array<Maybe<Scalars['ID']>>>
  submissions?: Maybe<Scalars['Long']>
}

export type TypeFormCollection = {
  __typename?: 'TypeFormCollection'
  name?: Maybe<Scalars['String']>
  sections?: Maybe<SectionRelationResponseCollection>
  type_form_url?: Maybe<Scalars['String']>
  programmes?: Maybe<ProgrammeRelationResponseCollection>
  programme_types?: Maybe<ProgrammeTypeRelationResponseCollection>
  submissions?: Maybe<Scalars['Long']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type TypeFormCollectionSectionsArgs = {
  filters?: Maybe<SectionFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeFormCollectionProgrammesArgs = {
  filters?: Maybe<ProgrammeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeFormCollectionProgramme_TypesArgs = {
  filters?: Maybe<ProgrammeTypeFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TypeFormCollectionEntity = {
  __typename?: 'TypeFormCollectionEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<TypeFormCollection>
}

export type TypeFormCollectionEntityResponse = {
  __typename?: 'TypeFormCollectionEntityResponse'
  data?: Maybe<TypeFormCollectionEntity>
}

export type TypeFormCollectionEntityResponseCollection = {
  __typename?: 'TypeFormCollectionEntityResponseCollection'
  data: Array<TypeFormCollectionEntity>
  meta: ResponseCollectionMeta
}

export enum Enum_Uploadedreport_Type {
  Section = 'Section',
  UserUpload = 'UserUpload'
}

export type UploadedReportFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  type?: Maybe<StringFilterInput>
  sub_module_submission?: Maybe<SubModuleSubmissionFiltersInput>
  section_or_module_id?: Maybe<StringFilterInput>
  submission?: Maybe<SubmissionFiltersInput>
  programme_submission?: Maybe<ProgrammeSubmissionFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UploadedReportFiltersInput>>>
  or?: Maybe<Array<Maybe<UploadedReportFiltersInput>>>
  not?: Maybe<UploadedReportFiltersInput>
}

export type UploadedReportInput = {
  name?: Maybe<Scalars['String']>
  type?: Maybe<Enum_Uploadedreport_Type>
  sub_module_submission?: Maybe<Scalars['ID']>
  report?: Maybe<Array<Maybe<Scalars['ID']>>>
  section_or_module_id?: Maybe<Scalars['String']>
  submission?: Maybe<Scalars['ID']>
  programme_submission?: Maybe<Scalars['ID']>
}

export type UploadedReport = {
  __typename?: 'UploadedReport'
  name?: Maybe<Scalars['String']>
  type?: Maybe<Enum_Uploadedreport_Type>
  sub_module_submission?: Maybe<SubModuleSubmissionEntityResponse>
  report?: Maybe<UploadFileRelationResponseCollection>
  section_or_module_id?: Maybe<Scalars['String']>
  submission?: Maybe<SubmissionEntityResponse>
  programme_submission?: Maybe<ProgrammeSubmissionEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UploadedReportReportArgs = {
  filters?: Maybe<UploadFileFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UploadedReportEntity = {
  __typename?: 'UploadedReportEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UploadedReport>
}

export type UploadedReportEntityResponse = {
  __typename?: 'UploadedReportEntityResponse'
  data?: Maybe<UploadedReportEntity>
}

export type UploadedReportEntityResponseCollection = {
  __typename?: 'UploadedReportEntityResponseCollection'
  data: Array<UploadedReportEntity>
  meta: ResponseCollectionMeta
}

export type UploadedReportRelationResponseCollection = {
  __typename?: 'UploadedReportRelationResponseCollection'
  data: Array<UploadedReportEntity>
}

export type UserUploadedDocumentFiltersInput = {
  id?: Maybe<IdFilterInput>
  name?: Maybe<StringFilterInput>
  user?: Maybe<UsersPermissionsUserFiltersInput>
  documents?: Maybe<ComponentQuestionTypesDocumentsFiltersInput>
  createdAt?: Maybe<DateTimeFilterInput>
  updatedAt?: Maybe<DateTimeFilterInput>
  and?: Maybe<Array<Maybe<UserUploadedDocumentFiltersInput>>>
  or?: Maybe<Array<Maybe<UserUploadedDocumentFiltersInput>>>
  not?: Maybe<UserUploadedDocumentFiltersInput>
}

export type UserUploadedDocumentInput = {
  name?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['ID']>
  documents?: Maybe<Array<Maybe<ComponentQuestionTypesDocumentsInput>>>
}

export type UserUploadedDocument = {
  __typename?: 'UserUploadedDocument'
  name?: Maybe<Scalars['String']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
  documents?: Maybe<Array<Maybe<ComponentQuestionTypesDocuments>>>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UserUploadedDocumentDocumentsArgs = {
  filters?: Maybe<ComponentQuestionTypesDocumentsFiltersInput>
  pagination?: Maybe<PaginationArg>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type UserUploadedDocumentEntity = {
  __typename?: 'UserUploadedDocumentEntity'
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UserUploadedDocument>
}

export type UserUploadedDocumentEntityResponse = {
  __typename?: 'UserUploadedDocumentEntityResponse'
  data?: Maybe<UserUploadedDocumentEntity>
}

export type UserUploadedDocumentEntityResponseCollection = {
  __typename?: 'UserUploadedDocumentEntityResponseCollection'
  data: Array<UserUploadedDocumentEntity>
  meta: ResponseCollectionMeta
}

export type UserUploadedDocumentRelationResponseCollection = {
  __typename?: 'UserUploadedDocumentRelationResponseCollection'
  data: Array<UserUploadedDocumentEntity>
}

export type GenericMorph =
  | ComponentGravitasScaleItemsGravitasScaleItems
  | ComponentOptionColorAndRating
  | ComponentOptionOption
  | ComponentOptionSliderHeadings
  | ComponentOptionSliderQuestions
  | ComponentOptionTimezone
  | ComponentQuestionTypesCompetency
  | ComponentQuestionTypesCompetency2
  | ComponentQuestionTypesDocuments
  | ComponentQuestionTypesFeedForward1
  | ComponentQuestionTypesFeedForward2
  | ComponentQuestionTypesGravitasGap
  | ComponentQuestionTypesGravitasScale
  | ComponentQuestionTypesGravitasSliders
  | ComponentQuestionTypesMultipleChoice
  | ComponentQuestionTypesNestedQuestions
  | ComponentQuestionTypesParticipant
  | ComponentQuestionTypesQuestionscompetency2
  | ComponentQuestionTypesSaboteurQuestions
  | ComponentQuestionTypesSaboteurVideos
  | ComponentQuestionTypesSaboteur
  | ComponentQuestionTypesSimpleQuestion
  | ComponentQuestionTypesSimpleReadOnly
  | ComponentQuestionTypesSliderQuestions
  | ComponentQuestionTypesStakeholderMap
  | ComponentQuestionTypesUploadDocuments
  | ComponentQuestionTypesUploadQuestion
  | ComponentQuestionTypesValuesBubble
  | ComponentQuestionTypesValuesCharacters
  | ComponentQuestionTypesValuesGaps
  | ComponentQuestionTypesValuesNested
  | ComponentQuestionTypesValuesSliders
  | ComponentQuestionTypesXyGraph
  | ComponentRatingInputRatingInput
  | ComponentSaboteurVideoSaboteurVideo
  | ComponentStakeholderMapItemStackholderMapItem
  | ComponentSubmissionsAnswer
  | ComponentSubmissionsCharacteristics
  | ComponentSubmissionsCompetencyAnswer
  | ComponentSubmissionsCompetencyParticipants
  | ComponentSubmissionsGravitasGap
  | ComponentSubmissionsGravitasSlidersAnswers
  | ComponentSubmissionsNotes
  | ComponentSubmissionsParticipants
  | ComponentSubmissionsProgrammeSubmissions
  | ComponentSubmissionsRepeatAnswer
  | ComponentSubmissionsSaboteurComponent
  | ComponentSubmissionsSabtourVideosComponent
  | ComponentSubmissionsSectionSubmission
  | ComponentSubmissionsSkipProofComponent
  | ComponentSubmissionsSubModuleSubmission
  | ComponentSubmissionsUploadDocuments
  | ComponentSubmissionsValuesGaps
  | ComponentTranslationsAboutUs
  | ComponentTranslationsButtons
  | ComponentTranslationsDashboardScreen
  | ComponentTranslationsFaqsFrench
  | ComponentTranslationsFaqsGerman
  | ComponentTranslationsFaqsMandarin
  | ComponentTranslationsFaqsQuestionAnswer
  | ComponentTranslationsFaqsSpanish
  | ComponentTranslationsFaqs
  | ComponentTranslationsHelpAndSupport
  | ComponentTranslationsHomeScreen
  | ComponentTranslationsLanguageSelection
  | ComponentTranslationsLetsGetStartedScreen
  | ComponentTranslationsMenu
  | ComponentTranslationsMyLibraryScreen
  | ComponentTranslationsMyProfileScreen
  | ComponentTranslationsMyWorkScreen
  | ComponentTranslationsPersonalScreen
  | ComponentTranslationsPrivacyPolicy
  | ComponentTranslationsProgrammeScreen
  | ComponentTranslationsSettingsScreen
  | ComponentTranslationsTermsOfService
  | ComponentTranslationsWelcomeScreen
  | ComponentTranslationsWhatToExpect
  | ComponentXyGraphItemXyGraphItem
  | UploadFile
  | UploadFolder
  | I18NLocale
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | AccountChange
  | Appointment
  | Article
  | AuditTrail
  | Booking
  | Coach
  | CoachSession
  | CoachSubmittedReport
  | Company
  | CompetencyUrl
  | Division
  | EmailLog
  | EmailTemplate
  | Event
  | FeedfowardUrl
  | ForwardedCard
  | Invite
  | Language
  | Logo
  | Modal
  | Module
  | Note
  | Notification
  | Participant
  | PostCard
  | PostCardImage
  | PostCardSentence
  | Programme
  | ProgrammeReport
  | ProgrammeSubmission
  | ProgrammeType
  | Region
  | Report
  | RespondentsAnswer
  | Section
  | SubModule
  | SubModuleSubmission
  | Submission
  | SubmoduleReport
  | SurveyReport
  | Translation
  | TypeFormCollection
  | UploadedReport
  | UserUploadedDocument

export type FileInfoInput = {
  name?: Maybe<Scalars['String']>
  alternativeText?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
}

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe'
  id: Scalars['ID']
  username: Scalars['String']
  email?: Maybe<Scalars['String']>
  confirmed?: Maybe<Scalars['Boolean']>
  blocked?: Maybe<Scalars['Boolean']>
  role?: Maybe<UsersPermissionsMeRole>
}

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type UsersPermissionsRegisterInput = {
  username: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
}

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']
  password: Scalars['String']
  provider: Scalars['String']
}

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload'
  jwt?: Maybe<Scalars['String']>
  user: UsersPermissionsMe
}

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload'
  ok: Scalars['Boolean']
}

export type PaginationArg = {
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type ModuleFragment = { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
    attributes?: Maybe<
      { __typename?: 'Module' } & Pick<
        Module,
        | 'name_french'
        | 'name_german'
        | 'name_english'
        | 'name_spanish'
        | 'name_mandarin'
        | 'description_french'
        | 'description_german'
        | 'description_english'
        | 'description_spanish'
        | 'description_mandarin'
      > & {
          cover?: Maybe<
            { __typename?: 'UploadFileEntityResponse' } & {
              data?: Maybe<
                { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                    attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                  }
              >
            }
          >
        }
    >
  }

type Questions_ComponentQuestionTypesMultipleChoice_Fragment = {
  __typename: 'ComponentQuestionTypesMultipleChoice'
} & Pick<
  ComponentQuestionTypesMultipleChoice,
  | 'id'
  | 'titleEnglish'
  | 'titleFrench'
  | 'titleGerman'
  | 'titleSpanish'
  | 'titleMandarin'
  | 'viewPrevious'
  | 'numberOfAnswers'
> & {
    option?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ComponentOptionOption' } & Pick<
            ComponentOptionOption,
            | 'id'
            | 'optionEnglish'
            | 'optionFrench'
            | 'optionGerman'
            | 'optionSpanish'
            | 'optionMandarin'
            | 'isCorrect'
          >
        >
      >
    >
  }

type Questions_ComponentQuestionTypesSaboteur_Fragment = {
  __typename: 'ComponentQuestionTypesSaboteur'
} & Pick<
  ComponentQuestionTypesSaboteur,
  | 'id'
  | 'titleEnglish'
  | 'subTitleEnglish'
  | 'titleFrench'
  | 'subTitleFrench'
  | 'titleGerman'
  | 'subTitleGerman'
  | 'titleSpanish'
  | 'subTitleSpanish'
  | 'titleMandarin'
  | 'subTitleMandarin'
> & {
    saboteurQuestions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ComponentQuestionTypesSaboteurQuestions' } & Pick<
            ComponentQuestionTypesSaboteurQuestions,
            | 'link'
            | 'skipProof'
            | 'labelEnglish'
            | 'labelFrench'
            | 'labelGerman'
            | 'labelSpanish'
            | 'labelMandarin'
          > & {
              proof?: Maybe<
                { __typename?: 'UploadFileRelationResponseCollection' } & {
                  data: Array<{ __typename?: 'UploadFileEntity' } & UploadFileFragment>
                }
              >
            }
        >
      >
    >
  }

type Questions_ComponentQuestionTypesSimpleQuestion_Fragment = {
  __typename: 'ComponentQuestionTypesSimpleQuestion'
} & Pick<
  ComponentQuestionTypesSimpleQuestion,
  | 'id'
  | 'titleEnglish'
  | 'titleFrench'
  | 'titleGerman'
  | 'titleSpanish'
  | 'titleMandarin'
  | 'subTitleEnglish'
  | 'subTitleFrench'
  | 'subTitleGerman'
  | 'subTitleSpanish'
  | 'subTitleMandarin'
  | 'viewPrevious'
>

type Questions_ComponentQuestionTypesXyGraph_Fragment = {
  __typename: 'ComponentQuestionTypesXYGraph'
} & Pick<
  ComponentQuestionTypesXyGraph,
  | 'id'
  | 'labelXEnglish'
  | 'labelYEnglish'
  | 'titleEnglish'
  | 'subTitleEnglish'
  | 'labelXFrench'
  | 'labelYFrench'
  | 'titleFrench'
  | 'subTitleFrench'
  | 'labelXGerman'
  | 'labelYGerman'
  | 'titleGerman'
  | 'subTitleGerman'
  | 'labelXSpanish'
  | 'labelYSpanish'
  | 'titleSpanish'
  | 'subTitleSpanish'
  | 'labelXMandarin'
  | 'labelYMandarin'
  | 'titleMandarin'
  | 'subTitleMandarin'
>

type Questions_ComponentQuestionTypesNestedQuestions_Fragment = {
  __typename: 'ComponentQuestionTypesNestedQuestions'
} & Pick<
  ComponentQuestionTypesNestedQuestions,
  | 'id'
  | 'titleEnglish'
  | 'titleFrench'
  | 'titleGerman'
  | 'titleSpanish'
  | 'titleMandarin'
  | 'viewPrevious'
> & {
    questions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
            ComponentQuestionTypesSimpleQuestion,
            | 'id'
            | 'titleEnglish'
            | 'subTitleEnglish'
            | 'titleFrench'
            | 'subTitleFrench'
            | 'titleGerman'
            | 'subTitleGerman'
            | 'titleSpanish'
            | 'subTitleSpanish'
            | 'titleMandarin'
            | 'subTitleMandarin'
            | 'isNumeric'
          >
        >
      >
    >
  }

type Questions_ComponentQuestionTypesGravitasScale_Fragment = {
  __typename: 'ComponentQuestionTypesGravitasScale'
} & Pick<
  ComponentQuestionTypesGravitasScale,
  | 'id'
  | 'titleEnglish'
  | 'subTitleEnglish'
  | 'questionOneEnglish'
  | 'questionTwoEnglish'
  | 'questionThreeEnglish'
  | 'titleOneEnglish'
  | 'titleTwoEnglish'
> & {
    ratingInput?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ComponentRatingInputRatingInput' } & Pick<
            ComponentRatingInputRatingInput,
            'id' | 'labelOneEnglish' | 'labelTwoEnglish'
          >
        >
      >
    >
  }

type Questions_ComponentQuestionTypesStakeholderMap_Fragment = {
  __typename: 'ComponentQuestionTypesStakeholderMap'
} & Pick<
  ComponentQuestionTypesStakeholderMap,
  | 'id'
  | 'titleEnglish'
  | 'subTitleEnglish'
  | 'titleFrench'
  | 'subTitleFrench'
  | 'titleSpanish'
  | 'subTitleSpanish'
  | 'titleGerman'
  | 'subTitleGerman'
  | 'titleMandarin'
  | 'subTitleMandarin'
  | 'questionX'
  | 'questionY'
>

type Questions_ComponentQuestionTypesSaboteurVideos_Fragment = {
  __typename: 'ComponentQuestionTypesSaboteurVideos'
} & Pick<
  ComponentQuestionTypesSaboteurVideos,
  | 'id'
  | 'titleEnglish'
  | 'titleFrench'
  | 'titleGerman'
  | 'titleSpanish'
  | 'titleMandarin'
  | 'subTitleEnglish'
  | 'subTitleFrench'
  | 'subTitleGerman'
  | 'subTitleSpanish'
  | 'subTitleMandarin'
> & {
    SaboteurVideo?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ComponentSaboteurVideoSaboteurVideo' } & Pick<
            ComponentSaboteurVideoSaboteurVideo,
            | 'id'
            | 'videoTitleEnglish'
            | 'videoTitleFrench'
            | 'videoTitleGerman'
            | 'videoTitleSpanish'
            | 'videoTitleMandarin'
            | 'videoDescriptionEnglish'
            | 'videoDescriptionFrench'
            | 'videoDescriptionGerman'
            | 'videoDescriptionSpanish'
            | 'videoDescriptionMandarin'
          > & {
              video?: Maybe<
                { __typename?: 'UploadFileEntityResponse' } & {
                  data?: Maybe<{ __typename?: 'UploadFileEntity' } & UploadFileFragment>
                }
              >
            }
        >
      >
    >
  }

type Questions_ComponentQuestionTypesValuesSliders_Fragment = {
  __typename: 'ComponentQuestionTypesValuesSliders'
}

type Questions_ComponentQuestionTypesSimpleReadOnly_Fragment = {
  __typename: 'ComponentQuestionTypesSimpleReadOnly'
}

type Questions_ComponentQuestionTypesValuesNested_Fragment = {
  __typename: 'ComponentQuestionTypesValuesNested'
}

type Questions_ComponentQuestionTypesValuesGaps_Fragment = {
  __typename: 'ComponentQuestionTypesValuesGaps'
}

type Questions_ComponentQuestionTypesUploadQuestion_Fragment = {
  __typename: 'ComponentQuestionTypesUploadQuestion'
}

type Questions_ComponentQuestionTypesParticipant_Fragment = {
  __typename: 'ComponentQuestionTypesParticipant'
}

type Questions_ComponentQuestionTypesValuesCharacters_Fragment = {
  __typename: 'ComponentQuestionTypesValuesCharacters'
}

type Questions_ComponentQuestionTypesCompetency_Fragment = {
  __typename: 'ComponentQuestionTypesCompetency'
}

type Questions_ComponentQuestionTypesValuesBubble_Fragment = {
  __typename: 'ComponentQuestionTypesValuesBubble'
}

type Questions_ComponentQuestionTypesSliderQuestions_Fragment = {
  __typename: 'ComponentQuestionTypesSliderQuestions'
}

type Questions_ComponentQuestionTypesFeedForward1_Fragment = {
  __typename: 'ComponentQuestionTypesFeedForward1'
} & Pick<ComponentQuestionTypesFeedForward1, 'id' | 'title' | 'question' | 'description'>

type Questions_ComponentQuestionTypesFeedForward2_Fragment = {
  __typename: 'ComponentQuestionTypesFeedForward2'
} & Pick<ComponentQuestionTypesFeedForward2, 'id' | 'title' | 'question' | 'description'>

type Questions_ComponentQuestionTypesCompetency2_Fragment = {
  __typename: 'ComponentQuestionTypesCompetency2'
} & Pick<ComponentQuestionTypesCompetency2, 'titleEnglish' | 'titleFrench' | 'titleMandarin'> & {
    questionscompetency2?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ComponentQuestionTypesQuestionscompetency2' } & Pick<
            ComponentQuestionTypesQuestionscompetency2,
            | 'title'
            | 'question'
            | 'maxiNumber'
            | 'miniNumber'
            | 'simpleQuestion'
            | 'miniLabel'
            | 'maxiLabel'
          >
        >
      >
    >
  }

type Questions_ComponentQuestionTypesGravitasSliders_Fragment = {
  __typename: 'ComponentQuestionTypesGravitasSliders'
}

type Questions_ComponentQuestionTypesUploadDocuments_Fragment = {
  __typename: 'ComponentQuestionTypesUploadDocuments'
}

type Questions_ComponentQuestionTypesGravitasGap_Fragment = {
  __typename: 'ComponentQuestionTypesGravitasGap'
}

type Questions_Error_Fragment = { __typename: 'Error' }

export type QuestionsFragment =
  | Questions_ComponentQuestionTypesMultipleChoice_Fragment
  | Questions_ComponentQuestionTypesSaboteur_Fragment
  | Questions_ComponentQuestionTypesSimpleQuestion_Fragment
  | Questions_ComponentQuestionTypesXyGraph_Fragment
  | Questions_ComponentQuestionTypesNestedQuestions_Fragment
  | Questions_ComponentQuestionTypesGravitasScale_Fragment
  | Questions_ComponentQuestionTypesStakeholderMap_Fragment
  | Questions_ComponentQuestionTypesSaboteurVideos_Fragment
  | Questions_ComponentQuestionTypesValuesSliders_Fragment
  | Questions_ComponentQuestionTypesSimpleReadOnly_Fragment
  | Questions_ComponentQuestionTypesValuesNested_Fragment
  | Questions_ComponentQuestionTypesValuesGaps_Fragment
  | Questions_ComponentQuestionTypesUploadQuestion_Fragment
  | Questions_ComponentQuestionTypesParticipant_Fragment
  | Questions_ComponentQuestionTypesValuesCharacters_Fragment
  | Questions_ComponentQuestionTypesCompetency_Fragment
  | Questions_ComponentQuestionTypesValuesBubble_Fragment
  | Questions_ComponentQuestionTypesSliderQuestions_Fragment
  | Questions_ComponentQuestionTypesFeedForward1_Fragment
  | Questions_ComponentQuestionTypesFeedForward2_Fragment
  | Questions_ComponentQuestionTypesCompetency2_Fragment
  | Questions_ComponentQuestionTypesGravitasSliders_Fragment
  | Questions_ComponentQuestionTypesUploadDocuments_Fragment
  | Questions_ComponentQuestionTypesGravitasGap_Fragment
  | Questions_Error_Fragment

export type SectionFragment = { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
    attributes?: Maybe<
      { __typename?: 'Section' } & Pick<
        Section,
        | 'title_french'
        | 'title_german'
        | 'title_english'
        | 'title_spanish'
        | 'title_mandarin'
        | 'sub_title_french'
        | 'sub_title_german'
        | 'sub_title_english'
        | 'sub_title_spanish'
        | 'sub_title_mandarin'
      > & {
          cover?: Maybe<
            { __typename?: 'UploadFileEntityResponse' } & {
              data?: Maybe<
                { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                    attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                  }
              >
            }
          >
        }
    >
  }

export type SubmissionFragment = { __typename?: 'SubmissionEntity' } & Pick<
  SubmissionEntity,
  'id'
> & {
    attributes?: Maybe<
      { __typename?: 'Submission' } & Pick<
        Submission,
        'views' | 'progress' | 'status' | 'time_spent'
      > & {
          language?: Maybe<
            { __typename?: 'LanguageEntityResponse' } & {
              data?: Maybe<
                { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                    attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                  }
              >
            }
          >
        }
    >
  }

export type UploadFileFragment = { __typename?: 'UploadFileEntity' } & Pick<
  UploadFileEntity,
  'id'
> & {
    attributes?: Maybe<
      { __typename?: 'UploadFile' } & Pick<
        UploadFile,
        | 'createdAt'
        | 'updatedAt'
        | 'name'
        | 'alternativeText'
        | 'caption'
        | 'width'
        | 'height'
        | 'formats'
        | 'hash'
        | 'ext'
        | 'mime'
        | 'size'
        | 'url'
        | 'previewUrl'
        | 'provider'
      >
    >
  }

export type AddDocumentMutationVariables = {
  input: EditUserUploadedDocumentInput
}

export type AddDocumentMutation = { __typename?: 'Mutation' } & {
  addDocument: { __typename?: 'UserUploadedDocument' } & Pick<UserUploadedDocument, 'name'>
}

export type AddNoteMutationVariables = {
  input: EditNoteInput
}

export type AddNoteMutation = { __typename?: 'Mutation' } & {
  addNote: { __typename?: 'Notes' } & Pick<Notes, 'note'>
}

export type ConfirmMyAppointmentsMutationVariables = {
  appointmentScheduleId: Scalars['ID']
  programmeSubmissionId: Scalars['ID']
  subModule: Scalars['Boolean']
}

export type ConfirmMyAppointmentsMutation = { __typename?: 'Mutation' } & {
  confirmMyAppointments: { __typename?: 'ConfirmAppointmentResponse' } & Pick<
    ConfirmAppointmentResponse,
    'success' | 'message'
  >
}

export type CreateAccountChangeMutationVariables = {
  data: AccountChangeInput
}

export type CreateAccountChangeMutation = { __typename?: 'Mutation' } & {
  createAccountChange?: Maybe<
    { __typename?: 'AccountChangeEntityResponse' } & {
      data?: Maybe<{ __typename?: 'AccountChangeEntity' } & Pick<AccountChangeEntity, 'id'>>
    }
  >
}

export type CreateBookingMutationVariables = {
  data: BookingInput
}

export type CreateBookingMutation = { __typename?: 'Mutation' } & {
  createBooking?: Maybe<
    { __typename?: 'BookingEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'BookingEntity' } & Pick<BookingEntity, 'id'> & {
            attributes?: Maybe<{ __typename?: 'Booking' } & Pick<Booking, 'title'>>
          }
      >
    }
  >
}

export type CreateProgrammeSubmissionMutationVariables = {
  input: ProgrammeSubmissionInput
}

export type CreateProgrammeSubmissionMutation = { __typename?: 'Mutation' } & {
  createProgrammeSubmission?: Maybe<
    { __typename?: 'ProgrammeSubmissionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'ProgrammeSubmissionEntity' } & Pick<ProgrammeSubmissionEntity, 'id'>
      >
    }
  >
}

export type CreateSessionMutationVariables = {
  input: CoachSessionInput
}

export type CreateSessionMutation = { __typename?: 'Mutation' } & {
  createCoachSession?: Maybe<
    { __typename?: 'CoachSessionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'CoachSessionEntity' } & {
          attributes?: Maybe<{ __typename?: 'CoachSession' } & Pick<CoachSession, 'title'>>
        }
      >
    }
  >
}

export type DeletedNoteMutationVariables = {
  id: Scalars['ID']
}

export type DeletedNoteMutation = { __typename?: 'Mutation' } & {
  deletedNote?: Maybe<{ __typename?: 'Notes' } & Pick<Notes, 'note'>>
}

export type GenerateCompetencyResponsesMutationVariables = {
  id: Scalars['ID']
}

export type GenerateCompetencyResponsesMutation = { __typename?: 'Mutation' } & {
  generateCompetencyResponses?: Maybe<
    { __typename?: 'ProgrammeSubmissionResponse' } & Pick<ProgrammeSubmissionResponse, 'success'>
  >
}

export type GenerateMyMiniSectionSubmissionResponseMutationVariables = {
  id: Scalars['ID']
  sectionId?: Maybe<Scalars['Int']>
  secId?: Maybe<Scalars['Int']>
}

export type GenerateMyMiniSectionSubmissionResponseMutation = { __typename?: 'Mutation' } & {
  generateMyMiniSectionSubmissionResponse?: Maybe<
    { __typename?: 'SubModuleSubmissionResponse' } & Pick<SubModuleSubmissionResponse, 'success'>
  >
}

export type GenerateMyProgrammeSubmissionResponsesMutationVariables = {
  id: Scalars['ID']
}

export type GenerateMyProgrammeSubmissionResponsesMutation = { __typename?: 'Mutation' } & {
  generateMyProgrammeSubmissionResponses?: Maybe<
    { __typename?: 'ProgrammeSubmissionResponse' } & Pick<ProgrammeSubmissionResponse, 'success'>
  >
}

export type GenerateMySubModuleSubmissionComptencyResponsesMutationVariables = {
  id: Scalars['ID']
  sectionId?: Maybe<Scalars['Int']>
}

export type GenerateMySubModuleSubmissionComptencyResponsesMutation = {
  __typename?: 'Mutation'
} & {
  generateMySubModuleSubmissionComptencyResponses?: Maybe<
    { __typename?: 'SubModuleSubmissionResponse' } & Pick<SubModuleSubmissionResponse, 'success'>
  >
}

export type GenerateMySubModuleSubmissionResponsesMutationVariables = {
  id: Scalars['ID']
  sectionId?: Maybe<Scalars['Int']>
}

export type GenerateMySubModuleSubmissionResponsesMutation = { __typename?: 'Mutation' } & {
  generateMySubModuleSubmissionResponses?: Maybe<
    { __typename?: 'SubModuleSubmissionResponse' } & Pick<SubModuleSubmissionResponse, 'success'>
  >
}

export type ResendMailMutationVariables = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ResendMailMutation = { __typename?: 'Mutation' } & {
  resendMail?: Maybe<{ __typename?: 'EmailSent' } & Pick<EmailSent, 'success'>>
}

export type ResendProgrammeMailMutationVariables = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ResendProgrammeMailMutation = { __typename?: 'Mutation' } & {
  resendProgrammeMail?: Maybe<
    { __typename?: 'EmailSentProgramme' } & Pick<EmailSentProgramme, 'successed'>
  >
}

export type UpdateMySubModuleSubmissionMutationVariables = {
  id: Scalars['ID']
  currentSection?: Maybe<Scalars['Int']>
  isPdf?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  programmeSubmissionId?: Maybe<Scalars['ID']>
  input: EditSubModuleSubmissionInput
}

export type UpdateMySubModuleSubmissionMutation = { __typename?: 'Mutation' } & {
  updateMySubModuleSubmission: { __typename?: 'SubModuleSubmission' } & Pick<
    SubModuleSubmission,
    'id' | 'view' | 'progress' | 'status' | 'time_spent'
  > & {
      language?: Maybe<
        { __typename?: 'LanguageEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
              }
          >
        }
      >
      participants?: Maybe<
        { __typename?: 'ParticipantRelationResponseCollection' } & {
          data: Array<
            { __typename?: 'ParticipantEntity' } & Pick<ParticipantEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'Participant' } & Pick<Participant, 'name' | 'email' | 'type'>
                >
              }
          >
        }
      >
      submission_participants?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ComponentSubmissionsParticipants' } & Pick<
              ComponentSubmissionsParticipants,
              'id' | 'name' | 'email'
            >
          >
        >
      >
      sub_module?: Maybe<
        { __typename?: 'SubModuleEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'SubModule' } & Pick<
                    SubModule,
                    | 'name_french'
                    | 'name_german'
                    | 'name_english'
                    | 'name_spanish'
                    | 'name_mandarin'
                    | 'description_french'
                    | 'description_german'
                    | 'description_english'
                    | 'description_spanish'
                  > & {
                      cover?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      programme_type?: Maybe<
                        { __typename?: 'ProgrammeTypeEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'>
                          >
                        }
                      >
                    }
                >
              }
          >
        }
      >
      section?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
              ComponentSubmissionsSectionSubmission,
              'id' | 'progress'
            > & {
                section?: Maybe<
                  { __typename?: 'SectionEntityResponse' } & {
                    data?: Maybe<{ __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>>
                  }
                >
                answers?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                        ComponentSubmissionsAnswer,
                        'id' | 'questionId' | 'answer' | 'isCorrect' | 'feedback' | 'outcome'
                      > & {
                          nestedAnswers?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ComponentSubmissionsRepeatAnswer' } & Pick<
                                  ComponentSubmissionsRepeatAnswer,
                                  'answer' | 'questionTitle'
                                >
                              >
                            >
                          >
                        }
                    >
                  >
                >
              }
          >
        >
      >
    }
}

export type UpdateNotificationMutationVariables = {
  id: Scalars['ID']
  data: NotificationInput
}

export type UpdateNotificationMutation = { __typename?: 'Mutation' } & {
  updateNotification?: Maybe<
    { __typename?: 'NotificationEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'NotificationEntity' } & Pick<NotificationEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Notification' } & Pick<
                Notification,
                'title' | 'time_stamp' | 'path' | 'read'
              >
            >
          }
      >
    }
  >
}

export type UpdateMyProgrammeSubmissionMutationVariables = {
  id: Scalars['ID']
  input: EditProgrammeSubmissionInput
  isPdf?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
}

export type UpdateMyProgrammeSubmissionMutation = { __typename?: 'Mutation' } & {
  updateMyProgrammeSubmissions: { __typename?: 'ProgrammeSubmission' } & Pick<
    ProgrammeSubmission,
    'id' | 'status' | 'progress' | 'selected'
  > & {
      programme_type?: Maybe<
        { __typename?: 'ProgrammeTypeEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'ProgrammeType' } & Pick<
                    ProgrammeType,
                    'title' | 'description' | 'is_coaching' | 'notes' | 'deadline' | 'archive'
                  > & {
                      cover?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      programme_questions?: Maybe<
                        Array<
                          Maybe<
                            | ({ __typename: 'ComponentQuestionTypesValuesNested' } & Pick<
                                ComponentQuestionTypesValuesNested,
                                'id' | 'title' | 'question'
                              > & {
                                  questions?: Maybe<
                                    Array<
                                      Maybe<
                                        {
                                          __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                        } & Pick<
                                          ComponentQuestionTypesSimpleQuestion,
                                          | 'id'
                                          | 'titleEnglish'
                                          | 'subTitleEnglish'
                                          | 'titleFrench'
                                          | 'subTitleFrench'
                                          | 'titleGerman'
                                          | 'subTitleGerman'
                                          | 'titleSpanish'
                                          | 'subTitleSpanish'
                                          | 'titleMandarin'
                                          | 'subTitleMandarin'
                                        >
                                      >
                                    >
                                  >
                                })
                            | { __typename: 'ComponentQuestionTypesValuesBubble' }
                            | { __typename: 'ComponentQuestionTypesValuesCharacters' }
                            | { __typename: 'ComponentQuestionTypesValuesSliders' }
                            | { __typename: 'ComponentQuestionTypesValuesGaps' }
                            | { __typename: 'ComponentQuestionTypesSaboteurVideos' }
                            | { __typename: 'ComponentQuestionTypesMultipleChoice' }
                            | { __typename: 'ComponentQuestionTypesSaboteur' }
                            | { __typename: 'ComponentQuestionTypesSimpleQuestion' }
                            | { __typename: 'ComponentQuestionTypesXYGraph' }
                            | { __typename: 'ComponentQuestionTypesNestedQuestions' }
                            | { __typename: 'ComponentQuestionTypesUploadQuestion' }
                            | { __typename: 'ComponentQuestionTypesGravitasScale' }
                            | { __typename: 'ComponentQuestionTypesParticipant' }
                            | { __typename: 'ComponentQuestionTypesStakeholderMap' }
                            | { __typename: 'ComponentQuestionTypesFeedForward1' }
                            | { __typename: 'ComponentQuestionTypesFeedForward2' }
                            | { __typename: 'ComponentQuestionTypesSimpleReadOnly' }
                            | { __typename: 'ComponentQuestionTypesCompetency2' }
                            | { __typename: 'ComponentQuestionTypesCompetency' }
                            | { __typename: 'ComponentQuestionTypesGravitasGap' }
                            | { __typename: 'ComponentQuestionTypesSliderQuestions' }
                            | { __typename: 'ComponentQuestionTypesGravitasSliders' }
                            | { __typename: 'Error' }
                          >
                        >
                      >
                      articles?: Maybe<
                        { __typename?: 'ArticleRelationResponseCollection' } & {
                          data: Array<
                            { __typename?: 'ArticleEntity' } & Pick<ArticleEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'Article' } & Pick<
                                    Article,
                                    | 'createdAt'
                                    | 'updatedAt'
                                    | 'title_english'
                                    | 'title_french'
                                    | 'title_german'
                                    | 'title_spanish'
                                    | 'title_mandarin'
                                    | 'content_english'
                                    | 'content_french'
                                    | 'content_german'
                                    | 'content_spanish'
                                    | 'content_mandarin'
                                    | 'date_published'
                                    | 'read_time'
                                    | 'link'
                                  > & {
                                      image?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & {
                                              attributes?: Maybe<
                                                { __typename?: 'UploadFile' } & Pick<
                                                  UploadFile,
                                                  'url'
                                                >
                                              >
                                            }
                                          >
                                        }
                                      >
                                    }
                                >
                              }
                          >
                        }
                      >
                      sub_modules?: Maybe<
                        { __typename?: 'SubModuleRelationResponseCollection' } & {
                          data: Array<
                            { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'SubModule' } & Pick<
                                    SubModule,
                                    'name_english' | 'description_english'
                                  > & {
                                      cover?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & Pick<
                                              UploadFileEntity,
                                              'id'
                                            > & {
                                                attributes?: Maybe<
                                                  { __typename?: 'UploadFile' } & Pick<
                                                    UploadFile,
                                                    'url'
                                                  >
                                                >
                                              }
                                          >
                                        }
                                      >
                                      programme_type?: Maybe<
                                        { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                              ProgrammeTypeEntity,
                                              'id'
                                            >
                                          >
                                        }
                                      >
                                    }
                                >
                              }
                          >
                        }
                      >
                    }
                >
              }
          >
        }
      >
      programme_responses?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ComponentSubmissionsProgrammeSubmissions' } & {
              answers?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                      ComponentSubmissionsAnswer,
                      | 'id'
                      | 'questionId'
                      | 'answer'
                      | 'isCorrect'
                      | 'feedback'
                      | 'outcome'
                      | 'questionType'
                      | 'questionTitle'
                    > & {
                        nestedAnswers?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'ComponentSubmissionsRepeatAnswer' } & Pick<
                                ComponentSubmissionsRepeatAnswer,
                                'answer' | 'questionTitle'
                              >
                            >
                          >
                        >
                      }
                  >
                >
              >
              stakeholderChart?: Maybe<
                { __typename?: 'UploadFileEntityResponse' } & {
                  data?: Maybe<
                    { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                        attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                      }
                  >
                }
              >
              saboteur?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ComponentSubmissionsSaboteurComponent' } & Pick<
                      ComponentSubmissionsSaboteurComponent,
                      'id'
                    >
                  >
                >
              >
              stakeholderItems?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ComponentStakeholderMapItemStackholderMapItem' } & Pick<
                      ComponentStakeholderMapItemStackholderMapItem,
                      'id' | 'valueX' | 'valueY' | 'name' | 'color'
                    >
                  >
                >
              >
              gravitasScales?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems' } & Pick<
                      ComponentGravitasScaleItemsGravitasScaleItems,
                      | 'id'
                      | 'labelOne'
                      | 'labelTwo'
                      | 'labelThree'
                      | 'question'
                      | 'answerOne'
                      | 'answerTwo'
                      | 'answerThree'
                    >
                  >
                >
              >
              xYGraphChart?: Maybe<
                { __typename?: 'UploadFileEntityResponse' } & {
                  data?: Maybe<
                    { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                        attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                      }
                  >
                }
              >
              saboteurImage?: Maybe<
                { __typename?: 'UploadFileEntityResponse' } & {
                  data?: Maybe<
                    { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                        attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                      }
                  >
                }
              >
              xYGraphItems?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                      ComponentXyGraphItemXyGraphItem,
                      'id' | 'valueX' | 'valueY' | 'color'
                    >
                  >
                >
              >
            }
          >
        >
      >
      my_notes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ComponentSubmissionsNotes' } & Pick<
              ComponentSubmissionsNotes,
              'id' | 'title' | 'description'
            > & {
                document?: Maybe<
                  { __typename?: 'UploadFileEntityResponse' } & {
                    data?: Maybe<
                      { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                    >
                  }
                >
              }
          >
        >
      >
    }
}

export type UpdateSessionMutationVariables = {
  id: Scalars['ID']
  data: CoachSessionInput
}

export type UpdateSessionMutation = { __typename?: 'Mutation' } & {
  updateCoachSession?: Maybe<
    { __typename?: 'CoachSessionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'CoachSessionEntity' } & Pick<CoachSessionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'CoachSession' } & Pick<CoachSession, 'title' | 'status'> & {
                  user?: Maybe<
                    { __typename?: 'UsersPermissionsUserEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UsersPermissionsUserEntity' } & Pick<
                          UsersPermissionsUserEntity,
                          'id'
                        >
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type UpdateMySubmissionMutationVariables = {
  id: Scalars['ID']
  currentSection?: Maybe<Scalars['Int']>
  programmeSubmissionId?: Maybe<Scalars['ID']>
  input: EditSubmissionInput
}

export type UpdateMySubmissionMutation = { __typename?: 'Mutation' } & {
  updateMySubmission: { __typename?: 'Submission' } & Pick<
    Submission,
    'id' | 'views' | 'progress' | 'status' | 'time_spent'
  > & {
      language?: Maybe<
        { __typename?: 'LanguageEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
              }
          >
        }
      >
      module?: Maybe<
        { __typename?: 'ModuleEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'Module' } & Pick<
                    Module,
                    | 'name_french'
                    | 'name_german'
                    | 'name_english'
                    | 'name_spanish'
                    | 'name_mandarin'
                    | 'description_french'
                    | 'description_german'
                    | 'description_english'
                    | 'description_spanish'
                    | 'description_mandarin'
                  > & {
                      cover?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
              }
          >
        }
      >
      section?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
              ComponentSubmissionsSectionSubmission,
              'id' | 'progress'
            > & {
                section?: Maybe<
                  { __typename?: 'SectionEntityResponse' } & {
                    data?: Maybe<{ __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>>
                  }
                >
                answers?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                        ComponentSubmissionsAnswer,
                        'id' | 'questionId' | 'answer' | 'isCorrect' | 'feedback' | 'outcome'
                      > & {
                          nestedAnswers?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ComponentSubmissionsRepeatAnswer' } & Pick<
                                  ComponentSubmissionsRepeatAnswer,
                                  'answer' | 'questionTitle'
                                >
                              >
                            >
                          >
                        }
                    >
                  >
                >
              }
          >
        >
      >
    }
}

export type UpdateSelfMutationVariables = {
  input: EditUserInput
}

export type UpdateSelfMutation = { __typename?: 'Mutation' } & {
  updateSelf: { __typename?: 'UsersPermissionsUser' } & Pick<
    UsersPermissionsUser,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'email'
    | 'confirmed'
    | 'blocked'
    | 'phoneNumber'
    | 'onboarding'
    | 'firstName'
    | 'lastName'
    | 'jobTitle'
    | 'modulesRead'
    | 'homePromptsRead'
    | 'programmePromptsRead'
    | 'modulePromptsRead'
    | 'bookmarks'
    | 'articlesViewed'
    | 'updatedDetails'
  > & {
      profilePicture?: Maybe<
        { __typename?: 'UploadFileEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
              }
          >
        }
      >
      language?: Maybe<
        { __typename?: 'LanguageEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
              }
          >
        }
      >
      division?: Maybe<
        { __typename?: 'DivisionEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'DivisionEntity' } & Pick<DivisionEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'Division' } & Pick<
                    Division,
                    | 'title_french'
                    | 'title_german'
                    | 'title_english'
                    | 'title_mandarin'
                    | 'title_spanish'
                  >
                >
              }
          >
        }
      >
      report?: Maybe<
        { __typename?: 'UploadFileEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
              }
          >
        }
      >
      company?: Maybe<
        { __typename?: 'CompanyEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'CompanyEntity' } & Pick<CompanyEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'Company' } & Pick<
                    Company,
                    'name' | 'programmes_title' | 'programmes_subTitle' | 'users_confirm_details'
                  > & {
                      logo?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      language_selection?: Maybe<
                        { __typename?: 'ComponentTranslationsLanguageSelection' } & Pick<
                          ComponentTranslationsLanguageSelection,
                          | 'id'
                          | 'titleEnglish'
                          | 'bodyEnglish'
                          | 'nextButtonEnglish'
                          | 'titleFrench'
                          | 'bodyFrench'
                          | 'nextButtonFrench'
                          | 'titleGerman'
                          | 'bodyGerman'
                          | 'nextButtonGerman'
                          | 'titleMandarin'
                          | 'bodyMandarin'
                          | 'nextButtonMandarin'
                          | 'titleSpanish'
                          | 'bodySpanish'
                          | 'nextButtonSpanish'
                        >
                      >
                      welcome_screen?: Maybe<
                        { __typename?: 'ComponentTranslationsWelcomeScreen' } & Pick<
                          ComponentTranslationsWelcomeScreen,
                          | 'id'
                          | 'titleEnglish'
                          | 'bodyEnglish'
                          | 'nextButtonEnglish'
                          | 'titleFrench'
                          | 'bodyFrench'
                          | 'nextButtonFrench'
                          | 'titleGerman'
                          | 'bodyGerman'
                          | 'nextButtonGerman'
                          | 'titleMandarin'
                          | 'bodyMandarin'
                          | 'nextButtonMandarin'
                          | 'titleSpanish'
                          | 'bodySpanish'
                          | 'nextButtonSpanish'
                        > & {
                            videoEnglish?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            videoFrench?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            videoGerman?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            videoMandarin?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            videoSpanish?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                      lets_get_started_screen?: Maybe<
                        { __typename?: 'ComponentTranslationsLetsGetStartedScreen' } & Pick<
                          ComponentTranslationsLetsGetStartedScreen,
                          | 'id'
                          | 'titleEnglish'
                          | 'subTitleEnglish'
                          | 'firstNameLabelEnglish'
                          | 'firstNameErrorEnglish'
                          | 'lastNameLabelEnglish'
                          | 'lastNameErrorEnglish'
                          | 'jobTitleLabelEnglish'
                          | 'jobTitleErrorEnglish'
                          | 'divisionLabelEnglish'
                          | 'divisionErrorEnglish'
                          | 'timezoneLabelEnglish'
                          | 'regionLabelEnglish'
                          | 'regionErrorEnglish'
                          | 'nextButtonEnglish'
                          | 'titleFrench'
                          | 'subTitleFrench'
                          | 'firstNameLabelFrench'
                          | 'firstNameErrorFrench'
                          | 'lastNameLabelFrench'
                          | 'lastNameErrorFrench'
                          | 'jobTitleLabelFrench'
                          | 'jobTitleErrorFrench'
                          | 'divisionLabelFrench'
                          | 'divisionErrorFrench'
                          | 'timezoneLabelFrench'
                          | 'regionLabelFrench'
                          | 'regionErrorFrench'
                          | 'nextButtonFrench'
                          | 'titleGerman'
                          | 'subTitleGerman'
                          | 'firstNameLabelGerman'
                          | 'firstNameErrorGerman'
                          | 'lastNameLabelGerman'
                          | 'lastNameErrorGerman'
                          | 'jobTitleLabelGerman'
                          | 'jobTitleErrorGerman'
                          | 'divisionLabelGerman'
                          | 'divisionErrorGerman'
                          | 'timezoneLabelGerman'
                          | 'regionLabelGerman'
                          | 'regionErrorGerman'
                          | 'nextButtonGerman'
                          | 'titleMandarin'
                          | 'subTitleMandarin'
                          | 'firstNameLabelMandarin'
                          | 'firstNameErrorMandarin'
                          | 'lastNameLabelMandarin'
                          | 'lastNameErrorMandarin'
                          | 'jobTitleLabelMandarin'
                          | 'jobTitleErrorMandarin'
                          | 'divisionLabelMandarin'
                          | 'divisionErrorMandarin'
                          | 'timezoneLabelMandarin'
                          | 'regionLabelMandarin'
                          | 'regionErrorMandarin'
                          | 'nextButtonMandarin'
                          | 'titleSpanish'
                          | 'subTitleSpanish'
                          | 'firstNameLabelSpanish'
                          | 'firstNameErrorSpanish'
                          | 'lastNameLabelSpanish'
                          | 'lastNameErrorSpanish'
                          | 'jobTitleLabelSpanish'
                          | 'jobTitleErrorSpanish'
                          | 'divisionLabelSpanish'
                          | 'divisionErrorSpanish'
                          | 'timezoneLabelSpanish'
                          | 'regionLabelSpanish'
                          | 'regionErrorSpanish'
                          | 'nextButtonSpanish'
                          | 'leftTitleEnglish'
                          | 'leftSubtitleEnglish'
                          | 'leftTitleSpanish'
                          | 'leftSubtitleSpanish'
                          | 'leftTitleMandarin'
                          | 'leftSubTitleMandarin'
                          | 'leftTitleFrench'
                          | 'leftSubtitleFrench'
                          | 'leftSubtitleGerman'
                          | 'leftTitleGerman'
                        > & {
                            image?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                      what_to_expect?: Maybe<
                        { __typename?: 'ComponentTranslationsWhatToExpect' } & Pick<
                          ComponentTranslationsWhatToExpect,
                          | 'id'
                          | 'titleEnglish'
                          | 'bodyEnglish'
                          | 'getStartedButtonEnglish'
                          | 'titleFrench'
                          | 'bodyFrench'
                          | 'getStartedButtonFrench'
                          | 'titleGerman'
                          | 'bodyGerman'
                          | 'getStartedButtonGerman'
                          | 'titleMandarin'
                          | 'bodyMandarin'
                          | 'getStartedButtonMandarin'
                          | 'titleSpanish'
                          | 'bodySpanish'
                          | 'getStartedButtonSpanish'
                        > & {
                            image?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                      about_us?: Maybe<
                        { __typename?: 'ComponentTranslationsAboutUs' } & Pick<
                          ComponentTranslationsAboutUs,
                          | 'id'
                          | 'textEnglish'
                          | 'textFrench'
                          | 'textGerman'
                          | 'userGuidelinesTextEnglish'
                          | 'userGuidelinesTextGerman'
                          | 'userGuidelinesTextFrench'
                        > & {
                            userGuidelinesEnglish?: Maybe<
                              { __typename?: 'UploadFileRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            userGuidelinesGerman?: Maybe<
                              { __typename?: 'UploadFileRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            userGuidelinesFrench?: Maybe<
                              { __typename?: 'UploadFileRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'UploadFileEntity' } & {
                                    attributes?: Maybe<
                                      { __typename?: 'UploadFile' } & Pick<UploadFile, 'id' | 'url'>
                                    >
                                  }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
          >
        }
      >
      submissions?: Maybe<
        { __typename?: 'SubmissionRelationResponseCollection' } & {
          data: Array<{ __typename?: 'SubmissionEntity' } & Pick<SubmissionEntity, 'id'>>
        }
      >
      programmes?: Maybe<
        { __typename?: 'ProgrammeRelationResponseCollection' } & {
          data: Array<
            { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'Programme' } & Pick<
                    Programme,
                    'name' | 'description' | 'start' | 'is_custom_journey' | 'position'
                  > & {
                      logo?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
              }
          >
        }
      >
      region?: Maybe<
        { __typename?: 'RegionEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'RegionEntity' } & Pick<RegionEntity, 'id'> & {
                attributes?: Maybe<
                  { __typename?: 'Region' } & Pick<
                    Region,
                    | 'name_english'
                    | 'name_french'
                    | 'name_german'
                    | 'name_spanish'
                    | 'name_mandarin'
                  >
                >
              }
          >
        }
      >
      timezone?: Maybe<
        { __typename?: 'ComponentOptionTimezone' } & Pick<
          ComponentOptionTimezone,
          'abbrev' | 'altName' | 'label' | 'value'
        >
      >
    }
}

export type CheckResponsesFromTypeFormQueryVariables = {
  id: Scalars['ID']
  sectionid?: Maybe<Scalars['Int']>
}

export type CheckResponsesFromTypeFormQuery = { __typename?: 'Query' } & {
  checkResponsesFromTypeForm?: Maybe<{ __typename?: 'Emails' } & Pick<Emails, 'emails'>>
}

export type CheckResponsesFromTypeFormProgrammeQueryVariables = {
  id: Scalars['ID']
  sectionid?: Maybe<Scalars['Int']>
}

export type CheckResponsesFromTypeFormProgrammeQuery = { __typename?: 'Query' } & {
  checkResponsesFromTypeFormProgramme?: Maybe<
    { __typename?: 'EmailsSent' } & Pick<EmailsSent, 'emails'>
  >
}

export type GetAllsectionReportQueryVariables = {
  id: Scalars['ID']
}

export type GetAllsectionReportQuery = { __typename?: 'Query' } & {
  getAllsectionReport?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Report' } & Pick<
          Report,
          'id' | 'name' | 'type' | 'section_or_module_id'
        > & {
            report?: Maybe<
              { __typename?: 'UploadFileEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                      attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                    }
                >
              }
            >
          }
      >
    >
  >
}

export type GetArticlesQueryVariables = {
  where?: Maybe<ArticleFiltersInput>
  start?: Maybe<Scalars['Int']>
  limit: Scalars['Int']
}

export type GetArticlesQuery = { __typename?: 'Query' } & {
  articles?: Maybe<
    { __typename?: 'ArticleEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'ArticleEntity' } & Pick<ArticleEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Article' } & Pick<
                Article,
                | 'createdAt'
                | 'updatedAt'
                | 'title_english'
                | 'title_french'
                | 'title_german'
                | 'title_spanish'
                | 'title_mandarin'
                | 'content_english'
                | 'content_french'
                | 'content_german'
                | 'content_spanish'
                | 'content_mandarin'
                | 'position'
                | 'date_published'
                | 'read_time'
                | 'link'
              > & {
                  companies?: Maybe<
                    { __typename?: 'CompanyRelationResponseCollection' } & {
                      data: Array<{ __typename?: 'CompanyEntity' } & Pick<CompanyEntity, 'id'>>
                    }
                  >
                  image?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetCoachSubmittedReportsQueryVariables = {
  where: CoachSubmittedReportFiltersInput
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
  limit: Scalars['Int']
}

export type GetCoachSubmittedReportsQuery = { __typename?: 'Query' } & {
  coachSubmittedReports?: Maybe<
    { __typename?: 'CoachSubmittedReportEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'CoachSubmittedReportEntity' } & Pick<CoachSubmittedReportEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'CoachSubmittedReport' } & Pick<CoachSubmittedReport, 'name'> & {
                  notes_id?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsSaboteurComponent' } & Pick<
                          ComponentSubmissionsSaboteurComponent,
                          'name'
                        > & {
                            saboteurProofValue?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    >
                  >
                }
            >
          }
      >
    }
  >
}

export type GetCompanyQueryVariables = {
  id: Scalars['ID']
}

export type GetCompanyQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'CompanyEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'CompanyEntity' } & {
          attributes?: Maybe<
            { __typename?: 'Company' } & {
              programmes?: Maybe<
                { __typename?: 'ProgrammeRelationResponseCollection' } & {
                  data: Array<
                    { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                        attributes?: Maybe<
                          { __typename?: 'Programme' } & Pick<
                            Programme,
                            | 'name'
                            | 'description'
                            | 'start'
                            | 'is_custom_journey'
                            | 'show_timeline'
                            | 'position'
                          > & {
                              programme_types?: Maybe<
                                { __typename?: 'ProgrammeTypeRelationResponseCollection' } & {
                                  data: Array<
                                    { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                      ProgrammeTypeEntity,
                                      'id'
                                    > & {
                                        attributes?: Maybe<
                                          { __typename?: 'ProgrammeType' } & {
                                            programme_submissions?: Maybe<
                                              {
                                                __typename?: 'ProgrammeSubmissionRelationResponseCollection'
                                              } & {
                                                data: Array<
                                                  {
                                                    __typename?: 'ProgrammeSubmissionEntity'
                                                  } & Pick<ProgrammeSubmissionEntity, 'id'> & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'ProgrammeSubmission' } & {
                                                          user?: Maybe<
                                                            {
                                                              __typename?: 'UsersPermissionsUserEntityResponse'
                                                            } & {
                                                              data?: Maybe<
                                                                {
                                                                  __typename?: 'UsersPermissionsUserEntity'
                                                                } & Pick<
                                                                  UsersPermissionsUserEntity,
                                                                  'id'
                                                                >
                                                              >
                                                            }
                                                          >
                                                          programme?: Maybe<
                                                            {
                                                              __typename?: 'ProgrammeEntityResponse'
                                                            } & {
                                                              data?: Maybe<
                                                                {
                                                                  __typename?: 'ProgrammeEntity'
                                                                } & Pick<ProgrammeEntity, 'id'> & {
                                                                    attributes?: Maybe<
                                                                      {
                                                                        __typename?: 'Programme'
                                                                      } & Pick<
                                                                        Programme,
                                                                        | 'is_custom_journey'
                                                                        | 'show_timeline'
                                                                      > & {
                                                                          logo?: Maybe<
                                                                            {
                                                                              __typename?: 'UploadFileEntityResponse'
                                                                            } & {
                                                                              data?: Maybe<
                                                                                {
                                                                                  __typename?: 'UploadFileEntity'
                                                                                } & Pick<
                                                                                  UploadFileEntity,
                                                                                  'id'
                                                                                > & {
                                                                                    attributes?: Maybe<
                                                                                      {
                                                                                        __typename?: 'UploadFile'
                                                                                      } & Pick<
                                                                                        UploadFile,
                                                                                        'url'
                                                                                      >
                                                                                    >
                                                                                  }
                                                                              >
                                                                            }
                                                                          >
                                                                        }
                                                                    >
                                                                  }
                                                              >
                                                            }
                                                          >
                                                        }
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                        >
                                      }
                                  >
                                }
                              >
                            }
                        >
                      }
                  >
                }
              >
            }
          >
        }
      >
    }
  >
}

export type GetCompanyDivisionsQueryVariables = {
  where?: Maybe<DivisionFiltersInput>
}

export type GetCompanyDivisionsQuery = { __typename?: 'Query' } & {
  divisions?: Maybe<
    { __typename?: 'DivisionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'DivisionEntity' } & Pick<DivisionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Division' } & Pick<
                Division,
                | 'title_english'
                | 'title_french'
                | 'title_german'
                | 'title_spanish'
                | 'title_mandarin'
                | 'createdAt'
                | 'updatedAt'
              >
            >
          }
      >
    }
  >
}

export type GetCompanyRegionsQueryVariables = {
  where?: Maybe<RegionFiltersInput>
}

export type GetCompanyRegionsQuery = { __typename?: 'Query' } & {
  regions?: Maybe<
    { __typename?: 'RegionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'RegionEntity' } & Pick<RegionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Region' } & Pick<
                Region,
                | 'name_english'
                | 'name_french'
                | 'name_german'
                | 'name_spanish'
                | 'name_mandarin'
                | 'createdAt'
                | 'updatedAt'
              >
            >
          }
      >
    }
  >
}

export type GetDivisionsQueryVariables = {
  where?: Maybe<DivisionFiltersInput>
}

export type GetDivisionsQuery = { __typename?: 'Query' } & {
  divisions?: Maybe<
    { __typename?: 'DivisionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'DivisionEntity' } & Pick<DivisionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Division' } & Pick<
                Division,
                | 'title_english'
                | 'title_french'
                | 'title_german'
                | 'title_spanish'
                | 'title_mandarin'
                | 'createdAt'
                | 'updatedAt'
              >
            >
          }
      >
    }
  >
}

export type GetFirstFourNotesQueryVariables = {}

export type GetFirstFourNotesQuery = { __typename?: 'Query' } & {
  getFirstFourNotes?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Notes' } & Pick<
          Notes,
          'id' | 'name' | 'note' | 'sectionOrSubmissionId' | 'created_at'
        > & {
            document?: Maybe<
              Array<Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'id' | 'url'>>>
            >
            programme?: Maybe<{ __typename?: 'Programmes' } & Pick<Programmes, 'name'>>
            notesId?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsSaboteurComponent' } & {
                    saboteurProofValue?: Maybe<
                      { __typename?: 'UploadFileEntityResponse' } & {
                        data?: Maybe<
                          { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                              attributes?: Maybe<
                                { __typename?: 'UploadFile' } & Pick<UploadFile, 'url' | 'name'>
                              >
                            }
                        >
                      }
                    >
                  }
                >
              >
            >
            sub_module_submission?: Maybe<
              { __typename?: 'SubModuleSubmission' } & {
                sub_module?: Maybe<
                  { __typename?: 'SubModuleEntityResponse' } & {
                    data?: Maybe<
                      { __typename?: 'SubModuleEntity' } & {
                        attributes?: Maybe<
                          { __typename?: 'SubModule' } & Pick<SubModule, 'name_english'>
                        >
                      }
                    >
                  }
                >
              }
            >
          }
      >
    >
  >
}

export type GetFirstTwelveArticlesQueryVariables = {}

export type GetFirstTwelveArticlesQuery = { __typename?: 'Query' } & {
  getFirstTwelveArticles?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Article' } & Pick<
          Article,
          | 'id'
          | 'createdAt'
          | 'updatedAt'
          | 'title_english'
          | 'title_french'
          | 'title_german'
          | 'title_spanish'
          | 'title_mandarin'
          | 'content_english'
          | 'content_french'
          | 'content_german'
          | 'content_spanish'
          | 'content_mandarin'
          | 'position'
          | 'date_published'
          | 'read_time'
          | 'link'
        > & {
            companies?: Maybe<
              { __typename?: 'CompanyRelationResponseCollection' } & {
                data: Array<{ __typename?: 'CompanyEntity' } & Pick<CompanyEntity, 'id'>>
              }
            >
            image?: Maybe<
              { __typename?: 'UploadFileEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'UploadFileEntity' } & {
                    attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
                  }
                >
              }
            >
          }
      >
    >
  >
}

export type GetInviteQueryVariables = {
  where?: Maybe<InviteFiltersInput>
}

export type GetInviteQuery = { __typename?: 'Query' } & {
  invites?: Maybe<
    { __typename?: 'InviteEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'InviteEntity' } & {
          attributes?: Maybe<
            { __typename?: 'Invite' } & Pick<Invite, 'email'> & {
                company?: Maybe<
                  { __typename?: 'CompanyEntityResponse' } & {
                    data?: Maybe<
                      { __typename?: 'CompanyEntity' } & Pick<CompanyEntity, 'id'> & {
                          attributes?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'name'>>
                        }
                    >
                  }
                >
              }
          >
        }
      >
    }
  >
}

export type GetLanguagesQueryVariables = {
  where?: Maybe<LanguageFiltersInput>
}

export type GetLanguagesQuery = { __typename?: 'Query' } & {
  languages?: Maybe<
    { __typename?: 'LanguageEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Language' } & Pick<Language, 'name'> & {
                  image?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                      >
                    }
                  >
                  companies?: Maybe<
                    { __typename?: 'CompanyRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'CompanyEntity' } & Pick<CompanyEntity, 'id'> & {
                            attributes?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'name'>>
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetModuleQueryVariables = {
  id: Scalars['ID']
}

export type GetModuleQuery = { __typename?: 'Query' } & {
  module?: Maybe<
    { __typename?: 'ModuleEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Module' } & Pick<
                Module,
                | 'name_english'
                | 'name_french'
                | 'name_spanish'
                | 'name_german'
                | 'name_mandarin'
                | 'description_english'
                | 'description_french'
                | 'description_german'
                | 'description_mandarin'
                | 'description_spanish'
              > & {
                  cover?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  sections?: Maybe<
                    { __typename?: 'SectionRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Section' } & Pick<
                                Section,
                                | 'title_french'
                                | 'title_german'
                                | 'title_english'
                                | 'title_spanish'
                                | 'title_mandarin'
                                | 'sub_title_french'
                                | 'sub_title_german'
                                | 'sub_title_english'
                                | 'sub_title_spanish'
                                | 'sub_title_mandarin'
                                | 'position'
                                | 'archive'
                              > & {
                                  video_english?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_german?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_spanish?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_mandarin?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_french?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  questions?: Maybe<
                                    Array<
                                      Maybe<
                                        | ({
                                            __typename?: 'ComponentQuestionTypesMultipleChoice'
                                          } & Questions_ComponentQuestionTypesMultipleChoice_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteur'
                                          } & Questions_ComponentQuestionTypesSaboteur_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                          } & Questions_ComponentQuestionTypesSimpleQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesXYGraph'
                                          } & Questions_ComponentQuestionTypesXyGraph_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesNestedQuestions'
                                          } & Questions_ComponentQuestionTypesNestedQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasScale'
                                          } & Questions_ComponentQuestionTypesGravitasScale_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesStakeholderMap'
                                          } & Questions_ComponentQuestionTypesStakeholderMap_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteurVideos'
                                          } & Questions_ComponentQuestionTypesSaboteurVideos_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesSliders'
                                          } & Questions_ComponentQuestionTypesValuesSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleReadOnly'
                                          } & Questions_ComponentQuestionTypesSimpleReadOnly_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesNested'
                                          } & Questions_ComponentQuestionTypesValuesNested_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesGaps'
                                          } & Questions_ComponentQuestionTypesValuesGaps_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadQuestion'
                                          } & Questions_ComponentQuestionTypesUploadQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesParticipant'
                                          } & Questions_ComponentQuestionTypesParticipant_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesCharacters'
                                          } & Questions_ComponentQuestionTypesValuesCharacters_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency'
                                          } & Questions_ComponentQuestionTypesCompetency_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesBubble'
                                          } & Questions_ComponentQuestionTypesValuesBubble_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSliderQuestions'
                                          } & Questions_ComponentQuestionTypesSliderQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward1'
                                          } & Questions_ComponentQuestionTypesFeedForward1_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward2'
                                          } & Questions_ComponentQuestionTypesFeedForward2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency2'
                                          } & Questions_ComponentQuestionTypesCompetency2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasSliders'
                                          } & Questions_ComponentQuestionTypesGravitasSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadDocuments'
                                          } & Questions_ComponentQuestionTypesUploadDocuments_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasGap'
                                          } & Questions_ComponentQuestionTypesGravitasGap_Fragment)
                                        | ({ __typename?: 'Error' } & Questions_Error_Fragment)
                                      >
                                    >
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetModulesQueryVariables = {}

export type GetModulesQuery = { __typename?: 'Query' } & {
  modules?: Maybe<
    { __typename?: 'ModuleEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Module' } & Pick<
                Module,
                | 'name_english'
                | 'name_french'
                | 'name_spanish'
                | 'name_german'
                | 'name_mandarin'
                | 'description_english'
                | 'description_french'
                | 'description_spanish'
                | 'description_german'
                | 'description_mandarin'
              > & {
                  cover?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  sections?: Maybe<
                    { __typename?: 'SectionRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Section' } & Pick<
                                Section,
                                | 'title_french'
                                | 'title_german'
                                | 'title_english'
                                | 'title_spanish'
                                | 'title_mandarin'
                                | 'sub_title_french'
                                | 'sub_title_german'
                                | 'sub_title_english'
                                | 'sub_title_spanish'
                                | 'sub_title_mandarin'
                                | 'archive'
                              > & {
                                  video_english?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_french?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_spanish?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_german?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_mandarin?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  questions?: Maybe<
                                    Array<
                                      Maybe<
                                        | ({
                                            __typename?: 'ComponentQuestionTypesMultipleChoice'
                                          } & Questions_ComponentQuestionTypesMultipleChoice_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteur'
                                          } & Questions_ComponentQuestionTypesSaboteur_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                          } & Questions_ComponentQuestionTypesSimpleQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesXYGraph'
                                          } & Questions_ComponentQuestionTypesXyGraph_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesNestedQuestions'
                                          } & Questions_ComponentQuestionTypesNestedQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasScale'
                                          } & Questions_ComponentQuestionTypesGravitasScale_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesStakeholderMap'
                                          } & Questions_ComponentQuestionTypesStakeholderMap_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteurVideos'
                                          } & Questions_ComponentQuestionTypesSaboteurVideos_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesSliders'
                                          } & Questions_ComponentQuestionTypesValuesSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleReadOnly'
                                          } & Questions_ComponentQuestionTypesSimpleReadOnly_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesNested'
                                          } & Questions_ComponentQuestionTypesValuesNested_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesGaps'
                                          } & Questions_ComponentQuestionTypesValuesGaps_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadQuestion'
                                          } & Questions_ComponentQuestionTypesUploadQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesParticipant'
                                          } & Questions_ComponentQuestionTypesParticipant_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesCharacters'
                                          } & Questions_ComponentQuestionTypesValuesCharacters_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency'
                                          } & Questions_ComponentQuestionTypesCompetency_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesBubble'
                                          } & Questions_ComponentQuestionTypesValuesBubble_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSliderQuestions'
                                          } & Questions_ComponentQuestionTypesSliderQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward1'
                                          } & Questions_ComponentQuestionTypesFeedForward1_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward2'
                                          } & Questions_ComponentQuestionTypesFeedForward2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency2'
                                          } & Questions_ComponentQuestionTypesCompetency2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasSliders'
                                          } & Questions_ComponentQuestionTypesGravitasSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadDocuments'
                                          } & Questions_ComponentQuestionTypesUploadDocuments_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasGap'
                                          } & Questions_ComponentQuestionTypesGravitasGap_Fragment)
                                        | ({ __typename?: 'Error' } & Questions_Error_Fragment)
                                      >
                                    >
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMyBookingsQueryVariables = {
  where: BookingFiltersInput
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
  limit: Scalars['Int']
}

export type GetMyBookingsQuery = { __typename?: 'Query' } & {
  bookings?: Maybe<
    { __typename?: 'BookingEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'BookingEntity' } & Pick<BookingEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Booking' } & Pick<
                Booking,
                | 'title'
                | 'description'
                | 'duration'
                | 'date'
                | 'confirmed'
                | 'event_id'
                | 'cancelled'
                | 'organiser'
                | 'location'
                | 'is_updated'
                | 'end'
              >
            >
          }
      >
    }
  >
}

export type GetMyCoachSessionsQueryVariables = {
  where: CoachSessionFiltersInput
}

export type GetMyCoachSessionsQuery = { __typename?: 'Query' } & {
  coachSessions?: Maybe<
    { __typename?: 'CoachSessionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'CoachSessionEntity' } & Pick<CoachSessionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'CoachSession' } & Pick<CoachSession, 'title' | 'status'> & {
                  user?: Maybe<
                    { __typename?: 'UsersPermissionsUserEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UsersPermissionsUserEntity' } & Pick<
                          UsersPermissionsUserEntity,
                          'id'
                        >
                      >
                    }
                  >
                  programme_submission?: Maybe<
                    { __typename?: 'ProgrammeSubmissionEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeSubmissionEntity' } & Pick<
                          ProgrammeSubmissionEntity,
                          'id'
                        >
                      >
                    }
                  >
                  sub_module_submission?: Maybe<
                    { __typename?: 'SubModuleSubmissionEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleSubmissionEntity' } & Pick<
                          SubModuleSubmissionEntity,
                          'id'
                        >
                      >
                    }
                  >
                  appointment?: Maybe<
                    { __typename?: 'AppointmentEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'AppointmentEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'Appointment' } & Pick<
                              Appointment,
                              | 'appointment_url'
                              | 'appointment_start_date'
                              | 'appointment_end_date'
                              | 'status'
                            >
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMyNotificationsQueryVariables = {
  where: NotificationFiltersInput
}

export type GetMyNotificationsQuery = { __typename?: 'Query' } & {
  notifications?: Maybe<
    { __typename?: 'NotificationEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'NotificationEntity' } & Pick<NotificationEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Notification' } & Pick<
                Notification,
                'createdAt' | 'updatedAt' | 'title' | 'time_stamp' | 'path' | 'read'
              > & {
                  users?: Maybe<
                    { __typename?: 'UsersPermissionsUserRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'UsersPermissionsUserEntity' } & Pick<
                          UsersPermissionsUserEntity,
                          'id'
                        >
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMySubModuleReportQueryVariables = {
  where: ReportFiltersInput
  sort: Array<Maybe<Scalars['String']>>
  limit: Scalars['Int']
}

export type GetMySubModuleReportQuery = { __typename?: 'Query' } & {
  reports?: Maybe<
    { __typename?: 'ReportEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'ReportEntity' } & Pick<ReportEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Report' } & Pick<
                Report,
                'id' | 'name' | 'type' | 'section_or_module_id'
              > & {
                  report?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  sub_module_submission?: Maybe<
                    { __typename?: 'SubModuleSubmissionEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleSubmissionEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'SubModuleSubmission' } & {
                              section?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                                      ComponentSubmissionsSectionSubmission,
                                      'progress'
                                    > & {
                                        section?: Maybe<
                                          { __typename?: 'SectionEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'SectionEntity' } & {
                                                attributes?: Maybe<
                                                  { __typename?: 'Section' } & Pick<
                                                    Section,
                                                    'title_english'
                                                  >
                                                >
                                              }
                                            >
                                          }
                                        >
                                      }
                                  >
                                >
                              >
                              sub_module?: Maybe<
                                { __typename?: 'SubModuleEntityResponse' } & {
                                  data?: Maybe<
                                    { __typename?: 'SubModuleEntity' } & Pick<
                                      SubModuleEntity,
                                      'id'
                                    > & {
                                        attributes?: Maybe<
                                          { __typename?: 'SubModule' } & {
                                            programme_type?: Maybe<
                                              { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                                    ProgrammeTypeEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'ProgrammeType' } & Pick<
                                                          ProgrammeType,
                                                          'title'
                                                        > & {
                                                            programmes?: Maybe<
                                                              {
                                                                __typename?: 'ProgrammeRelationResponseCollection'
                                                              } & {
                                                                data: Array<
                                                                  {
                                                                    __typename?: 'ProgrammeEntity'
                                                                  } & {
                                                                    attributes?: Maybe<
                                                                      {
                                                                        __typename?: 'Programme'
                                                                      } & Pick<Programme, 'name'>
                                                                    >
                                                                  }
                                                                >
                                                              }
                                                            >
                                                          }
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                        >
                                      }
                                  >
                                }
                              >
                            }
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMySubModuleSubmissionsReportsQueryVariables = {}

export type GetMySubModuleSubmissionsReportsQuery = { __typename?: 'Query' } & {
  getMySubModuleSubmissionsReports?: Maybe<
    Array<
      Maybe<
        { __typename?: 'SubModuleSubmissionReport' } & Pick<SubModuleSubmissionReport, 'id'> & {
            sub_module: { __typename?: 'SubModule' } & Pick<
              SubModule,
              | 'id'
              | 'name_french'
              | 'name_german'
              | 'name_english'
              | 'name_spanish'
              | 'name_mandarin'
              | 'description_french'
              | 'description_german'
              | 'description_english'
              | 'description_spanish'
              | 'description_mandarin'
              | 'position'
            > & {
                cover?: Maybe<
                  { __typename?: 'UploadFileEntityResponse' } & {
                    data?: Maybe<
                      { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                    >
                  }
                >
                sections?: Maybe<
                  { __typename?: 'SectionRelationResponseCollection' } & {
                    data: Array<
                      { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                          attributes?: Maybe<
                            { __typename?: 'Section' } & Pick<
                              Section,
                              | 'title_french'
                              | 'title_german'
                              | 'title_english'
                              | 'title_spanish'
                              | 'title_mandarin'
                              | 'sub_title_french'
                              | 'sub_title_german'
                              | 'sub_title_english'
                              | 'sub_title_spanish'
                              | 'sub_title_mandarin'
                              | 'position'
                            > & {
                                cover?: Maybe<
                                  { __typename?: 'UploadFileEntityResponse' } & {
                                    data?: Maybe<
                                      { __typename?: 'UploadFileEntity' } & Pick<
                                        UploadFileEntity,
                                        'id'
                                      > & {
                                          attributes?: Maybe<
                                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                          >
                                        }
                                    >
                                  }
                                >
                              }
                          >
                        }
                    >
                  }
                >
                programme_type?: Maybe<
                  { __typename?: 'ProgrammeTypeEntityResponse' } & {
                    data?: Maybe<
                      { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                          attributes?: Maybe<
                            { __typename?: 'ProgrammeType' } & Pick<
                              ProgrammeType,
                              | 'title'
                              | 'description'
                              | 'position'
                              | 'is_coaching'
                              | 'is_sub_module'
                              | 'notes'
                              | 'show_article'
                              | 'show_session'
                              | 'show_participants'
                              | 'competency_survey'
                              | 'feedForward_survey'
                              | 'deadline'
                            > & {
                                cover?: Maybe<
                                  { __typename?: 'UploadFileEntityResponse' } & {
                                    data?: Maybe<
                                      { __typename?: 'UploadFileEntity' } & Pick<
                                        UploadFileEntity,
                                        'id'
                                      > & {
                                          attributes?: Maybe<
                                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                          >
                                        }
                                    >
                                  }
                                >
                                articles?: Maybe<
                                  { __typename?: 'ArticleRelationResponseCollection' } & {
                                    data: Array<
                                      { __typename?: 'ArticleEntity' } & Pick<
                                        ArticleEntity,
                                        'id'
                                      > & {
                                          attributes?: Maybe<
                                            { __typename?: 'Article' } & Pick<
                                              Article,
                                              | 'createdAt'
                                              | 'updatedAt'
                                              | 'title_english'
                                              | 'title_french'
                                              | 'title_german'
                                              | 'title_spanish'
                                              | 'title_mandarin'
                                              | 'content_english'
                                              | 'content_french'
                                              | 'content_german'
                                              | 'content_spanish'
                                              | 'content_mandarin'
                                              | 'date_published'
                                              | 'read_time'
                                              | 'link'
                                            > & {
                                                image?: Maybe<
                                                  { __typename?: 'UploadFileEntityResponse' } & {
                                                    data?: Maybe<
                                                      { __typename?: 'UploadFileEntity' } & {
                                                        attributes?: Maybe<
                                                          { __typename?: 'UploadFile' } & Pick<
                                                            UploadFile,
                                                            'url'
                                                          >
                                                        >
                                                      }
                                                    >
                                                  }
                                                >
                                              }
                                          >
                                        }
                                    >
                                  }
                                >
                                modal?: Maybe<
                                  { __typename?: 'ModalEntityResponse' } & {
                                    data?: Maybe<
                                      { __typename?: 'ModalEntity' } & Pick<ModalEntity, 'id'> & {
                                          attributes?: Maybe<
                                            { __typename?: 'Modal' } & Pick<
                                              Modal,
                                              'title' | 'subtitle' | 'message'
                                            > & {
                                                image?: Maybe<
                                                  { __typename?: 'UploadFileEntityResponse' } & {
                                                    data?: Maybe<
                                                      { __typename?: 'UploadFileEntity' } & Pick<
                                                        UploadFileEntity,
                                                        'id'
                                                      > & {
                                                          attributes?: Maybe<
                                                            { __typename?: 'UploadFile' } & Pick<
                                                              UploadFile,
                                                              'url' | 'name'
                                                            >
                                                          >
                                                        }
                                                    >
                                                  }
                                                >
                                              }
                                          >
                                        }
                                    >
                                  }
                                >
                                events?: Maybe<
                                  { __typename?: 'EventRelationResponseCollection' } & {
                                    data: Array<
                                      { __typename?: 'EventEntity' } & Pick<EventEntity, 'id'> & {
                                          attributes?: Maybe<
                                            { __typename?: 'Event' } & Pick<Event, 'name' | 'link'>
                                          >
                                        }
                                    >
                                  }
                                >
                              }
                          >
                        }
                    >
                  }
                >
              }
            reports: Array<
              { __typename?: 'Report' } & Pick<
                Report,
                'id' | 'name' | 'type' | 'section_or_module_id'
              > & {
                  report?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    >
  >
}

export type GetMySubModuleNotesQueryVariables = {
  where: NoteFiltersInput
  sort: Array<Maybe<Scalars['String']>>
  limit: Scalars['Int']
}

export type GetMySubModuleNotesQuery = { __typename?: 'Query' } & {
  notes?: Maybe<
    { __typename?: 'NoteEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'NoteEntity' } & Pick<NoteEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Note' } & Pick<
                Note,
                'name' | 'note' | 'section_or_submission_id' | 'createdAt'
              > & {
                  document?: Maybe<
                    { __typename?: 'UploadFileRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  programme?: Maybe<
                    { __typename?: 'ProgrammeEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeEntity' } & {
                          attributes?: Maybe<{ __typename?: 'Programme' } & Pick<Programme, 'name'>>
                        }
                      >
                    }
                  >
                  notes_id?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsSaboteurComponent' } & {
                          saboteurProofValue?: Maybe<
                            { __typename?: 'UploadFileEntityResponse' } & {
                              data?: Maybe<
                                { __typename?: 'UploadFileEntity' } & Pick<
                                  UploadFileEntity,
                                  'id'
                                > & {
                                    attributes?: Maybe<
                                      { __typename?: 'UploadFile' } & Pick<
                                        UploadFile,
                                        'url' | 'name'
                                      >
                                    >
                                  }
                              >
                            }
                          >
                        }
                      >
                    >
                  >
                  sub_module_submission?: Maybe<
                    { __typename?: 'SubModuleSubmissionEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleSubmissionEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'SubModuleSubmission' } & {
                              sub_module?: Maybe<
                                { __typename?: 'SubModuleEntityResponse' } & {
                                  data?: Maybe<
                                    { __typename?: 'SubModuleEntity' } & {
                                      attributes?: Maybe<
                                        { __typename?: 'SubModule' } & Pick<
                                          SubModule,
                                          'name_english'
                                        >
                                      >
                                    }
                                  >
                                }
                              >
                            }
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMyUploadedReportQueryVariables = {
  where: UploadedReportFiltersInput
  sort: Array<Maybe<Scalars['String']>>
  limit: Scalars['Int']
}

export type GetMyUploadedReportQuery = { __typename?: 'Query' } & {
  uploadedReports?: Maybe<
    { __typename?: 'UploadedReportEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'UploadedReportEntity' } & Pick<UploadedReportEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'UploadedReport' } & Pick<
                UploadedReport,
                'name' | 'type' | 'section_or_module_id'
              > & {
                  report?: Maybe<
                    { __typename?: 'UploadFileRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url' | 'createdAt'>
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMyWorkQueryVariables = {}

export type GetMyWorkQuery = { __typename?: 'Query' } & {
  getMySubmissions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Submission' } & Pick<
          Submission,
          'id' | 'views' | 'progress' | 'status' | 'time_spent'
        > & {
            language?: Maybe<
              { __typename?: 'LanguageEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'LanguageEntity' } & {
                    attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                  }
                >
              }
            >
            module?: Maybe<
              { __typename?: 'ModuleEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Module' } & Pick<
                          Module,
                          | 'name_french'
                          | 'name_german'
                          | 'name_english'
                          | 'name_spanish'
                          | 'name_mandarin'
                          | 'description_french'
                          | 'description_german'
                          | 'description_english'
                          | 'description_spanish'
                          | 'description_mandarin'
                        > & {
                            cover?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            sections?: Maybe<
                              { __typename?: 'SectionRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                                      attributes?: Maybe<
                                        { __typename?: 'Section' } & Pick<
                                          Section,
                                          | 'title_english'
                                          | 'title_french'
                                          | 'title_german'
                                          | 'title_spanish'
                                          | 'title_mandarin'
                                          | 'sub_title_english'
                                          | 'sub_title_french'
                                          | 'sub_title_german'
                                          | 'sub_title_spanish'
                                          | 'sub_title_mandarin'
                                        > & {
                                            video_english?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            video_french?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            video_german?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            video_spanish?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            video_mandarin?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            questions?: Maybe<
                                              Array<
                                                Maybe<
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesMultipleChoice'
                                                    } & Questions_ComponentQuestionTypesMultipleChoice_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesSaboteur'
                                                    } & Questions_ComponentQuestionTypesSaboteur_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                                    } & Questions_ComponentQuestionTypesSimpleQuestion_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesXYGraph'
                                                    } & Questions_ComponentQuestionTypesXyGraph_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesNestedQuestions'
                                                    } & Questions_ComponentQuestionTypesNestedQuestions_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesGravitasScale'
                                                    } & Questions_ComponentQuestionTypesGravitasScale_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesStakeholderMap'
                                                    } & Questions_ComponentQuestionTypesStakeholderMap_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesSaboteurVideos'
                                                    } & Questions_ComponentQuestionTypesSaboteurVideos_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesValuesSliders'
                                                    } & Questions_ComponentQuestionTypesValuesSliders_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesSimpleReadOnly'
                                                    } & Questions_ComponentQuestionTypesSimpleReadOnly_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesValuesNested'
                                                    } & Questions_ComponentQuestionTypesValuesNested_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesValuesGaps'
                                                    } & Questions_ComponentQuestionTypesValuesGaps_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesUploadQuestion'
                                                    } & Questions_ComponentQuestionTypesUploadQuestion_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesParticipant'
                                                    } & Questions_ComponentQuestionTypesParticipant_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesValuesCharacters'
                                                    } & Questions_ComponentQuestionTypesValuesCharacters_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesCompetency'
                                                    } & Questions_ComponentQuestionTypesCompetency_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesValuesBubble'
                                                    } & Questions_ComponentQuestionTypesValuesBubble_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesSliderQuestions'
                                                    } & Questions_ComponentQuestionTypesSliderQuestions_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesFeedForward1'
                                                    } & Questions_ComponentQuestionTypesFeedForward1_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesFeedForward2'
                                                    } & Questions_ComponentQuestionTypesFeedForward2_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesCompetency2'
                                                    } & Questions_ComponentQuestionTypesCompetency2_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesGravitasSliders'
                                                    } & Questions_ComponentQuestionTypesGravitasSliders_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesUploadDocuments'
                                                    } & Questions_ComponentQuestionTypesUploadDocuments_Fragment)
                                                  | ({
                                                      __typename?: 'ComponentQuestionTypesGravitasGap'
                                                    } & Questions_ComponentQuestionTypesGravitasGap_Fragment)
                                                  | ({
                                                      __typename?: 'Error'
                                                    } & Questions_Error_Fragment)
                                                >
                                              >
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
            section?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                    ComponentSubmissionsSectionSubmission,
                    'id'
                  > & {
                      answers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                              ComponentSubmissionsAnswer,
                              'id' | 'questionId' | 'answer' | 'isCorrect' | 'feedback' | 'outcome'
                            > & {
                                nestedAnswers?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'ComponentSubmissionsRepeatAnswer' } & Pick<
                                        ComponentSubmissionsRepeatAnswer,
                                        'answer' | 'questionTitle'
                                      >
                                    >
                                  >
                                >
                              }
                          >
                        >
                      >
                      section?: Maybe<
                        { __typename?: 'SectionEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'Section' } & Pick<
                                    Section,
                                    | 'createdAt'
                                    | 'updatedAt'
                                    | 'title_english'
                                    | 'title_french'
                                    | 'title_german'
                                    | 'title_spanish'
                                    | 'title_mandarin'
                                    | 'sub_title_english'
                                    | 'sub_title_french'
                                    | 'sub_title_german'
                                    | 'sub_title_spanish'
                                    | 'sub_title_mandarin'
                                  > & {
                                      video_english?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & Pick<
                                              UploadFileEntity,
                                              'id'
                                            > & {
                                                attributes?: Maybe<
                                                  { __typename?: 'UploadFile' } & Pick<
                                                    UploadFile,
                                                    'url'
                                                  >
                                                >
                                              }
                                          >
                                        }
                                      >
                                      video_french?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & Pick<
                                              UploadFileEntity,
                                              'id'
                                            > & {
                                                attributes?: Maybe<
                                                  { __typename?: 'UploadFile' } & Pick<
                                                    UploadFile,
                                                    'url'
                                                  >
                                                >
                                              }
                                          >
                                        }
                                      >
                                      video_german?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & Pick<
                                              UploadFileEntity,
                                              'id'
                                            > & {
                                                attributes?: Maybe<
                                                  { __typename?: 'UploadFile' } & Pick<
                                                    UploadFile,
                                                    'url'
                                                  >
                                                >
                                              }
                                          >
                                        }
                                      >
                                      video_spanish?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & Pick<
                                              UploadFileEntity,
                                              'id'
                                            > & {
                                                attributes?: Maybe<
                                                  { __typename?: 'UploadFile' } & Pick<
                                                    UploadFile,
                                                    'url'
                                                  >
                                                >
                                              }
                                          >
                                        }
                                      >
                                      video_mandarin?: Maybe<
                                        { __typename?: 'UploadFileEntityResponse' } & {
                                          data?: Maybe<
                                            { __typename?: 'UploadFileEntity' } & Pick<
                                              UploadFileEntity,
                                              'id'
                                            > & {
                                                attributes?: Maybe<
                                                  { __typename?: 'UploadFile' } & Pick<
                                                    UploadFile,
                                                    'url'
                                                  >
                                                >
                                              }
                                          >
                                        }
                                      >
                                      questions?: Maybe<
                                        Array<
                                          Maybe<
                                            | ({
                                                __typename?: 'ComponentQuestionTypesMultipleChoice'
                                              } & Questions_ComponentQuestionTypesMultipleChoice_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesSaboteur'
                                              } & Questions_ComponentQuestionTypesSaboteur_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                              } & Questions_ComponentQuestionTypesSimpleQuestion_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesXYGraph'
                                              } & Questions_ComponentQuestionTypesXyGraph_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesNestedQuestions'
                                              } & Questions_ComponentQuestionTypesNestedQuestions_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesGravitasScale'
                                              } & Questions_ComponentQuestionTypesGravitasScale_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesStakeholderMap'
                                              } & Questions_ComponentQuestionTypesStakeholderMap_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesSaboteurVideos'
                                              } & Questions_ComponentQuestionTypesSaboteurVideos_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesValuesSliders'
                                              } & Questions_ComponentQuestionTypesValuesSliders_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesSimpleReadOnly'
                                              } & Questions_ComponentQuestionTypesSimpleReadOnly_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesValuesNested'
                                              } & Questions_ComponentQuestionTypesValuesNested_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesValuesGaps'
                                              } & Questions_ComponentQuestionTypesValuesGaps_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesUploadQuestion'
                                              } & Questions_ComponentQuestionTypesUploadQuestion_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesParticipant'
                                              } & Questions_ComponentQuestionTypesParticipant_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesValuesCharacters'
                                              } & Questions_ComponentQuestionTypesValuesCharacters_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesCompetency'
                                              } & Questions_ComponentQuestionTypesCompetency_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesValuesBubble'
                                              } & Questions_ComponentQuestionTypesValuesBubble_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesSliderQuestions'
                                              } & Questions_ComponentQuestionTypesSliderQuestions_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesFeedForward1'
                                              } & Questions_ComponentQuestionTypesFeedForward1_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesFeedForward2'
                                              } & Questions_ComponentQuestionTypesFeedForward2_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesCompetency2'
                                              } & Questions_ComponentQuestionTypesCompetency2_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesGravitasSliders'
                                              } & Questions_ComponentQuestionTypesGravitasSliders_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesUploadDocuments'
                                              } & Questions_ComponentQuestionTypesUploadDocuments_Fragment)
                                            | ({
                                                __typename?: 'ComponentQuestionTypesGravitasGap'
                                              } & Questions_ComponentQuestionTypesGravitasGap_Fragment)
                                            | ({ __typename?: 'Error' } & Questions_Error_Fragment)
                                          >
                                        >
                                      >
                                    }
                                >
                              }
                          >
                        }
                      >
                    }
                >
              >
            >
          }
      >
    >
  >
}

export type GetSubModuleSubmissionQueryVariables = {
  id: Scalars['ID']
}

export type GetSubModuleSubmissionQuery = { __typename?: 'Query' } & {
  subModuleSubmission?: Maybe<
    { __typename?: 'SubModuleSubmissionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'SubModuleSubmissionEntity' } & Pick<SubModuleSubmissionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'SubModuleSubmission' } & Pick<
                SubModuleSubmission,
                'view' | 'progress' | 'status' | 'time_spent'
              > & {
                  feedfoward_url?: Maybe<
                    { __typename?: 'FeedfowardUrlEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'FeedfowardUrlEntity' } & Pick<FeedfowardUrlEntity, 'id'>
                      >
                    }
                  >
                  competency_url?: Maybe<
                    { __typename?: 'CompetencyUrlEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'CompetencyUrlEntity' } & Pick<CompetencyUrlEntity, 'id'>
                      >
                    }
                  >
                  competency_participants?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsCompetencyParticipants' } & Pick<
                          ComponentSubmissionsCompetencyParticipants,
                          'name' | 'email'
                        >
                      >
                    >
                  >
                  participants?: Maybe<
                    { __typename?: 'ParticipantRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ParticipantEntity' } & Pick<ParticipantEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Participant' } & Pick<
                                Participant,
                                'name' | 'email' | 'type'
                              >
                            >
                          }
                      >
                    }
                  >
                  submission_participants?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsParticipants' } & Pick<
                          ComponentSubmissionsParticipants,
                          'id' | 'name' | 'email'
                        >
                      >
                    >
                  >
                  language?: Maybe<
                    { __typename?: 'LanguageEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                            attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                          }
                      >
                    }
                  >
                  section?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                          ComponentSubmissionsSectionSubmission,
                          'id' | 'progress' | 'sliderAnswers'
                        > & {
                            section?: Maybe<
                              { __typename?: 'SectionEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>
                                >
                              }
                            >
                            answers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                                    ComponentSubmissionsAnswer,
                                    | 'id'
                                    | 'questionId'
                                    | 'answer'
                                    | 'isCorrect'
                                    | 'feedback'
                                    | 'outcome'
                                    | 'questionType'
                                    | 'questionTitle'
                                  > & {
                                      nestedAnswers?: Maybe<
                                        Array<
                                          Maybe<
                                            {
                                              __typename?: 'ComponentSubmissionsRepeatAnswer'
                                            } & Pick<
                                              ComponentSubmissionsRepeatAnswer,
                                              'answer' | 'questionTitle'
                                            >
                                          >
                                        >
                                      >
                                    }
                                >
                              >
                            >
                            stakeholderChart?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            stakeholderItems?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentStakeholderMapItemStackholderMapItem'
                                  } & Pick<
                                    ComponentStakeholderMapItemStackholderMapItem,
                                    'id' | 'valueX' | 'valueY' | 'name' | 'color'
                                  >
                                >
                              >
                            >
                            gravitasScales?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems'
                                  } & Pick<
                                    ComponentGravitasScaleItemsGravitasScaleItems,
                                    | 'id'
                                    | 'labelOne'
                                    | 'labelTwo'
                                    | 'labelThree'
                                    | 'question'
                                    | 'answerOne'
                                    | 'answerTwo'
                                    | 'answerThree'
                                  >
                                >
                              >
                            >
                            gravitasSliderAnswers?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentSubmissionsGravitasSlidersAnswers'
                                  } & Pick<
                                    ComponentSubmissionsGravitasSlidersAnswers,
                                    'negativeQuestion' | 'positiveQuestion'
                                  > & {
                                      colorAndRating?: Maybe<
                                        Array<
                                          Maybe<
                                            { __typename?: 'ComponentOptionColorAndRating' } & Pick<
                                              ComponentOptionColorAndRating,
                                              'color' | 'rate'
                                            >
                                          >
                                        >
                                      >
                                    }
                                >
                              >
                            >
                            gravitasGap?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsGravitasGap' } & Pick<
                                    ComponentSubmissionsGravitasGap,
                                    'id' | 'gap' | 'commitment'
                                  >
                                >
                              >
                            >
                            xYGraphChart?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            saboteurImage?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            xYGraphItems?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                                    ComponentXyGraphItemXyGraphItem,
                                    'id' | 'valueX' | 'valueY' | 'color'
                                  >
                                >
                              >
                            >
                            characteristics?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsCharacteristics' } & Pick<
                                    ComponentSubmissionsCharacteristics,
                                    'id' | 'answer' | 'type'
                                  >
                                >
                              >
                            >
                            gapsAnswers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsValuesGaps' } & Pick<
                                    ComponentSubmissionsValuesGaps,
                                    'id' | 'gap' | 'commitment'
                                  >
                                >
                              >
                            >
                            competencyAnswers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsCompetencyAnswer' } & Pick<
                                    ComponentSubmissionsCompetencyAnswer,
                                    'id' | 'question' | 'rating' | 'steps' | 'response'
                                  >
                                >
                              >
                            >
                            uploadDocuments?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsUploadDocuments' } & {
                                    saboteurProofValue?: Maybe<
                                      { __typename?: 'UploadFileEntityResponse' } & {
                                        data?: Maybe<
                                          { __typename?: 'UploadFileEntity' } & Pick<
                                            UploadFileEntity,
                                            'id'
                                          >
                                        >
                                      }
                                    >
                                  }
                                >
                              >
                            >
                          }
                      >
                    >
                  >
                  sub_module?: Maybe<
                    { __typename?: 'SubModuleEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'SubModule' } & Pick<
                                SubModule,
                                | 'name_french'
                                | 'name_german'
                                | 'name_english'
                                | 'name_spanish'
                                | 'name_mandarin'
                                | 'description_french'
                                | 'description_german'
                                | 'description_english'
                                | 'description_spanish'
                                | 'description_mandarin'
                                | 'position'
                              > & {
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  sections?: Maybe<
                                    { __typename?: 'SectionRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'SectionEntity' } & Pick<
                                          SectionEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'Section' } & Pick<
                                                Section,
                                                | 'title_french'
                                                | 'title_german'
                                                | 'title_english'
                                                | 'title_spanish'
                                                | 'title_mandarin'
                                                | 'sub_title_french'
                                                | 'sub_title_german'
                                                | 'sub_title_english'
                                                | 'sub_title_spanish'
                                                | 'sub_title_mandarin'
                                                | 'position'
                                                | 'archive'
                                              > & {
                                                  cover?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                  programme_type?: Maybe<
                                    { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                          ProgrammeTypeEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'ProgrammeType' } & Pick<
                                                ProgrammeType,
                                                | 'title'
                                                | 'description'
                                                | 'position'
                                                | 'is_coaching'
                                                | 'is_sub_module'
                                                | 'notes'
                                                | 'show_article'
                                                | 'show_session'
                                                | 'show_participants'
                                                | 'competency_survey'
                                                | 'feedForward_survey'
                                                | 'deadline'
                                              > & {
                                                  cover?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  articles?: Maybe<
                                                    {
                                                      __typename?: 'ArticleRelationResponseCollection'
                                                    } & {
                                                      data: Array<
                                                        { __typename?: 'ArticleEntity' } & Pick<
                                                          ArticleEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Article' } & Pick<
                                                                Article,
                                                                | 'createdAt'
                                                                | 'updatedAt'
                                                                | 'title_english'
                                                                | 'title_french'
                                                                | 'title_german'
                                                                | 'title_spanish'
                                                                | 'title_mandarin'
                                                                | 'content_english'
                                                                | 'content_french'
                                                                | 'content_german'
                                                                | 'content_spanish'
                                                                | 'content_mandarin'
                                                                | 'date_published'
                                                                | 'read_time'
                                                                | 'link'
                                                              > & {
                                                                  image?: Maybe<
                                                                    {
                                                                      __typename?: 'UploadFileEntityResponse'
                                                                    } & {
                                                                      data?: Maybe<
                                                                        {
                                                                          __typename?: 'UploadFileEntity'
                                                                        } & {
                                                                          attributes?: Maybe<
                                                                            {
                                                                              __typename?: 'UploadFile'
                                                                            } & Pick<
                                                                              UploadFile,
                                                                              'url'
                                                                            >
                                                                          >
                                                                        }
                                                                      >
                                                                    }
                                                                  >
                                                                }
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  modal?: Maybe<
                                                    { __typename?: 'ModalEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'ModalEntity' } & Pick<
                                                          ModalEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Modal' } & Pick<
                                                                Modal,
                                                                'title' | 'subtitle' | 'message'
                                                              > & {
                                                                  image?: Maybe<
                                                                    {
                                                                      __typename?: 'UploadFileEntityResponse'
                                                                    } & {
                                                                      data?: Maybe<
                                                                        {
                                                                          __typename?: 'UploadFileEntity'
                                                                        } & Pick<
                                                                          UploadFileEntity,
                                                                          'id'
                                                                        > & {
                                                                            attributes?: Maybe<
                                                                              {
                                                                                __typename?: 'UploadFile'
                                                                              } & Pick<
                                                                                UploadFile,
                                                                                'url' | 'name'
                                                                              >
                                                                            >
                                                                          }
                                                                      >
                                                                    }
                                                                  >
                                                                }
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  events?: Maybe<
                                                    {
                                                      __typename?: 'EventRelationResponseCollection'
                                                    } & {
                                                      data: Array<
                                                        { __typename?: 'EventEntity' } & Pick<
                                                          EventEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Event' } & Pick<
                                                                Event,
                                                                'name' | 'link'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  reports?: Maybe<
                    { __typename?: 'ReportRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ReportEntity' } & Pick<ReportEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Report' } & Pick<
                                Report,
                                'name' | 'type' | 'section_or_module_id'
                              > & {
                                  report?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetNewTypeformFeedResponsesQueryVariables = {}

export type GetNewTypeformFeedResponsesQuery = { __typename?: 'Query' } & {
  getNewTypeformFeedResponses?: Maybe<
    { __typename?: 'CheckConfirmed' } & Pick<CheckConfirmed, 'success'>
  >
}

export type GetNotificationsQueryVariables = {}

export type GetNotificationsQuery = { __typename?: 'Query' } & {
  notifications?: Maybe<
    { __typename?: 'NotificationEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'NotificationEntity' } & Pick<NotificationEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Notification' } & Pick<
                Notification,
                'createdAt' | 'updatedAt' | 'title' | 'time_stamp' | 'path' | 'read'
              > & {
                  users?: Maybe<
                    { __typename?: 'UsersPermissionsUserRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'UsersPermissionsUserEntity' } & Pick<
                          UsersPermissionsUserEntity,
                          'id'
                        >
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetOrderedSubmissionsQueryVariables = {
  programme: Scalars['ID']
}

export type GetOrderedSubmissionsQuery = { __typename?: 'Query' } & {
  getOrderedSubmissions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OrderedSubmissions' } & {
          programmeSubmission: { __typename?: 'ProgrammeSubmission' } & Pick<
            ProgrammeSubmission,
            'id' | 'progress'
          > & {
              programme?: Maybe<
                { __typename?: 'ProgrammeEntityResponse' } & {
                  data?: Maybe<
                    { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                        attributes?: Maybe<
                          { __typename?: 'Programme' } & Pick<Programme, 'name' | 'description'> & {
                              logo?: Maybe<
                                { __typename?: 'UploadFileEntityResponse' } & {
                                  data?: Maybe<
                                    { __typename?: 'UploadFileEntity' } & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                  >
                                }
                              >
                            }
                        >
                      }
                  >
                }
              >
              programme_type?: Maybe<
                { __typename?: 'ProgrammeTypeEntityResponse' } & {
                  data?: Maybe<
                    { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                        attributes?: Maybe<
                          { __typename?: 'ProgrammeType' } & Pick<
                            ProgrammeType,
                            'title' | 'is_sub_module' | 'archive' | 'position'
                          > & {
                              sub_modules?: Maybe<
                                { __typename?: 'SubModuleRelationResponseCollection' } & {
                                  data: Array<
                                    { __typename?: 'SubModuleEntity' } & Pick<
                                      SubModuleEntity,
                                      'id'
                                    > & {
                                        attributes?: Maybe<
                                          { __typename?: 'SubModule' } & Pick<
                                            SubModule,
                                            | 'name_english'
                                            | 'name_french'
                                            | 'name_spanish'
                                            | 'name_mandarin'
                                            | 'name_german'
                                            | 'position'
                                          > & {
                                              sections?: Maybe<
                                                {
                                                  __typename?: 'SectionRelationResponseCollection'
                                                } & {
                                                  data: Array<
                                                    { __typename?: 'SectionEntity' } & Pick<
                                                      SectionEntity,
                                                      'id'
                                                    > & {
                                                        attributes?: Maybe<
                                                          { __typename?: 'Section' } & Pick<
                                                            Section,
                                                            | 'title_english'
                                                            | 'title_french'
                                                            | 'title_german'
                                                            | 'title_spanish'
                                                            | 'title_mandarin'
                                                            | 'position'
                                                          >
                                                        >
                                                      }
                                                  >
                                                }
                                              >
                                            }
                                        >
                                      }
                                  >
                                }
                              >
                            }
                        >
                      }
                  >
                }
              >
            }
          subModuleSubmissions: Array<
            { __typename?: 'SubModuleSubmission' } & Pick<
              SubModuleSubmission,
              'id' | 'view' | 'progress'
            > & {
                sub_module?: Maybe<
                  { __typename?: 'SubModuleEntityResponse' } & {
                    data?: Maybe<
                      { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                          attributes?: Maybe<
                            { __typename?: 'SubModule' } & Pick<
                              SubModule,
                              | 'name_french'
                              | 'name_german'
                              | 'name_english'
                              | 'name_spanish'
                              | 'name_mandarin'
                              | 'position'
                            > & {
                                sections?: Maybe<
                                  { __typename?: 'SectionRelationResponseCollection' } & {
                                    data: Array<
                                      { __typename?: 'SectionEntity' } & Pick<
                                        SectionEntity,
                                        'id'
                                      > & {
                                          attributes?: Maybe<
                                            { __typename?: 'Section' } & Pick<
                                              Section,
                                              | 'title_french'
                                              | 'title_german'
                                              | 'title_english'
                                              | 'title_spanish'
                                              | 'title_mandarin'
                                              | 'position'
                                            >
                                          >
                                        }
                                    >
                                  }
                                >
                              }
                          >
                        }
                    >
                  }
                >
                section?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                        ComponentSubmissionsSectionSubmission,
                        'id' | 'progress'
                      > & {
                          section?: Maybe<
                            { __typename?: 'SectionEntityResponse' } & {
                              data?: Maybe<
                                { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>
                              >
                            }
                          >
                        }
                    >
                  >
                >
              }
          >
        }
      >
    >
  >
}

export type GetProgammeReportsGroupedQueryVariables = {}

export type GetProgammeReportsGroupedQuery = { __typename?: 'Query' } & {
  getProgammeReportsGrouped?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ReportsGrouped' } & Pick<ReportsGrouped, 'companyName'> & {
            programmeType?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ProgrammeTypeReport' } & Pick<
                    ProgrammeTypeReport,
                    'programmeType' | 'programmeName'
                  > & {
                      sections?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'SectionReport' } & Pick<
                              SectionReport,
                              'programmeName' | 'programmeType' | 'name'
                            > & {
                                report?: Maybe<
                                  { __typename?: 'ReportDetail' } & Pick<ReportDetail, 'id' | 'url'>
                                >
                              }
                          >
                        >
                      >
                    }
                >
              >
            >
          }
      >
    >
  >
}

export type GetProgrammeQueryVariables = {
  id: Scalars['ID']
}

export type GetProgrammeQuery = { __typename?: 'Query' } & {
  programme?: Maybe<
    { __typename?: 'ProgrammeEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Programme' } & Pick<
                Programme,
                'name' | 'description' | 'what_to_expect' | 'completion'
              > & {
                  logo?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetProgrammeProgrammeSubmissionsQueryVariables = {
  where: ProgrammeSubmissionFiltersInput
  limit?: Maybe<Scalars['Int']>
}

export type GetProgrammeProgrammeSubmissionsQuery = { __typename?: 'Query' } & {
  programmeSubmissions?: Maybe<
    { __typename?: 'ProgrammeSubmissionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'ProgrammeSubmissionEntity' } & Pick<ProgrammeSubmissionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'ProgrammeSubmission' } & Pick<ProgrammeSubmission, 'progress'> & {
                  programme?: Maybe<
                    { __typename?: 'ProgrammeEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Programme' } & Pick<
                                Programme,
                                'name' | 'description'
                              > & {
                                  logo?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & {
                                          attributes?: Maybe<
                                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                          >
                                        }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  programme_type?: Maybe<
                    { __typename?: 'ProgrammeTypeEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'ProgrammeType' } & Pick<
                                ProgrammeType,
                                'title' | 'is_sub_module' | 'archive' | 'position'
                              > & {
                                  sub_modules?: Maybe<
                                    { __typename?: 'SubModuleRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'SubModuleEntity' } & Pick<
                                          SubModuleEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'SubModule' } & Pick<
                                                SubModule,
                                                | 'name_english'
                                                | 'name_french'
                                                | 'name_spanish'
                                                | 'name_mandarin'
                                                | 'name_german'
                                                | 'position'
                                              > & {
                                                  sections?: Maybe<
                                                    {
                                                      __typename?: 'SectionRelationResponseCollection'
                                                    } & {
                                                      data: Array<
                                                        { __typename?: 'SectionEntity' } & Pick<
                                                          SectionEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Section' } & Pick<
                                                                Section,
                                                                | 'title_english'
                                                                | 'title_french'
                                                                | 'title_german'
                                                                | 'title_spanish'
                                                                | 'title_mandarin'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMyProgrammeSubmissionQueryVariables = {
  id: Scalars['ID']
}

export type GetMyProgrammeSubmissionQuery = { __typename?: 'Query' } & {
  programmeSubmission?: Maybe<
    { __typename?: 'ProgrammeSubmissionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'ProgrammeSubmissionEntity' } & Pick<ProgrammeSubmissionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'ProgrammeSubmission' } & Pick<
                ProgrammeSubmission,
                'status' | 'progress' | 'selected'
              > & {
                  competency_url?: Maybe<
                    { __typename?: 'CompetencyUrlEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'CompetencyUrlEntity' } & Pick<CompetencyUrlEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'CompetencyUrl' } & Pick<CompetencyUrl, 'url'>
                            >
                          }
                      >
                    }
                  >
                  feedfoward_url?: Maybe<
                    { __typename?: 'FeedfowardUrlEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'FeedfowardUrlEntity' } & Pick<FeedfowardUrlEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'FeedfowardUrl' } & Pick<FeedfowardUrl, 'url'>
                            >
                          }
                      >
                    }
                  >
                  programme_type?: Maybe<
                    { __typename?: 'ProgrammeTypeEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'ProgrammeType' } & Pick<
                                ProgrammeType,
                                | 'title'
                                | 'description'
                                | 'show_participants'
                                | 'is_coaching'
                                | 'is_sub_module'
                                | 'notes'
                                | 'deadline'
                              > & {
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  programme_questions?: Maybe<
                                    Array<
                                      Maybe<
                                        | ({
                                            __typename: 'ComponentQuestionTypesValuesNested'
                                          } & Pick<
                                            ComponentQuestionTypesValuesNested,
                                            'id' | 'title' | 'question' | 'description'
                                          > & {
                                              questions?: Maybe<
                                                Array<
                                                  Maybe<
                                                    {
                                                      __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                                    } & Pick<
                                                      ComponentQuestionTypesSimpleQuestion,
                                                      | 'id'
                                                      | 'titleEnglish'
                                                      | 'subTitleEnglish'
                                                      | 'titleFrench'
                                                      | 'subTitleFrench'
                                                      | 'titleGerman'
                                                      | 'subTitleGerman'
                                                      | 'titleSpanish'
                                                      | 'subTitleSpanish'
                                                      | 'titleMandarin'
                                                      | 'subTitleMandarin'
                                                    >
                                                  >
                                                >
                                              >
                                            })
                                        | { __typename: 'ComponentQuestionTypesValuesBubble' }
                                        | { __typename: 'ComponentQuestionTypesValuesCharacters' }
                                        | { __typename: 'ComponentQuestionTypesValuesSliders' }
                                        | { __typename: 'ComponentQuestionTypesValuesGaps' }
                                        | { __typename: 'ComponentQuestionTypesSaboteurVideos' }
                                        | { __typename: 'ComponentQuestionTypesMultipleChoice' }
                                        | { __typename: 'ComponentQuestionTypesSaboteur' }
                                        | { __typename: 'ComponentQuestionTypesSimpleQuestion' }
                                        | { __typename: 'ComponentQuestionTypesXYGraph' }
                                        | { __typename: 'ComponentQuestionTypesNestedQuestions' }
                                        | { __typename: 'ComponentQuestionTypesUploadQuestion' }
                                        | { __typename: 'ComponentQuestionTypesGravitasScale' }
                                        | { __typename: 'ComponentQuestionTypesParticipant' }
                                        | { __typename: 'ComponentQuestionTypesStakeholderMap' }
                                        | { __typename: 'ComponentQuestionTypesFeedForward1' }
                                        | { __typename: 'ComponentQuestionTypesFeedForward2' }
                                        | { __typename: 'ComponentQuestionTypesSimpleReadOnly' }
                                        | ({
                                            __typename: 'ComponentQuestionTypesCompetency2'
                                          } & Pick<
                                            ComponentQuestionTypesCompetency2,
                                            'titleEnglish' | 'titleFrench' | 'titleMandarin'
                                          > & {
                                              questionscompetency2?: Maybe<
                                                Array<
                                                  Maybe<
                                                    {
                                                      __typename?: 'ComponentQuestionTypesQuestionscompetency2'
                                                    } & Pick<
                                                      ComponentQuestionTypesQuestionscompetency2,
                                                      | 'title'
                                                      | 'question'
                                                      | 'maxiNumber'
                                                      | 'miniNumber'
                                                      | 'simpleQuestion'
                                                      | 'miniLabel'
                                                      | 'maxiLabel'
                                                      | 'midiLabel'
                                                      | 'textboxQuestion'
                                                    >
                                                  >
                                                >
                                              >
                                            })
                                        | { __typename: 'ComponentQuestionTypesCompetency' }
                                        | { __typename: 'ComponentQuestionTypesGravitasGap' }
                                        | { __typename: 'ComponentQuestionTypesSliderQuestions' }
                                        | { __typename: 'ComponentQuestionTypesGravitasSliders' }
                                        | { __typename: 'Error' }
                                      >
                                    >
                                  >
                                  articles?: Maybe<
                                    { __typename?: 'ArticleRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'ArticleEntity' } & Pick<
                                          ArticleEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'Article' } & Pick<
                                                Article,
                                                | 'createdAt'
                                                | 'updatedAt'
                                                | 'title_english'
                                                | 'title_french'
                                                | 'title_german'
                                                | 'title_spanish'
                                                | 'title_mandarin'
                                                | 'content_english'
                                                | 'content_french'
                                                | 'content_german'
                                                | 'content_spanish'
                                                | 'content_mandarin'
                                                | 'date_published'
                                                | 'read_time'
                                                | 'link'
                                              > & {
                                                  image?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & {
                                                          attributes?: Maybe<
                                                            { __typename?: 'UploadFile' } & Pick<
                                                              UploadFile,
                                                              'url'
                                                            >
                                                          >
                                                        }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                  events?: Maybe<
                                    { __typename?: 'EventRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'EventEntity' } & Pick<EventEntity, 'id'> & {
                                            attributes?: Maybe<
                                              { __typename?: 'Event' } & Pick<
                                                Event,
                                                'name' | 'link' | 'session_id'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  sub_modules?: Maybe<
                                    { __typename?: 'SubModuleRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'SubModuleEntity' } & Pick<
                                          SubModuleEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'SubModule' } & Pick<
                                                SubModule,
                                                'name_english' | 'description_english'
                                              > & {
                                                  cover?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  programme_type?: Maybe<
                                                    {
                                                      __typename?: 'ProgrammeTypeEntityResponse'
                                                    } & {
                                                      data?: Maybe<
                                                        {
                                                          __typename?: 'ProgrammeTypeEntity'
                                                        } & Pick<ProgrammeTypeEntity, 'id'>
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  programme_responses?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsProgrammeSubmissions' } & {
                          answers?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                                  ComponentSubmissionsAnswer,
                                  | 'id'
                                  | 'questionId'
                                  | 'answer'
                                  | 'isCorrect'
                                  | 'feedback'
                                  | 'outcome'
                                  | 'questionType'
                                  | 'questionTitle'
                                > & {
                                    nestedAnswers?: Maybe<
                                      Array<
                                        Maybe<
                                          {
                                            __typename?: 'ComponentSubmissionsRepeatAnswer'
                                          } & Pick<
                                            ComponentSubmissionsRepeatAnswer,
                                            'answer' | 'questionTitle'
                                          >
                                        >
                                      >
                                    >
                                  }
                              >
                            >
                          >
                          stakeholderChart?: Maybe<
                            { __typename?: 'UploadFileEntityResponse' } & {
                              data?: Maybe<
                                { __typename?: 'UploadFileEntity' } & Pick<
                                  UploadFileEntity,
                                  'id'
                                > & {
                                    attributes?: Maybe<
                                      { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                    >
                                  }
                              >
                            }
                          >
                          stakeholderItems?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'ComponentStakeholderMapItemStackholderMapItem'
                                } & Pick<
                                  ComponentStakeholderMapItemStackholderMapItem,
                                  'id' | 'valueX' | 'valueY' | 'name' | 'color'
                                >
                              >
                            >
                          >
                          competencyAnswers?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ComponentSubmissionsCompetencyAnswer' } & Pick<
                                  ComponentSubmissionsCompetencyAnswer,
                                  'question' | 'rating' | 'response' | 'steps' | 'textboxQuestion'
                                >
                              >
                            >
                          >
                          gravitasScales?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems'
                                } & Pick<
                                  ComponentGravitasScaleItemsGravitasScaleItems,
                                  | 'id'
                                  | 'labelOne'
                                  | 'labelTwo'
                                  | 'labelThree'
                                  | 'question'
                                  | 'answerOne'
                                  | 'answerTwo'
                                  | 'answerThree'
                                >
                              >
                            >
                          >
                          xYGraphChart?: Maybe<
                            { __typename?: 'UploadFileEntityResponse' } & {
                              data?: Maybe<
                                { __typename?: 'UploadFileEntity' } & Pick<
                                  UploadFileEntity,
                                  'id'
                                > & {
                                    attributes?: Maybe<
                                      { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                    >
                                  }
                              >
                            }
                          >
                          saboteurImage?: Maybe<
                            { __typename?: 'UploadFileEntityResponse' } & {
                              data?: Maybe<
                                { __typename?: 'UploadFileEntity' } & Pick<
                                  UploadFileEntity,
                                  'id'
                                > & {
                                    attributes?: Maybe<
                                      { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                    >
                                  }
                              >
                            }
                          >
                          xYGraphItems?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                                  ComponentXyGraphItemXyGraphItem,
                                  'id' | 'valueX' | 'valueY' | 'color'
                                >
                              >
                            >
                          >
                        }
                      >
                    >
                  >
                  participants?: Maybe<
                    { __typename?: 'ParticipantRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ParticipantEntity' } & Pick<ParticipantEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Participant' } & Pick<
                                Participant,
                                'name' | 'email' | 'type'
                              >
                            >
                          }
                      >
                    }
                  >
                  submission_participants?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsParticipants' } & Pick<
                          ComponentSubmissionsParticipants,
                          'id' | 'name' | 'email'
                        >
                      >
                    >
                  >
                  my_notes?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsNotes' } & Pick<
                          ComponentSubmissionsNotes,
                          'id' | 'title' | 'description'
                        > & {
                            document?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    >
                  >
                }
            >
          }
      >
    }
  >
}

export type GetProgrammesQueryVariables = {}

export type GetProgrammesQuery = { __typename?: 'Query' } & {
  programmes?: Maybe<
    { __typename?: 'ProgrammeEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Programme' } & Pick<Programme, 'name' | 'description'> & {
                  logo?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                      >
                    }
                  >
                  programme_types?: Maybe<
                    { __typename?: 'ProgrammeTypeRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ProgrammeTypeEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'ProgrammeType' } & Pick<ProgrammeType, 'title'> & {
                                sub_modules?: Maybe<
                                  { __typename?: 'SubModuleRelationResponseCollection' } & {
                                    data: Array<
                                      { __typename?: 'SubModuleEntity' } & {
                                        attributes?: Maybe<
                                          { __typename?: 'SubModule' } & {
                                            sections?: Maybe<
                                              {
                                                __typename?: 'SectionRelationResponseCollection'
                                              } & {
                                                data: Array<
                                                  { __typename?: 'SectionEntity' } & {
                                                    attributes?: Maybe<
                                                      { __typename?: 'Section' } & Pick<
                                                        Section,
                                                        'title_english'
                                                      >
                                                    >
                                                  }
                                                >
                                              }
                                            >
                                          }
                                        >
                                      }
                                    >
                                  }
                                >
                              }
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetRegionsQueryVariables = {}

export type GetRegionsQuery = { __typename?: 'Query' } & {
  regions?: Maybe<
    { __typename?: 'RegionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'RegionEntity' } & Pick<RegionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Region' } & Pick<
                Region,
                | 'name_english'
                | 'name_french'
                | 'name_german'
                | 'name_spanish'
                | 'name_mandarin'
                | 'createdAt'
                | 'updatedAt'
              >
            >
          }
      >
    }
  >
}

export type GetReportsGroupedQueryVariables = {}

export type GetReportsGroupedQuery = { __typename?: 'Query' } & {
  getReportsGrouped?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ReportsGrouped' } & Pick<ReportsGrouped, 'companyName'> & {
            programmeType?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ProgrammeTypeReport' } & Pick<
                    ProgrammeTypeReport,
                    'programmeType' | 'programmeName'
                  > & {
                      sections?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'SectionReport' } & Pick<
                              SectionReport,
                              'programmeName' | 'programmeType' | 'name'
                            > & {
                                report?: Maybe<
                                  { __typename?: 'ReportDetail' } & Pick<ReportDetail, 'id' | 'url'>
                                >
                              }
                          >
                        >
                      >
                    }
                >
              >
            >
          }
      >
    >
  >
}

export type GetSectionQueryVariables = {
  id: Scalars['ID']
}

export type GetSectionQuery = { __typename?: 'Query' } & {
  section?: Maybe<
    { __typename?: 'SectionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Section' } & Pick<
                Section,
                'title_english' | 'sub_title_english' | 'get_started_text' | 'show_session'
              > & {
                  cover?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  video_english?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  questions?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename: 'ComponentQuestionTypesMultipleChoice' } & Pick<
                            ComponentQuestionTypesMultipleChoice,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'viewPrevious'
                            | 'numberOfAnswers'
                          > & {
                              option?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentOptionOption' } & Pick<
                                      ComponentOptionOption,
                                      | 'id'
                                      | 'optionEnglish'
                                      | 'optionFrench'
                                      | 'optionGerman'
                                      | 'optionSpanish'
                                      | 'optionMandarin'
                                      | 'isCorrect'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesSaboteur' } & Pick<
                            ComponentQuestionTypesSaboteur,
                            | 'id'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                            | 'labelEnglish'
                            | 'labelFrench'
                            | 'labelGerman'
                            | 'labelSpanish'
                          > & {
                              saboteurQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'ComponentQuestionTypesSaboteurQuestions'
                                    } & Pick<
                                      ComponentQuestionTypesSaboteurQuestions,
                                      | 'link'
                                      | 'skipProof'
                                      | 'labelEnglish'
                                      | 'labelFrench'
                                      | 'labelGerman'
                                      | 'labelSpanish'
                                      | 'labelMandarin'
                                    > & {
                                        proof?: Maybe<
                                          {
                                            __typename?: 'UploadFileRelationResponseCollection'
                                          } & {
                                            data: Array<
                                              {
                                                __typename?: 'UploadFileEntity'
                                              } & UploadFileFragment
                                            >
                                          }
                                        >
                                      }
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                            ComponentQuestionTypesSimpleQuestion,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleSpanish'
                            | 'subTitleMandarin'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesXYGraph' } & Pick<
                            ComponentQuestionTypesXyGraph,
                            | 'id'
                            | 'labelXEnglish'
                            | 'labelYEnglish'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'labelXFrench'
                            | 'labelYFrench'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'labelXGerman'
                            | 'labelYGerman'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'labelXSpanish'
                            | 'labelYSpanish'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'labelXMandarin'
                            | 'labelYMandarin'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesNestedQuestions' } & Pick<
                            ComponentQuestionTypesNestedQuestions,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'viewPrevious'
                          > & {
                              questions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                                      ComponentQuestionTypesSimpleQuestion,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                      | 'isNumeric'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesGravitasScale' } & Pick<
                            ComponentQuestionTypesGravitasScale,
                            | 'id'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'questionOneEnglish'
                            | 'questionTwoEnglish'
                            | 'questionThreeEnglish'
                            | 'titleOneEnglish'
                            | 'titleTwoEnglish'
                          > & {
                              ratingInput?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentRatingInputRatingInput' } & Pick<
                                      ComponentRatingInputRatingInput,
                                      'id' | 'labelOneEnglish' | 'labelTwoEnglish'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesStakeholderMap' } & Pick<
                            ComponentQuestionTypesStakeholderMap,
                            | 'id'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                            | 'questionX'
                            | 'questionY'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesSaboteurVideos' } & Pick<
                            ComponentQuestionTypesSaboteurVideos,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleSpanish'
                            | 'subTitleMandarin'
                          > & {
                              SaboteurVideo?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentSaboteurVideoSaboteurVideo' } & Pick<
                                      ComponentSaboteurVideoSaboteurVideo,
                                      | 'id'
                                      | 'videoTitleEnglish'
                                      | 'videoTitleFrench'
                                      | 'videoTitleGerman'
                                      | 'videoTitleSpanish'
                                      | 'videoTitleMandarin'
                                    > & {
                                        video?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                      }
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesValuesSliders' } & Pick<
                            ComponentQuestionTypesValuesSliders,
                            'id' | 'title' | 'question' | 'description'
                          > & {
                              sliderQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentQuestionTypesSliderQuestions' } & Pick<
                                      ComponentQuestionTypesSliderQuestions,
                                      'id' | 'question' | 'color'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesSimpleReadOnly' } & Pick<
                            ComponentQuestionTypesSimpleReadOnly,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleMandarin'
                            | 'titleSpanish'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleMandarin'
                            | 'subTitleSpanish'
                            | 'contentEnglish'
                            | 'contentFrench'
                            | 'contentGerman'
                            | 'contentMandarin'
                            | 'contentSpanish'
                          > & {
                              photo?: Maybe<
                                { __typename?: 'UploadFileEntityResponse' } & {
                                  data?: Maybe<
                                    { __typename?: 'UploadFileEntity' } & Pick<
                                      UploadFileEntity,
                                      'id'
                                    > & {
                                        attributes?: Maybe<
                                          { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                        >
                                      }
                                  >
                                }
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesValuesNested' } & Pick<
                            ComponentQuestionTypesValuesNested,
                            'id' | 'title' | 'question' | 'description'
                          > & {
                              questions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                                      ComponentQuestionTypesSimpleQuestion,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesValuesGaps' } & Pick<
                            ComponentQuestionTypesValuesGaps,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | { __typename: 'ComponentQuestionTypesUploadQuestion' }
                        | { __typename: 'ComponentQuestionTypesParticipant' }
                        | ({ __typename: 'ComponentQuestionTypesValuesCharacters' } & Pick<
                            ComponentQuestionTypesValuesCharacters,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesCompetency' } & Pick<
                            ComponentQuestionTypesCompetency,
                            | 'id'
                            | 'title'
                            | 'question'
                            | 'minNumber'
                            | 'maxNumber'
                            | 'miniLabel'
                            | 'maxiLabel'
                            | 'simpleQuestion'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesValuesBubble' } & Pick<
                            ComponentQuestionTypesValuesBubble,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | { __typename: 'ComponentQuestionTypesSliderQuestions' }
                        | ({ __typename: 'ComponentQuestionTypesFeedForward1' } & Pick<
                            ComponentQuestionTypesFeedForward1,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesFeedForward2' } & Pick<
                            ComponentQuestionTypesFeedForward2,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesCompetency2' } & Pick<
                            ComponentQuestionTypesCompetency2,
                            'titleEnglish' | 'titleFrench' | 'titleMandarin'
                          > & {
                              questionscompetency2?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'ComponentQuestionTypesQuestionscompetency2'
                                    } & Pick<
                                      ComponentQuestionTypesQuestionscompetency2,
                                      | 'title'
                                      | 'question'
                                      | 'maxiNumber'
                                      | 'miniNumber'
                                      | 'simpleQuestion'
                                      | 'miniLabel'
                                      | 'maxiLabel'
                                      | 'midiLabel'
                                      | 'textboxQuestion'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesGravitasSliders' } & Pick<
                            ComponentQuestionTypesGravitasSliders,
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleMandarin'
                            | 'titleSpanish'
                            | 'titleGerman'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'positiveHeadingEnglish'
                            | 'positiveHeadingFrench'
                            | 'positiveHeadingSpanish'
                            | 'positiveHeadingMandarin'
                            | 'positiveHeadingGerman'
                            | 'negativeHeadingEnglish'
                            | 'negativeHeadingFrench'
                            | 'negativeHeadingSpanish'
                            | 'negativeHeadingMandarin'
                            | 'negativeHeadingGerman'
                          > & {
                              sliderQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentOptionSliderQuestions' } & Pick<
                                      ComponentOptionSliderQuestions,
                                      | 'questionPositiveEnglish'
                                      | 'questionPositiveGerman'
                                      | 'questionPositiveMandarin'
                                      | 'questionPositiveSpanish'
                                      | 'questionPositiveFrench'
                                      | 'questionNegativeEnglish'
                                      | 'questionNegativeGerman'
                                      | 'questionNegativeFrench'
                                      | 'questionNegativeMandarin'
                                      | 'questionNegativeSpanish'
                                    >
                                  >
                                >
                              >
                              sliderHeadings?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentOptionSliderHeadings' } & Pick<
                                      ComponentOptionSliderHeadings,
                                      | 'headingEnglish'
                                      | 'headingSpanish'
                                      | 'headingGerman'
                                      | 'headingFrench'
                                      | 'rating'
                                      | 'color'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesUploadDocuments' } & Pick<
                            ComponentQuestionTypesUploadDocuments,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleMandarin'
                            | 'titleSpanish'
                            | 'titleGerman'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleMandarin'
                            | 'subTitleSpanish'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesGravitasGap' } & Pick<
                            ComponentQuestionTypesGravitasGap,
                            | 'id'
                            | 'title'
                            | 'question'
                            | 'description'
                            | 'color1'
                            | 'color2'
                            | 'color3'
                          >)
                        | { __typename: 'Error' }
                      >
                    >
                  >
                  events?: Maybe<
                    { __typename?: 'EventRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'EventEntity' } & Pick<EventEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Event' } & Pick<Event, 'name' | 'link' | 'session_id'>
                            >
                          }
                      >
                    }
                  >
                  sub_module?: Maybe<
                    { __typename?: 'SubModuleEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'SubModule' } & {
                                programme_type?: Maybe<
                                  { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                    data?: Maybe<
                                      { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                        ProgrammeTypeEntity,
                                        'id'
                                      > & {
                                          attributes?: Maybe<
                                            { __typename?: 'ProgrammeType' } & Pick<
                                              ProgrammeType,
                                              'title'
                                            >
                                          >
                                        }
                                    >
                                  }
                                >
                              }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetSectionsQueryVariables = {}

export type GetSectionsQuery = { __typename?: 'Query' } & {
  sections?: Maybe<
    { __typename?: 'SectionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Section' } & Pick<
                Section,
                | 'title_english'
                | 'sub_title_english'
                | 'title_french'
                | 'title_german'
                | 'title_spanish'
                | 'title_mandarin'
                | 'sub_title_french'
                | 'sub_title_german'
                | 'sub_title_spanish'
                | 'sub_title_mandarin'
                | 'show_session'
                | 'archive'
              > & {
                  video_english?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  video_french?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  video_german?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  video_spanish?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  video_mandarin?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  questions?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename: 'ComponentQuestionTypesMultipleChoice' } & Pick<
                            ComponentQuestionTypesMultipleChoice,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'numberOfAnswers'
                          > & {
                              option?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentOptionOption' } & Pick<
                                      ComponentOptionOption,
                                      | 'id'
                                      | 'optionEnglish'
                                      | 'optionFrench'
                                      | 'optionGerman'
                                      | 'optionSpanish'
                                      | 'optionMandarin'
                                      | 'isCorrect'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesSaboteur' } & Pick<
                            ComponentQuestionTypesSaboteur,
                            | 'id'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                            | 'labelEnglish'
                            | 'labelFrench'
                            | 'labelGerman'
                            | 'labelSpanish'
                          > & {
                              saboteurQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'ComponentQuestionTypesSaboteurQuestions'
                                    } & Pick<
                                      ComponentQuestionTypesSaboteurQuestions,
                                      | 'link'
                                      | 'skipProof'
                                      | 'labelEnglish'
                                      | 'labelFrench'
                                      | 'labelGerman'
                                      | 'labelSpanish'
                                      | 'labelMandarin'
                                    > & {
                                        proof?: Maybe<
                                          {
                                            __typename?: 'UploadFileRelationResponseCollection'
                                          } & {
                                            data: Array<
                                              {
                                                __typename?: 'UploadFileEntity'
                                              } & UploadFileFragment
                                            >
                                          }
                                        >
                                      }
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                            ComponentQuestionTypesSimpleQuestion,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleSpanish'
                            | 'subTitleMandarin'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesXYGraph' } & Pick<
                            ComponentQuestionTypesXyGraph,
                            | 'id'
                            | 'labelXEnglish'
                            | 'labelYEnglish'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'labelXFrench'
                            | 'labelYFrench'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'labelXGerman'
                            | 'labelYGerman'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'labelXSpanish'
                            | 'labelYSpanish'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'labelXMandarin'
                            | 'labelYMandarin'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesNestedQuestions' } & Pick<
                            ComponentQuestionTypesNestedQuestions,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                          > & {
                              questions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                                      ComponentQuestionTypesSimpleQuestion,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                      | 'isNumeric'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesGravitasScale' } & Pick<
                            ComponentQuestionTypesGravitasScale,
                            | 'id'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'questionOneEnglish'
                            | 'questionTwoEnglish'
                            | 'questionThreeEnglish'
                            | 'titleOneEnglish'
                            | 'titleTwoEnglish'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'questionOneFrench'
                            | 'questionTwoFrench'
                            | 'questionThreeFrench'
                            | 'titleOneFrench'
                            | 'titleTwoFrench'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'questionOneGerman'
                            | 'questionTwoGerman'
                            | 'questionThreeGerman'
                            | 'titleOneGerman'
                            | 'titleTwoGerman'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'questionOneSpanish'
                            | 'questionTwoSpanish'
                            | 'questionThreeSpanish'
                            | 'titleOneSpanish'
                            | 'titleTwoSpanish'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                            | 'questionOneMandarin'
                            | 'questionTwoMandarin'
                            | 'questionThreeMandarin'
                            | 'titleOneMandarin'
                            | 'titleTwoMandarin'
                          > & {
                              ratingInput?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentRatingInputRatingInput' } & Pick<
                                      ComponentRatingInputRatingInput,
                                      | 'id'
                                      | 'labelOneEnglish'
                                      | 'labelTwoEnglish'
                                      | 'labelOneFrench'
                                      | 'labelTwoFrench'
                                      | 'labelOneGerman'
                                      | 'labelTwoGerman'
                                      | 'labelOneSpanish'
                                      | 'labelTwoSpanish'
                                      | 'labelOneMandarin'
                                      | 'labelTwoMandarin'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesStakeholderMap' } & Pick<
                            ComponentQuestionTypesStakeholderMap,
                            | 'id'
                            | 'titleEnglish'
                            | 'subTitleEnglish'
                            | 'titleFrench'
                            | 'subTitleFrench'
                            | 'titleGerman'
                            | 'subTitleGerman'
                            | 'titleSpanish'
                            | 'subTitleSpanish'
                            | 'titleMandarin'
                            | 'subTitleMandarin'
                            | 'questionX'
                            | 'questionY'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesSaboteurVideos' } & Pick<
                            ComponentQuestionTypesSaboteurVideos,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleSpanish'
                            | 'titleMandarin'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleSpanish'
                            | 'subTitleMandarin'
                          > & {
                              SaboteurVideo?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentSaboteurVideoSaboteurVideo' } & Pick<
                                      ComponentSaboteurVideoSaboteurVideo,
                                      | 'id'
                                      | 'videoTitleEnglish'
                                      | 'videoTitleFrench'
                                      | 'videoTitleGerman'
                                      | 'videoTitleSpanish'
                                      | 'videoTitleMandarin'
                                    > & {
                                        video?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                      }
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesValuesSliders' } & Pick<
                            ComponentQuestionTypesValuesSliders,
                            'id' | 'title' | 'question' | 'description'
                          > & {
                              sliderQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentQuestionTypesSliderQuestions' } & Pick<
                                      ComponentQuestionTypesSliderQuestions,
                                      'id' | 'question' | 'color'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesSimpleReadOnly' } & Pick<
                            ComponentQuestionTypesSimpleReadOnly,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleGerman'
                            | 'titleMandarin'
                            | 'titleSpanish'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleMandarin'
                            | 'subTitleSpanish'
                            | 'contentEnglish'
                            | 'contentFrench'
                            | 'contentGerman'
                            | 'contentMandarin'
                            | 'contentSpanish'
                          > & {
                              photo?: Maybe<
                                { __typename?: 'UploadFileEntityResponse' } & {
                                  data?: Maybe<
                                    { __typename?: 'UploadFileEntity' } & Pick<
                                      UploadFileEntity,
                                      'id'
                                    > & {
                                        attributes?: Maybe<
                                          { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                        >
                                      }
                                  >
                                }
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesValuesNested' } & Pick<
                            ComponentQuestionTypesValuesNested,
                            'id' | 'title' | 'question' | 'description'
                          > & {
                              questions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                                      ComponentQuestionTypesSimpleQuestion,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesValuesGaps' } & Pick<
                            ComponentQuestionTypesValuesGaps,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | { __typename: 'ComponentQuestionTypesUploadQuestion' }
                        | { __typename: 'ComponentQuestionTypesParticipant' }
                        | ({ __typename: 'ComponentQuestionTypesValuesCharacters' } & Pick<
                            ComponentQuestionTypesValuesCharacters,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesCompetency' } & Pick<
                            ComponentQuestionTypesCompetency,
                            | 'id'
                            | 'title'
                            | 'question'
                            | 'minNumber'
                            | 'maxNumber'
                            | 'miniLabel'
                            | 'maxiLabel'
                            | 'simpleQuestion'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesValuesBubble' } & Pick<
                            ComponentQuestionTypesValuesBubble,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | { __typename: 'ComponentQuestionTypesSliderQuestions' }
                        | ({ __typename: 'ComponentQuestionTypesFeedForward1' } & Pick<
                            ComponentQuestionTypesFeedForward1,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesFeedForward2' } & Pick<
                            ComponentQuestionTypesFeedForward2,
                            'id' | 'title' | 'question' | 'description'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesCompetency2' } & Pick<
                            ComponentQuestionTypesCompetency2,
                            'titleEnglish' | 'titleFrench' | 'titleMandarin'
                          > & {
                              questionscompetency2?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'ComponentQuestionTypesQuestionscompetency2'
                                    } & Pick<
                                      ComponentQuestionTypesQuestionscompetency2,
                                      | 'title'
                                      | 'question'
                                      | 'maxiNumber'
                                      | 'miniNumber'
                                      | 'simpleQuestion'
                                      | 'miniLabel'
                                      | 'maxiLabel'
                                      | 'midiLabel'
                                      | 'textboxQuestion'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesGravitasSliders' } & Pick<
                            ComponentQuestionTypesGravitasSliders,
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleMandarin'
                            | 'titleSpanish'
                            | 'titleGerman'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'positiveHeadingEnglish'
                            | 'positiveHeadingFrench'
                            | 'positiveHeadingSpanish'
                            | 'positiveHeadingMandarin'
                            | 'positiveHeadingGerman'
                            | 'negativeHeadingEnglish'
                            | 'negativeHeadingFrench'
                            | 'negativeHeadingSpanish'
                            | 'negativeHeadingMandarin'
                            | 'negativeHeadingGerman'
                          > & {
                              sliderQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentOptionSliderQuestions' } & Pick<
                                      ComponentOptionSliderQuestions,
                                      | 'questionPositiveEnglish'
                                      | 'questionPositiveGerman'
                                      | 'questionPositiveMandarin'
                                      | 'questionPositiveSpanish'
                                      | 'questionPositiveFrench'
                                      | 'questionNegativeEnglish'
                                      | 'questionNegativeGerman'
                                      | 'questionNegativeFrench'
                                      | 'questionNegativeMandarin'
                                      | 'questionNegativeSpanish'
                                    >
                                  >
                                >
                              >
                              sliderHeadings?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'ComponentOptionSliderHeadings' } & Pick<
                                      ComponentOptionSliderHeadings,
                                      | 'headingEnglish'
                                      | 'headingSpanish'
                                      | 'headingGerman'
                                      | 'headingFrench'
                                      | 'headingMandarin'
                                      | 'color'
                                      | 'rating'
                                    >
                                  >
                                >
                              >
                            })
                        | ({ __typename: 'ComponentQuestionTypesUploadDocuments' } & Pick<
                            ComponentQuestionTypesUploadDocuments,
                            | 'id'
                            | 'titleEnglish'
                            | 'titleFrench'
                            | 'titleMandarin'
                            | 'titleSpanish'
                            | 'titleGerman'
                            | 'subTitleEnglish'
                            | 'subTitleFrench'
                            | 'subTitleGerman'
                            | 'subTitleMandarin'
                            | 'subTitleSpanish'
                          >)
                        | ({ __typename: 'ComponentQuestionTypesGravitasGap' } & Pick<
                            ComponentQuestionTypesGravitasGap,
                            | 'id'
                            | 'title'
                            | 'question'
                            | 'description'
                            | 'color1'
                            | 'color2'
                            | 'color3'
                          >)
                        | { __typename: 'Error' }
                      >
                    >
                  >
                  events?: Maybe<
                    { __typename?: 'EventRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'EventEntity' } & Pick<EventEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Event' } & Pick<Event, 'name' | 'link' | 'session_id'>
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetCoachSessionsQueryVariables = {}

export type GetCoachSessionsQuery = { __typename?: 'Query' } & {
  coachSessions?: Maybe<
    { __typename?: 'CoachSessionEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'CoachSessionEntity' } & Pick<CoachSessionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'CoachSession' } & Pick<CoachSession, 'title' | 'status'> & {
                  user?: Maybe<
                    { __typename?: 'UsersPermissionsUserEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UsersPermissionsUserEntity' } & Pick<
                          UsersPermissionsUserEntity,
                          'id'
                        >
                      >
                    }
                  >
                  programme_submission?: Maybe<
                    { __typename?: 'ProgrammeSubmissionEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeSubmissionEntity' } & Pick<
                          ProgrammeSubmissionEntity,
                          'id'
                        >
                      >
                    }
                  >
                  sub_module_submission?: Maybe<
                    { __typename?: 'SubModuleSubmissionEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleSubmissionEntity' } & Pick<
                          SubModuleSubmissionEntity,
                          'id'
                        >
                      >
                    }
                  >
                  appointment?: Maybe<
                    { __typename?: 'AppointmentEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'AppointmentEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'Appointment' } & Pick<
                              Appointment,
                              | 'appointment_url'
                              | 'appointment_start_date'
                              | 'appointment_end_date'
                              | 'status'
                            >
                          >
                        }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetSubModuleQueryVariables = {
  id: Scalars['ID']
}

export type GetSubModuleQuery = { __typename?: 'Query' } & {
  subModule?: Maybe<
    { __typename?: 'SubModuleEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'SubModule' } & Pick<
                SubModule,
                | 'name_english'
                | 'name_french'
                | 'name_spanish'
                | 'name_german'
                | 'name_mandarin'
                | 'description_english'
                | 'description_french'
                | 'description_spanish'
                | 'description_german'
                | 'description_mandarin'
              > & {
                  cover?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  sections?: Maybe<
                    { __typename?: 'SectionRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Section' } & Pick<
                                Section,
                                | 'title_french'
                                | 'title_german'
                                | 'title_english'
                                | 'title_spanish'
                                | 'title_mandarin'
                                | 'sub_title_french'
                                | 'sub_title_german'
                                | 'sub_title_english'
                                | 'sub_title_spanish'
                                | 'sub_title_mandarin'
                                | 'position'
                                | 'archive'
                              > & {
                                  video_english?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_german?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_spanish?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_mandarin?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_french?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  questions?: Maybe<
                                    Array<
                                      Maybe<
                                        | ({
                                            __typename?: 'ComponentQuestionTypesMultipleChoice'
                                          } & Questions_ComponentQuestionTypesMultipleChoice_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteur'
                                          } & Questions_ComponentQuestionTypesSaboteur_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                          } & Questions_ComponentQuestionTypesSimpleQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesXYGraph'
                                          } & Questions_ComponentQuestionTypesXyGraph_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesNestedQuestions'
                                          } & Questions_ComponentQuestionTypesNestedQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasScale'
                                          } & Questions_ComponentQuestionTypesGravitasScale_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesStakeholderMap'
                                          } & Questions_ComponentQuestionTypesStakeholderMap_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteurVideos'
                                          } & Questions_ComponentQuestionTypesSaboteurVideos_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesSliders'
                                          } & Questions_ComponentQuestionTypesValuesSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleReadOnly'
                                          } & Questions_ComponentQuestionTypesSimpleReadOnly_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesNested'
                                          } & Questions_ComponentQuestionTypesValuesNested_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesGaps'
                                          } & Questions_ComponentQuestionTypesValuesGaps_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadQuestion'
                                          } & Questions_ComponentQuestionTypesUploadQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesParticipant'
                                          } & Questions_ComponentQuestionTypesParticipant_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesCharacters'
                                          } & Questions_ComponentQuestionTypesValuesCharacters_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency'
                                          } & Questions_ComponentQuestionTypesCompetency_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesBubble'
                                          } & Questions_ComponentQuestionTypesValuesBubble_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSliderQuestions'
                                          } & Questions_ComponentQuestionTypesSliderQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward1'
                                          } & Questions_ComponentQuestionTypesFeedForward1_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward2'
                                          } & Questions_ComponentQuestionTypesFeedForward2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency2'
                                          } & Questions_ComponentQuestionTypesCompetency2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasSliders'
                                          } & Questions_ComponentQuestionTypesGravitasSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadDocuments'
                                          } & Questions_ComponentQuestionTypesUploadDocuments_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasGap'
                                          } & Questions_ComponentQuestionTypesGravitasGap_Fragment)
                                        | ({ __typename?: 'Error' } & Questions_Error_Fragment)
                                      >
                                    >
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  programme_type?: Maybe<
                    { __typename?: 'ProgrammeTypeEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'ProgrammeType' } & Pick<
                                ProgrammeType,
                                | 'title'
                                | 'description'
                                | 'position'
                                | 'is_coaching'
                                | 'is_sub_module'
                                | 'notes'
                                | 'show_article'
                                | 'show_session'
                                | 'show_participants'
                                | 'competency_survey'
                                | 'feedForward_survey'
                                | 'deadline'
                              > & {
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  articles?: Maybe<
                                    { __typename?: 'ArticleRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'ArticleEntity' } & Pick<
                                          ArticleEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'Article' } & Pick<
                                                Article,
                                                | 'createdAt'
                                                | 'updatedAt'
                                                | 'title_english'
                                                | 'title_french'
                                                | 'title_german'
                                                | 'title_spanish'
                                                | 'title_mandarin'
                                                | 'content_english'
                                                | 'content_french'
                                                | 'content_german'
                                                | 'content_spanish'
                                                | 'content_mandarin'
                                                | 'date_published'
                                                | 'read_time'
                                                | 'link'
                                              > & {
                                                  image?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & {
                                                          attributes?: Maybe<
                                                            { __typename?: 'UploadFile' } & Pick<
                                                              UploadFile,
                                                              'url'
                                                            >
                                                          >
                                                        }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                  modal?: Maybe<
                                    { __typename?: 'ModalEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'ModalEntity' } & Pick<ModalEntity, 'id'> & {
                                            attributes?: Maybe<
                                              { __typename?: 'Modal' } & Pick<
                                                Modal,
                                                'title' | 'subtitle' | 'message'
                                              > & {
                                                  image?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url' | 'name'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                  events?: Maybe<
                                    { __typename?: 'EventRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'EventEntity' } & Pick<EventEntity, 'id'> & {
                                            attributes?: Maybe<
                                              { __typename?: 'Event' } & Pick<
                                                Event,
                                                'name' | 'link'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetMySubModuleSubmissionQueryVariables = {
  id: Scalars['ID']
}

export type GetMySubModuleSubmissionQuery = { __typename?: 'Query' } & {
  subModuleSubmission?: Maybe<
    { __typename?: 'SubModuleSubmissionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'SubModuleSubmissionEntity' } & Pick<SubModuleSubmissionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'SubModuleSubmission' } & Pick<
                SubModuleSubmission,
                'view' | 'progress' | 'status' | 'time_spent'
              > & {
                  participants?: Maybe<
                    { __typename?: 'ParticipantRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ParticipantEntity' } & Pick<ParticipantEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Participant' } & Pick<
                                Participant,
                                'name' | 'email' | 'type'
                              >
                            >
                          }
                      >
                    }
                  >
                  submission_participants?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsParticipants' } & Pick<
                          ComponentSubmissionsParticipants,
                          'id' | 'name' | 'email'
                        >
                      >
                    >
                  >
                  feedfoward_url?: Maybe<
                    { __typename?: 'FeedfowardUrlEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'FeedfowardUrlEntity' } & Pick<FeedfowardUrlEntity, 'id'>
                      >
                    }
                  >
                  language?: Maybe<
                    { __typename?: 'LanguageEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                            attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                          }
                      >
                    }
                  >
                  sub_module?: Maybe<
                    { __typename?: 'SubModuleEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'SubModule' } & Pick<
                                SubModule,
                                | 'name_french'
                                | 'name_german'
                                | 'name_english'
                                | 'name_spanish'
                                | 'name_mandarin'
                                | 'description_french'
                                | 'description_german'
                                | 'description_english'
                                | 'description_spanish'
                                | 'description_mandarin'
                                | 'position'
                              > & {
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  sections?: Maybe<
                                    { __typename?: 'SectionRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'SectionEntity' } & Pick<
                                          SectionEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'Section' } & Pick<
                                                Section,
                                                | 'title_french'
                                                | 'title_german'
                                                | 'title_english'
                                                | 'title_spanish'
                                                | 'title_mandarin'
                                                | 'sub_title_french'
                                                | 'sub_title_german'
                                                | 'sub_title_english'
                                                | 'sub_title_spanish'
                                                | 'sub_title_mandarin'
                                                | 'position'
                                                | 'archive'
                                              > & {
                                                  cover?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                  programme_type?: Maybe<
                                    { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                          ProgrammeTypeEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'ProgrammeType' } & Pick<
                                                ProgrammeType,
                                                | 'title'
                                                | 'description'
                                                | 'position'
                                                | 'is_coaching'
                                                | 'is_sub_module'
                                                | 'notes'
                                                | 'show_article'
                                                | 'show_session'
                                                | 'show_participants'
                                                | 'competency_survey'
                                                | 'feedForward_survey'
                                                | 'deadline'
                                                | 'show_statistics'
                                              > & {
                                                  cover?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  articles?: Maybe<
                                                    {
                                                      __typename?: 'ArticleRelationResponseCollection'
                                                    } & {
                                                      data: Array<
                                                        { __typename?: 'ArticleEntity' } & Pick<
                                                          ArticleEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Article' } & Pick<
                                                                Article,
                                                                | 'createdAt'
                                                                | 'updatedAt'
                                                                | 'title_english'
                                                                | 'title_french'
                                                                | 'title_german'
                                                                | 'title_spanish'
                                                                | 'title_mandarin'
                                                                | 'content_english'
                                                                | 'content_french'
                                                                | 'content_german'
                                                                | 'content_spanish'
                                                                | 'content_mandarin'
                                                                | 'date_published'
                                                                | 'read_time'
                                                                | 'link'
                                                              > & {
                                                                  image?: Maybe<
                                                                    {
                                                                      __typename?: 'UploadFileEntityResponse'
                                                                    } & {
                                                                      data?: Maybe<
                                                                        {
                                                                          __typename?: 'UploadFileEntity'
                                                                        } & {
                                                                          attributes?: Maybe<
                                                                            {
                                                                              __typename?: 'UploadFile'
                                                                            } & Pick<
                                                                              UploadFile,
                                                                              'url'
                                                                            >
                                                                          >
                                                                        }
                                                                      >
                                                                    }
                                                                  >
                                                                }
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  modal?: Maybe<
                                                    { __typename?: 'ModalEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'ModalEntity' } & Pick<
                                                          ModalEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Modal' } & Pick<
                                                                Modal,
                                                                'title' | 'subtitle' | 'message'
                                                              > & {
                                                                  image?: Maybe<
                                                                    {
                                                                      __typename?: 'UploadFileEntityResponse'
                                                                    } & {
                                                                      data?: Maybe<
                                                                        {
                                                                          __typename?: 'UploadFileEntity'
                                                                        } & Pick<
                                                                          UploadFileEntity,
                                                                          'id'
                                                                        > & {
                                                                            attributes?: Maybe<
                                                                              {
                                                                                __typename?: 'UploadFile'
                                                                              } & Pick<
                                                                                UploadFile,
                                                                                'url' | 'name'
                                                                              >
                                                                            >
                                                                          }
                                                                      >
                                                                    }
                                                                  >
                                                                }
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                  events?: Maybe<
                                                    {
                                                      __typename?: 'EventRelationResponseCollection'
                                                    } & {
                                                      data: Array<
                                                        { __typename?: 'EventEntity' } & Pick<
                                                          EventEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'Event' } & Pick<
                                                                Event,
                                                                'name' | 'link'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  section?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                          ComponentSubmissionsSectionSubmission,
                          'id' | 'progress' | 'sliderAnswers'
                        > & {
                            section?: Maybe<
                              { __typename?: 'SectionEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>
                                >
                              }
                            >
                            answers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                                    ComponentSubmissionsAnswer,
                                    | 'id'
                                    | 'questionId'
                                    | 'answer'
                                    | 'isCorrect'
                                    | 'feedback'
                                    | 'outcome'
                                    | 'questionType'
                                    | 'questionTitle'
                                  > & {
                                      nestedAnswers?: Maybe<
                                        Array<
                                          Maybe<
                                            {
                                              __typename?: 'ComponentSubmissionsRepeatAnswer'
                                            } & Pick<
                                              ComponentSubmissionsRepeatAnswer,
                                              'answer' | 'questionTitle'
                                            >
                                          >
                                        >
                                      >
                                    }
                                >
                              >
                            >
                            stakeholderChart?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            stakeholderItems?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentStakeholderMapItemStackholderMapItem'
                                  } & Pick<
                                    ComponentStakeholderMapItemStackholderMapItem,
                                    'id' | 'valueX' | 'valueY' | 'name' | 'color'
                                  >
                                >
                              >
                            >
                            gravitasScales?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems'
                                  } & Pick<
                                    ComponentGravitasScaleItemsGravitasScaleItems,
                                    | 'id'
                                    | 'labelOne'
                                    | 'labelTwo'
                                    | 'labelThree'
                                    | 'question'
                                    | 'answerOne'
                                    | 'answerTwo'
                                    | 'answerThree'
                                  >
                                >
                              >
                            >
                            gravitasSliderAnswers?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentSubmissionsGravitasSlidersAnswers'
                                  } & Pick<
                                    ComponentSubmissionsGravitasSlidersAnswers,
                                    'negativeQuestion' | 'positiveQuestion'
                                  > & {
                                      colorAndRating?: Maybe<
                                        Array<
                                          Maybe<
                                            { __typename?: 'ComponentOptionColorAndRating' } & Pick<
                                              ComponentOptionColorAndRating,
                                              'color' | 'rate'
                                            >
                                          >
                                        >
                                      >
                                    }
                                >
                              >
                            >
                            gravitasGap?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsGravitasGap' } & Pick<
                                    ComponentSubmissionsGravitasGap,
                                    'id' | 'gap' | 'commitment'
                                  >
                                >
                              >
                            >
                            xYGraphChart?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            saboteurImage?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            xYGraphItems?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                                    ComponentXyGraphItemXyGraphItem,
                                    'id' | 'valueX' | 'valueY' | 'color'
                                  >
                                >
                              >
                            >
                            characteristics?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsCharacteristics' } & Pick<
                                    ComponentSubmissionsCharacteristics,
                                    'id' | 'answer' | 'type'
                                  >
                                >
                              >
                            >
                            gapsAnswers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsValuesGaps' } & Pick<
                                    ComponentSubmissionsValuesGaps,
                                    'id' | 'gap' | 'commitment'
                                  >
                                >
                              >
                            >
                            competencyAnswers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsCompetencyAnswer' } & Pick<
                                    ComponentSubmissionsCompetencyAnswer,
                                    'id' | 'question' | 'rating' | 'steps' | 'response'
                                  >
                                >
                              >
                            >
                            uploadDocuments?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsUploadDocuments' } & {
                                    saboteurProofValue?: Maybe<
                                      { __typename?: 'UploadFileEntityResponse' } & {
                                        data?: Maybe<
                                          { __typename?: 'UploadFileEntity' } & Pick<
                                            UploadFileEntity,
                                            'id'
                                          >
                                        >
                                      }
                                    >
                                  }
                                >
                              >
                            >
                          }
                      >
                    >
                  >
                  reports?: Maybe<
                    { __typename?: 'ReportRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ReportEntity' } & Pick<ReportEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Report' } & Pick<
                                Report,
                                'name' | 'type' | 'section_or_module_id'
                              > & {
                                  report?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetSubModulesQueryVariables = {
  where: SubModuleFiltersInput
}

export type GetSubModulesQuery = { __typename?: 'Query' } & {
  subModules?: Maybe<
    { __typename?: 'SubModuleEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'SubModule' } & Pick<
                SubModule,
                | 'name_english'
                | 'name_french'
                | 'name_german'
                | 'name_spanish'
                | 'name_mandarin'
                | 'description_english'
                | 'description_french'
                | 'description_german'
                | 'description_spanish'
                | 'description_mandarin'
              > & {
                  cover?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                            >
                          }
                      >
                    }
                  >
                  sections?: Maybe<
                    { __typename?: 'SectionRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Section' } & Pick<
                                Section,
                                | 'title_english'
                                | 'title_french'
                                | 'title_german'
                                | 'title_spanish'
                                | 'title_mandarin'
                                | 'sub_title_english'
                                | 'sub_title_french'
                                | 'sub_title_german'
                                | 'sub_title_spanish'
                                | 'sub_title_mandarin'
                                | 'archive'
                              > & {
                                  video_english?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_french?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_german?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_spanish?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  video_mandarin?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  questions?: Maybe<
                                    Array<
                                      Maybe<
                                        | ({
                                            __typename?: 'ComponentQuestionTypesMultipleChoice'
                                          } & Questions_ComponentQuestionTypesMultipleChoice_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteur'
                                          } & Questions_ComponentQuestionTypesSaboteur_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                          } & Questions_ComponentQuestionTypesSimpleQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesXYGraph'
                                          } & Questions_ComponentQuestionTypesXyGraph_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesNestedQuestions'
                                          } & Questions_ComponentQuestionTypesNestedQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasScale'
                                          } & Questions_ComponentQuestionTypesGravitasScale_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesStakeholderMap'
                                          } & Questions_ComponentQuestionTypesStakeholderMap_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSaboteurVideos'
                                          } & Questions_ComponentQuestionTypesSaboteurVideos_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesSliders'
                                          } & Questions_ComponentQuestionTypesValuesSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSimpleReadOnly'
                                          } & Questions_ComponentQuestionTypesSimpleReadOnly_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesNested'
                                          } & Questions_ComponentQuestionTypesValuesNested_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesGaps'
                                          } & Questions_ComponentQuestionTypesValuesGaps_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadQuestion'
                                          } & Questions_ComponentQuestionTypesUploadQuestion_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesParticipant'
                                          } & Questions_ComponentQuestionTypesParticipant_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesCharacters'
                                          } & Questions_ComponentQuestionTypesValuesCharacters_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency'
                                          } & Questions_ComponentQuestionTypesCompetency_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesValuesBubble'
                                          } & Questions_ComponentQuestionTypesValuesBubble_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesSliderQuestions'
                                          } & Questions_ComponentQuestionTypesSliderQuestions_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward1'
                                          } & Questions_ComponentQuestionTypesFeedForward1_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesFeedForward2'
                                          } & Questions_ComponentQuestionTypesFeedForward2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesCompetency2'
                                          } & Questions_ComponentQuestionTypesCompetency2_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasSliders'
                                          } & Questions_ComponentQuestionTypesGravitasSliders_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesUploadDocuments'
                                          } & Questions_ComponentQuestionTypesUploadDocuments_Fragment)
                                        | ({
                                            __typename?: 'ComponentQuestionTypesGravitasGap'
                                          } & Questions_ComponentQuestionTypesGravitasGap_Fragment)
                                        | ({ __typename?: 'Error' } & Questions_Error_Fragment)
                                      >
                                    >
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetSectionReportQueryVariables = {
  id: Scalars['ID']
}

export type GetSectionReportQuery = { __typename?: 'Query' } & {
  getSectionReport: { __typename?: 'Report' } & Pick<
    Report,
    'id' | 'name' | 'type' | 'section_or_module_id'
  > & {
      report?: Maybe<
        { __typename?: 'UploadFileEntityResponse' } & {
          data?: Maybe<
            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                attributes?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>
              }
          >
        }
      >
    }
}

export type GetMySubmissionQueryVariables = {
  id: Scalars['ID']
}

export type GetMySubmissionQuery = { __typename?: 'Query' } & {
  submission?: Maybe<
    { __typename?: 'SubmissionEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'SubmissionEntity' } & Pick<SubmissionEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Submission' } & Pick<
                Submission,
                'views' | 'progress' | 'status' | 'time_spent'
              > & {
                  language?: Maybe<
                    { __typename?: 'LanguageEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                            attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                          }
                      >
                    }
                  >
                  module?: Maybe<
                    { __typename?: 'ModuleEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Module' } & Pick<
                                Module,
                                | 'name_french'
                                | 'name_german'
                                | 'name_english'
                                | 'name_spanish'
                                | 'name_mandarin'
                                | 'description_french'
                                | 'description_german'
                                | 'description_english'
                                | 'description_spanish'
                                | 'description_mandarin'
                                | 'position'
                              > & {
                                  cover?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  sections?: Maybe<
                                    { __typename?: 'SectionRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'SectionEntity' } & Pick<
                                          SectionEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'Section' } & Pick<
                                                Section,
                                                | 'title_french'
                                                | 'title_german'
                                                | 'title_english'
                                                | 'title_spanish'
                                                | 'title_mandarin'
                                                | 'sub_title_french'
                                                | 'sub_title_german'
                                                | 'sub_title_english'
                                                | 'sub_title_spanish'
                                                | 'sub_title_mandarin'
                                              > & {
                                                  cover?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  section?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                          ComponentSubmissionsSectionSubmission,
                          'id' | 'progress'
                        > & {
                            section?: Maybe<
                              { __typename?: 'SectionEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>
                                >
                              }
                            >
                            answers?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                                    ComponentSubmissionsAnswer,
                                    | 'id'
                                    | 'questionId'
                                    | 'answer'
                                    | 'isCorrect'
                                    | 'feedback'
                                    | 'outcome'
                                    | 'questionType'
                                    | 'questionTitle'
                                  > & {
                                      nestedAnswers?: Maybe<
                                        Array<
                                          Maybe<
                                            {
                                              __typename?: 'ComponentSubmissionsRepeatAnswer'
                                            } & Pick<
                                              ComponentSubmissionsRepeatAnswer,
                                              'answer' | 'questionTitle'
                                            >
                                          >
                                        >
                                      >
                                    }
                                >
                              >
                            >
                            stakeholderChart?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            stakeholderItems?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentStakeholderMapItemStackholderMapItem'
                                  } & Pick<
                                    ComponentStakeholderMapItemStackholderMapItem,
                                    'id' | 'valueX' | 'valueY' | 'name' | 'color'
                                  >
                                >
                              >
                            >
                            gravitasScales?: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems'
                                  } & Pick<
                                    ComponentGravitasScaleItemsGravitasScaleItems,
                                    | 'id'
                                    | 'labelOne'
                                    | 'labelTwo'
                                    | 'labelThree'
                                    | 'question'
                                    | 'answerOne'
                                    | 'answerTwo'
                                    | 'answerThree'
                                  >
                                >
                              >
                            >
                            xYGraphChart?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            saboteurImage?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            xYGraphItems?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                                    ComponentXyGraphItemXyGraphItem,
                                    'id' | 'valueX' | 'valueY' | 'color'
                                  >
                                >
                              >
                            >
                          }
                      >
                    >
                  >
                  reports?: Maybe<
                    { __typename?: 'ReportRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ReportEntity' } & Pick<ReportEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Report' } & Pick<
                                Report,
                                'name' | 'type' | 'section_or_module_id'
                              > & {
                                  report?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetSubmissionReportGroupedQueryVariables = {}

export type GetSubmissionReportGroupedQuery = { __typename?: 'Query' } & {
  getSubmissionReportGrouped?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ReportsSubmissionGrouped' } & Pick<
          ReportsSubmissionGrouped,
          | 'id'
          | 'name'
          | 'type'
          | 'created_by'
          | 'updated_by'
          | 'programmeType'
          | 'sub_module_submission'
          | 'notification'
        > & { report?: Maybe<{ __typename?: 'ReportDetail' } & Pick<ReportDetail, 'id' | 'url'>> }
      >
    >
  >
}

export type GetTranslationsQueryVariables = {}

export type GetTranslationsQuery = { __typename?: 'Query' } & {
  translation?: Maybe<
    { __typename?: 'TranslationEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'TranslationEntity' } & Pick<TranslationEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'Translation' } & {
                menu?: Maybe<
                  { __typename?: 'ComponentTranslationsMenu' } & Pick<
                    ComponentTranslationsMenu,
                    | 'id'
                    | 'homeEnglish'
                    | 'myProgrammeEnglish'
                    | 'myWorkEnglish'
                    | 'myLibraryEnglish'
                    | 'myProfileEnglish'
                    | 'helpAndSupportEnglish'
                    | 'signOutEnglish'
                    | 'homeFrench'
                    | 'myProgrammeFrench'
                    | 'myWorkFrench'
                    | 'myLibraryFrench'
                    | 'myProfileFrench'
                    | 'helpAndSupportFrench'
                    | 'signOutFrench'
                    | 'homeGerman'
                    | 'myProgrammeGerman'
                    | 'myWorkGerman'
                    | 'myLibraryGerman'
                    | 'myProfileGerman'
                    | 'helpAndSupportGerman'
                    | 'signOutGerman'
                    | 'homeSpanish'
                    | 'myProgrammeSpanish'
                    | 'myWorkSpanish'
                    | 'myLibrarySpanish'
                    | 'myProfileSpanish'
                    | 'helpAndSupportSpanish'
                    | 'signOutSpanish'
                    | 'homeMandarin'
                    | 'myProgrammeMandarin'
                    | 'myWorkMandarin'
                    | 'myLibraryMandarin'
                    | 'myProfileMandarin'
                    | 'helpAndSupportMandarin'
                    | 'signOutMandarin'
                    | 'aboutUsEnglish'
                    | 'aboutUsGerman'
                    | 'aboutUsFrench'
                    | 'myNotesEnglish'
                    | 'myNotesGerman'
                    | 'myNotesFrench'
                    | 'myNotesSpanish'
                    | 'myNotesMandarin'
                    | 'notificationsEnglish'
                    | 'notificationsGerman'
                    | 'notificationsFrench'
                    | 'notificationsSpanish'
                    | 'notificationsMandarin'
                  >
                >
                language_selection?: Maybe<
                  { __typename?: 'ComponentTranslationsLanguageSelection' } & Pick<
                    ComponentTranslationsLanguageSelection,
                    | 'id'
                    | 'titleEnglish'
                    | 'bodyEnglish'
                    | 'nextButtonEnglish'
                    | 'titleFrench'
                    | 'bodyFrench'
                    | 'nextButtonFrench'
                    | 'titleGerman'
                    | 'bodyGerman'
                    | 'nextButtonGerman'
                    | 'titleMandarin'
                    | 'bodyMandarin'
                    | 'nextButtonMandarin'
                    | 'titleSpanish'
                    | 'bodySpanish'
                    | 'nextButtonSpanish'
                  >
                >
                welcome_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsWelcomeScreen' } & Pick<
                    ComponentTranslationsWelcomeScreen,
                    | 'id'
                    | 'titleEnglish'
                    | 'bodyEnglish'
                    | 'nextButtonEnglish'
                    | 'titleFrench'
                    | 'bodyFrench'
                    | 'nextButtonFrench'
                    | 'titleGerman'
                    | 'bodyGerman'
                    | 'nextButtonGerman'
                    | 'titleMandarin'
                    | 'bodyMandarin'
                    | 'nextButtonMandarin'
                    | 'titleSpanish'
                    | 'bodySpanish'
                    | 'nextButtonSpanish'
                  > & {
                      videoEnglish?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      videoFrench?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      videoGerman?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      videoMandarin?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      videoSpanish?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
                lets_get_started_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsLetsGetStartedScreen' } & Pick<
                    ComponentTranslationsLetsGetStartedScreen,
                    | 'id'
                    | 'titleEnglish'
                    | 'subTitleEnglish'
                    | 'firstNameLabelEnglish'
                    | 'firstNameErrorEnglish'
                    | 'lastNameLabelEnglish'
                    | 'lastNameErrorEnglish'
                    | 'jobTitleLabelEnglish'
                    | 'jobTitleErrorEnglish'
                    | 'divisionLabelEnglish'
                    | 'divisionErrorEnglish'
                    | 'timezoneLabelEnglish'
                    | 'regionLabelEnglish'
                    | 'regionErrorEnglish'
                    | 'nextButtonEnglish'
                    | 'titleFrench'
                    | 'subTitleFrench'
                    | 'firstNameLabelFrench'
                    | 'firstNameErrorFrench'
                    | 'lastNameLabelFrench'
                    | 'lastNameErrorFrench'
                    | 'jobTitleLabelFrench'
                    | 'jobTitleErrorFrench'
                    | 'divisionLabelFrench'
                    | 'divisionErrorFrench'
                    | 'timezoneLabelFrench'
                    | 'regionLabelFrench'
                    | 'regionErrorFrench'
                    | 'nextButtonFrench'
                    | 'titleGerman'
                    | 'subTitleGerman'
                    | 'firstNameLabelGerman'
                    | 'firstNameErrorGerman'
                    | 'lastNameLabelGerman'
                    | 'lastNameErrorGerman'
                    | 'jobTitleLabelGerman'
                    | 'jobTitleErrorGerman'
                    | 'divisionLabelGerman'
                    | 'divisionErrorGerman'
                    | 'timezoneLabelGerman'
                    | 'regionLabelGerman'
                    | 'regionErrorGerman'
                    | 'nextButtonGerman'
                    | 'titleMandarin'
                    | 'subTitleMandarin'
                    | 'firstNameLabelMandarin'
                    | 'firstNameErrorMandarin'
                    | 'lastNameLabelMandarin'
                    | 'lastNameErrorMandarin'
                    | 'jobTitleLabelMandarin'
                    | 'jobTitleErrorMandarin'
                    | 'divisionLabelMandarin'
                    | 'divisionErrorMandarin'
                    | 'timezoneLabelMandarin'
                    | 'regionLabelMandarin'
                    | 'regionErrorMandarin'
                    | 'nextButtonMandarin'
                    | 'titleSpanish'
                    | 'subTitleSpanish'
                    | 'firstNameLabelSpanish'
                    | 'firstNameErrorSpanish'
                    | 'lastNameLabelSpanish'
                    | 'lastNameErrorSpanish'
                    | 'jobTitleLabelSpanish'
                    | 'jobTitleErrorSpanish'
                    | 'divisionLabelSpanish'
                    | 'divisionErrorSpanish'
                    | 'timezoneLabelSpanish'
                    | 'regionLabelSpanish'
                    | 'regionErrorSpanish'
                    | 'nextButtonSpanish'
                    | 'leftTitleEnglish'
                    | 'leftSubtitleEnglish'
                    | 'leftTitleSpanish'
                    | 'leftSubtitleSpanish'
                    | 'leftTitleMandarin'
                    | 'leftSubTitleMandarin'
                    | 'leftTitleFrench'
                    | 'leftSubtitleFrench'
                    | 'leftSubtitleGerman'
                    | 'leftTitleGerman'
                    | 'havingTroubleEnglish'
                    | 'havingTroubleGerman'
                    | 'havingTroubleFrench'
                    | 'havingTroubleMandarin'
                    | 'havingTroubleSpanish'
                    | 'getHelpButtonEnglish'
                    | 'getHelpButtonSpanish'
                    | 'getHelpButtonFrench'
                    | 'getHelpButtonGerman'
                    | 'getHelpButtonMandarin'
                  > & {
                      image?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
                what_to_expect?: Maybe<
                  { __typename?: 'ComponentTranslationsWhatToExpect' } & Pick<
                    ComponentTranslationsWhatToExpect,
                    | 'id'
                    | 'titleEnglish'
                    | 'bodyEnglish'
                    | 'getStartedButtonEnglish'
                    | 'titleFrench'
                    | 'bodyFrench'
                    | 'getStartedButtonFrench'
                    | 'titleGerman'
                    | 'bodyGerman'
                    | 'getStartedButtonGerman'
                    | 'titleMandarin'
                    | 'bodyMandarin'
                    | 'getStartedButtonMandarin'
                    | 'titleSpanish'
                    | 'bodySpanish'
                    | 'getStartedButtonSpanish'
                  > & {
                      image?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
                home_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsHomeScreen' } & Pick<
                    ComponentTranslationsHomeScreen,
                    | 'id'
                    | 'titleEnglish'
                    | 'myProgrammeEnglish'
                    | 'completedModulesEnglish'
                    | 'noChangesEnglish'
                    | 'changesUpEnglish'
                    | 'changesDownEnglish'
                    | 'viewModulesEnglish'
                    | 'myLibraryEnglish'
                    | 'viewedArticlesEnglish'
                    | 'viewLibraryEnglish'
                    | 'titleFrench'
                    | 'myProgrammeFrench'
                    | 'completedModulesFrench'
                    | 'noChangesFrench'
                    | 'changesUpFrench'
                    | 'changesDownFrench'
                    | 'viewModulesFrench'
                    | 'myLibraryFrench'
                    | 'viewedArticlesFrench'
                    | 'viewLibraryFrench'
                    | 'titleGerman'
                    | 'myProgrammeGerman'
                    | 'completedModulesGerman'
                    | 'noChangesGerman'
                    | 'changesUpGerman'
                    | 'changesDownGerman'
                    | 'viewModulesGerman'
                    | 'myLibraryGerman'
                    | 'viewedArticlesGerman'
                    | 'viewLibraryGerman'
                    | 'titleSpanish'
                    | 'myProgrammeSpanish'
                    | 'completedModulesSpanish'
                    | 'noChangesSpanish'
                    | 'changesUpSpanish'
                    | 'changesDownSpanish'
                    | 'viewModulesSpanish'
                    | 'myLibrarySpanish'
                    | 'viewedArticlesSpanish'
                    | 'viewLibrarySpanish'
                    | 'titleMandarin'
                    | 'myProgrammeMandarin'
                    | 'completedModulesMandarin'
                    | 'noChangesMandarin'
                    | 'changesUpMandarin'
                    | 'changesDownMandarin'
                    | 'viewModulesMandarin'
                    | 'myLibraryMandarin'
                    | 'viewedArticlesMandarin'
                    | 'viewLibraryMandarin'
                    | 'welcomeEnglish'
                    | 'welcomeFrench'
                    | 'welcomeGerman'
                    | 'welcomeSpanish'
                    | 'welcomeMandarin'
                    | 'programmeProgressEnglish'
                    | 'programmeProgressFrench'
                    | 'programmeProgressGerman'
                    | 'programmeProgressSpanish'
                    | 'programmeProgressMandarin'
                    | 'whatsNextEnglish'
                    | 'whatsNextFrench'
                    | 'whatsNextGerman'
                    | 'whatsNextSpanish'
                    | 'whatsNextMandarin'
                    | 'whatToExpectEnglish'
                    | 'whatToExpectFrench'
                    | 'whatToExpectGerman'
                    | 'whatToExpectSpanish'
                    | 'whatToExpectMandarin'
                  >
                >
                my_work_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsMyWorkScreen' } & Pick<
                    ComponentTranslationsMyWorkScreen,
                    | 'id'
                    | 'emptyStateTitleEnglish'
                    | 'emptyStateDescEnglish'
                    | 'subtitleEnglish'
                    | 'feedbackEnglish'
                    | 'feedbackNotEligibleEnglish'
                    | 'feedbackNotReceivedEnglish'
                    | 'notStartedEnglish'
                    | 'emptyStateTitleFrench'
                    | 'emptyStateDescFrench'
                    | 'subtitleFrench'
                    | 'feedbackFrench'
                    | 'feedbackNotEligibleFrench'
                    | 'feedbackNotReceivedFrench'
                    | 'notStartedFrench'
                    | 'emptyStateTitleGerman'
                    | 'emptyStateDescGerman'
                    | 'subtitleGerman'
                    | 'feedbackGerman'
                    | 'feedbackNotEligibleGerman'
                    | 'feedbackNotReceivedGerman'
                    | 'notStartedGerman'
                    | 'emptyStateTitleSpanish'
                    | 'emptyStateDescSpanish'
                    | 'subtitleSpanish'
                    | 'feedbackSpanish'
                    | 'feedbackNotEligibleSpanish'
                    | 'feedbackNotReceivedSpanish'
                    | 'notStartedSpanish'
                    | 'emptyStateTitleMandarin'
                    | 'emptyStateDescMandarin'
                    | 'subtitleMandarin'
                    | 'feedbackMandarin'
                    | 'feedbackNotEligibleMandarin'
                    | 'feedbackNotReceivedMandarin'
                    | 'notStartedMandarin'
                    | 'downloadContentTextEnglish'
                    | 'downloadContentTextFrench'
                    | 'downloadContentTextGerman'
                    | 'downloadContentTextSpanish'
                    | 'downloadContentTextMandarin'
                    | 'myNotesEnglish'
                    | 'myNotesSpanish'
                    | 'myNotesFrench'
                    | 'myNotesGerman'
                    | 'myNotesMandarin'
                    | 'moduleReportsEnglish'
                    | 'moduleReportsSpanish'
                    | 'moduleReportsFrench'
                    | 'moduleReportsGerman'
                    | 'moduleReportsMandarin'
                    | 'documentsEnglish'
                    | 'documentsSpanish'
                    | 'documentsMandarin'
                    | 'documentsFrench'
                  >
                >
                my_library_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsMyLibraryScreen' } & Pick<
                    ComponentTranslationsMyLibraryScreen,
                    | 'id'
                    | 'allArticlesEnglish'
                    | 'myBookmarksEnglish'
                    | 'allArticlesFrench'
                    | 'myBookmarksFrench'
                    | 'allArticlesGerman'
                    | 'myBookmarksGerman'
                    | 'allArticlesSpanish'
                    | 'myBookmarksSpanish'
                    | 'allArticlesMandarin'
                    | 'myBookmarksMandarin'
                    | 'viewArticleEnglish'
                    | 'viewArticleFrench'
                    | 'viewArticleGerman'
                    | 'viewArticleSpanish'
                    | 'viewArticleMandarin'
                  >
                >
                my_profile_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsMyProfileScreen' } & Pick<
                    ComponentTranslationsMyProfileScreen,
                    | 'id'
                    | 'detailsEnglish'
                    | 'settingsEnglish'
                    | 'logoutEnglish'
                    | 'joinEnglish'
                    | 'detailsFrench'
                    | 'settingsFrench'
                    | 'logoutFrench'
                    | 'joinFrench'
                    | 'detailsGerman'
                    | 'settingsGerman'
                    | 'logoutGerman'
                    | 'joinGerman'
                    | 'detailsSpanish'
                    | 'settingsSpanish'
                    | 'logoutSpanish'
                    | 'joinSpanish'
                    | 'detailsMandarin'
                    | 'settingsMandarin'
                    | 'logoutMandarin'
                  >
                >
                personal_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsPersonalScreen' } & Pick<
                    ComponentTranslationsPersonalScreen,
                    | 'id'
                    | 'changePhotoEnglish'
                    | 'firstNameLabelEnglish'
                    | 'lastNameLabelEnglish'
                    | 'emailLabelEnglish'
                    | 'jobTitleLabelEnglish'
                    | 'divisionLabelEnglish'
                    | 'editInfoButtonEnglish'
                    | 'changePhotoFrench'
                    | 'firstNameLabelFrench'
                    | 'lastNameLabelFrench'
                    | 'emailLabelFrench'
                    | 'jobTitleLabelFrench'
                    | 'divisionLabelFrench'
                    | 'editInfoButtonFrench'
                    | 'changePhotoGerman'
                    | 'firstNameLabelGerman'
                    | 'lastNameLabelGerman'
                    | 'emailLabelGerman'
                    | 'jobTitleLabelGerman'
                    | 'divisionLabelGerman'
                    | 'editInfoButtonGerman'
                    | 'changePhotoSpanish'
                    | 'firstNameLabelSpanish'
                    | 'lastNameLabelSpanish'
                    | 'emailLabelSpanish'
                    | 'jobTitleLabelSpanish'
                    | 'divisionLabelSpanish'
                    | 'editInfoButtonSpanish'
                    | 'changePhotoMandarin'
                    | 'firstNameLabelMandarin'
                    | 'lastNameLabelMandarin'
                    | 'emailLabelMandarin'
                    | 'jobTitleLabelMandarin'
                    | 'divisionLabelMandarin'
                    | 'editInfoButtonMandarin'
                    | 'resetPasswordEnglish'
                    | 'resetPasswordFrench'
                    | 'resetPasswordGerman'
                    | 'resetPasswordSpanish'
                    | 'resetPasswordMandarin'
                  >
                >
                settings_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsSettingsScreen' } & Pick<
                    ComponentTranslationsSettingsScreen,
                    | 'id'
                    | 'titleEnglish'
                    | 'saveButtonEnglish'
                    | 'titleFrench'
                    | 'saveButtonFrench'
                    | 'titleGerman'
                    | 'saveButtonGerman'
                    | 'titleSpanish'
                    | 'saveButtonSpanish'
                    | 'titleMandarin'
                    | 'saveButtonMandarin'
                  >
                >
                help_and_support?: Maybe<
                  { __typename?: 'ComponentTranslationsHelpAndSupport' } & Pick<
                    ComponentTranslationsHelpAndSupport,
                    | 'id'
                    | 'titleEnglish'
                    | 'knowledgeBaseEnglish'
                    | 'liveChatEnglish'
                    | 'myTicketsEnglish'
                    | 'titleFrench'
                    | 'knowledgeBaseFrench'
                    | 'liveChatFrench'
                    | 'myTicketsFrench'
                    | 'titleGerman'
                    | 'knowledgeBaseGerman'
                    | 'liveChatGerman'
                    | 'myTicketsGerman'
                    | 'titleSpanish'
                    | 'knowledgeBaseSpanish'
                    | 'liveChatSpanish'
                    | 'myTicketsSpanish'
                    | 'titleMandarin'
                    | 'knowledgeBaseMandarin'
                    | 'liveChatMandarin'
                    | 'myTicketsMandarin'
                  >
                >
                about_us?: Maybe<
                  { __typename?: 'ComponentTranslationsAboutUs' } & Pick<
                    ComponentTranslationsAboutUs,
                    | 'id'
                    | 'textEnglish'
                    | 'textFrench'
                    | 'textGerman'
                    | 'userGuidelinesTextEnglish'
                    | 'userGuidelinesTextGerman'
                    | 'userGuidelinesTextFrench'
                  > & {
                      userGuidelinesEnglish?: Maybe<
                        { __typename?: 'UploadFileRelationResponseCollection' } & {
                          data: Array<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      userGuidelinesGerman?: Maybe<
                        { __typename?: 'UploadFileRelationResponseCollection' } & {
                          data: Array<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      userGuidelinesFrench?: Maybe<
                        { __typename?: 'UploadFileRelationResponseCollection' } & {
                          data: Array<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
                buttons?: Maybe<
                  { __typename?: 'ComponentTranslationsButtons' } & Pick<
                    ComponentTranslationsButtons,
                    | 'id'
                    | 'backBtnsEnglish'
                    | 'backBtnsFrench'
                    | 'backBtnsGerman'
                    | 'nextBtnsEnglish'
                    | 'nextBtnsFrench'
                    | 'nextBtnsGerman'
                    | 'addMoreBtnsEnglish'
                    | 'addMoreBtnsFrench'
                    | 'addMoreBtnsGerman'
                  >
                >
                programmes_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsProgrammeScreen' } & Pick<
                    ComponentTranslationsProgrammeScreen,
                    | 'id'
                    | 'changeButtonEnglish'
                    | 'changeButtonFrench'
                    | 'changeButtonGerman'
                    | 'changeButtonSpanish'
                    | 'changeButtonMandarin'
                    | 'programmeOverviewEnglish'
                    | 'programmeOverviewFrench'
                    | 'programmeOverviewGerman'
                    | 'programmeOverviewSpanish'
                    | 'programmeOverviewMandarin'
                    | 'programmeTextEnglish'
                    | 'programmeTextFrench'
                    | 'programmeTextGerman'
                    | 'programmeTextSpanish'
                    | 'programmeTextMandarin'
                    | 'informationEnglish'
                    | 'informationFrench'
                    | 'informationGerman'
                    | 'informationSpanish'
                    | 'informationMandarin'
                    | 'selectProgrammesTitleEnglish'
                    | 'selectProgrammesTitleFrench'
                    | 'selectProgrammesTitleGerman'
                    | 'selectProgrammesTitleSpanish'
                    | 'selectProgrammesTitleMandarin'
                    | 'selectProgrammesSubTitleEnglish'
                    | 'selectProgrammesSubTitleFrench'
                    | 'selectProgrammesSubTitleGerman'
                    | 'selectProgrammesSubTitleSpanish'
                    | 'selectProgrammesSubTitleMandarin'
                    | 'selectButtonEnglish'
                    | 'selectButtonFrench'
                    | 'selectButtonGerman'
                    | 'selectButtonSpanish'
                    | 'selectButtonMandarin'
                    | 'changeProgrammeTypeEnglish'
                    | 'changeProgrammeTypeFrench'
                    | 'changeProgrammeTypeGerman'
                    | 'changeProgrammeTypeSpanish'
                    | 'changeProgrammeTypeMandarin'
                  >
                >
                faqs?: Maybe<
                  { __typename?: 'ComponentTranslationsFaqs' } & {
                    faqsEnglish?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'ComponentTranslationsFaqsQuestionAnswer' } & Pick<
                            ComponentTranslationsFaqsQuestionAnswer,
                            'question' | 'answer'
                          >
                        >
                      >
                    >
                    faqsFrench?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'ComponentTranslationsFaqsFrench' } & Pick<
                            ComponentTranslationsFaqsFrench,
                            'question' | 'answer'
                          >
                        >
                      >
                    >
                    faqsGerman?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'ComponentTranslationsFaqsGerman' } & Pick<
                            ComponentTranslationsFaqsGerman,
                            'question' | 'answer'
                          >
                        >
                      >
                    >
                    faqsMandarin?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'ComponentTranslationsFaqsMandarin' } & Pick<
                            ComponentTranslationsFaqsMandarin,
                            'question' | 'answer'
                          >
                        >
                      >
                    >
                    faqsSpanish?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'ComponentTranslationsFaqsSpanish' } & Pick<
                            ComponentTranslationsFaqsSpanish,
                            'question' | 'answer'
                          >
                        >
                      >
                    >
                  }
                >
                dashboard_screen?: Maybe<
                  { __typename?: 'ComponentTranslationsDashboardScreen' } & Pick<
                    ComponentTranslationsDashboardScreen,
                    | 'id'
                    | 'myProgrammesEnglish'
                    | 'myProgrammesFrench'
                    | 'myProgrammesGerman'
                    | 'myProgrammesSpanish'
                    | 'myProgrammesMandarin'
                    | 'suggestedTitleEnglish'
                    | 'suggestedTitleFrench'
                    | 'suggestedTitleGerman'
                    | 'suggestedTitleSpanish'
                    | 'suggestedTitleMandarin'
                    | 'uploadTitleEnglish'
                    | 'uploadTitleFrench'
                    | 'uploadTitleGerman'
                    | 'uploadTitleSpanish'
                    | 'uploadTitleMandarin'
                    | 'viewAllEnglish'
                    | 'viewAllFrench'
                    | 'viewAllGerman'
                    | 'viewAllSpanish'
                    | 'viewAllMandarin'
                    | 'logOutDashboardEnglish'
                    | 'logOutDashboardFrench'
                    | 'logOutDashboardGerman'
                    | 'logOutDashboardSpanish'
                    | 'logOutDashboardMandarin'
                    | 'notepadTextEnglish'
                    | 'notepadTextFrench'
                    | 'notepadTextGerman'
                    | 'notepadTextSpanish'
                    | 'notepadTextMandarin'
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type GetTypeformCompetencyResponsesQueryVariables = {}

export type GetTypeformCompetencyResponsesQuery = { __typename?: 'Query' } & {
  getTypeformCompetencyResponses?: Maybe<
    { __typename?: 'CheckConfirmed' } & Pick<CheckConfirmed, 'success'>
  >
}

export type GetUserUploadedDocumentQueryVariables = {
  where: UserUploadedDocumentFiltersInput
  sort: Array<Maybe<Scalars['String']>>
  limit: Scalars['Int']
}

export type GetUserUploadedDocumentQuery = { __typename?: 'Query' } & {
  userUploadedDocuments?: Maybe<
    { __typename?: 'UserUploadedDocumentEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'UserUploadedDocumentEntity' } & Pick<UserUploadedDocumentEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'UserUploadedDocument' } & Pick<UserUploadedDocument, 'name'> & {
                  documents?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ComponentQuestionTypesDocuments' } & Pick<
                          ComponentQuestionTypesDocuments,
                          'id' | 'name'
                        > & {
                            document?: Maybe<
                              { __typename?: 'UploadFileRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    >
                  >
                }
            >
          }
      >
    }
  >
}

export type GetUserProgrammeSubmissionsQueryVariables = {}

export type GetUserProgrammeSubmissionsQuery = { __typename?: 'Query' } & {
  getMyProgrammeSubmissions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProgrammeSubmission' } & Pick<
          ProgrammeSubmission,
          'id' | 'status' | 'progress'
        > & {
            programme_type?: Maybe<
              { __typename?: 'ProgrammeTypeEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'ProgrammeType' } & Pick<
                          ProgrammeType,
                          'title' | 'is_sub_module'
                        > & {
                            sub_modules?: Maybe<
                              { __typename?: 'SubModuleRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'SubModuleEntity' } & Pick<
                                    SubModuleEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'SubModule' } & Pick<
                                          SubModule,
                                          'name_english'
                                        > & {
                                            sections?: Maybe<
                                              {
                                                __typename?: 'SectionRelationResponseCollection'
                                              } & {
                                                data: Array<
                                                  { __typename?: 'SectionEntity' } & Pick<
                                                    SectionEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'Section' } & Pick<
                                                          Section,
                                                          'title_english'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
            programme?: Maybe<
              { __typename?: 'ProgrammeEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Programme' } & Pick<
                          Programme,
                          'name' | 'position' | 'start'
                        >
                      >
                    }
                >
              }
            >
          }
      >
    >
  >
}

export type GetMyProgrammeSubmissionsQueryVariables = {}

export type GetMyProgrammeSubmissionsQuery = { __typename?: 'Query' } & {
  getMyProgrammeSubmissions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProgrammeSubmission' } & Pick<
          ProgrammeSubmission,
          'id' | 'status' | 'progress' | 'selected'
        > & {
            competency_url?: Maybe<
              { __typename?: 'CompetencyUrlEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'CompetencyUrlEntity' } & Pick<CompetencyUrlEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'CompetencyUrl' } & Pick<CompetencyUrl, 'url'>
                      >
                    }
                >
              }
            >
            feedfoward_url?: Maybe<
              { __typename?: 'FeedfowardUrlEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'FeedfowardUrlEntity' } & Pick<FeedfowardUrlEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'FeedfowardUrl' } & Pick<FeedfowardUrl, 'url'>
                      >
                    }
                >
              }
            >
            programme_type?: Maybe<
              { __typename?: 'ProgrammeTypeEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ProgrammeTypeEntity' } & Pick<ProgrammeTypeEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'ProgrammeType' } & Pick<
                          ProgrammeType,
                          | 'programme_type_instructions'
                          | 'title'
                          | 'description'
                          | 'position'
                          | 'is_coaching'
                          | 'is_sub_module'
                          | 'notes'
                          | 'show_article'
                          | 'show_session'
                          | 'show_participants'
                          | 'competency_survey'
                          | 'feedForward_survey'
                          | 'deadline'
                          | 'archive'
                        > & {
                            cover?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            programme_questions?: Maybe<
                              Array<
                                Maybe<
                                  | ({ __typename: 'ComponentQuestionTypesValuesNested' } & Pick<
                                      ComponentQuestionTypesValuesNested,
                                      'id' | 'title' | 'question' | 'description'
                                    > & {
                                        questions?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                              } & Pick<
                                                ComponentQuestionTypesSimpleQuestion,
                                                | 'id'
                                                | 'titleEnglish'
                                                | 'subTitleEnglish'
                                                | 'titleFrench'
                                                | 'subTitleFrench'
                                                | 'titleGerman'
                                                | 'subTitleGerman'
                                                | 'titleSpanish'
                                                | 'subTitleSpanish'
                                                | 'titleMandarin'
                                                | 'subTitleMandarin'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesValuesBubble' } & Pick<
                                      ComponentQuestionTypesValuesBubble,
                                      'id' | 'title' | 'question' | 'description'
                                    >)
                                  | ({
                                      __typename: 'ComponentQuestionTypesValuesCharacters'
                                    } & Pick<
                                      ComponentQuestionTypesValuesCharacters,
                                      'id' | 'title' | 'question' | 'description'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesValuesSliders' } & Pick<
                                      ComponentQuestionTypesValuesSliders,
                                      'id' | 'title' | 'question' | 'description'
                                    > & {
                                        sliderQuestions?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentQuestionTypesSliderQuestions'
                                              } & Pick<
                                                ComponentQuestionTypesSliderQuestions,
                                                'id' | 'question' | 'color'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesValuesGaps' } & Pick<
                                      ComponentQuestionTypesValuesGaps,
                                      'id' | 'title' | 'question' | 'description'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesSaboteurVideos' } & Pick<
                                      ComponentQuestionTypesSaboteurVideos,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleGerman'
                                      | 'titleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleEnglish'
                                      | 'subTitleFrench'
                                      | 'subTitleGerman'
                                      | 'subTitleSpanish'
                                      | 'subTitleMandarin'
                                    > & {
                                        SaboteurVideo?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentSaboteurVideoSaboteurVideo'
                                              } & Pick<
                                                ComponentSaboteurVideoSaboteurVideo,
                                                | 'id'
                                                | 'videoTitleEnglish'
                                                | 'videoTitleFrench'
                                                | 'videoTitleGerman'
                                                | 'videoTitleSpanish'
                                                | 'videoTitleMandarin'
                                              > & {
                                                  video?: Maybe<
                                                    { __typename?: 'UploadFileEntityResponse' } & {
                                                      data?: Maybe<
                                                        { __typename?: 'UploadFileEntity' } & Pick<
                                                          UploadFileEntity,
                                                          'id'
                                                        > & {
                                                            attributes?: Maybe<
                                                              { __typename?: 'UploadFile' } & Pick<
                                                                UploadFile,
                                                                'url'
                                                              >
                                                            >
                                                          }
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          >
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesMultipleChoice' } & Pick<
                                      ComponentQuestionTypesMultipleChoice,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleGerman'
                                      | 'titleSpanish'
                                      | 'titleMandarin'
                                      | 'numberOfAnswers'
                                    > & {
                                        option?: Maybe<
                                          Array<
                                            Maybe<
                                              { __typename?: 'ComponentOptionOption' } & Pick<
                                                ComponentOptionOption,
                                                'id' | 'optionEnglish' | 'isCorrect'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesSaboteur' } & Pick<
                                      ComponentQuestionTypesSaboteur,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                      | 'labelEnglish'
                                      | 'labelFrench'
                                      | 'labelGerman'
                                      | 'labelSpanish'
                                    > & {
                                        saboteurQuestions?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentQuestionTypesSaboteurQuestions'
                                              } & Pick<
                                                ComponentQuestionTypesSaboteurQuestions,
                                                | 'link'
                                                | 'skipProof'
                                                | 'labelEnglish'
                                                | 'labelFrench'
                                                | 'labelGerman'
                                                | 'labelSpanish'
                                                | 'labelMandarin'
                                              > & {
                                                  proof?: Maybe<
                                                    {
                                                      __typename?: 'UploadFileRelationResponseCollection'
                                                    } & {
                                                      data: Array<
                                                        {
                                                          __typename?: 'UploadFileEntity'
                                                        } & UploadFileFragment
                                                      >
                                                    }
                                                  >
                                                }
                                            >
                                          >
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesSimpleQuestion' } & Pick<
                                      ComponentQuestionTypesSimpleQuestion,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleGerman'
                                      | 'titleSpanish'
                                      | 'titleMandarin'
                                      | 'subTitleEnglish'
                                      | 'subTitleFrench'
                                      | 'subTitleGerman'
                                      | 'subTitleSpanish'
                                      | 'subTitleMandarin'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesXYGraph' } & Pick<
                                      ComponentQuestionTypesXyGraph,
                                      | 'id'
                                      | 'labelXEnglish'
                                      | 'labelYEnglish'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'labelXFrench'
                                      | 'labelYFrench'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'labelXGerman'
                                      | 'labelYGerman'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'labelXSpanish'
                                      | 'labelYSpanish'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'labelXMandarin'
                                      | 'labelYMandarin'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesNestedQuestions' } & Pick<
                                      ComponentQuestionTypesNestedQuestions,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleGerman'
                                      | 'titleSpanish'
                                      | 'titleMandarin'
                                    > & {
                                        questions?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentQuestionTypesSimpleQuestion'
                                              } & Pick<
                                                ComponentQuestionTypesSimpleQuestion,
                                                | 'id'
                                                | 'titleEnglish'
                                                | 'subTitleEnglish'
                                                | 'titleFrench'
                                                | 'subTitleFrench'
                                                | 'titleGerman'
                                                | 'subTitleGerman'
                                                | 'titleSpanish'
                                                | 'subTitleSpanish'
                                                | 'titleMandarin'
                                                | 'subTitleMandarin'
                                                | 'isNumeric'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | { __typename: 'ComponentQuestionTypesUploadQuestion' }
                                  | ({ __typename: 'ComponentQuestionTypesGravitasScale' } & Pick<
                                      ComponentQuestionTypesGravitasScale,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'questionOneEnglish'
                                      | 'questionTwoEnglish'
                                      | 'questionThreeEnglish'
                                      | 'titleOneEnglish'
                                      | 'titleTwoEnglish'
                                    > & {
                                        ratingInput?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentRatingInputRatingInput'
                                              } & Pick<
                                                ComponentRatingInputRatingInput,
                                                'id' | 'labelOneEnglish' | 'labelTwoEnglish'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | { __typename: 'ComponentQuestionTypesParticipant' }
                                  | ({ __typename: 'ComponentQuestionTypesStakeholderMap' } & Pick<
                                      ComponentQuestionTypesStakeholderMap,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                      | 'questionX'
                                      | 'questionY'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesFeedForward1' } & Pick<
                                      ComponentQuestionTypesFeedForward1,
                                      'id' | 'title' | 'question' | 'description'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesFeedForward2' } & Pick<
                                      ComponentQuestionTypesFeedForward2,
                                      'id' | 'title' | 'question' | 'description'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesSimpleReadOnly' } & Pick<
                                      ComponentQuestionTypesSimpleReadOnly,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleGerman'
                                      | 'titleMandarin'
                                      | 'titleSpanish'
                                      | 'subTitleEnglish'
                                      | 'subTitleFrench'
                                      | 'subTitleGerman'
                                      | 'subTitleMandarin'
                                      | 'subTitleSpanish'
                                      | 'contentEnglish'
                                      | 'contentFrench'
                                      | 'contentGerman'
                                      | 'contentMandarin'
                                      | 'contentSpanish'
                                    > & {
                                        photo?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesCompetency2' } & Pick<
                                      ComponentQuestionTypesCompetency2,
                                      'titleEnglish' | 'titleFrench' | 'titleMandarin'
                                    > & {
                                        questionscompetency2?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentQuestionTypesQuestionscompetency2'
                                              } & Pick<
                                                ComponentQuestionTypesQuestionscompetency2,
                                                | 'title'
                                                | 'question'
                                                | 'maxiNumber'
                                                | 'miniNumber'
                                                | 'simpleQuestion'
                                                | 'miniLabel'
                                                | 'maxiLabel'
                                                | 'midiLabel'
                                                | 'textboxQuestion'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | ({ __typename: 'ComponentQuestionTypesCompetency' } & Pick<
                                      ComponentQuestionTypesCompetency,
                                      | 'id'
                                      | 'title'
                                      | 'question'
                                      | 'minNumber'
                                      | 'maxNumber'
                                      | 'miniLabel'
                                      | 'maxiLabel'
                                      | 'simpleQuestion'
                                    >)
                                  | ({ __typename: 'ComponentQuestionTypesGravitasGap' } & Pick<
                                      ComponentQuestionTypesGravitasGap,
                                      | 'id'
                                      | 'title'
                                      | 'question'
                                      | 'description'
                                      | 'color1'
                                      | 'color2'
                                      | 'color3'
                                    >)
                                  | { __typename: 'ComponentQuestionTypesSliderQuestions' }
                                  | ({ __typename: 'ComponentQuestionTypesGravitasSliders' } & Pick<
                                      ComponentQuestionTypesGravitasSliders,
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleMandarin'
                                      | 'titleSpanish'
                                      | 'titleGerman'
                                      | 'subTitleEnglish'
                                      | 'subTitleFrench'
                                      | 'subTitleGerman'
                                      | 'positiveHeadingEnglish'
                                      | 'positiveHeadingFrench'
                                      | 'positiveHeadingSpanish'
                                      | 'positiveHeadingMandarin'
                                      | 'positiveHeadingGerman'
                                      | 'negativeHeadingEnglish'
                                      | 'negativeHeadingFrench'
                                      | 'negativeHeadingSpanish'
                                      | 'negativeHeadingMandarin'
                                      | 'negativeHeadingGerman'
                                    > & {
                                        sliderQuestions?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentOptionSliderQuestions'
                                              } & Pick<
                                                ComponentOptionSliderQuestions,
                                                | 'questionPositiveEnglish'
                                                | 'questionPositiveGerman'
                                                | 'questionPositiveMandarin'
                                                | 'questionPositiveSpanish'
                                                | 'questionPositiveFrench'
                                                | 'questionNegativeEnglish'
                                                | 'questionNegativeGerman'
                                                | 'questionNegativeFrench'
                                                | 'questionNegativeMandarin'
                                                | 'questionNegativeSpanish'
                                              >
                                            >
                                          >
                                        >
                                        sliderHeadings?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'ComponentOptionSliderHeadings'
                                              } & Pick<
                                                ComponentOptionSliderHeadings,
                                                | 'headingEnglish'
                                                | 'headingSpanish'
                                                | 'headingGerman'
                                                | 'headingFrench'
                                                | 'headingMandarin'
                                                | 'color'
                                                | 'rating'
                                              >
                                            >
                                          >
                                        >
                                      })
                                  | { __typename: 'Error' }
                                >
                              >
                            >
                            articles?: Maybe<
                              { __typename?: 'ArticleRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'ArticleEntity' } & Pick<ArticleEntity, 'id'> & {
                                      attributes?: Maybe<
                                        { __typename?: 'Article' } & Pick<
                                          Article,
                                          | 'createdAt'
                                          | 'updatedAt'
                                          | 'title_english'
                                          | 'title_french'
                                          | 'title_german'
                                          | 'title_spanish'
                                          | 'title_mandarin'
                                          | 'content_english'
                                          | 'content_french'
                                          | 'content_german'
                                          | 'content_spanish'
                                          | 'content_mandarin'
                                          | 'date_published'
                                          | 'read_time'
                                          | 'link'
                                        > & {
                                            image?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & {
                                                    attributes?: Maybe<
                                                      { __typename?: 'UploadFile' } & Pick<
                                                        UploadFile,
                                                        'url'
                                                      >
                                                    >
                                                  }
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                            modal?: Maybe<
                              { __typename?: 'ModalEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'ModalEntity' } & Pick<ModalEntity, 'id'> & {
                                      attributes?: Maybe<
                                        { __typename?: 'Modal' } & Pick<
                                          Modal,
                                          'title' | 'subtitle' | 'message'
                                        > & {
                                            image?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url' | 'name'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                            events?: Maybe<
                              { __typename?: 'EventRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'EventEntity' } & Pick<EventEntity, 'id'> & {
                                      attributes?: Maybe<
                                        { __typename?: 'Event' } & Pick<
                                          Event,
                                          'name' | 'link' | 'session_id'
                                        >
                                      >
                                    }
                                >
                              }
                            >
                            sub_modules?: Maybe<
                              { __typename?: 'SubModuleRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'SubModuleEntity' } & Pick<
                                    SubModuleEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'SubModule' } & Pick<
                                          SubModule,
                                          'name_english' | 'description_english'
                                        > & {
                                            cover?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            programme_type?: Maybe<
                                              { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                                    ProgrammeTypeEntity,
                                                    'id'
                                                  >
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
            programme_responses?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsProgrammeSubmissions' } & Pick<
                    ComponentSubmissionsProgrammeSubmissions,
                    'sliderAnswers'
                  > & {
                      answers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                              ComponentSubmissionsAnswer,
                              | 'id'
                              | 'questionId'
                              | 'answer'
                              | 'isCorrect'
                              | 'feedback'
                              | 'outcome'
                              | 'questionType'
                              | 'questionTitle'
                            > & {
                                nestedAnswers?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'ComponentSubmissionsRepeatAnswer' } & Pick<
                                        ComponentSubmissionsRepeatAnswer,
                                        'answer' | 'questionTitle'
                                      >
                                    >
                                  >
                                >
                              }
                          >
                        >
                      >
                      stakeholderChart?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      stakeholderItems?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentStakeholderMapItemStackholderMapItem' } & Pick<
                              ComponentStakeholderMapItemStackholderMapItem,
                              'id' | 'valueX' | 'valueY' | 'name' | 'color'
                            >
                          >
                        >
                      >
                      gravitasScales?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems' } & Pick<
                              ComponentGravitasScaleItemsGravitasScaleItems,
                              | 'id'
                              | 'labelOne'
                              | 'labelTwo'
                              | 'labelThree'
                              | 'question'
                              | 'answerOne'
                              | 'answerTwo'
                              | 'answerThree'
                            >
                          >
                        >
                      >
                      xYGraphChart?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      saboteurImage?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      xYGraphItems?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                              ComponentXyGraphItemXyGraphItem,
                              'id' | 'valueX' | 'valueY' | 'color'
                            >
                          >
                        >
                      >
                      characteristics?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsCharacteristics' } & Pick<
                              ComponentSubmissionsCharacteristics,
                              'id' | 'answer' | 'type'
                            >
                          >
                        >
                      >
                      gapsAnswers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsValuesGaps' } & Pick<
                              ComponentSubmissionsValuesGaps,
                              'id' | 'gap' | 'commitment'
                            >
                          >
                        >
                      >
                      competencyAnswers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsCompetencyAnswer' } & Pick<
                              ComponentSubmissionsCompetencyAnswer,
                              | 'id'
                              | 'question'
                              | 'rating'
                              | 'isSimpleQuestion'
                              | 'response'
                              | 'steps'
                            >
                          >
                        >
                      >
                      gravitasSliderAnswers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsGravitasSlidersAnswers' } & Pick<
                              ComponentSubmissionsGravitasSlidersAnswers,
                              'negativeQuestion' | 'positiveQuestion'
                            > & {
                                colorAndRating?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'ComponentOptionColorAndRating' } & Pick<
                                        ComponentOptionColorAndRating,
                                        'color' | 'rate'
                                      >
                                    >
                                  >
                                >
                              }
                          >
                        >
                      >
                      gravitasGap?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsGravitasGap' } & Pick<
                              ComponentSubmissionsGravitasGap,
                              'id' | 'gap' | 'commitment'
                            >
                          >
                        >
                      >
                    }
                >
              >
            >
            my_notes?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsNotes' } & Pick<
                    ComponentSubmissionsNotes,
                    'id' | 'title' | 'description'
                  > & {
                      document?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                    }
                >
              >
            >
            participants?: Maybe<
              { __typename?: 'ParticipantRelationResponseCollection' } & {
                data: Array<
                  { __typename?: 'ParticipantEntity' } & Pick<ParticipantEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Participant' } & Pick<
                          Participant,
                          'name' | 'email' | 'type'
                        >
                      >
                    }
                >
              }
            >
            submission_participants?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsParticipants' } & Pick<
                    ComponentSubmissionsParticipants,
                    'id' | 'name' | 'email'
                  >
                >
              >
            >
            programme_report?: Maybe<
              { __typename?: 'ProgrammeReportEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ProgrammeReportEntity' } & Pick<ProgrammeReportEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'ProgrammeReport' } & Pick<ProgrammeReport, 'name'> & {
                            report?: Maybe<
                              { __typename?: 'UploadFileRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<
                                          UploadFile,
                                          'url' | 'name'
                                        >
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
            programme?: Maybe<
              { __typename?: 'ProgrammeEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Programme' } & Pick<
                          Programme,
                          'is_custom_journey' | 'show_timeline' | 'programme_title'
                        > & {
                            logo?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
          }
      >
    >
  >
}

export type GetMySubModuleSubmissionsQueryVariables = {}

export type GetMySubModuleSubmissionsQuery = { __typename?: 'Query' } & {
  getMySubModuleSubmissions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'SubModuleSubmission' } & Pick<
          SubModuleSubmission,
          'id' | 'view' | 'progress' | 'status' | 'time_spent'
        > & {
            feedfoward_url?: Maybe<
              { __typename?: 'FeedfowardUrlEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'FeedfowardUrlEntity' } & Pick<FeedfowardUrlEntity, 'id'>
                >
              }
            >
            competency_url?: Maybe<
              { __typename?: 'CompetencyUrlEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'CompetencyUrlEntity' } & Pick<CompetencyUrlEntity, 'id'>
                >
              }
            >
            competency_participants?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsCompetencyParticipants' } & Pick<
                    ComponentSubmissionsCompetencyParticipants,
                    'name' | 'email'
                  >
                >
              >
            >
            participants?: Maybe<
              { __typename?: 'ParticipantRelationResponseCollection' } & {
                data: Array<
                  { __typename?: 'ParticipantEntity' } & Pick<ParticipantEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Participant' } & Pick<
                          Participant,
                          'name' | 'email' | 'type'
                        >
                      >
                    }
                >
              }
            >
            submission_participants?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsParticipants' } & Pick<
                    ComponentSubmissionsParticipants,
                    'id' | 'name' | 'email'
                  >
                >
              >
            >
            language?: Maybe<
              { __typename?: 'LanguageEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                      attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                    }
                >
              }
            >
            section?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ComponentSubmissionsSectionSubmission' } & Pick<
                    ComponentSubmissionsSectionSubmission,
                    'id' | 'progress' | 'sliderAnswers'
                  > & {
                      section?: Maybe<
                        { __typename?: 'SectionEntityResponse' } & {
                          data?: Maybe<{ __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'>>
                        }
                      >
                      answers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsAnswer' } & Pick<
                              ComponentSubmissionsAnswer,
                              | 'id'
                              | 'questionId'
                              | 'answer'
                              | 'isCorrect'
                              | 'feedback'
                              | 'outcome'
                              | 'questionType'
                              | 'questionTitle'
                            > & {
                                nestedAnswers?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'ComponentSubmissionsRepeatAnswer' } & Pick<
                                        ComponentSubmissionsRepeatAnswer,
                                        'answer' | 'questionTitle'
                                      >
                                    >
                                  >
                                >
                              }
                          >
                        >
                      >
                      stakeholderChart?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      stakeholderItems?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentStakeholderMapItemStackholderMapItem' } & Pick<
                              ComponentStakeholderMapItemStackholderMapItem,
                              'id' | 'valueX' | 'valueY' | 'name' | 'color'
                            >
                          >
                        >
                      >
                      gravitasScales?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentGravitasScaleItemsGravitasScaleItems' } & Pick<
                              ComponentGravitasScaleItemsGravitasScaleItems,
                              | 'id'
                              | 'labelOne'
                              | 'labelTwo'
                              | 'labelThree'
                              | 'question'
                              | 'answerOne'
                              | 'answerTwo'
                              | 'answerThree'
                            >
                          >
                        >
                      >
                      gravitasSliderAnswers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsGravitasSlidersAnswers' } & Pick<
                              ComponentSubmissionsGravitasSlidersAnswers,
                              'negativeQuestion' | 'positiveQuestion'
                            > & {
                                colorAndRating?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'ComponentOptionColorAndRating' } & Pick<
                                        ComponentOptionColorAndRating,
                                        'color' | 'rate'
                                      >
                                    >
                                  >
                                >
                              }
                          >
                        >
                      >
                      gravitasGap?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsGravitasGap' } & Pick<
                              ComponentSubmissionsGravitasGap,
                              'id' | 'gap' | 'commitment'
                            >
                          >
                        >
                      >
                      xYGraphChart?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      saboteurImage?: Maybe<
                        { __typename?: 'UploadFileEntityResponse' } & {
                          data?: Maybe<
                            { __typename?: 'UploadFileEntity' } & Pick<UploadFileEntity, 'id'> & {
                                attributes?: Maybe<
                                  { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                >
                              }
                          >
                        }
                      >
                      xYGraphItems?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentXYGraphItemXYGraphItem' } & Pick<
                              ComponentXyGraphItemXyGraphItem,
                              'id' | 'valueX' | 'valueY' | 'color'
                            >
                          >
                        >
                      >
                      characteristics?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsCharacteristics' } & Pick<
                              ComponentSubmissionsCharacteristics,
                              'id' | 'answer' | 'type'
                            >
                          >
                        >
                      >
                      gapsAnswers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsValuesGaps' } & Pick<
                              ComponentSubmissionsValuesGaps,
                              'id' | 'gap' | 'commitment'
                            >
                          >
                        >
                      >
                      competencyAnswers?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsCompetencyAnswer' } & Pick<
                              ComponentSubmissionsCompetencyAnswer,
                              'id' | 'question' | 'rating' | 'steps' | 'response'
                            >
                          >
                        >
                      >
                      uploadDocuments?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ComponentSubmissionsUploadDocuments' } & {
                              saboteurProofValue?: Maybe<
                                { __typename?: 'UploadFileEntityResponse' } & {
                                  data?: Maybe<
                                    { __typename?: 'UploadFileEntity' } & Pick<
                                      UploadFileEntity,
                                      'id'
                                    >
                                  >
                                }
                              >
                            }
                          >
                        >
                      >
                    }
                >
              >
            >
            sub_module?: Maybe<
              { __typename?: 'SubModuleEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'SubModuleEntity' } & Pick<SubModuleEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'SubModule' } & Pick<
                          SubModule,
                          | 'name_french'
                          | 'name_german'
                          | 'name_english'
                          | 'name_spanish'
                          | 'name_mandarin'
                          | 'description_french'
                          | 'description_german'
                          | 'description_english'
                          | 'description_spanish'
                          | 'description_mandarin'
                          | 'position'
                        > & {
                            cover?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            sections?: Maybe<
                              { __typename?: 'SectionRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                                      attributes?: Maybe<
                                        { __typename?: 'Section' } & Pick<
                                          Section,
                                          | 'title_french'
                                          | 'title_german'
                                          | 'title_english'
                                          | 'title_spanish'
                                          | 'title_mandarin'
                                          | 'sub_title_french'
                                          | 'sub_title_german'
                                          | 'sub_title_english'
                                          | 'sub_title_spanish'
                                          | 'sub_title_mandarin'
                                          | 'position'
                                          | 'archive'
                                        > & {
                                            cover?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                            programme_type?: Maybe<
                              { __typename?: 'ProgrammeTypeEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'ProgrammeTypeEntity' } & Pick<
                                    ProgrammeTypeEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'ProgrammeType' } & Pick<
                                          ProgrammeType,
                                          | 'title'
                                          | 'description'
                                          | 'position'
                                          | 'is_coaching'
                                          | 'is_sub_module'
                                          | 'notes'
                                          | 'show_article'
                                          | 'show_session'
                                          | 'show_participants'
                                          | 'competency_survey'
                                          | 'feedForward_survey'
                                          | 'deadline'
                                        > & {
                                            cover?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            articles?: Maybe<
                                              {
                                                __typename?: 'ArticleRelationResponseCollection'
                                              } & {
                                                data: Array<
                                                  { __typename?: 'ArticleEntity' } & Pick<
                                                    ArticleEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'Article' } & Pick<
                                                          Article,
                                                          | 'createdAt'
                                                          | 'updatedAt'
                                                          | 'title_english'
                                                          | 'title_french'
                                                          | 'title_german'
                                                          | 'title_spanish'
                                                          | 'title_mandarin'
                                                          | 'content_english'
                                                          | 'content_french'
                                                          | 'content_german'
                                                          | 'content_spanish'
                                                          | 'content_mandarin'
                                                          | 'date_published'
                                                          | 'read_time'
                                                          | 'link'
                                                        > & {
                                                            image?: Maybe<
                                                              {
                                                                __typename?: 'UploadFileEntityResponse'
                                                              } & {
                                                                data?: Maybe<
                                                                  {
                                                                    __typename?: 'UploadFileEntity'
                                                                  } & {
                                                                    attributes?: Maybe<
                                                                      {
                                                                        __typename?: 'UploadFile'
                                                                      } & Pick<UploadFile, 'url'>
                                                                    >
                                                                  }
                                                                >
                                                              }
                                                            >
                                                          }
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            modal?: Maybe<
                                              { __typename?: 'ModalEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'ModalEntity' } & Pick<
                                                    ModalEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'Modal' } & Pick<
                                                          Modal,
                                                          'title' | 'subtitle' | 'message'
                                                        > & {
                                                            image?: Maybe<
                                                              {
                                                                __typename?: 'UploadFileEntityResponse'
                                                              } & {
                                                                data?: Maybe<
                                                                  {
                                                                    __typename?: 'UploadFileEntity'
                                                                  } & Pick<
                                                                    UploadFileEntity,
                                                                    'id'
                                                                  > & {
                                                                      attributes?: Maybe<
                                                                        {
                                                                          __typename?: 'UploadFile'
                                                                        } & Pick<
                                                                          UploadFile,
                                                                          'url' | 'name'
                                                                        >
                                                                      >
                                                                    }
                                                                >
                                                              }
                                                            >
                                                          }
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                            events?: Maybe<
                                              { __typename?: 'EventRelationResponseCollection' } & {
                                                data: Array<
                                                  { __typename?: 'EventEntity' } & Pick<
                                                    EventEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'Event' } & Pick<
                                                          Event,
                                                          'name' | 'link'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
            reports?: Maybe<
              { __typename?: 'ReportRelationResponseCollection' } & {
                data: Array<
                  { __typename?: 'ReportEntity' } & Pick<ReportEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Report' } & Pick<
                          Report,
                          'name' | 'type' | 'section_or_module_id'
                        > & {
                            report?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
          }
      >
    >
  >
}

export type GetMySubmissionsQueryVariables = {}

export type GetMySubmissionsQuery = { __typename?: 'Query' } & {
  getMySubmissions?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Submission' } & Pick<
          Submission,
          'id' | 'views' | 'progress' | 'status' | 'time_spent'
        > & {
            language?: Maybe<
              { __typename?: 'LanguageEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                      attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                    }
                >
              }
            >
            module?: Maybe<
              { __typename?: 'ModuleEntityResponse' } & {
                data?: Maybe<
                  { __typename?: 'ModuleEntity' } & Pick<ModuleEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Module' } & Pick<
                          Module,
                          | 'name_french'
                          | 'name_german'
                          | 'name_english'
                          | 'name_spanish'
                          | 'name_mandarin'
                          | 'description_french'
                          | 'description_german'
                          | 'description_english'
                          | 'description_spanish'
                          | 'description_mandarin'
                          | 'position'
                        > & {
                            cover?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                            sections?: Maybe<
                              { __typename?: 'SectionRelationResponseCollection' } & {
                                data: Array<
                                  { __typename?: 'SectionEntity' } & Pick<SectionEntity, 'id'> & {
                                      attributes?: Maybe<
                                        { __typename?: 'Section' } & Pick<
                                          Section,
                                          | 'title_french'
                                          | 'title_german'
                                          | 'title_english'
                                          | 'title_spanish'
                                          | 'title_mandarin'
                                          | 'sub_title_french'
                                          | 'sub_title_german'
                                          | 'sub_title_english'
                                          | 'sub_title_spanish'
                                          | 'sub_title_mandarin'
                                        > & {
                                            cover?: Maybe<
                                              { __typename?: 'UploadFileEntityResponse' } & {
                                                data?: Maybe<
                                                  { __typename?: 'UploadFileEntity' } & Pick<
                                                    UploadFileEntity,
                                                    'id'
                                                  > & {
                                                      attributes?: Maybe<
                                                        { __typename?: 'UploadFile' } & Pick<
                                                          UploadFile,
                                                          'url'
                                                        >
                                                      >
                                                    }
                                                >
                                              }
                                            >
                                          }
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
            reports?: Maybe<
              { __typename?: 'ReportRelationResponseCollection' } & {
                data: Array<
                  { __typename?: 'ReportEntity' } & Pick<ReportEntity, 'id'> & {
                      attributes?: Maybe<
                        { __typename?: 'Report' } & Pick<
                          Report,
                          'name' | 'type' | 'section_or_module_id'
                        > & {
                            report?: Maybe<
                              { __typename?: 'UploadFileEntityResponse' } & {
                                data?: Maybe<
                                  { __typename?: 'UploadFileEntity' } & Pick<
                                    UploadFileEntity,
                                    'id'
                                  > & {
                                      attributes?: Maybe<
                                        { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                                      >
                                    }
                                >
                              }
                            >
                          }
                      >
                    }
                >
              }
            >
          }
      >
    >
  >
}

export type UserQueryVariables = {
  id: Scalars['ID']
}

export type UserQuery = { __typename?: 'Query' } & {
  usersPermissionsUser?: Maybe<
    { __typename?: 'UsersPermissionsUserEntityResponse' } & {
      data?: Maybe<
        { __typename?: 'UsersPermissionsUserEntity' } & Pick<UsersPermissionsUserEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'UsersPermissionsUser' } & Pick<
                UsersPermissionsUser,
                | 'username'
                | 'firstName'
                | 'lastName'
                | 'email'
                | 'jobTitle'
                | 'confirmed'
                | 'blocked'
                | 'phoneNumber'
                | 'bookmarks'
                | 'modulesRead'
                | 'homePromptsRead'
                | 'programmePromptsRead'
                | 'modulePromptsRead'
                | 'articlesViewed'
                | 'feedForwardUrl'
              > & {
                  company?: Maybe<
                    { __typename?: 'CompanyEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'CompanyEntity' } & Pick<CompanyEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Company' } & Pick<
                                Company,
                                'name' | 'users_confirm_details'
                              > & {
                                  logo?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  language_selection?: Maybe<
                                    {
                                      __typename?: 'ComponentTranslationsLanguageSelection'
                                    } & Pick<
                                      ComponentTranslationsLanguageSelection,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleSpanish'
                                      | 'titleGerman'
                                      | 'titleMandarin'
                                      | 'bodyEnglish'
                                      | 'bodyFrench'
                                      | 'bodySpanish'
                                      | 'bodyGerman'
                                      | 'bodyMandarin'
                                      | 'nextButtonEnglish'
                                      | 'nextButtonFrench'
                                      | 'nextButtonSpanish'
                                      | 'nextButtonGerman'
                                      | 'nextButtonMandarin'
                                    >
                                  >
                                  welcome_screen?: Maybe<
                                    { __typename?: 'ComponentTranslationsWelcomeScreen' } & Pick<
                                      ComponentTranslationsWelcomeScreen,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'titleFrench'
                                      | 'titleSpanish'
                                      | 'titleGerman'
                                      | 'titleMandarin'
                                      | 'bodyEnglish'
                                      | 'bodyFrench'
                                      | 'bodySpanish'
                                      | 'bodyGerman'
                                      | 'bodyMandarin'
                                      | 'nextButtonEnglish'
                                      | 'nextButtonFrench'
                                      | 'nextButtonSpanish'
                                      | 'nextButtonGerman'
                                      | 'nextButtonMandarin'
                                    > & {
                                        videoEnglish?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                        videoFrench?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                        videoSpanish?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                        videoGerman?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                        videoMandarin?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                      }
                                  >
                                  lets_get_started_screen?: Maybe<
                                    {
                                      __typename?: 'ComponentTranslationsLetsGetStartedScreen'
                                    } & Pick<
                                      ComponentTranslationsLetsGetStartedScreen,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'subTitleEnglish'
                                      | 'firstNameLabelEnglish'
                                      | 'firstNameErrorEnglish'
                                      | 'lastNameLabelEnglish'
                                      | 'lastNameErrorEnglish'
                                      | 'jobTitleLabelEnglish'
                                      | 'jobTitleErrorEnglish'
                                      | 'divisionLabelEnglish'
                                      | 'divisionErrorEnglish'
                                      | 'timezoneLabelEnglish'
                                      | 'regionLabelEnglish'
                                      | 'regionErrorEnglish'
                                      | 'nextButtonEnglish'
                                      | 'titleFrench'
                                      | 'subTitleFrench'
                                      | 'firstNameLabelFrench'
                                      | 'firstNameErrorFrench'
                                      | 'lastNameLabelFrench'
                                      | 'lastNameErrorFrench'
                                      | 'jobTitleLabelFrench'
                                      | 'jobTitleErrorFrench'
                                      | 'divisionLabelFrench'
                                      | 'divisionErrorFrench'
                                      | 'timezoneLabelFrench'
                                      | 'regionLabelFrench'
                                      | 'regionErrorFrench'
                                      | 'nextButtonFrench'
                                      | 'titleGerman'
                                      | 'subTitleGerman'
                                      | 'firstNameLabelGerman'
                                      | 'firstNameErrorGerman'
                                      | 'lastNameLabelGerman'
                                      | 'lastNameErrorGerman'
                                      | 'jobTitleLabelGerman'
                                      | 'jobTitleErrorGerman'
                                      | 'divisionLabelGerman'
                                      | 'divisionErrorGerman'
                                      | 'timezoneLabelGerman'
                                      | 'regionLabelGerman'
                                      | 'regionErrorGerman'
                                      | 'nextButtonGerman'
                                      | 'titleMandarin'
                                      | 'subTitleMandarin'
                                      | 'firstNameLabelMandarin'
                                      | 'firstNameErrorMandarin'
                                      | 'lastNameLabelMandarin'
                                      | 'lastNameErrorMandarin'
                                      | 'jobTitleLabelMandarin'
                                      | 'jobTitleErrorMandarin'
                                      | 'divisionLabelMandarin'
                                      | 'divisionErrorMandarin'
                                      | 'timezoneLabelMandarin'
                                      | 'regionLabelMandarin'
                                      | 'regionErrorMandarin'
                                      | 'nextButtonMandarin'
                                      | 'titleSpanish'
                                      | 'subTitleSpanish'
                                      | 'firstNameLabelSpanish'
                                      | 'firstNameErrorSpanish'
                                      | 'lastNameLabelSpanish'
                                      | 'lastNameErrorSpanish'
                                      | 'jobTitleLabelSpanish'
                                      | 'jobTitleErrorSpanish'
                                      | 'divisionLabelSpanish'
                                      | 'divisionErrorSpanish'
                                      | 'timezoneLabelSpanish'
                                      | 'regionLabelSpanish'
                                      | 'regionErrorSpanish'
                                      | 'nextButtonSpanish'
                                      | 'leftTitleEnglish'
                                      | 'leftSubtitleEnglish'
                                      | 'leftTitleSpanish'
                                      | 'leftSubtitleSpanish'
                                      | 'leftTitleMandarin'
                                      | 'leftSubTitleMandarin'
                                      | 'leftTitleFrench'
                                      | 'leftSubtitleFrench'
                                      | 'leftSubtitleGerman'
                                      | 'leftTitleGerman'
                                      | 'havingTroubleEnglish'
                                      | 'havingTroubleGerman'
                                      | 'havingTroubleFrench'
                                      | 'havingTroubleMandarin'
                                      | 'havingTroubleSpanish'
                                      | 'getHelpButtonEnglish'
                                      | 'getHelpButtonSpanish'
                                      | 'getHelpButtonFrench'
                                      | 'getHelpButtonGerman'
                                      | 'getHelpButtonMandarin'
                                    > & {
                                        image?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                      }
                                  >
                                  what_to_expect?: Maybe<
                                    { __typename?: 'ComponentTranslationsWhatToExpect' } & Pick<
                                      ComponentTranslationsWhatToExpect,
                                      | 'id'
                                      | 'titleEnglish'
                                      | 'bodyEnglish'
                                      | 'getStartedButtonEnglish'
                                      | 'titleFrench'
                                      | 'bodyFrench'
                                      | 'getStartedButtonFrench'
                                      | 'titleGerman'
                                      | 'bodyGerman'
                                      | 'getStartedButtonGerman'
                                      | 'titleMandarin'
                                      | 'bodyMandarin'
                                      | 'getStartedButtonMandarin'
                                      | 'titleSpanish'
                                      | 'bodySpanish'
                                      | 'getStartedButtonSpanish'
                                    > & {
                                        image?: Maybe<
                                          { __typename?: 'UploadFileEntityResponse' } & {
                                            data?: Maybe<
                                              { __typename?: 'UploadFileEntity' } & Pick<
                                                UploadFileEntity,
                                                'id'
                                              > & {
                                                  attributes?: Maybe<
                                                    { __typename?: 'UploadFile' } & Pick<
                                                      UploadFile,
                                                      'url'
                                                    >
                                                  >
                                                }
                                            >
                                          }
                                        >
                                      }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                  division?: Maybe<
                    { __typename?: 'DivisionEntityResponse' } & {
                      data?: Maybe<{ __typename?: 'DivisionEntity' } & Pick<DivisionEntity, 'id'>>
                    }
                  >
                  language?: Maybe<
                    { __typename?: 'LanguageEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                            attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                          }
                      >
                    }
                  >
                  profilePicture?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                      >
                    }
                  >
                  role?: Maybe<
                    { __typename?: 'UsersPermissionsRoleEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UsersPermissionsRoleEntity' } & Pick<
                          UsersPermissionsRoleEntity,
                          'id'
                        > & {
                            attributes?: Maybe<
                              { __typename?: 'UsersPermissionsRole' } & Pick<
                                UsersPermissionsRole,
                                'name' | 'description' | 'type'
                              >
                            >
                          }
                      >
                    }
                  >
                  certificate?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<{ __typename?: 'UploadFileEntity' } & UploadFileFragment>
                    }
                  >
                  programmes?: Maybe<
                    { __typename?: 'ProgrammeRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Programme' } & Pick<
                                Programme,
                                'name' | 'description' | 'start' | 'is_custom_journey' | 'position'
                              > & {
                                  logo?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                  programme_types?: Maybe<
                                    { __typename?: 'ProgrammeTypeRelationResponseCollection' } & {
                                      data: Array<
                                        { __typename?: 'ProgrammeTypeEntity' } & {
                                          attributes?: Maybe<
                                            { __typename?: 'ProgrammeType' } & Pick<
                                              ProgrammeType,
                                              'title'
                                            > & {
                                                sub_modules?: Maybe<
                                                  {
                                                    __typename?: 'SubModuleRelationResponseCollection'
                                                  } & {
                                                    data: Array<
                                                      { __typename?: 'SubModuleEntity' } & {
                                                        attributes?: Maybe<
                                                          { __typename?: 'SubModule' } & {
                                                            sections?: Maybe<
                                                              {
                                                                __typename?: 'SectionRelationResponseCollection'
                                                              } & {
                                                                data: Array<
                                                                  {
                                                                    __typename?: 'SectionEntity'
                                                                  } & {
                                                                    attributes?: Maybe<
                                                                      {
                                                                        __typename?: 'Section'
                                                                      } & Pick<
                                                                        Section,
                                                                        'title_english'
                                                                      >
                                                                    >
                                                                  }
                                                                >
                                                              }
                                                            >
                                                          }
                                                        >
                                                      }
                                                    >
                                                  }
                                                >
                                              }
                                          >
                                        }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type UsersQueryVariables = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  where: UsersPermissionsUserFiltersInput
}

export type UsersQuery = { __typename?: 'Query' } & {
  usersPermissionsUsers?: Maybe<
    { __typename?: 'UsersPermissionsUserEntityResponseCollection' } & {
      data: Array<
        { __typename?: 'UsersPermissionsUserEntity' } & Pick<UsersPermissionsUserEntity, 'id'> & {
            attributes?: Maybe<
              { __typename?: 'UsersPermissionsUser' } & Pick<
                UsersPermissionsUser,
                | 'username'
                | 'firstName'
                | 'lastName'
                | 'email'
                | 'jobTitle'
                | 'confirmed'
                | 'blocked'
                | 'phoneNumber'
                | 'bookmarks'
                | 'modulesRead'
                | 'homePromptsRead'
                | 'programmePromptsRead'
                | 'modulePromptsRead'
                | 'articlesViewed'
              > & {
                  division?: Maybe<
                    { __typename?: 'DivisionEntityResponse' } & {
                      data?: Maybe<{ __typename?: 'DivisionEntity' } & Pick<DivisionEntity, 'id'>>
                    }
                  >
                  language?: Maybe<
                    { __typename?: 'LanguageEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'LanguageEntity' } & Pick<LanguageEntity, 'id'> & {
                            attributes?: Maybe<{ __typename?: 'Language' } & Pick<Language, 'name'>>
                          }
                      >
                    }
                  >
                  profilePicture?: Maybe<
                    { __typename?: 'UploadFileEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UploadFileEntity' } & {
                          attributes?: Maybe<
                            { __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>
                          >
                        }
                      >
                    }
                  >
                  role?: Maybe<
                    { __typename?: 'UsersPermissionsRoleEntityResponse' } & {
                      data?: Maybe<
                        { __typename?: 'UsersPermissionsRoleEntity' } & Pick<
                          UsersPermissionsRoleEntity,
                          'id'
                        > & {
                            attributes?: Maybe<
                              { __typename?: 'UsersPermissionsRole' } & Pick<
                                UsersPermissionsRole,
                                'name' | 'description' | 'type'
                              >
                            >
                          }
                      >
                    }
                  >
                  programmes?: Maybe<
                    { __typename?: 'ProgrammeRelationResponseCollection' } & {
                      data: Array<
                        { __typename?: 'ProgrammeEntity' } & Pick<ProgrammeEntity, 'id'> & {
                            attributes?: Maybe<
                              { __typename?: 'Programme' } & Pick<
                                Programme,
                                'name' | 'description' | 'start' | 'is_custom_journey' | 'position'
                              > & {
                                  logo?: Maybe<
                                    { __typename?: 'UploadFileEntityResponse' } & {
                                      data?: Maybe<
                                        { __typename?: 'UploadFileEntity' } & Pick<
                                          UploadFileEntity,
                                          'id'
                                        > & {
                                            attributes?: Maybe<
                                              { __typename?: 'UploadFile' } & Pick<
                                                UploadFile,
                                                'url'
                                              >
                                            >
                                          }
                                      >
                                    }
                                  >
                                }
                            >
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export const ModuleFragmentDoc = gql`
  fragment module on ModuleEntity {
    id
    attributes {
      name_french
      name_german
      name_english
      name_spanish
      name_mandarin
      description_french
      description_german
      description_english
      description_spanish
      description_mandarin
      cover {
        data {
          id
          attributes {
            url
          }
        }
      }
    }
  }
`
export const UploadFileFragmentDoc = gql`
  fragment uploadFile on UploadFileEntity {
    id
    attributes {
      createdAt
      updatedAt
      name
      alternativeText
      caption
      width
      height
      formats
      hash
      ext
      mime
      size
      url
      previewUrl
      provider
    }
  }
`
export const QuestionsFragmentDoc = gql`
  fragment questions on SectionQuestionsDynamicZone {
    __typename
    ... on ComponentQuestionTypesSimpleQuestion {
      id
      titleEnglish
      titleFrench
      titleGerman
      titleSpanish
      titleMandarin
      subTitleEnglish
      subTitleFrench
      subTitleGerman
      subTitleSpanish
      subTitleMandarin
      viewPrevious
    }
    ... on ComponentQuestionTypesCompetency2 {
      titleEnglish
      titleFrench
      titleMandarin
      questionscompetency2 {
        title
        question
        maxiNumber
        miniNumber
        simpleQuestion
        miniLabel
        maxiLabel
      }
    }
    ... on ComponentQuestionTypesXYGraph {
      id
      labelXEnglish
      labelYEnglish
      titleEnglish
      subTitleEnglish
      labelXFrench
      labelYFrench
      titleFrench
      subTitleFrench
      labelXGerman
      labelYGerman
      titleGerman
      subTitleGerman
      labelXSpanish
      labelYSpanish
      titleSpanish
      subTitleSpanish
      labelXMandarin
      labelYMandarin
      titleMandarin
      subTitleMandarin
    }
    ... on ComponentQuestionTypesMultipleChoice {
      id
      titleEnglish
      titleFrench
      titleGerman
      titleSpanish
      titleMandarin
      viewPrevious
      option {
        id
        optionEnglish
        optionFrench
        optionGerman
        optionSpanish
        optionMandarin
        isCorrect
      }
      numberOfAnswers
    }
    ... on ComponentQuestionTypesFeedForward1 {
      id
      title
      question
      description
    }
    ... on ComponentQuestionTypesFeedForward2 {
      id
      title
      question
      description
    }
    ... on ComponentQuestionTypesSaboteur {
      id
      titleEnglish
      subTitleEnglish
      titleFrench
      subTitleFrench
      titleGerman
      subTitleGerman
      titleSpanish
      subTitleSpanish
      titleMandarin
      subTitleMandarin
      saboteurQuestions {
        link
        skipProof
        labelEnglish
        labelFrench
        labelGerman
        labelSpanish
        labelMandarin
        proof {
          data {
            ...uploadFile
          }
        }
        labelFrench
        labelGerman
        labelSpanish
        labelMandarin
      }
    }
    ... on ComponentQuestionTypesNestedQuestions {
      id
      titleEnglish
      titleFrench
      titleGerman
      titleSpanish
      titleMandarin
      viewPrevious
      questions {
        id
        titleEnglish
        subTitleEnglish
        titleFrench
        subTitleFrench
        titleGerman
        subTitleGerman
        titleSpanish
        subTitleSpanish
        titleMandarin
        subTitleMandarin
        isNumeric
      }
    }
    ... on ComponentQuestionTypesGravitasScale {
      id
      titleEnglish
      subTitleEnglish
      questionOneEnglish
      questionTwoEnglish
      questionThreeEnglish
      titleOneEnglish
      titleTwoEnglish
      ratingInput {
        id
        labelOneEnglish
        labelTwoEnglish
      }
    }
    ... on ComponentQuestionTypesStakeholderMap {
      id
      titleEnglish
      subTitleEnglish
      titleFrench
      subTitleFrench
      titleSpanish
      subTitleSpanish
      titleGerman
      subTitleGerman
      titleMandarin
      subTitleMandarin
      questionX
      questionY
    }
    ... on ComponentQuestionTypesSaboteurVideos {
      id
      titleEnglish
      titleFrench
      titleGerman
      titleSpanish
      titleMandarin
      subTitleEnglish
      subTitleFrench
      subTitleGerman
      subTitleSpanish
      subTitleMandarin
      SaboteurVideo {
        id
        videoTitleEnglish
        videoTitleFrench
        videoTitleGerman
        videoTitleSpanish
        videoTitleMandarin
        video {
          data {
            ...uploadFile
          }
        }
        videoDescriptionEnglish
        videoDescriptionFrench
        videoDescriptionGerman
        videoDescriptionSpanish
        videoDescriptionMandarin
      }
    }
  }
  ${UploadFileFragmentDoc}
`
export const SectionFragmentDoc = gql`
  fragment section on SectionEntity {
    id
    attributes {
      title_french
      title_german
      title_english
      title_spanish
      title_mandarin
      sub_title_french
      sub_title_german
      sub_title_english
      sub_title_spanish
      sub_title_mandarin
      cover {
        data {
          id
          attributes {
            url
          }
        }
      }
    }
  }
`
export const SubmissionFragmentDoc = gql`
  fragment submission on SubmissionEntity {
    id
    attributes {
      views
      progress
      status
      time_spent
      language {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
`
export const AddDocumentDocument = gql`
  mutation addDocument($input: editUserUploadedDocumentInput!) {
    addDocument(input: $input) {
      name
    }
  }
`
export type AddDocumentMutationFn = ApolloReactCommon.MutationFunction<
  AddDocumentMutation,
  AddDocumentMutationVariables
>
export type AddDocumentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<AddDocumentMutation, AddDocumentMutationVariables>,
  'mutation'
>

export const AddDocumentComponent = (props: AddDocumentComponentProps) => (
  <ApolloReactComponents.Mutation<AddDocumentMutation, AddDocumentMutationVariables>
    mutation={AddDocumentDocument}
    {...props}
  />
)

export type AddDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AddDocumentMutation,
    AddDocumentMutationVariables
  >
} &
  TChildProps
export function withAddDocument<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddDocumentMutation,
    AddDocumentMutationVariables,
    AddDocumentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddDocumentMutation,
    AddDocumentMutationVariables,
    AddDocumentProps<TChildProps, TDataName>
  >(AddDocumentDocument, {
    alias: 'addDocument',
    ...operationOptions
  })
}

/**
 * __useAddDocumentMutation__
 *
 * To run a mutation, you first call `useAddDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentMutation, { data, loading, error }] = useAddDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddDocumentMutation,
    AddDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddDocumentMutation, AddDocumentMutationVariables>(
    AddDocumentDocument,
    baseOptions
  )
}
export type AddDocumentMutationHookResult = ReturnType<typeof useAddDocumentMutation>
export type AddDocumentMutationResult = ApolloReactCommon.MutationResult<AddDocumentMutation>
export type AddDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddDocumentMutation,
  AddDocumentMutationVariables
>
export const AddNoteDocument = gql`
  mutation addNote($input: editNoteInput!) {
    addNote(input: $input) {
      note
    }
  }
`
export type AddNoteMutationFn = ApolloReactCommon.MutationFunction<
  AddNoteMutation,
  AddNoteMutationVariables
>
export type AddNoteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<AddNoteMutation, AddNoteMutationVariables>,
  'mutation'
>

export const AddNoteComponent = (props: AddNoteComponentProps) => (
  <ApolloReactComponents.Mutation<AddNoteMutation, AddNoteMutationVariables>
    mutation={AddNoteDocument}
    {...props}
  />
)

export type AddNoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<AddNoteMutation, AddNoteMutationVariables>
} &
  TChildProps
export function withAddNote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddNoteMutation,
    AddNoteMutationVariables,
    AddNoteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddNoteMutation,
    AddNoteMutationVariables,
    AddNoteProps<TChildProps, TDataName>
  >(AddNoteDocument, {
    alias: 'addNote',
    ...operationOptions
  })
}

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>
) {
  return ApolloReactHooks.useMutation<AddNoteMutation, AddNoteMutationVariables>(
    AddNoteDocument,
    baseOptions
  )
}
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>
export type AddNoteMutationResult = ApolloReactCommon.MutationResult<AddNoteMutation>
export type AddNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddNoteMutation,
  AddNoteMutationVariables
>
export const ConfirmMyAppointmentsDocument = gql`
  mutation confirmMyAppointments(
    $appointmentScheduleId: ID!
    $programmeSubmissionId: ID!
    $subModule: Boolean!
  ) {
    confirmMyAppointments(
      appointmentScheduleId: $appointmentScheduleId
      programmeSubmissionId: $programmeSubmissionId
      subModule: $subModule
    ) {
      success
      message
    }
  }
`
export type ConfirmMyAppointmentsMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmMyAppointmentsMutation,
  ConfirmMyAppointmentsMutationVariables
>
export type ConfirmMyAppointmentsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables
  >,
  'mutation'
>

export const ConfirmMyAppointmentsComponent = (props: ConfirmMyAppointmentsComponentProps) => (
  <ApolloReactComponents.Mutation<
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables
  >
    mutation={ConfirmMyAppointmentsDocument}
    {...props}
  />
)

export type ConfirmMyAppointmentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables
  >
} &
  TChildProps
export function withConfirmMyAppointments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables,
    ConfirmMyAppointmentsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables,
    ConfirmMyAppointmentsProps<TChildProps, TDataName>
  >(ConfirmMyAppointmentsDocument, {
    alias: 'confirmMyAppointments',
    ...operationOptions
  })
}

/**
 * __useConfirmMyAppointmentsMutation__
 *
 * To run a mutation, you first call `useConfirmMyAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMyAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMyAppointmentsMutation, { data, loading, error }] = useConfirmMyAppointmentsMutation({
 *   variables: {
 *      appointmentScheduleId: // value for 'appointmentScheduleId'
 *      programmeSubmissionId: // value for 'programmeSubmissionId'
 *      subModule: // value for 'subModule'
 *   },
 * });
 */
export function useConfirmMyAppointmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConfirmMyAppointmentsMutation,
    ConfirmMyAppointmentsMutationVariables
  >(ConfirmMyAppointmentsDocument, baseOptions)
}
export type ConfirmMyAppointmentsMutationHookResult = ReturnType<
  typeof useConfirmMyAppointmentsMutation
>
export type ConfirmMyAppointmentsMutationResult = ApolloReactCommon.MutationResult<
  ConfirmMyAppointmentsMutation
>
export type ConfirmMyAppointmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmMyAppointmentsMutation,
  ConfirmMyAppointmentsMutationVariables
>
export const CreateAccountChangeDocument = gql`
  mutation createAccountChange($data: AccountChangeInput!) {
    createAccountChange(data: $data) {
      data {
        id
      }
    }
  }
`
export type CreateAccountChangeMutationFn = ApolloReactCommon.MutationFunction<
  CreateAccountChangeMutation,
  CreateAccountChangeMutationVariables
>
export type CreateAccountChangeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateAccountChangeMutation,
    CreateAccountChangeMutationVariables
  >,
  'mutation'
>

export const CreateAccountChangeComponent = (props: CreateAccountChangeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateAccountChangeMutation, CreateAccountChangeMutationVariables>
    mutation={CreateAccountChangeDocument}
    {...props}
  />
)

export type CreateAccountChangeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateAccountChangeMutation,
    CreateAccountChangeMutationVariables
  >
} &
  TChildProps
export function withCreateAccountChange<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateAccountChangeMutation,
    CreateAccountChangeMutationVariables,
    CreateAccountChangeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateAccountChangeMutation,
    CreateAccountChangeMutationVariables,
    CreateAccountChangeProps<TChildProps, TDataName>
  >(CreateAccountChangeDocument, {
    alias: 'createAccountChange',
    ...operationOptions
  })
}

/**
 * __useCreateAccountChangeMutation__
 *
 * To run a mutation, you first call `useCreateAccountChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountChangeMutation, { data, loading, error }] = useCreateAccountChangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccountChangeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateAccountChangeMutation,
    CreateAccountChangeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateAccountChangeMutation,
    CreateAccountChangeMutationVariables
  >(CreateAccountChangeDocument, baseOptions)
}
export type CreateAccountChangeMutationHookResult = ReturnType<
  typeof useCreateAccountChangeMutation
>
export type CreateAccountChangeMutationResult = ApolloReactCommon.MutationResult<
  CreateAccountChangeMutation
>
export type CreateAccountChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAccountChangeMutation,
  CreateAccountChangeMutationVariables
>
export const CreateBookingDocument = gql`
  mutation createBooking($data: BookingInput!) {
    createBooking(data: $data) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`
export type CreateBookingMutationFn = ApolloReactCommon.MutationFunction<
  CreateBookingMutation,
  CreateBookingMutationVariables
>
export type CreateBookingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >,
  'mutation'
>

export const CreateBookingComponent = (props: CreateBookingComponentProps) => (
  <ApolloReactComponents.Mutation<CreateBookingMutation, CreateBookingMutationVariables>
    mutation={CreateBookingDocument}
    {...props}
  />
)

export type CreateBookingProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >
} &
  TChildProps
export function withCreateBooking<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateBookingMutation,
    CreateBookingMutationVariables,
    CreateBookingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateBookingMutation,
    CreateBookingMutationVariables,
    CreateBookingProps<TChildProps, TDataName>
  >(CreateBookingDocument, {
    alias: 'createBooking',
    ...operationOptions
  })
}

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBookingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
    CreateBookingDocument,
    baseOptions
  )
}
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>
export type CreateBookingMutationResult = ApolloReactCommon.MutationResult<CreateBookingMutation>
export type CreateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBookingMutation,
  CreateBookingMutationVariables
>
export const CreateProgrammeSubmissionDocument = gql`
  mutation createProgrammeSubmission($input: ProgrammeSubmissionInput!) {
    createProgrammeSubmission(data: $input) {
      data {
        id
      }
    }
  }
`
export type CreateProgrammeSubmissionMutationFn = ApolloReactCommon.MutationFunction<
  CreateProgrammeSubmissionMutation,
  CreateProgrammeSubmissionMutationVariables
>
export type CreateProgrammeSubmissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables
  >,
  'mutation'
>

export const CreateProgrammeSubmissionComponent = (
  props: CreateProgrammeSubmissionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables
  >
    mutation={CreateProgrammeSubmissionDocument}
    {...props}
  />
)

export type CreateProgrammeSubmissionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables
  >
} &
  TChildProps
export function withCreateProgrammeSubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables,
    CreateProgrammeSubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables,
    CreateProgrammeSubmissionProps<TChildProps, TDataName>
  >(CreateProgrammeSubmissionDocument, {
    alias: 'createProgrammeSubmission',
    ...operationOptions
  })
}

/**
 * __useCreateProgrammeSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateProgrammeSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgrammeSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgrammeSubmissionMutation, { data, loading, error }] = useCreateProgrammeSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgrammeSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProgrammeSubmissionMutation,
    CreateProgrammeSubmissionMutationVariables
  >(CreateProgrammeSubmissionDocument, baseOptions)
}
export type CreateProgrammeSubmissionMutationHookResult = ReturnType<
  typeof useCreateProgrammeSubmissionMutation
>
export type CreateProgrammeSubmissionMutationResult = ApolloReactCommon.MutationResult<
  CreateProgrammeSubmissionMutation
>
export type CreateProgrammeSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProgrammeSubmissionMutation,
  CreateProgrammeSubmissionMutationVariables
>
export const CreateSessionDocument = gql`
  mutation createSession($input: CoachSessionInput!) {
    createCoachSession(data: $input) {
      data {
        attributes {
          title
        }
      }
    }
  }
`
export type CreateSessionMutationFn = ApolloReactCommon.MutationFunction<
  CreateSessionMutation,
  CreateSessionMutationVariables
>
export type CreateSessionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >,
  'mutation'
>

export const CreateSessionComponent = (props: CreateSessionComponentProps) => (
  <ApolloReactComponents.Mutation<CreateSessionMutation, CreateSessionMutationVariables>
    mutation={CreateSessionDocument}
    {...props}
  />
)

export type CreateSessionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >
} &
  TChildProps
export function withCreateSession<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSessionMutation,
    CreateSessionMutationVariables,
    CreateSessionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSessionMutation,
    CreateSessionMutationVariables,
    CreateSessionProps<TChildProps, TDataName>
  >(CreateSessionDocument, {
    alias: 'createSession',
    ...operationOptions
  })
}

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(
    CreateSessionDocument,
    baseOptions
  )
}
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>
export type CreateSessionMutationResult = ApolloReactCommon.MutationResult<CreateSessionMutation>
export type CreateSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSessionMutation,
  CreateSessionMutationVariables
>
export const DeletedNoteDocument = gql`
  mutation deletedNote($id: ID!) {
    deletedNote(id: $id) {
      note
    }
  }
`
export type DeletedNoteMutationFn = ApolloReactCommon.MutationFunction<
  DeletedNoteMutation,
  DeletedNoteMutationVariables
>
export type DeletedNoteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeletedNoteMutation, DeletedNoteMutationVariables>,
  'mutation'
>

export const DeletedNoteComponent = (props: DeletedNoteComponentProps) => (
  <ApolloReactComponents.Mutation<DeletedNoteMutation, DeletedNoteMutationVariables>
    mutation={DeletedNoteDocument}
    {...props}
  />
)

export type DeletedNoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    DeletedNoteMutation,
    DeletedNoteMutationVariables
  >
} &
  TChildProps
export function withDeletedNote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletedNoteMutation,
    DeletedNoteMutationVariables,
    DeletedNoteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletedNoteMutation,
    DeletedNoteMutationVariables,
    DeletedNoteProps<TChildProps, TDataName>
  >(DeletedNoteDocument, {
    alias: 'deletedNote',
    ...operationOptions
  })
}

/**
 * __useDeletedNoteMutation__
 *
 * To run a mutation, you first call `useDeletedNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletedNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletedNoteMutation, { data, loading, error }] = useDeletedNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletedNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletedNoteMutation,
    DeletedNoteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeletedNoteMutation, DeletedNoteMutationVariables>(
    DeletedNoteDocument,
    baseOptions
  )
}
export type DeletedNoteMutationHookResult = ReturnType<typeof useDeletedNoteMutation>
export type DeletedNoteMutationResult = ApolloReactCommon.MutationResult<DeletedNoteMutation>
export type DeletedNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletedNoteMutation,
  DeletedNoteMutationVariables
>
export const GenerateCompetencyResponsesDocument = gql`
  mutation generateCompetencyResponses($id: ID!) {
    generateCompetencyResponses(id: $id) {
      success
    }
  }
`
export type GenerateCompetencyResponsesMutationFn = ApolloReactCommon.MutationFunction<
  GenerateCompetencyResponsesMutation,
  GenerateCompetencyResponsesMutationVariables
>
export type GenerateCompetencyResponsesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables
  >,
  'mutation'
>

export const GenerateCompetencyResponsesComponent = (
  props: GenerateCompetencyResponsesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables
  >
    mutation={GenerateCompetencyResponsesDocument}
    {...props}
  />
)

export type GenerateCompetencyResponsesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables
  >
} &
  TChildProps
export function withGenerateCompetencyResponses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables,
    GenerateCompetencyResponsesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables,
    GenerateCompetencyResponsesProps<TChildProps, TDataName>
  >(GenerateCompetencyResponsesDocument, {
    alias: 'generateCompetencyResponses',
    ...operationOptions
  })
}

/**
 * __useGenerateCompetencyResponsesMutation__
 *
 * To run a mutation, you first call `useGenerateCompetencyResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCompetencyResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCompetencyResponsesMutation, { data, loading, error }] = useGenerateCompetencyResponsesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateCompetencyResponsesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateCompetencyResponsesMutation,
    GenerateCompetencyResponsesMutationVariables
  >(GenerateCompetencyResponsesDocument, baseOptions)
}
export type GenerateCompetencyResponsesMutationHookResult = ReturnType<
  typeof useGenerateCompetencyResponsesMutation
>
export type GenerateCompetencyResponsesMutationResult = ApolloReactCommon.MutationResult<
  GenerateCompetencyResponsesMutation
>
export type GenerateCompetencyResponsesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateCompetencyResponsesMutation,
  GenerateCompetencyResponsesMutationVariables
>
export const GenerateMyMiniSectionSubmissionResponseDocument = gql`
  mutation generateMyMiniSectionSubmissionResponse($id: ID!, $sectionId: Int, $secId: Int) {
    generateMyMiniSectionSubmissionResponse(id: $id, sectionId: $sectionId, secId: $secId) {
      success
    }
  }
`
export type GenerateMyMiniSectionSubmissionResponseMutationFn = ApolloReactCommon.MutationFunction<
  GenerateMyMiniSectionSubmissionResponseMutation,
  GenerateMyMiniSectionSubmissionResponseMutationVariables
>
export type GenerateMyMiniSectionSubmissionResponseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables
  >,
  'mutation'
>

export const GenerateMyMiniSectionSubmissionResponseComponent = (
  props: GenerateMyMiniSectionSubmissionResponseComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables
  >
    mutation={GenerateMyMiniSectionSubmissionResponseDocument}
    {...props}
  />
)

export type GenerateMyMiniSectionSubmissionResponseProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables
  >
} &
  TChildProps
export function withGenerateMyMiniSectionSubmissionResponse<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables,
    GenerateMyMiniSectionSubmissionResponseProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables,
    GenerateMyMiniSectionSubmissionResponseProps<TChildProps, TDataName>
  >(GenerateMyMiniSectionSubmissionResponseDocument, {
    alias: 'generateMyMiniSectionSubmissionResponse',
    ...operationOptions
  })
}

/**
 * __useGenerateMyMiniSectionSubmissionResponseMutation__
 *
 * To run a mutation, you first call `useGenerateMyMiniSectionSubmissionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMyMiniSectionSubmissionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMyMiniSectionSubmissionResponseMutation, { data, loading, error }] = useGenerateMyMiniSectionSubmissionResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sectionId: // value for 'sectionId'
 *      secId: // value for 'secId'
 *   },
 * });
 */
export function useGenerateMyMiniSectionSubmissionResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateMyMiniSectionSubmissionResponseMutation,
    GenerateMyMiniSectionSubmissionResponseMutationVariables
  >(GenerateMyMiniSectionSubmissionResponseDocument, baseOptions)
}
export type GenerateMyMiniSectionSubmissionResponseMutationHookResult = ReturnType<
  typeof useGenerateMyMiniSectionSubmissionResponseMutation
>
export type GenerateMyMiniSectionSubmissionResponseMutationResult = ApolloReactCommon.MutationResult<
  GenerateMyMiniSectionSubmissionResponseMutation
>
export type GenerateMyMiniSectionSubmissionResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateMyMiniSectionSubmissionResponseMutation,
  GenerateMyMiniSectionSubmissionResponseMutationVariables
>
export const GenerateMyProgrammeSubmissionResponsesDocument = gql`
  mutation generateMyProgrammeSubmissionResponses($id: ID!) {
    generateMyProgrammeSubmissionResponses(id: $id) {
      success
    }
  }
`
export type GenerateMyProgrammeSubmissionResponsesMutationFn = ApolloReactCommon.MutationFunction<
  GenerateMyProgrammeSubmissionResponsesMutation,
  GenerateMyProgrammeSubmissionResponsesMutationVariables
>
export type GenerateMyProgrammeSubmissionResponsesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables
  >,
  'mutation'
>

export const GenerateMyProgrammeSubmissionResponsesComponent = (
  props: GenerateMyProgrammeSubmissionResponsesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables
  >
    mutation={GenerateMyProgrammeSubmissionResponsesDocument}
    {...props}
  />
)

export type GenerateMyProgrammeSubmissionResponsesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables
  >
} &
  TChildProps
export function withGenerateMyProgrammeSubmissionResponses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables,
    GenerateMyProgrammeSubmissionResponsesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables,
    GenerateMyProgrammeSubmissionResponsesProps<TChildProps, TDataName>
  >(GenerateMyProgrammeSubmissionResponsesDocument, {
    alias: 'generateMyProgrammeSubmissionResponses',
    ...operationOptions
  })
}

/**
 * __useGenerateMyProgrammeSubmissionResponsesMutation__
 *
 * To run a mutation, you first call `useGenerateMyProgrammeSubmissionResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMyProgrammeSubmissionResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMyProgrammeSubmissionResponsesMutation, { data, loading, error }] = useGenerateMyProgrammeSubmissionResponsesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateMyProgrammeSubmissionResponsesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateMyProgrammeSubmissionResponsesMutation,
    GenerateMyProgrammeSubmissionResponsesMutationVariables
  >(GenerateMyProgrammeSubmissionResponsesDocument, baseOptions)
}
export type GenerateMyProgrammeSubmissionResponsesMutationHookResult = ReturnType<
  typeof useGenerateMyProgrammeSubmissionResponsesMutation
>
export type GenerateMyProgrammeSubmissionResponsesMutationResult = ApolloReactCommon.MutationResult<
  GenerateMyProgrammeSubmissionResponsesMutation
>
export type GenerateMyProgrammeSubmissionResponsesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateMyProgrammeSubmissionResponsesMutation,
  GenerateMyProgrammeSubmissionResponsesMutationVariables
>
export const GenerateMySubModuleSubmissionComptencyResponsesDocument = gql`
  mutation generateMySubModuleSubmissionComptencyResponses($id: ID!, $sectionId: Int) {
    generateMySubModuleSubmissionComptencyResponses(id: $id, sectionId: $sectionId) {
      success
    }
  }
`
export type GenerateMySubModuleSubmissionComptencyResponsesMutationFn = ApolloReactCommon.MutationFunction<
  GenerateMySubModuleSubmissionComptencyResponsesMutation,
  GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
>
export type GenerateMySubModuleSubmissionComptencyResponsesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
  >,
  'mutation'
>

export const GenerateMySubModuleSubmissionComptencyResponsesComponent = (
  props: GenerateMySubModuleSubmissionComptencyResponsesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
  >
    mutation={GenerateMySubModuleSubmissionComptencyResponsesDocument}
    {...props}
  />
)

export type GenerateMySubModuleSubmissionComptencyResponsesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
  >
} &
  TChildProps
export function withGenerateMySubModuleSubmissionComptencyResponses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables,
    GenerateMySubModuleSubmissionComptencyResponsesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables,
    GenerateMySubModuleSubmissionComptencyResponsesProps<TChildProps, TDataName>
  >(GenerateMySubModuleSubmissionComptencyResponsesDocument, {
    alias: 'generateMySubModuleSubmissionComptencyResponses',
    ...operationOptions
  })
}

/**
 * __useGenerateMySubModuleSubmissionComptencyResponsesMutation__
 *
 * To run a mutation, you first call `useGenerateMySubModuleSubmissionComptencyResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMySubModuleSubmissionComptencyResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMySubModuleSubmissionComptencyResponsesMutation, { data, loading, error }] = useGenerateMySubModuleSubmissionComptencyResponsesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useGenerateMySubModuleSubmissionComptencyResponsesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateMySubModuleSubmissionComptencyResponsesMutation,
    GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
  >(GenerateMySubModuleSubmissionComptencyResponsesDocument, baseOptions)
}
export type GenerateMySubModuleSubmissionComptencyResponsesMutationHookResult = ReturnType<
  typeof useGenerateMySubModuleSubmissionComptencyResponsesMutation
>
export type GenerateMySubModuleSubmissionComptencyResponsesMutationResult = ApolloReactCommon.MutationResult<
  GenerateMySubModuleSubmissionComptencyResponsesMutation
>
export type GenerateMySubModuleSubmissionComptencyResponsesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateMySubModuleSubmissionComptencyResponsesMutation,
  GenerateMySubModuleSubmissionComptencyResponsesMutationVariables
>
export const GenerateMySubModuleSubmissionResponsesDocument = gql`
  mutation generateMySubModuleSubmissionResponses($id: ID!, $sectionId: Int) {
    generateMySubModuleSubmissionResponses(id: $id, sectionId: $sectionId) {
      success
    }
  }
`
export type GenerateMySubModuleSubmissionResponsesMutationFn = ApolloReactCommon.MutationFunction<
  GenerateMySubModuleSubmissionResponsesMutation,
  GenerateMySubModuleSubmissionResponsesMutationVariables
>
export type GenerateMySubModuleSubmissionResponsesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables
  >,
  'mutation'
>

export const GenerateMySubModuleSubmissionResponsesComponent = (
  props: GenerateMySubModuleSubmissionResponsesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables
  >
    mutation={GenerateMySubModuleSubmissionResponsesDocument}
    {...props}
  />
)

export type GenerateMySubModuleSubmissionResponsesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables
  >
} &
  TChildProps
export function withGenerateMySubModuleSubmissionResponses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables,
    GenerateMySubModuleSubmissionResponsesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables,
    GenerateMySubModuleSubmissionResponsesProps<TChildProps, TDataName>
  >(GenerateMySubModuleSubmissionResponsesDocument, {
    alias: 'generateMySubModuleSubmissionResponses',
    ...operationOptions
  })
}

/**
 * __useGenerateMySubModuleSubmissionResponsesMutation__
 *
 * To run a mutation, you first call `useGenerateMySubModuleSubmissionResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMySubModuleSubmissionResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMySubModuleSubmissionResponsesMutation, { data, loading, error }] = useGenerateMySubModuleSubmissionResponsesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useGenerateMySubModuleSubmissionResponsesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateMySubModuleSubmissionResponsesMutation,
    GenerateMySubModuleSubmissionResponsesMutationVariables
  >(GenerateMySubModuleSubmissionResponsesDocument, baseOptions)
}
export type GenerateMySubModuleSubmissionResponsesMutationHookResult = ReturnType<
  typeof useGenerateMySubModuleSubmissionResponsesMutation
>
export type GenerateMySubModuleSubmissionResponsesMutationResult = ApolloReactCommon.MutationResult<
  GenerateMySubModuleSubmissionResponsesMutation
>
export type GenerateMySubModuleSubmissionResponsesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateMySubModuleSubmissionResponsesMutation,
  GenerateMySubModuleSubmissionResponsesMutationVariables
>
export const ResendMailDocument = gql`
  mutation resendMail($id: ID!, $name: String, $email: String) {
    resendMail(id: $id, name: $name, email: $email) {
      success
    }
  }
`
export type ResendMailMutationFn = ApolloReactCommon.MutationFunction<
  ResendMailMutation,
  ResendMailMutationVariables
>
export type ResendMailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ResendMailMutation, ResendMailMutationVariables>,
  'mutation'
>

export const ResendMailComponent = (props: ResendMailComponentProps) => (
  <ApolloReactComponents.Mutation<ResendMailMutation, ResendMailMutationVariables>
    mutation={ResendMailDocument}
    {...props}
  />
)

export type ResendMailProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ResendMailMutation,
    ResendMailMutationVariables
  >
} &
  TChildProps
export function withResendMail<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResendMailMutation,
    ResendMailMutationVariables,
    ResendMailProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResendMailMutation,
    ResendMailMutationVariables,
    ResendMailProps<TChildProps, TDataName>
  >(ResendMailDocument, {
    alias: 'resendMail',
    ...operationOptions
  })
}

/**
 * __useResendMailMutation__
 *
 * To run a mutation, you first call `useResendMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMailMutation, { data, loading, error }] = useResendMailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendMailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendMailMutation,
    ResendMailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ResendMailMutation, ResendMailMutationVariables>(
    ResendMailDocument,
    baseOptions
  )
}
export type ResendMailMutationHookResult = ReturnType<typeof useResendMailMutation>
export type ResendMailMutationResult = ApolloReactCommon.MutationResult<ResendMailMutation>
export type ResendMailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendMailMutation,
  ResendMailMutationVariables
>
export const ResendProgrammeMailDocument = gql`
  mutation resendProgrammeMail($id: ID!, $name: String, $email: String) {
    resendProgrammeMail(id: $id, name: $name, email: $email) {
      successed
    }
  }
`
export type ResendProgrammeMailMutationFn = ApolloReactCommon.MutationFunction<
  ResendProgrammeMailMutation,
  ResendProgrammeMailMutationVariables
>
export type ResendProgrammeMailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResendProgrammeMailMutation,
    ResendProgrammeMailMutationVariables
  >,
  'mutation'
>

export const ResendProgrammeMailComponent = (props: ResendProgrammeMailComponentProps) => (
  <ApolloReactComponents.Mutation<ResendProgrammeMailMutation, ResendProgrammeMailMutationVariables>
    mutation={ResendProgrammeMailDocument}
    {...props}
  />
)

export type ResendProgrammeMailProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ResendProgrammeMailMutation,
    ResendProgrammeMailMutationVariables
  >
} &
  TChildProps
export function withResendProgrammeMail<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResendProgrammeMailMutation,
    ResendProgrammeMailMutationVariables,
    ResendProgrammeMailProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResendProgrammeMailMutation,
    ResendProgrammeMailMutationVariables,
    ResendProgrammeMailProps<TChildProps, TDataName>
  >(ResendProgrammeMailDocument, {
    alias: 'resendProgrammeMail',
    ...operationOptions
  })
}

/**
 * __useResendProgrammeMailMutation__
 *
 * To run a mutation, you first call `useResendProgrammeMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendProgrammeMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendProgrammeMailMutation, { data, loading, error }] = useResendProgrammeMailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendProgrammeMailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendProgrammeMailMutation,
    ResendProgrammeMailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResendProgrammeMailMutation,
    ResendProgrammeMailMutationVariables
  >(ResendProgrammeMailDocument, baseOptions)
}
export type ResendProgrammeMailMutationHookResult = ReturnType<
  typeof useResendProgrammeMailMutation
>
export type ResendProgrammeMailMutationResult = ApolloReactCommon.MutationResult<
  ResendProgrammeMailMutation
>
export type ResendProgrammeMailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendProgrammeMailMutation,
  ResendProgrammeMailMutationVariables
>
export const UpdateMySubModuleSubmissionDocument = gql`
  mutation updateMySubModuleSubmission(
    $id: ID!
    $currentSection: Int
    $isPdf: Boolean
    $name: String
    $programmeSubmissionId: ID
    $input: editSubModuleSubmissionInput!
  ) {
    updateMySubModuleSubmission(
      id: $id
      isPdf: $isPdf
      name: $name
      currentSection: $currentSection
      programmeSubmissionId: $programmeSubmissionId
      input: $input
    ) {
      id
      view
      progress
      status
      time_spent
      language {
        data {
          id
          attributes {
            name
          }
        }
      }
      participants {
        data {
          id
          attributes {
            name
            email
            type
          }
        }
      }
      submission_participants {
        id
        name
        email
      }
      sub_module {
        data {
          id
          attributes {
            name_french
            name_german
            name_english
            name_spanish
            name_mandarin
            description_french
            description_german
            description_english
            description_spanish
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
            programme_type {
              data {
                id
              }
            }
          }
        }
      }
      section {
        id
        progress
        section {
          data {
            id
          }
        }
        answers {
          id
          questionId
          answer
          nestedAnswers {
            answer
            questionTitle
          }
          isCorrect
          feedback
          outcome
        }
      }
    }
  }
`
export type UpdateMySubModuleSubmissionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMySubModuleSubmissionMutation,
  UpdateMySubModuleSubmissionMutationVariables
>
export type UpdateMySubModuleSubmissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables
  >,
  'mutation'
>

export const UpdateMySubModuleSubmissionComponent = (
  props: UpdateMySubModuleSubmissionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables
  >
    mutation={UpdateMySubModuleSubmissionDocument}
    {...props}
  />
)

export type UpdateMySubModuleSubmissionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables
  >
} &
  TChildProps
export function withUpdateMySubModuleSubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables,
    UpdateMySubModuleSubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables,
    UpdateMySubModuleSubmissionProps<TChildProps, TDataName>
  >(UpdateMySubModuleSubmissionDocument, {
    alias: 'updateMySubModuleSubmission',
    ...operationOptions
  })
}

/**
 * __useUpdateMySubModuleSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateMySubModuleSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMySubModuleSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMySubModuleSubmissionMutation, { data, loading, error }] = useUpdateMySubModuleSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentSection: // value for 'currentSection'
 *      isPdf: // value for 'isPdf'
 *      name: // value for 'name'
 *      programmeSubmissionId: // value for 'programmeSubmissionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMySubModuleSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMySubModuleSubmissionMutation,
    UpdateMySubModuleSubmissionMutationVariables
  >(UpdateMySubModuleSubmissionDocument, baseOptions)
}
export type UpdateMySubModuleSubmissionMutationHookResult = ReturnType<
  typeof useUpdateMySubModuleSubmissionMutation
>
export type UpdateMySubModuleSubmissionMutationResult = ApolloReactCommon.MutationResult<
  UpdateMySubModuleSubmissionMutation
>
export type UpdateMySubModuleSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMySubModuleSubmissionMutation,
  UpdateMySubModuleSubmissionMutationVariables
>
export const UpdateNotificationDocument = gql`
  mutation updateNotification($id: ID!, $data: NotificationInput!) {
    updateNotification(id: $id, data: $data) {
      data {
        id
        attributes {
          title
          time_stamp
          path
          read
        }
      }
    }
  }
`
export type UpdateNotificationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>
export type UpdateNotificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >,
  'mutation'
>

export const UpdateNotificationComponent = (props: UpdateNotificationComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>
    mutation={UpdateNotificationDocument}
    {...props}
  />
)

export type UpdateNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >
} &
  TChildProps
export function withUpdateNotification<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables,
    UpdateNotificationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables,
    UpdateNotificationProps<TChildProps, TDataName>
  >(UpdateNotificationDocument, {
    alias: 'updateNotification',
    ...operationOptions
  })
}

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, baseOptions)
}
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>
export type UpdateNotificationMutationResult = ApolloReactCommon.MutationResult<
  UpdateNotificationMutation
>
export type UpdateNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>
export const UpdateMyProgrammeSubmissionDocument = gql`
  mutation updateMyProgrammeSubmission(
    $id: ID!
    $input: editProgrammeSubmissionInput!
    $isPdf: Boolean
    $name: String
  ) {
    updateMyProgrammeSubmissions(id: $id, isPdf: $isPdf, name: $name, input: $input) {
      id
      status
      progress
      programme_type {
        data {
          id
          attributes {
            title
            description
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
            is_coaching
            notes
            deadline
            programme_questions {
              __typename
              ... on ComponentQuestionTypesValuesNested {
                id
                title
                question
                questions {
                  id
                  titleEnglish
                  subTitleEnglish
                  titleFrench
                  subTitleFrench
                  titleGerman
                  subTitleGerman
                  titleSpanish
                  subTitleSpanish
                  titleMandarin
                  subTitleMandarin
                }
              }
            }
            articles {
              data {
                id
                attributes {
                  createdAt
                  updatedAt
                  title_english
                  title_french
                  title_german
                  title_spanish
                  title_mandarin
                  content_english
                  content_french
                  content_german
                  content_spanish
                  content_mandarin
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  date_published
                  read_time
                  link
                }
              }
            }
            sub_modules {
              data {
                id
                attributes {
                  name_english
                  description_english
                  cover {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  programme_type {
                    data {
                      id
                    }
                  }
                }
              }
            }
            archive
          }
        }
      }
      programme_responses {
        answers {
          id
          questionId
          answer
          nestedAnswers {
            answer
            questionTitle
          }
          isCorrect
          feedback
          outcome
          questionType
          questionTitle
        }
        stakeholderChart {
          data {
            id
            attributes {
              url
            }
          }
        }
        saboteur {
          id
        }
        stakeholderItems {
          id
          valueX
          valueY
          name
          color
        }
        gravitasScales {
          id
          labelOne
          labelTwo
          labelThree
          question
          answerOne
          answerTwo
          answerThree
        }
        xYGraphChart {
          data {
            id
            attributes {
              url
            }
          }
        }
        saboteurImage {
          data {
            id
            attributes {
              url
            }
          }
        }
        xYGraphItems {
          id
          valueX
          valueY
          color
        }
      }
      my_notes {
        id
        title
        description
        document {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
      selected
    }
  }
`
export type UpdateMyProgrammeSubmissionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMyProgrammeSubmissionMutation,
  UpdateMyProgrammeSubmissionMutationVariables
>
export type UpdateMyProgrammeSubmissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables
  >,
  'mutation'
>

export const UpdateMyProgrammeSubmissionComponent = (
  props: UpdateMyProgrammeSubmissionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables
  >
    mutation={UpdateMyProgrammeSubmissionDocument}
    {...props}
  />
)

export type UpdateMyProgrammeSubmissionProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables
  >
} &
  TChildProps
export function withUpdateMyProgrammeSubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables,
    UpdateMyProgrammeSubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables,
    UpdateMyProgrammeSubmissionProps<TChildProps, TDataName>
  >(UpdateMyProgrammeSubmissionDocument, {
    alias: 'updateMyProgrammeSubmission',
    ...operationOptions
  })
}

/**
 * __useUpdateMyProgrammeSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateMyProgrammeSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProgrammeSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProgrammeSubmissionMutation, { data, loading, error }] = useUpdateMyProgrammeSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      isPdf: // value for 'isPdf'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateMyProgrammeSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMyProgrammeSubmissionMutation,
    UpdateMyProgrammeSubmissionMutationVariables
  >(UpdateMyProgrammeSubmissionDocument, baseOptions)
}
export type UpdateMyProgrammeSubmissionMutationHookResult = ReturnType<
  typeof useUpdateMyProgrammeSubmissionMutation
>
export type UpdateMyProgrammeSubmissionMutationResult = ApolloReactCommon.MutationResult<
  UpdateMyProgrammeSubmissionMutation
>
export type UpdateMyProgrammeSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMyProgrammeSubmissionMutation,
  UpdateMyProgrammeSubmissionMutationVariables
>
export const UpdateSessionDocument = gql`
  mutation updateSession($id: ID!, $data: CoachSessionInput!) {
    updateCoachSession(id: $id, data: $data) {
      data {
        id
        attributes {
          title
          status
          user {
            data {
              id
            }
          }
        }
      }
    }
  }
`
export type UpdateSessionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSessionMutation,
  UpdateSessionMutationVariables
>
export type UpdateSessionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >,
  'mutation'
>

export const UpdateSessionComponent = (props: UpdateSessionComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSessionMutation, UpdateSessionMutationVariables>
    mutation={UpdateSessionDocument}
    {...props}
  />
)

export type UpdateSessionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >
} &
  TChildProps
export function withUpdateSession<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSessionMutation,
    UpdateSessionMutationVariables,
    UpdateSessionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSessionMutation,
    UpdateSessionMutationVariables,
    UpdateSessionProps<TChildProps, TDataName>
  >(UpdateSessionDocument, {
    alias: 'updateSession',
    ...operationOptions
  })
}

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSessionMutation,
    UpdateSessionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(
    UpdateSessionDocument,
    baseOptions
  )
}
export type UpdateSessionMutationHookResult = ReturnType<typeof useUpdateSessionMutation>
export type UpdateSessionMutationResult = ApolloReactCommon.MutationResult<UpdateSessionMutation>
export type UpdateSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSessionMutation,
  UpdateSessionMutationVariables
>
export const UpdateMySubmissionDocument = gql`
  mutation updateMySubmission(
    $id: ID!
    $currentSection: Int
    $programmeSubmissionId: ID
    $input: editSubmissionInput!
  ) {
    updateMySubmission(
      id: $id
      currentSection: $currentSection
      programmeSubmissionId: $programmeSubmissionId
      input: $input
    ) {
      id
      views
      progress
      status
      time_spent
      language {
        data {
          id
          attributes {
            name
          }
        }
      }
      module {
        data {
          id
          attributes {
            name_french
            name_german
            name_english
            name_spanish
            name_mandarin
            description_french
            description_german
            description_english
            description_spanish
            description_mandarin
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
      section {
        id
        progress
        section {
          data {
            id
          }
        }
        answers {
          id
          questionId
          answer
          nestedAnswers {
            answer
            questionTitle
          }
          isCorrect
          feedback
          outcome
        }
      }
    }
  }
`
export type UpdateMySubmissionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMySubmissionMutation,
  UpdateMySubmissionMutationVariables
>
export type UpdateMySubmissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMySubmissionMutation,
    UpdateMySubmissionMutationVariables
  >,
  'mutation'
>

export const UpdateMySubmissionComponent = (props: UpdateMySubmissionComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMySubmissionMutation, UpdateMySubmissionMutationVariables>
    mutation={UpdateMySubmissionDocument}
    {...props}
  />
)

export type UpdateMySubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateMySubmissionMutation,
    UpdateMySubmissionMutationVariables
  >
} &
  TChildProps
export function withUpdateMySubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMySubmissionMutation,
    UpdateMySubmissionMutationVariables,
    UpdateMySubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMySubmissionMutation,
    UpdateMySubmissionMutationVariables,
    UpdateMySubmissionProps<TChildProps, TDataName>
  >(UpdateMySubmissionDocument, {
    alias: 'updateMySubmission',
    ...operationOptions
  })
}

/**
 * __useUpdateMySubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateMySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMySubmissionMutation, { data, loading, error }] = useUpdateMySubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentSection: // value for 'currentSection'
 *      programmeSubmissionId: // value for 'programmeSubmissionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMySubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMySubmissionMutation,
    UpdateMySubmissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMySubmissionMutation,
    UpdateMySubmissionMutationVariables
  >(UpdateMySubmissionDocument, baseOptions)
}
export type UpdateMySubmissionMutationHookResult = ReturnType<typeof useUpdateMySubmissionMutation>
export type UpdateMySubmissionMutationResult = ApolloReactCommon.MutationResult<
  UpdateMySubmissionMutation
>
export type UpdateMySubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMySubmissionMutation,
  UpdateMySubmissionMutationVariables
>
export const UpdateSelfDocument = gql`
  mutation updateSelf($input: editUserInput!) {
    updateSelf(user: $input) {
      id
      createdAt
      updatedAt
      email
      confirmed
      blocked
      phoneNumber
      onboarding
      profilePicture {
        data {
          id
          attributes {
            url
          }
        }
      }
      language {
        data {
          id
          attributes {
            name
          }
        }
      }
      firstName
      lastName
      jobTitle
      division {
        data {
          id
          attributes {
            title_french
            title_german
            title_english
            title_mandarin
            title_spanish
          }
        }
      }
      modulesRead
      homePromptsRead
      programmePromptsRead
      modulePromptsRead
      bookmarks
      articlesViewed
      report {
        data {
          id
          attributes {
            url
          }
        }
      }
      company {
        data {
          id
          attributes {
            name
            logo {
              data {
                id
                attributes {
                  url
                }
              }
            }
            language_selection {
              id
              titleEnglish
              bodyEnglish
              nextButtonEnglish
              titleFrench
              bodyFrench
              nextButtonFrench
              titleGerman
              bodyGerman
              nextButtonGerman
              titleMandarin
              bodyMandarin
              nextButtonMandarin
              titleSpanish
              bodySpanish
              nextButtonSpanish
            }
            welcome_screen {
              id
              titleEnglish
              bodyEnglish
              videoEnglish {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              nextButtonEnglish
              titleFrench
              bodyFrench
              videoFrench {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              nextButtonFrench
              titleGerman
              bodyGerman
              videoGerman {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              nextButtonGerman
              titleMandarin
              bodyMandarin
              videoMandarin {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              nextButtonMandarin
              titleSpanish
              bodySpanish
              videoSpanish {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              nextButtonSpanish
            }
            lets_get_started_screen {
              id
              titleEnglish
              subTitleEnglish
              firstNameLabelEnglish
              firstNameErrorEnglish
              lastNameLabelEnglish
              lastNameErrorEnglish
              jobTitleLabelEnglish
              jobTitleErrorEnglish
              divisionLabelEnglish
              divisionErrorEnglish
              timezoneLabelEnglish
              regionLabelEnglish
              regionErrorEnglish
              image {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              nextButtonEnglish
              titleFrench
              subTitleFrench
              firstNameLabelFrench
              firstNameErrorFrench
              lastNameLabelFrench
              lastNameErrorFrench
              jobTitleLabelFrench
              jobTitleErrorFrench
              divisionLabelFrench
              divisionErrorFrench
              timezoneLabelFrench
              regionLabelFrench
              regionErrorFrench
              nextButtonFrench
              titleGerman
              subTitleGerman
              firstNameLabelGerman
              firstNameErrorGerman
              lastNameLabelGerman
              lastNameErrorGerman
              jobTitleLabelGerman
              jobTitleErrorGerman
              divisionLabelGerman
              divisionErrorGerman
              timezoneLabelGerman
              regionLabelGerman
              regionErrorGerman
              nextButtonGerman
              titleMandarin
              subTitleMandarin
              firstNameLabelMandarin
              firstNameErrorMandarin
              lastNameLabelMandarin
              lastNameErrorMandarin
              jobTitleLabelMandarin
              jobTitleErrorMandarin
              divisionLabelMandarin
              divisionErrorMandarin
              timezoneLabelMandarin
              regionLabelMandarin
              regionErrorMandarin
              nextButtonMandarin
              titleSpanish
              subTitleSpanish
              firstNameLabelSpanish
              firstNameErrorSpanish
              lastNameLabelSpanish
              lastNameErrorSpanish
              jobTitleLabelSpanish
              jobTitleErrorSpanish
              divisionLabelSpanish
              divisionErrorSpanish
              timezoneLabelSpanish
              regionLabelSpanish
              regionErrorSpanish
              nextButtonSpanish
              leftTitleEnglish
              leftSubtitleEnglish
              leftTitleSpanish
              leftSubtitleSpanish
              leftTitleMandarin
              leftSubTitleMandarin
              leftTitleFrench
              leftSubtitleFrench
              leftSubtitleGerman
              leftTitleGerman
            }
            what_to_expect {
              id
              titleEnglish
              bodyEnglish
              image {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              getStartedButtonEnglish
              titleFrench
              bodyFrench
              getStartedButtonFrench
              titleGerman
              bodyGerman
              getStartedButtonGerman
              titleMandarin
              bodyMandarin
              getStartedButtonMandarin
              titleSpanish
              bodySpanish
              getStartedButtonSpanish
            }
            about_us {
              id
              textEnglish
              textFrench
              textGerman
              userGuidelinesTextEnglish
              userGuidelinesTextGerman
              userGuidelinesTextFrench
              userGuidelinesEnglish {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              userGuidelinesGerman {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              userGuidelinesFrench {
                data {
                  attributes {
                    id
                    url
                  }
                }
              }
            }
            programmes_title
            programmes_subTitle
            users_confirm_details
          }
        }
      }
      submissions {
        data {
          id
        }
      }
      programmes {
        data {
          id
          attributes {
            name
            description
            start
            is_custom_journey
            position
            logo {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
      updatedDetails
      region {
        data {
          id
          attributes {
            name_english
            name_french
            name_german
            name_spanish
            name_mandarin
          }
        }
      }
      timezone {
        abbrev
        altName
        label
        value
      }
    }
  }
`
export type UpdateSelfMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSelfMutation,
  UpdateSelfMutationVariables
>
export type UpdateSelfComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateSelfMutation, UpdateSelfMutationVariables>,
  'mutation'
>

export const UpdateSelfComponent = (props: UpdateSelfComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSelfMutation, UpdateSelfMutationVariables>
    mutation={UpdateSelfDocument}
    {...props}
  />
)

export type UpdateSelfProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateSelfMutation,
    UpdateSelfMutationVariables
  >
} &
  TChildProps
export function withUpdateSelf<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSelfMutation,
    UpdateSelfMutationVariables,
    UpdateSelfProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSelfMutation,
    UpdateSelfMutationVariables,
    UpdateSelfProps<TChildProps, TDataName>
  >(UpdateSelfDocument, {
    alias: 'updateSelf',
    ...operationOptions
  })
}

/**
 * __useUpdateSelfMutation__
 *
 * To run a mutation, you first call `useUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfMutation, { data, loading, error }] = useUpdateSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSelfMutation,
    UpdateSelfMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSelfMutation, UpdateSelfMutationVariables>(
    UpdateSelfDocument,
    baseOptions
  )
}
export type UpdateSelfMutationHookResult = ReturnType<typeof useUpdateSelfMutation>
export type UpdateSelfMutationResult = ApolloReactCommon.MutationResult<UpdateSelfMutation>
export type UpdateSelfMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSelfMutation,
  UpdateSelfMutationVariables
>
export const CheckResponsesFromTypeFormDocument = gql`
  query checkResponsesFromTypeForm($id: ID!, $sectionid: Int) {
    checkResponsesFromTypeForm(id: $id, sectionid: $sectionid) {
      emails
    }
  }
`
export type CheckResponsesFromTypeFormComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >,
  'query'
> &
  ({ variables: CheckResponsesFromTypeFormQueryVariables; skip?: boolean } | { skip: boolean })

export const CheckResponsesFromTypeFormComponent = (
  props: CheckResponsesFromTypeFormComponentProps
) => (
  <ApolloReactComponents.Query<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >
    query={CheckResponsesFromTypeFormDocument}
    {...props}
  />
)

export type CheckResponsesFromTypeFormProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >
} &
  TChildProps
export function withCheckResponsesFromTypeForm<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables,
    CheckResponsesFromTypeFormProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables,
    CheckResponsesFromTypeFormProps<TChildProps, TDataName>
  >(CheckResponsesFromTypeFormDocument, {
    alias: 'checkResponsesFromTypeForm',
    ...operationOptions
  })
}

/**
 * __useCheckResponsesFromTypeFormQuery__
 *
 * To run a query within a React component, call `useCheckResponsesFromTypeFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckResponsesFromTypeFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckResponsesFromTypeFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sectionid: // value for 'sectionid'
 *   },
 * });
 */
export function useCheckResponsesFromTypeFormQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >(CheckResponsesFromTypeFormDocument, baseOptions)
}
export function useCheckResponsesFromTypeFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CheckResponsesFromTypeFormQuery,
    CheckResponsesFromTypeFormQueryVariables
  >(CheckResponsesFromTypeFormDocument, baseOptions)
}
export type CheckResponsesFromTypeFormQueryHookResult = ReturnType<
  typeof useCheckResponsesFromTypeFormQuery
>
export type CheckResponsesFromTypeFormLazyQueryHookResult = ReturnType<
  typeof useCheckResponsesFromTypeFormLazyQuery
>
export type CheckResponsesFromTypeFormQueryResult = ApolloReactCommon.QueryResult<
  CheckResponsesFromTypeFormQuery,
  CheckResponsesFromTypeFormQueryVariables
>
export const CheckResponsesFromTypeFormProgrammeDocument = gql`
  query checkResponsesFromTypeFormProgramme($id: ID!, $sectionid: Int) {
    checkResponsesFromTypeFormProgramme(id: $id, sectionid: $sectionid) {
      emails
    }
  }
`
export type CheckResponsesFromTypeFormProgrammeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >,
  'query'
> &
  (
    | { variables: CheckResponsesFromTypeFormProgrammeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CheckResponsesFromTypeFormProgrammeComponent = (
  props: CheckResponsesFromTypeFormProgrammeComponentProps
) => (
  <ApolloReactComponents.Query<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >
    query={CheckResponsesFromTypeFormProgrammeDocument}
    {...props}
  />
)

export type CheckResponsesFromTypeFormProgrammeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >
} &
  TChildProps
export function withCheckResponsesFromTypeFormProgramme<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables,
    CheckResponsesFromTypeFormProgrammeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables,
    CheckResponsesFromTypeFormProgrammeProps<TChildProps, TDataName>
  >(CheckResponsesFromTypeFormProgrammeDocument, {
    alias: 'checkResponsesFromTypeFormProgramme',
    ...operationOptions
  })
}

/**
 * __useCheckResponsesFromTypeFormProgrammeQuery__
 *
 * To run a query within a React component, call `useCheckResponsesFromTypeFormProgrammeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckResponsesFromTypeFormProgrammeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckResponsesFromTypeFormProgrammeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sectionid: // value for 'sectionid'
 *   },
 * });
 */
export function useCheckResponsesFromTypeFormProgrammeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >(CheckResponsesFromTypeFormProgrammeDocument, baseOptions)
}
export function useCheckResponsesFromTypeFormProgrammeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CheckResponsesFromTypeFormProgrammeQuery,
    CheckResponsesFromTypeFormProgrammeQueryVariables
  >(CheckResponsesFromTypeFormProgrammeDocument, baseOptions)
}
export type CheckResponsesFromTypeFormProgrammeQueryHookResult = ReturnType<
  typeof useCheckResponsesFromTypeFormProgrammeQuery
>
export type CheckResponsesFromTypeFormProgrammeLazyQueryHookResult = ReturnType<
  typeof useCheckResponsesFromTypeFormProgrammeLazyQuery
>
export type CheckResponsesFromTypeFormProgrammeQueryResult = ApolloReactCommon.QueryResult<
  CheckResponsesFromTypeFormProgrammeQuery,
  CheckResponsesFromTypeFormProgrammeQueryVariables
>

/**
 * __useConfirmMyAppointmentsMutation__
 *
 * To run a mutation, you first call `useConfirmMyAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMyAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMyAppointmentsMutation, { data, loading, error }] = useConfirmMyAppointmentsMutation({
 *   variables: {
 *      appointmentScheduleId: // value for 'appointmentScheduleId'
 *      programmeSubmissionId: // value for 'programmeSubmissionId'
 *      subModule: // value for 'subModule'
 *   },
 * });
 */

export const GetAllsectionReportDocument = gql`
  query getAllsectionReport($id: ID!) {
    getAllsectionReport(id: $id) {
      id
      name
      type
      report {
        data {
          id
          attributes {
            url
          }
        }
      }
      section_or_module_id
    }
  }
`
export type GetAllsectionReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAllsectionReportQuery,
    GetAllsectionReportQueryVariables
  >,
  'query'
> &
  ({ variables: GetAllsectionReportQueryVariables; skip?: boolean } | { skip: boolean })

export const GetAllsectionReportComponent = (props: GetAllsectionReportComponentProps) => (
  <ApolloReactComponents.Query<GetAllsectionReportQuery, GetAllsectionReportQueryVariables>
    query={GetAllsectionReportDocument}
    {...props}
  />
)

export type GetAllsectionReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllsectionReportQuery,
    GetAllsectionReportQueryVariables
  >
} &
  TChildProps
export function withGetAllsectionReport<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllsectionReportQuery,
    GetAllsectionReportQueryVariables,
    GetAllsectionReportProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllsectionReportQuery,
    GetAllsectionReportQueryVariables,
    GetAllsectionReportProps<TChildProps, TDataName>
  >(GetAllsectionReportDocument, {
    alias: 'getAllsectionReport',
    ...operationOptions
  })
}

/**
 * __useGetAllsectionReportQuery__
 *
 * To run a query within a React component, call `useGetAllsectionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllsectionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllsectionReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllsectionReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllsectionReportQuery,
    GetAllsectionReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllsectionReportQuery, GetAllsectionReportQueryVariables>(
    GetAllsectionReportDocument,
    baseOptions
  )
}
export function useGetAllsectionReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllsectionReportQuery,
    GetAllsectionReportQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllsectionReportQuery, GetAllsectionReportQueryVariables>(
    GetAllsectionReportDocument,
    baseOptions
  )
}
export type GetAllsectionReportQueryHookResult = ReturnType<typeof useGetAllsectionReportQuery>
export type GetAllsectionReportLazyQueryHookResult = ReturnType<
  typeof useGetAllsectionReportLazyQuery
>
export type GetAllsectionReportQueryResult = ApolloReactCommon.QueryResult<
  GetAllsectionReportQuery,
  GetAllsectionReportQueryVariables
>
export const GetArticlesDocument = gql`
  query getArticles($where: ArticleFiltersInput, $start: Int, $limit: Int!) {
    articles(filters: $where, pagination: { start: $start, limit: $limit }) {
      data {
        id
        attributes {
          createdAt
          updatedAt
          title_english
          title_french
          title_german
          title_spanish
          title_mandarin
          content_english
          content_french
          content_german
          content_spanish
          content_mandarin
          position
          companies {
            data {
              id
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
          date_published
          read_time
          link
        }
      }
    }
  }
`
export type GetArticlesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetArticlesQuery, GetArticlesQueryVariables>,
  'query'
> &
  ({ variables: GetArticlesQueryVariables; skip?: boolean } | { skip: boolean })

export const GetArticlesComponent = (props: GetArticlesComponentProps) => (
  <ApolloReactComponents.Query<GetArticlesQuery, GetArticlesQueryVariables>
    query={GetArticlesDocument}
    {...props}
  />
)

export type GetArticlesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetArticlesQuery, GetArticlesQueryVariables>
} &
  TChildProps
export function withGetArticles<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetArticlesQuery,
    GetArticlesQueryVariables,
    GetArticlesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetArticlesQuery,
    GetArticlesQueryVariables,
    GetArticlesProps<TChildProps, TDataName>
  >(GetArticlesDocument, {
    alias: 'getArticles',
    ...operationOptions
  })
}

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetArticlesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    baseOptions
  )
}
export function useGetArticlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    baseOptions
  )
}
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>
export type GetArticlesQueryResult = ApolloReactCommon.QueryResult<
  GetArticlesQuery,
  GetArticlesQueryVariables
>
export const GetCoachSubmittedReportsDocument = gql`
  query getCoachSubmittedReports(
    $where: CoachSubmittedReportFiltersInput!
    $sort: [String]
    $limit: Int!
  ) {
    coachSubmittedReports(filters: $where, sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          name
          notes_id {
            name
            saboteurProofValue {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
export type GetCoachSubmittedReportsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >,
  'query'
> &
  ({ variables: GetCoachSubmittedReportsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetCoachSubmittedReportsComponent = (
  props: GetCoachSubmittedReportsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >
    query={GetCoachSubmittedReportsDocument}
    {...props}
  />
)

export type GetCoachSubmittedReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >
} &
  TChildProps
export function withGetCoachSubmittedReports<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables,
    GetCoachSubmittedReportsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables,
    GetCoachSubmittedReportsProps<TChildProps, TDataName>
  >(GetCoachSubmittedReportsDocument, {
    alias: 'getCoachSubmittedReports',
    ...operationOptions
  })
}

/**
 * __useGetCoachSubmittedReportsQuery__
 *
 * To run a query within a React component, call `useGetCoachSubmittedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachSubmittedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachSubmittedReportsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCoachSubmittedReportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >(GetCoachSubmittedReportsDocument, baseOptions)
}
export function useGetCoachSubmittedReportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCoachSubmittedReportsQuery,
    GetCoachSubmittedReportsQueryVariables
  >(GetCoachSubmittedReportsDocument, baseOptions)
}
export type GetCoachSubmittedReportsQueryHookResult = ReturnType<
  typeof useGetCoachSubmittedReportsQuery
>
export type GetCoachSubmittedReportsLazyQueryHookResult = ReturnType<
  typeof useGetCoachSubmittedReportsLazyQuery
>
export type GetCoachSubmittedReportsQueryResult = ApolloReactCommon.QueryResult<
  GetCoachSubmittedReportsQuery,
  GetCoachSubmittedReportsQueryVariables
>
export const GetCompanyDocument = gql`
  query getCompany($id: ID!) {
    company(id: $id) {
      data {
        attributes {
          programmes {
            data {
              id
              attributes {
                name
                description
                start
                programme_types {
                  data {
                    id
                    attributes {
                      programme_submissions {
                        data {
                          id
                          attributes {
                            user {
                              data {
                                id
                              }
                            }
                            programme {
                              data {
                                id
                                attributes {
                                  is_custom_journey
                                  show_timeline
                                  logo {
                                    data {
                                      id
                                      attributes {
                                        url
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                is_custom_journey
                show_timeline
                position
              }
            }
          }
        }
      }
    }
  }
`
export type GetCompanyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetCompanyQuery, GetCompanyQueryVariables>,
  'query'
> &
  ({ variables: GetCompanyQueryVariables; skip?: boolean } | { skip: boolean })

export const GetCompanyComponent = (props: GetCompanyComponentProps) => (
  <ApolloReactComponents.Query<GetCompanyQuery, GetCompanyQueryVariables>
    query={GetCompanyDocument}
    {...props}
  />
)

export type GetCompanyProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetCompanyQuery, GetCompanyQueryVariables>
} &
  TChildProps
export function withGetCompany<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCompanyQuery,
    GetCompanyQueryVariables,
    GetCompanyProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCompanyQuery,
    GetCompanyQueryVariables,
    GetCompanyProps<TChildProps, TDataName>
  >(GetCompanyDocument, {
    alias: 'getCompany',
    ...operationOptions
  })
}

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    baseOptions
  )
}
export function useGetCompanyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    baseOptions
  )
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyQuery,
  GetCompanyQueryVariables
>
export const GetCompanyDivisionsDocument = gql`
  query getCompanyDivisions($where: DivisionFiltersInput) {
    divisions(filters: $where) {
      data {
        id
        attributes {
          title_english
          title_french
          title_german
          title_spanish
          title_mandarin
          createdAt
          updatedAt
        }
      }
    }
  }
`
export type GetCompanyDivisionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCompanyDivisionsQuery,
    GetCompanyDivisionsQueryVariables
  >,
  'query'
>

export const GetCompanyDivisionsComponent = (props: GetCompanyDivisionsComponentProps) => (
  <ApolloReactComponents.Query<GetCompanyDivisionsQuery, GetCompanyDivisionsQueryVariables>
    query={GetCompanyDivisionsDocument}
    {...props}
  />
)

export type GetCompanyDivisionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCompanyDivisionsQuery,
    GetCompanyDivisionsQueryVariables
  >
} &
  TChildProps
export function withGetCompanyDivisions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCompanyDivisionsQuery,
    GetCompanyDivisionsQueryVariables,
    GetCompanyDivisionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCompanyDivisionsQuery,
    GetCompanyDivisionsQueryVariables,
    GetCompanyDivisionsProps<TChildProps, TDataName>
  >(GetCompanyDivisionsDocument, {
    alias: 'getCompanyDivisions',
    ...operationOptions
  })
}

/**
 * __useGetCompanyDivisionsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDivisionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompanyDivisionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyDivisionsQuery,
    GetCompanyDivisionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCompanyDivisionsQuery, GetCompanyDivisionsQueryVariables>(
    GetCompanyDivisionsDocument,
    baseOptions
  )
}
export function useGetCompanyDivisionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyDivisionsQuery,
    GetCompanyDivisionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCompanyDivisionsQuery, GetCompanyDivisionsQueryVariables>(
    GetCompanyDivisionsDocument,
    baseOptions
  )
}
export type GetCompanyDivisionsQueryHookResult = ReturnType<typeof useGetCompanyDivisionsQuery>
export type GetCompanyDivisionsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDivisionsLazyQuery
>
export type GetCompanyDivisionsQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyDivisionsQuery,
  GetCompanyDivisionsQueryVariables
>
export const GetCompanyRegionsDocument = gql`
  query getCompanyRegions($where: RegionFiltersInput) {
    regions(filters: $where) {
      data {
        id
        attributes {
          name_english
          name_french
          name_german
          name_spanish
          name_mandarin
          createdAt
          updatedAt
        }
      }
    }
  }
`
export type GetCompanyRegionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCompanyRegionsQuery,
    GetCompanyRegionsQueryVariables
  >,
  'query'
>

export const GetCompanyRegionsComponent = (props: GetCompanyRegionsComponentProps) => (
  <ApolloReactComponents.Query<GetCompanyRegionsQuery, GetCompanyRegionsQueryVariables>
    query={GetCompanyRegionsDocument}
    {...props}
  />
)

export type GetCompanyRegionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCompanyRegionsQuery,
    GetCompanyRegionsQueryVariables
  >
} &
  TChildProps
export function withGetCompanyRegions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCompanyRegionsQuery,
    GetCompanyRegionsQueryVariables,
    GetCompanyRegionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCompanyRegionsQuery,
    GetCompanyRegionsQueryVariables,
    GetCompanyRegionsProps<TChildProps, TDataName>
  >(GetCompanyRegionsDocument, {
    alias: 'getCompanyRegions',
    ...operationOptions
  })
}

/**
 * __useGetCompanyRegionsQuery__
 *
 * To run a query within a React component, call `useGetCompanyRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRegionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompanyRegionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyRegionsQuery,
    GetCompanyRegionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCompanyRegionsQuery, GetCompanyRegionsQueryVariables>(
    GetCompanyRegionsDocument,
    baseOptions
  )
}
export function useGetCompanyRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyRegionsQuery,
    GetCompanyRegionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCompanyRegionsQuery, GetCompanyRegionsQueryVariables>(
    GetCompanyRegionsDocument,
    baseOptions
  )
}
export type GetCompanyRegionsQueryHookResult = ReturnType<typeof useGetCompanyRegionsQuery>
export type GetCompanyRegionsLazyQueryHookResult = ReturnType<typeof useGetCompanyRegionsLazyQuery>
export type GetCompanyRegionsQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyRegionsQuery,
  GetCompanyRegionsQueryVariables
>
export const GetDivisionsDocument = gql`
  query getDivisions($where: DivisionFiltersInput) {
    divisions(filters: $where) {
      data {
        id
        attributes {
          title_english
          title_french
          title_german
          title_spanish
          title_mandarin
          createdAt
          updatedAt
        }
      }
    }
  }
`
export type GetDivisionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetDivisionsQuery, GetDivisionsQueryVariables>,
  'query'
>

export const GetDivisionsComponent = (props: GetDivisionsComponentProps) => (
  <ApolloReactComponents.Query<GetDivisionsQuery, GetDivisionsQueryVariables>
    query={GetDivisionsDocument}
    {...props}
  />
)

export type GetDivisionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetDivisionsQuery, GetDivisionsQueryVariables>
} &
  TChildProps
export function withGetDivisions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDivisionsQuery,
    GetDivisionsQueryVariables,
    GetDivisionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDivisionsQuery,
    GetDivisionsQueryVariables,
    GetDivisionsProps<TChildProps, TDataName>
  >(GetDivisionsDocument, {
    alias: 'getDivisions',
    ...operationOptions
  })
}

/**
 * __useGetDivisionsQuery__
 *
 * To run a query within a React component, call `useGetDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDivisionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDivisionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDivisionsQuery, GetDivisionsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(
    GetDivisionsDocument,
    baseOptions
  )
}
export function useGetDivisionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDivisionsQuery, GetDivisionsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetDivisionsQuery, GetDivisionsQueryVariables>(
    GetDivisionsDocument,
    baseOptions
  )
}
export type GetDivisionsQueryHookResult = ReturnType<typeof useGetDivisionsQuery>
export type GetDivisionsLazyQueryHookResult = ReturnType<typeof useGetDivisionsLazyQuery>
export type GetDivisionsQueryResult = ApolloReactCommon.QueryResult<
  GetDivisionsQuery,
  GetDivisionsQueryVariables
>
export const GetFirstFourNotesDocument = gql`
  query getFirstFourNotes {
    getFirstFourNotes {
      id
      name
      note
      document {
        id
        url
      }
      programme {
        name
      }
      notesId {
        saboteurProofValue {
          data {
            id
            attributes {
              url
              name
            }
          }
        }
      }
      sub_module_submission {
        sub_module {
          data {
            attributes {
              name_english
            }
          }
        }
      }
      sectionOrSubmissionId
      created_at
    }
  }
`
export type GetFirstFourNotesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetFirstFourNotesQuery,
    GetFirstFourNotesQueryVariables
  >,
  'query'
>

export const GetFirstFourNotesComponent = (props: GetFirstFourNotesComponentProps) => (
  <ApolloReactComponents.Query<GetFirstFourNotesQuery, GetFirstFourNotesQueryVariables>
    query={GetFirstFourNotesDocument}
    {...props}
  />
)

export type GetFirstFourNotesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetFirstFourNotesQuery,
    GetFirstFourNotesQueryVariables
  >
} &
  TChildProps
export function withGetFirstFourNotes<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetFirstFourNotesQuery,
    GetFirstFourNotesQueryVariables,
    GetFirstFourNotesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetFirstFourNotesQuery,
    GetFirstFourNotesQueryVariables,
    GetFirstFourNotesProps<TChildProps, TDataName>
  >(GetFirstFourNotesDocument, {
    alias: 'getFirstFourNotes',
    ...operationOptions
  })
}

/**
 * __useGetFirstFourNotesQuery__
 *
 * To run a query within a React component, call `useGetFirstFourNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstFourNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstFourNotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFirstFourNotesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFirstFourNotesQuery,
    GetFirstFourNotesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetFirstFourNotesQuery, GetFirstFourNotesQueryVariables>(
    GetFirstFourNotesDocument,
    baseOptions
  )
}
export function useGetFirstFourNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFirstFourNotesQuery,
    GetFirstFourNotesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetFirstFourNotesQuery, GetFirstFourNotesQueryVariables>(
    GetFirstFourNotesDocument,
    baseOptions
  )
}
export type GetFirstFourNotesQueryHookResult = ReturnType<typeof useGetFirstFourNotesQuery>
export type GetFirstFourNotesLazyQueryHookResult = ReturnType<typeof useGetFirstFourNotesLazyQuery>
export type GetFirstFourNotesQueryResult = ApolloReactCommon.QueryResult<
  GetFirstFourNotesQuery,
  GetFirstFourNotesQueryVariables
>
export const GetFirstTwelveArticlesDocument = gql`
  query getFirstTwelveArticles {
    getFirstTwelveArticles {
      id
      createdAt
      updatedAt
      title_english
      title_french
      title_german
      title_spanish
      title_mandarin
      content_english
      content_french
      content_german
      content_spanish
      content_mandarin
      position
      companies {
        data {
          id
        }
      }
      image {
        data {
          attributes {
            url
          }
        }
      }
      date_published
      read_time
      link
    }
  }
`
export type GetFirstTwelveArticlesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables
  >,
  'query'
>

export const GetFirstTwelveArticlesComponent = (props: GetFirstTwelveArticlesComponentProps) => (
  <ApolloReactComponents.Query<GetFirstTwelveArticlesQuery, GetFirstTwelveArticlesQueryVariables>
    query={GetFirstTwelveArticlesDocument}
    {...props}
  />
)

export type GetFirstTwelveArticlesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables
  >
} &
  TChildProps
export function withGetFirstTwelveArticles<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables,
    GetFirstTwelveArticlesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables,
    GetFirstTwelveArticlesProps<TChildProps, TDataName>
  >(GetFirstTwelveArticlesDocument, {
    alias: 'getFirstTwelveArticles',
    ...operationOptions
  })
}

/**
 * __useGetFirstTwelveArticlesQuery__
 *
 * To run a query within a React component, call `useGetFirstTwelveArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstTwelveArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstTwelveArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFirstTwelveArticlesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables
  >(GetFirstTwelveArticlesDocument, baseOptions)
}
export function useGetFirstTwelveArticlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetFirstTwelveArticlesQuery,
    GetFirstTwelveArticlesQueryVariables
  >(GetFirstTwelveArticlesDocument, baseOptions)
}
export type GetFirstTwelveArticlesQueryHookResult = ReturnType<
  typeof useGetFirstTwelveArticlesQuery
>
export type GetFirstTwelveArticlesLazyQueryHookResult = ReturnType<
  typeof useGetFirstTwelveArticlesLazyQuery
>
export type GetFirstTwelveArticlesQueryResult = ApolloReactCommon.QueryResult<
  GetFirstTwelveArticlesQuery,
  GetFirstTwelveArticlesQueryVariables
>
export const GetInviteDocument = gql`
  query getInvite($where: InviteFiltersInput) {
    invites(filters: $where) {
      data {
        attributes {
          email
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`
export type GetInviteComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetInviteQuery, GetInviteQueryVariables>,
  'query'
>

export const GetInviteComponent = (props: GetInviteComponentProps) => (
  <ApolloReactComponents.Query<GetInviteQuery, GetInviteQueryVariables>
    query={GetInviteDocument}
    {...props}
  />
)

export type GetInviteProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetInviteQuery, GetInviteQueryVariables>
} &
  TChildProps
export function withGetInvite<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetInviteQuery,
    GetInviteQueryVariables,
    GetInviteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetInviteQuery,
    GetInviteQueryVariables,
    GetInviteProps<TChildProps, TDataName>
  >(GetInviteDocument, {
    alias: 'getInvite',
    ...operationOptions
  })
}

/**
 * __useGetInviteQuery__
 *
 * To run a query within a React component, call `useGetInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInviteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetInviteQuery, GetInviteQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetInviteQuery, GetInviteQueryVariables>(
    GetInviteDocument,
    baseOptions
  )
}
export function useGetInviteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInviteQuery, GetInviteQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetInviteQuery, GetInviteQueryVariables>(
    GetInviteDocument,
    baseOptions
  )
}
export type GetInviteQueryHookResult = ReturnType<typeof useGetInviteQuery>
export type GetInviteLazyQueryHookResult = ReturnType<typeof useGetInviteLazyQuery>
export type GetInviteQueryResult = ApolloReactCommon.QueryResult<
  GetInviteQuery,
  GetInviteQueryVariables
>
export const GetLanguagesDocument = gql`
  query getLanguages($where: LanguageFiltersInput) {
    languages(filters: $where) {
      data {
        id
        attributes {
          name
          image {
            data {
              attributes {
                url
              }
            }
          }
          companies {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`
export type GetLanguagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetLanguagesQuery, GetLanguagesQueryVariables>,
  'query'
>

export const GetLanguagesComponent = (props: GetLanguagesComponentProps) => (
  <ApolloReactComponents.Query<GetLanguagesQuery, GetLanguagesQueryVariables>
    query={GetLanguagesDocument}
    {...props}
  />
)

export type GetLanguagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetLanguagesQuery, GetLanguagesQueryVariables>
} &
  TChildProps
export function withGetLanguages<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetLanguagesQuery,
    GetLanguagesQueryVariables,
    GetLanguagesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetLanguagesQuery,
    GetLanguagesQueryVariables,
    GetLanguagesProps<TChildProps, TDataName>
  >(GetLanguagesDocument, {
    alias: 'getLanguages',
    ...operationOptions
  })
}

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLanguagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(
    GetLanguagesDocument,
    baseOptions
  )
}
export function useGetLanguagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(
    GetLanguagesDocument,
    baseOptions
  )
}
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>
export type GetLanguagesQueryResult = ApolloReactCommon.QueryResult<
  GetLanguagesQuery,
  GetLanguagesQueryVariables
>
export const GetModuleDocument = gql`
  query getModule($id: ID!) {
    module(id: $id) {
      data {
        id
        attributes {
          name_english
          name_french
          name_spanish
          name_german
          name_mandarin
          description_english
          description_french
          description_german
          description_mandarin
          description_spanish
          cover {
            data {
              id
              attributes {
                url
              }
            }
          }
          sections {
            data {
              id
              attributes {
                title_french
                title_german
                title_english
                title_spanish
                title_mandarin
                sub_title_french
                sub_title_german
                sub_title_english
                sub_title_spanish
                sub_title_mandarin
                video_english {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_german {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_spanish {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_mandarin {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_french {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                questions {
                  ...questions
                }
                position
                archive
              }
            }
          }
        }
      }
    }
  }
  ${QuestionsFragmentDoc}
`
export type GetModuleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetModuleQuery, GetModuleQueryVariables>,
  'query'
> &
  ({ variables: GetModuleQueryVariables; skip?: boolean } | { skip: boolean })

export const GetModuleComponent = (props: GetModuleComponentProps) => (
  <ApolloReactComponents.Query<GetModuleQuery, GetModuleQueryVariables>
    query={GetModuleDocument}
    {...props}
  />
)

export type GetModuleProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetModuleQuery, GetModuleQueryVariables>
} &
  TChildProps
export function withGetModule<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetModuleQuery,
    GetModuleQueryVariables,
    GetModuleProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetModuleQuery,
    GetModuleQueryVariables,
    GetModuleProps<TChildProps, TDataName>
  >(GetModuleDocument, {
    alias: 'getModule',
    ...operationOptions
  })
}

/**
 * __useGetModuleQuery__
 *
 * To run a query within a React component, call `useGetModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetModuleQuery, GetModuleQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetModuleQuery, GetModuleQueryVariables>(
    GetModuleDocument,
    baseOptions
  )
}
export function useGetModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModuleQuery, GetModuleQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetModuleQuery, GetModuleQueryVariables>(
    GetModuleDocument,
    baseOptions
  )
}
export type GetModuleQueryHookResult = ReturnType<typeof useGetModuleQuery>
export type GetModuleLazyQueryHookResult = ReturnType<typeof useGetModuleLazyQuery>
export type GetModuleQueryResult = ApolloReactCommon.QueryResult<
  GetModuleQuery,
  GetModuleQueryVariables
>
export const GetModulesDocument = gql`
  query getModules {
    modules {
      data {
        id
        attributes {
          name_english
          name_french
          name_spanish
          name_german
          name_mandarin
          description_english
          description_french
          description_spanish
          description_german
          description_mandarin
          cover {
            data {
              id
              attributes {
                url
              }
            }
          }
          sections {
            data {
              id
              attributes {
                title_french
                title_german
                title_english
                title_spanish
                title_mandarin
                sub_title_french
                sub_title_german
                sub_title_english
                sub_title_spanish
                sub_title_mandarin
                video_english {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_french {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_spanish {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_german {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_mandarin {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                questions {
                  ...questions
                }
                archive
              }
            }
          }
        }
      }
    }
  }
  ${QuestionsFragmentDoc}
`
export type GetModulesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetModulesQuery, GetModulesQueryVariables>,
  'query'
>

export const GetModulesComponent = (props: GetModulesComponentProps) => (
  <ApolloReactComponents.Query<GetModulesQuery, GetModulesQueryVariables>
    query={GetModulesDocument}
    {...props}
  />
)

export type GetModulesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetModulesQuery, GetModulesQueryVariables>
} &
  TChildProps
export function withGetModules<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetModulesQuery,
    GetModulesQueryVariables,
    GetModulesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetModulesQuery,
    GetModulesQueryVariables,
    GetModulesProps<TChildProps, TDataName>
  >(GetModulesDocument, {
    alias: 'getModules',
    ...operationOptions
  })
}

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetModulesQuery, GetModulesQueryVariables>(
    GetModulesDocument,
    baseOptions
  )
}
export function useGetModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(
    GetModulesDocument,
    baseOptions
  )
}
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>
export type GetModulesQueryResult = ApolloReactCommon.QueryResult<
  GetModulesQuery,
  GetModulesQueryVariables
>
export const GetMyBookingsDocument = gql`
  query getMyBookings($where: BookingFiltersInput!, $sort: [String], $limit: Int!) {
    bookings(filters: $where, sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          title
          description
          duration
          date
          confirmed
          event_id
          cancelled
          organiser
          location
          is_updated
          end
        }
      }
    }
  }
`
export type GetMyBookingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetMyBookingsQuery, GetMyBookingsQueryVariables>,
  'query'
> &
  ({ variables: GetMyBookingsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMyBookingsComponent = (props: GetMyBookingsComponentProps) => (
  <ApolloReactComponents.Query<GetMyBookingsQuery, GetMyBookingsQueryVariables>
    query={GetMyBookingsDocument}
    {...props}
  />
)

export type GetMyBookingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetMyBookingsQuery, GetMyBookingsQueryVariables>
} &
  TChildProps
export function withGetMyBookings<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyBookingsQuery,
    GetMyBookingsQueryVariables,
    GetMyBookingsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyBookingsQuery,
    GetMyBookingsQueryVariables,
    GetMyBookingsProps<TChildProps, TDataName>
  >(GetMyBookingsDocument, {
    alias: 'getMyBookings',
    ...operationOptions
  })
}

/**
 * __useGetMyBookingsQuery__
 *
 * To run a query within a React component, call `useGetMyBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBookingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyBookingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyBookingsQuery, GetMyBookingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetMyBookingsQuery, GetMyBookingsQueryVariables>(
    GetMyBookingsDocument,
    baseOptions
  )
}
export function useGetMyBookingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyBookingsQuery,
    GetMyBookingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMyBookingsQuery, GetMyBookingsQueryVariables>(
    GetMyBookingsDocument,
    baseOptions
  )
}
export type GetMyBookingsQueryHookResult = ReturnType<typeof useGetMyBookingsQuery>
export type GetMyBookingsLazyQueryHookResult = ReturnType<typeof useGetMyBookingsLazyQuery>
export type GetMyBookingsQueryResult = ApolloReactCommon.QueryResult<
  GetMyBookingsQuery,
  GetMyBookingsQueryVariables
>
export const GetMyCoachSessionsDocument = gql`
  query getMyCoachSessions($where: CoachSessionFiltersInput!) {
    coachSessions(filters: $where) {
      data {
        id
        attributes {
          title
          status
          user {
            data {
              id
            }
          }
          programme_submission {
            data {
              id
            }
          }
          sub_module_submission {
            data {
              id
            }
          }
          appointment {
            data {
              attributes {
                appointment_url
                appointment_start_date
                appointment_end_date
                status
              }
            }
          }
        }
      }
    }
  }
`
export type GetMyCoachSessionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMyCoachSessionsQuery,
    GetMyCoachSessionsQueryVariables
  >,
  'query'
> &
  ({ variables: GetMyCoachSessionsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMyCoachSessionsComponent = (props: GetMyCoachSessionsComponentProps) => (
  <ApolloReactComponents.Query<GetMyCoachSessionsQuery, GetMyCoachSessionsQueryVariables>
    query={GetMyCoachSessionsDocument}
    {...props}
  />
)

export type GetMyCoachSessionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMyCoachSessionsQuery,
    GetMyCoachSessionsQueryVariables
  >
} &
  TChildProps
export function withGetMyCoachSessions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyCoachSessionsQuery,
    GetMyCoachSessionsQueryVariables,
    GetMyCoachSessionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyCoachSessionsQuery,
    GetMyCoachSessionsQueryVariables,
    GetMyCoachSessionsProps<TChildProps, TDataName>
  >(GetMyCoachSessionsDocument, {
    alias: 'getMyCoachSessions',
    ...operationOptions
  })
}

/**
 * __useGetMyCoachSessionsQuery__
 *
 * To run a query within a React component, call `useGetMyCoachSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCoachSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCoachSessionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMyCoachSessionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyCoachSessionsQuery,
    GetMyCoachSessionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMyCoachSessionsQuery, GetMyCoachSessionsQueryVariables>(
    GetMyCoachSessionsDocument,
    baseOptions
  )
}
export function useGetMyCoachSessionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyCoachSessionsQuery,
    GetMyCoachSessionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMyCoachSessionsQuery, GetMyCoachSessionsQueryVariables>(
    GetMyCoachSessionsDocument,
    baseOptions
  )
}
export type GetMyCoachSessionsQueryHookResult = ReturnType<typeof useGetMyCoachSessionsQuery>
export type GetMyCoachSessionsLazyQueryHookResult = ReturnType<
  typeof useGetMyCoachSessionsLazyQuery
>
export type GetMyCoachSessionsQueryResult = ApolloReactCommon.QueryResult<
  GetMyCoachSessionsQuery,
  GetMyCoachSessionsQueryVariables
>
export const GetMyNotificationsDocument = gql`
  query getMyNotifications($where: NotificationFiltersInput!) {
    notifications(filters: $where) {
      data {
        id
        attributes {
          createdAt
          updatedAt
          title
          time_stamp
          path
          read
          users {
            data {
              id
            }
          }
        }
      }
    }
  }
`
export type GetMyNotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMyNotificationsQuery,
    GetMyNotificationsQueryVariables
  >,
  'query'
> &
  ({ variables: GetMyNotificationsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMyNotificationsComponent = (props: GetMyNotificationsComponentProps) => (
  <ApolloReactComponents.Query<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>
    query={GetMyNotificationsDocument}
    {...props}
  />
)

export type GetMyNotificationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMyNotificationsQuery,
    GetMyNotificationsQueryVariables
  >
} &
  TChildProps
export function withGetMyNotifications<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyNotificationsQuery,
    GetMyNotificationsQueryVariables,
    GetMyNotificationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyNotificationsQuery,
    GetMyNotificationsQueryVariables,
    GetMyNotificationsProps<TChildProps, TDataName>
  >(GetMyNotificationsDocument, {
    alias: 'getMyNotifications',
    ...operationOptions
  })
}

/**
 * __useGetMyNotificationsQuery__
 *
 * To run a query within a React component, call `useGetMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMyNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyNotificationsQuery,
    GetMyNotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>(
    GetMyNotificationsDocument,
    baseOptions
  )
}
export function useGetMyNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyNotificationsQuery,
    GetMyNotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>(
    GetMyNotificationsDocument,
    baseOptions
  )
}
export type GetMyNotificationsQueryHookResult = ReturnType<typeof useGetMyNotificationsQuery>
export type GetMyNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetMyNotificationsLazyQuery
>
export type GetMyNotificationsQueryResult = ApolloReactCommon.QueryResult<
  GetMyNotificationsQuery,
  GetMyNotificationsQueryVariables
>
export const GetMySubModuleReportDocument = gql`
  query getMySubModuleReport($where: ReportFiltersInput!, $sort: [String]!, $limit: Int!) {
    reports(filters: $where, sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          id
          name
          type
          report {
            data {
              id
              attributes {
                url
              }
            }
          }
          section_or_module_id
          sub_module_submission {
            data {
              attributes {
                section {
                  progress
                  section {
                    data {
                      attributes {
                        title_english
                      }
                    }
                  }
                }
                sub_module {
                  data {
                    id
                    attributes {
                      programme_type {
                        data {
                          id
                          attributes {
                            title
                            programmes {
                              data {
                                attributes {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export type GetMySubModuleReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables
  >,
  'query'
> &
  ({ variables: GetMySubModuleReportQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMySubModuleReportComponent = (props: GetMySubModuleReportComponentProps) => (
  <ApolloReactComponents.Query<GetMySubModuleReportQuery, GetMySubModuleReportQueryVariables>
    query={GetMySubModuleReportDocument}
    {...props}
  />
)

export type GetMySubModuleReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables
  >
} &
  TChildProps
export function withGetMySubModuleReport<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables,
    GetMySubModuleReportProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables,
    GetMySubModuleReportProps<TChildProps, TDataName>
  >(GetMySubModuleReportDocument, {
    alias: 'getMySubModuleReport',
    ...operationOptions
  })
}

/**
 * __useGetMySubModuleReportQuery__
 *
 * To run a query within a React component, call `useGetMySubModuleReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubModuleReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubModuleReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMySubModuleReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMySubModuleReportQuery, GetMySubModuleReportQueryVariables>(
    GetMySubModuleReportDocument,
    baseOptions
  )
}
export function useGetMySubModuleReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMySubModuleReportQuery,
    GetMySubModuleReportQueryVariables
  >(GetMySubModuleReportDocument, baseOptions)
}
export type GetMySubModuleReportQueryHookResult = ReturnType<typeof useGetMySubModuleReportQuery>
export type GetMySubModuleReportLazyQueryHookResult = ReturnType<
  typeof useGetMySubModuleReportLazyQuery
>
export type GetMySubModuleReportQueryResult = ApolloReactCommon.QueryResult<
  GetMySubModuleReportQuery,
  GetMySubModuleReportQueryVariables
>
export const GetMySubModuleSubmissionsReportsDocument = gql`
  query getMySubModuleSubmissionsReports {
    getMySubModuleSubmissionsReports {
      id
      sub_module {
        id
        name_french
        name_german
        name_english
        name_spanish
        name_mandarin
        description_french
        description_german
        description_english
        description_spanish
        description_mandarin
        cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        sections {
          data {
            id
            attributes {
              title_french
              title_german
              title_english
              title_spanish
              title_mandarin
              sub_title_french
              sub_title_german
              sub_title_english
              sub_title_spanish
              sub_title_mandarin
              cover {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              position
            }
          }
        }
        position
        programme_type {
          data {
            id
            attributes {
              title
              description
              position
              cover {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              is_coaching
              is_sub_module
              notes
              show_article
              show_session
              show_participants
              competency_survey
              feedForward_survey
              deadline
              articles {
                data {
                  id
                  attributes {
                    createdAt
                    updatedAt
                    title_english
                    title_french
                    title_german
                    title_spanish
                    title_mandarin
                    content_english
                    content_french
                    content_german
                    content_spanish
                    content_mandarin
                    image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    date_published
                    read_time
                    link
                  }
                }
              }
              modal {
                data {
                  id
                  attributes {
                    title
                    subtitle
                    message
                    image {
                      data {
                        id
                        attributes {
                          url
                          name
                        }
                      }
                    }
                  }
                }
              }
              events {
                data {
                  id
                  attributes {
                    name
                    link
                  }
                }
              }
            }
          }
        }
      }
      reports {
        id
        name
        type
        report {
          data {
            id
            attributes {
              url
            }
          }
        }
        section_or_module_id
      }
    }
  }
`
export type GetMySubModuleSubmissionsReportsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >,
  'query'
>

export const GetMySubModuleSubmissionsReportsComponent = (
  props: GetMySubModuleSubmissionsReportsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >
    query={GetMySubModuleSubmissionsReportsDocument}
    {...props}
  />
)

export type GetMySubModuleSubmissionsReportsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >
} &
  TChildProps
export function withGetMySubModuleSubmissionsReports<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables,
    GetMySubModuleSubmissionsReportsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables,
    GetMySubModuleSubmissionsReportsProps<TChildProps, TDataName>
  >(GetMySubModuleSubmissionsReportsDocument, {
    alias: 'getMySubModuleSubmissionsReports',
    ...operationOptions
  })
}

/**
 * __useGetMySubModuleSubmissionsReportsQuery__
 *
 * To run a query within a React component, call `useGetMySubModuleSubmissionsReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubModuleSubmissionsReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubModuleSubmissionsReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySubModuleSubmissionsReportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >(GetMySubModuleSubmissionsReportsDocument, baseOptions)
}
export function useGetMySubModuleSubmissionsReportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMySubModuleSubmissionsReportsQuery,
    GetMySubModuleSubmissionsReportsQueryVariables
  >(GetMySubModuleSubmissionsReportsDocument, baseOptions)
}
export type GetMySubModuleSubmissionsReportsQueryHookResult = ReturnType<
  typeof useGetMySubModuleSubmissionsReportsQuery
>
export type GetMySubModuleSubmissionsReportsLazyQueryHookResult = ReturnType<
  typeof useGetMySubModuleSubmissionsReportsLazyQuery
>
export type GetMySubModuleSubmissionsReportsQueryResult = ApolloReactCommon.QueryResult<
  GetMySubModuleSubmissionsReportsQuery,
  GetMySubModuleSubmissionsReportsQueryVariables
>
export const GetMySubModuleNotesDocument = gql`
  query getMySubModuleNotes($where: NoteFiltersInput!, $sort: [String]!, $limit: Int!) {
    notes(filters: $where, sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          name
          note
          document {
            data {
              id
              attributes {
                url
              }
            }
          }
          programme {
            data {
              attributes {
                name
              }
            }
          }
          notes_id {
            saboteurProofValue {
              data {
                id
                attributes {
                  url
                  name
                }
              }
            }
          }
          sub_module_submission {
            data {
              attributes {
                sub_module {
                  data {
                    attributes {
                      name_english
                    }
                  }
                }
              }
            }
          }
          section_or_submission_id
          createdAt
        }
      }
    }
  }
`
export type GetMySubModuleNotesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMySubModuleNotesQuery,
    GetMySubModuleNotesQueryVariables
  >,
  'query'
> &
  ({ variables: GetMySubModuleNotesQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMySubModuleNotesComponent = (props: GetMySubModuleNotesComponentProps) => (
  <ApolloReactComponents.Query<GetMySubModuleNotesQuery, GetMySubModuleNotesQueryVariables>
    query={GetMySubModuleNotesDocument}
    {...props}
  />
)

export type GetMySubModuleNotesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMySubModuleNotesQuery,
    GetMySubModuleNotesQueryVariables
  >
} &
  TChildProps
export function withGetMySubModuleNotes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubModuleNotesQuery,
    GetMySubModuleNotesQueryVariables,
    GetMySubModuleNotesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubModuleNotesQuery,
    GetMySubModuleNotesQueryVariables,
    GetMySubModuleNotesProps<TChildProps, TDataName>
  >(GetMySubModuleNotesDocument, {
    alias: 'getMySubModuleNotes',
    ...operationOptions
  })
}

/**
 * __useGetMySubModuleNotesQuery__
 *
 * To run a query within a React component, call `useGetMySubModuleNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubModuleNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubModuleNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMySubModuleNotesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubModuleNotesQuery,
    GetMySubModuleNotesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMySubModuleNotesQuery, GetMySubModuleNotesQueryVariables>(
    GetMySubModuleNotesDocument,
    baseOptions
  )
}
export function useGetMySubModuleNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubModuleNotesQuery,
    GetMySubModuleNotesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMySubModuleNotesQuery, GetMySubModuleNotesQueryVariables>(
    GetMySubModuleNotesDocument,
    baseOptions
  )
}
export type GetMySubModuleNotesQueryHookResult = ReturnType<typeof useGetMySubModuleNotesQuery>
export type GetMySubModuleNotesLazyQueryHookResult = ReturnType<
  typeof useGetMySubModuleNotesLazyQuery
>
export type GetMySubModuleNotesQueryResult = ApolloReactCommon.QueryResult<
  GetMySubModuleNotesQuery,
  GetMySubModuleNotesQueryVariables
>
export const GetMyUploadedReportDocument = gql`
  query getMyUploadedReport($where: UploadedReportFiltersInput!, $sort: [String]!, $limit: Int!) {
    uploadedReports(filters: $where, sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          name
          type
          report {
            data {
              id
              attributes {
                url
                createdAt
              }
            }
          }
          section_or_module_id
        }
      }
    }
  }
`
export type GetMyUploadedReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMyUploadedReportQuery,
    GetMyUploadedReportQueryVariables
  >,
  'query'
> &
  ({ variables: GetMyUploadedReportQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMyUploadedReportComponent = (props: GetMyUploadedReportComponentProps) => (
  <ApolloReactComponents.Query<GetMyUploadedReportQuery, GetMyUploadedReportQueryVariables>
    query={GetMyUploadedReportDocument}
    {...props}
  />
)

export type GetMyUploadedReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMyUploadedReportQuery,
    GetMyUploadedReportQueryVariables
  >
} &
  TChildProps
export function withGetMyUploadedReport<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyUploadedReportQuery,
    GetMyUploadedReportQueryVariables,
    GetMyUploadedReportProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyUploadedReportQuery,
    GetMyUploadedReportQueryVariables,
    GetMyUploadedReportProps<TChildProps, TDataName>
  >(GetMyUploadedReportDocument, {
    alias: 'getMyUploadedReport',
    ...operationOptions
  })
}

/**
 * __useGetMyUploadedReportQuery__
 *
 * To run a query within a React component, call `useGetMyUploadedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUploadedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUploadedReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyUploadedReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyUploadedReportQuery,
    GetMyUploadedReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMyUploadedReportQuery, GetMyUploadedReportQueryVariables>(
    GetMyUploadedReportDocument,
    baseOptions
  )
}
export function useGetMyUploadedReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyUploadedReportQuery,
    GetMyUploadedReportQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMyUploadedReportQuery, GetMyUploadedReportQueryVariables>(
    GetMyUploadedReportDocument,
    baseOptions
  )
}
export type GetMyUploadedReportQueryHookResult = ReturnType<typeof useGetMyUploadedReportQuery>
export type GetMyUploadedReportLazyQueryHookResult = ReturnType<
  typeof useGetMyUploadedReportLazyQuery
>
export type GetMyUploadedReportQueryResult = ApolloReactCommon.QueryResult<
  GetMyUploadedReportQuery,
  GetMyUploadedReportQueryVariables
>
export const GetMyWorkDocument = gql`
  query getMyWork {
    getMySubmissions {
      id
      views
      progress
      status
      time_spent
      language {
        data {
          attributes {
            name
          }
        }
      }
      module {
        data {
          id
          attributes {
            name_french
            name_german
            name_english
            name_spanish
            name_mandarin
            description_french
            description_german
            description_english
            description_spanish
            description_mandarin
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
            sections {
              data {
                id
                attributes {
                  title_english
                  title_french
                  title_german
                  title_spanish
                  title_mandarin
                  sub_title_english
                  sub_title_french
                  sub_title_german
                  sub_title_spanish
                  sub_title_mandarin
                  video_english {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  video_french {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  video_german {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  video_spanish {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  video_mandarin {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  questions {
                    ...questions
                  }
                }
              }
            }
          }
        }
      }
      section {
        id
        answers {
          id
          questionId
          answer
          nestedAnswers {
            answer
            questionTitle
          }
          isCorrect
          feedback
          outcome
        }
        section {
          data {
            id
            attributes {
              createdAt
              updatedAt
              title_english
              title_french
              title_german
              title_spanish
              title_mandarin
              sub_title_english
              sub_title_french
              sub_title_german
              sub_title_spanish
              sub_title_mandarin
              video_english {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              video_french {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              video_german {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              video_spanish {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              video_mandarin {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              questions {
                ...questions
              }
            }
          }
        }
      }
    }
  }
  ${QuestionsFragmentDoc}
`
export type GetMyWorkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetMyWorkQuery, GetMyWorkQueryVariables>,
  'query'
>

export const GetMyWorkComponent = (props: GetMyWorkComponentProps) => (
  <ApolloReactComponents.Query<GetMyWorkQuery, GetMyWorkQueryVariables>
    query={GetMyWorkDocument}
    {...props}
  />
)

export type GetMyWorkProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetMyWorkQuery, GetMyWorkQueryVariables>
} &
  TChildProps
export function withGetMyWork<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyWorkQuery,
    GetMyWorkQueryVariables,
    GetMyWorkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyWorkQuery,
    GetMyWorkQueryVariables,
    GetMyWorkProps<TChildProps, TDataName>
  >(GetMyWorkDocument, {
    alias: 'getMyWork',
    ...operationOptions
  })
}

/**
 * __useGetMyWorkQuery__
 *
 * To run a query within a React component, call `useGetMyWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWorkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyWorkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyWorkQuery, GetMyWorkQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetMyWorkQuery, GetMyWorkQueryVariables>(
    GetMyWorkDocument,
    baseOptions
  )
}
export function useGetMyWorkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyWorkQuery, GetMyWorkQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetMyWorkQuery, GetMyWorkQueryVariables>(
    GetMyWorkDocument,
    baseOptions
  )
}
export type GetMyWorkQueryHookResult = ReturnType<typeof useGetMyWorkQuery>
export type GetMyWorkLazyQueryHookResult = ReturnType<typeof useGetMyWorkLazyQuery>
export type GetMyWorkQueryResult = ApolloReactCommon.QueryResult<
  GetMyWorkQuery,
  GetMyWorkQueryVariables
>
export const GetSubModuleSubmissionDocument = gql`
  query getSubModuleSubmission($id: ID!) {
    subModuleSubmission(id: $id) {
      data {
        id
        attributes {
          view
          progress
          status
          time_spent
          feedfoward_url {
            data {
              id
            }
          }
          competency_url {
            data {
              id
            }
          }
          competency_participants {
            name
            email
          }
          participants {
            data {
              id
              attributes {
                name
                email
                type
              }
            }
          }
          submission_participants {
            id
            name
            email
          }
          language {
            data {
              id
              attributes {
                name
              }
            }
          }
          section {
            id
            progress
            section {
              data {
                id
              }
            }
            answers {
              id
              questionId
              answer
              nestedAnswers {
                answer
                questionTitle
              }
              isCorrect
              feedback
              outcome
              questionType
              questionTitle
            }
            stakeholderChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            stakeholderItems {
              id
              valueX
              valueY
              name
              color
            }
            gravitasScales {
              id
              labelOne
              labelTwo
              labelThree
              question
              answerOne
              answerTwo
              answerThree
            }
            gravitasSliderAnswers {
              negativeQuestion
              positiveQuestion
              colorAndRating {
                color
                rate
              }
            }
            gravitasGap {
              id
              gap
              commitment
            }
            xYGraphChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            saboteurImage {
              data {
                id
                attributes {
                  url
                }
              }
            }
            xYGraphItems {
              id
              valueX
              valueY
              color
            }
            characteristics {
              id
              answer
              type
            }
            sliderAnswers
            gapsAnswers {
              id
              gap
              commitment
            }
            competencyAnswers {
              id
              question
              rating
              steps
              response
            }
            uploadDocuments {
              saboteurProofValue {
                data {
                  id
                }
              }
            }
          }
          sub_module {
            data {
              id
              attributes {
                name_french
                name_german
                name_english
                name_spanish
                name_mandarin
                description_french
                description_german
                description_english
                description_spanish
                description_mandarin
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                sections {
                  data {
                    id
                    attributes {
                      title_french
                      title_german
                      title_english
                      title_spanish
                      title_mandarin
                      sub_title_french
                      sub_title_german
                      sub_title_english
                      sub_title_spanish
                      sub_title_mandarin
                      cover {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      position
                      archive
                    }
                  }
                }
                position
                programme_type {
                  data {
                    id
                    attributes {
                      title
                      description
                      position
                      cover {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      is_coaching
                      is_sub_module
                      notes
                      show_article
                      show_session
                      show_participants
                      competency_survey
                      feedForward_survey
                      deadline
                      articles {
                        data {
                          id
                          attributes {
                            createdAt
                            updatedAt
                            title_english
                            title_french
                            title_german
                            title_spanish
                            title_mandarin
                            content_english
                            content_french
                            content_german
                            content_spanish
                            content_mandarin
                            image {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                            date_published
                            read_time
                            link
                          }
                        }
                      }
                      modal {
                        data {
                          id
                          attributes {
                            title
                            subtitle
                            message
                            image {
                              data {
                                id
                                attributes {
                                  url
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                      events {
                        data {
                          id
                          attributes {
                            name
                            link
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          reports {
            data {
              id
              attributes {
                name
                type
                report {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                section_or_module_id
              }
            }
          }
        }
      }
    }
  }
`
export type GetSubModuleSubmissionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables
  >,
  'query'
> &
  ({ variables: GetSubModuleSubmissionQueryVariables; skip?: boolean } | { skip: boolean })

export const GetSubModuleSubmissionComponent = (props: GetSubModuleSubmissionComponentProps) => (
  <ApolloReactComponents.Query<GetSubModuleSubmissionQuery, GetSubModuleSubmissionQueryVariables>
    query={GetSubModuleSubmissionDocument}
    {...props}
  />
)

export type GetSubModuleSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables
  >
} &
  TChildProps
export function withGetSubModuleSubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables,
    GetSubModuleSubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables,
    GetSubModuleSubmissionProps<TChildProps, TDataName>
  >(GetSubModuleSubmissionDocument, {
    alias: 'getSubModuleSubmission',
    ...operationOptions
  })
}

/**
 * __useGetSubModuleSubmissionQuery__
 *
 * To run a query within a React component, call `useGetSubModuleSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubModuleSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubModuleSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubModuleSubmissionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables
  >(GetSubModuleSubmissionDocument, baseOptions)
}
export function useGetSubModuleSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSubModuleSubmissionQuery,
    GetSubModuleSubmissionQueryVariables
  >(GetSubModuleSubmissionDocument, baseOptions)
}
export type GetSubModuleSubmissionQueryHookResult = ReturnType<
  typeof useGetSubModuleSubmissionQuery
>
export type GetSubModuleSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetSubModuleSubmissionLazyQuery
>
export type GetSubModuleSubmissionQueryResult = ApolloReactCommon.QueryResult<
  GetSubModuleSubmissionQuery,
  GetSubModuleSubmissionQueryVariables
>
export const GetNewTypeformFeedResponsesDocument = gql`
  query getNewTypeformFeedResponses {
    getNewTypeformFeedResponses {
      success
    }
  }
`
export type GetNewTypeformFeedResponsesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >,
  'query'
>

export const GetNewTypeformFeedResponsesComponent = (
  props: GetNewTypeformFeedResponsesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >
    query={GetNewTypeformFeedResponsesDocument}
    {...props}
  />
)

export type GetNewTypeformFeedResponsesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >
} &
  TChildProps
export function withGetNewTypeformFeedResponses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables,
    GetNewTypeformFeedResponsesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables,
    GetNewTypeformFeedResponsesProps<TChildProps, TDataName>
  >(GetNewTypeformFeedResponsesDocument, {
    alias: 'getNewTypeformFeedResponses',
    ...operationOptions
  })
}

/**
 * __useGetNewTypeformFeedResponsesQuery__
 *
 * To run a query within a React component, call `useGetNewTypeformFeedResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewTypeformFeedResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewTypeformFeedResponsesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewTypeformFeedResponsesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >(GetNewTypeformFeedResponsesDocument, baseOptions)
}
export function useGetNewTypeformFeedResponsesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetNewTypeformFeedResponsesQuery,
    GetNewTypeformFeedResponsesQueryVariables
  >(GetNewTypeformFeedResponsesDocument, baseOptions)
}
export type GetNewTypeformFeedResponsesQueryHookResult = ReturnType<
  typeof useGetNewTypeformFeedResponsesQuery
>
export type GetNewTypeformFeedResponsesLazyQueryHookResult = ReturnType<
  typeof useGetNewTypeformFeedResponsesLazyQuery
>
export type GetNewTypeformFeedResponsesQueryResult = ApolloReactCommon.QueryResult<
  GetNewTypeformFeedResponsesQuery,
  GetNewTypeformFeedResponsesQueryVariables
>
export const GetNotificationsDocument = gql`
  query getNotifications {
    notifications {
      data {
        id
        attributes {
          createdAt
          updatedAt
          title
          time_stamp
          path
          read
          users {
            data {
              id
            }
          }
        }
      }
    }
  }
`
export type GetNotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >,
  'query'
>

export const GetNotificationsComponent = (props: GetNotificationsComponentProps) => (
  <ApolloReactComponents.Query<GetNotificationsQuery, GetNotificationsQueryVariables>
    query={GetNotificationsDocument}
    {...props}
  />
)

export type GetNotificationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
} &
  TChildProps
export function withGetNotifications<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetNotificationsQuery,
    GetNotificationsQueryVariables,
    GetNotificationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetNotificationsQuery,
    GetNotificationsQueryVariables,
    GetNotificationsProps<TChildProps, TDataName>
  >(GetNotificationsDocument, {
    alias: 'getNotifications',
    ...operationOptions
  })
}

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    baseOptions
  )
}
export function useGetNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    baseOptions
  )
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const GetOrderedSubmissionsDocument = gql`
  query getOrderedSubmissions($programme: ID!) {
    getOrderedSubmissions(programme: $programme) {
      programmeSubmission {
        id
        progress
        programme {
          data {
            id
            attributes {
              name
              description
              logo {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        programme_type {
          data {
            id
            attributes {
              title
              is_sub_module
              sub_modules {
                data {
                  id
                  attributes {
                    name_english
                    name_french
                    name_spanish
                    name_mandarin
                    name_german
                    sections {
                      data {
                        id
                        attributes {
                          title_english
                          title_french
                          title_german
                          title_spanish
                          title_mandarin
                          position
                        }
                      }
                    }
                    position
                  }
                }
              }
              archive
              position
            }
          }
        }
      }
      subModuleSubmissions {
        id
        view
        progress
        sub_module {
          data {
            id
            attributes {
              name_french
              name_german
              name_english
              name_spanish
              name_mandarin
              sections {
                data {
                  id
                  attributes {
                    title_french
                    title_german
                    title_english
                    title_spanish
                    title_mandarin
                    position
                  }
                }
              }
              position
            }
          }
        }
        section {
          id
          progress
          section {
            data {
              id
            }
          }
        }
      }
    }
  }
`
export type GetOrderedSubmissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables
  >,
  'query'
> &
  ({ variables: GetOrderedSubmissionsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetOrderedSubmissionsComponent = (props: GetOrderedSubmissionsComponentProps) => (
  <ApolloReactComponents.Query<GetOrderedSubmissionsQuery, GetOrderedSubmissionsQueryVariables>
    query={GetOrderedSubmissionsDocument}
    {...props}
  />
)

export type GetOrderedSubmissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables
  >
} &
  TChildProps
export function withGetOrderedSubmissions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables,
    GetOrderedSubmissionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables,
    GetOrderedSubmissionsProps<TChildProps, TDataName>
  >(GetOrderedSubmissionsDocument, {
    alias: 'getOrderedSubmissions',
    ...operationOptions
  })
}

/**
 * __useGetOrderedSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetOrderedSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderedSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderedSubmissionsQuery({
 *   variables: {
 *      programme: // value for 'programme'
 *   },
 * });
 */
export function useGetOrderedSubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetOrderedSubmissionsQuery, GetOrderedSubmissionsQueryVariables>(
    GetOrderedSubmissionsDocument,
    baseOptions
  )
}
export function useGetOrderedSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetOrderedSubmissionsQuery,
    GetOrderedSubmissionsQueryVariables
  >(GetOrderedSubmissionsDocument, baseOptions)
}
export type GetOrderedSubmissionsQueryHookResult = ReturnType<typeof useGetOrderedSubmissionsQuery>
export type GetOrderedSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetOrderedSubmissionsLazyQuery
>
export type GetOrderedSubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetOrderedSubmissionsQuery,
  GetOrderedSubmissionsQueryVariables
>
export const GetProgammeReportsGroupedDocument = gql`
  query getProgammeReportsGrouped {
    getProgammeReportsGrouped {
      companyName
      programmeType {
        programmeType
        programmeName
        sections {
          programmeName
          programmeType
          name
          report {
            id
            url
          }
        }
      }
    }
  }
`
export type GetProgammeReportsGroupedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >,
  'query'
>

export const GetProgammeReportsGroupedComponent = (
  props: GetProgammeReportsGroupedComponentProps
) => (
  <ApolloReactComponents.Query<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >
    query={GetProgammeReportsGroupedDocument}
    {...props}
  />
)

export type GetProgammeReportsGroupedProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >
} &
  TChildProps
export function withGetProgammeReportsGrouped<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables,
    GetProgammeReportsGroupedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables,
    GetProgammeReportsGroupedProps<TChildProps, TDataName>
  >(GetProgammeReportsGroupedDocument, {
    alias: 'getProgammeReportsGrouped',
    ...operationOptions
  })
}

/**
 * __useGetProgammeReportsGroupedQuery__
 *
 * To run a query within a React component, call `useGetProgammeReportsGroupedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgammeReportsGroupedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgammeReportsGroupedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgammeReportsGroupedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >(GetProgammeReportsGroupedDocument, baseOptions)
}
export function useGetProgammeReportsGroupedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProgammeReportsGroupedQuery,
    GetProgammeReportsGroupedQueryVariables
  >(GetProgammeReportsGroupedDocument, baseOptions)
}
export type GetProgammeReportsGroupedQueryHookResult = ReturnType<
  typeof useGetProgammeReportsGroupedQuery
>
export type GetProgammeReportsGroupedLazyQueryHookResult = ReturnType<
  typeof useGetProgammeReportsGroupedLazyQuery
>
export type GetProgammeReportsGroupedQueryResult = ApolloReactCommon.QueryResult<
  GetProgammeReportsGroupedQuery,
  GetProgammeReportsGroupedQueryVariables
>
export const GetProgrammeDocument = gql`
  query getProgramme($id: ID!) {
    programme(id: $id) {
      data {
        id
        attributes {
          name
          description
          logo {
            data {
              attributes {
                url
              }
            }
          }
          what_to_expect
          completion
        }
      }
    }
  }
`
export type GetProgrammeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetProgrammeQuery, GetProgrammeQueryVariables>,
  'query'
> &
  ({ variables: GetProgrammeQueryVariables; skip?: boolean } | { skip: boolean })

export const GetProgrammeComponent = (props: GetProgrammeComponentProps) => (
  <ApolloReactComponents.Query<GetProgrammeQuery, GetProgrammeQueryVariables>
    query={GetProgrammeDocument}
    {...props}
  />
)

export type GetProgrammeProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetProgrammeQuery, GetProgrammeQueryVariables>
} &
  TChildProps
export function withGetProgramme<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetProgrammeQuery,
    GetProgrammeQueryVariables,
    GetProgrammeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetProgrammeQuery,
    GetProgrammeQueryVariables,
    GetProgrammeProps<TChildProps, TDataName>
  >(GetProgrammeDocument, {
    alias: 'getProgramme',
    ...operationOptions
  })
}

/**
 * __useGetProgrammeQuery__
 *
 * To run a query within a React component, call `useGetProgrammeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgrammeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgrammeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProgrammeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
    GetProgrammeDocument,
    baseOptions
  )
}
export function useGetProgrammeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
    GetProgrammeDocument,
    baseOptions
  )
}
export type GetProgrammeQueryHookResult = ReturnType<typeof useGetProgrammeQuery>
export type GetProgrammeLazyQueryHookResult = ReturnType<typeof useGetProgrammeLazyQuery>
export type GetProgrammeQueryResult = ApolloReactCommon.QueryResult<
  GetProgrammeQuery,
  GetProgrammeQueryVariables
>
export const GetProgrammeProgrammeSubmissionsDocument = gql`
  query getProgrammeProgrammeSubmissions($where: ProgrammeSubmissionFiltersInput!, $limit: Int) {
    programmeSubmissions(filters: $where, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          progress
          programme {
            data {
              id
              attributes {
                name
                description
                logo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          programme_type {
            data {
              id
              attributes {
                title
                is_sub_module
                sub_modules {
                  data {
                    id
                    attributes {
                      name_english
                      name_french
                      name_spanish
                      name_mandarin
                      name_german
                      sections {
                        data {
                          id
                          attributes {
                            title_english
                            title_french
                            title_german
                            title_spanish
                            title_mandarin
                          }
                        }
                      }
                      position
                    }
                  }
                }
                archive
                position
              }
            }
          }
        }
      }
    }
  }
`
export type GetProgrammeProgrammeSubmissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetProgrammeProgrammeSubmissionsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const GetProgrammeProgrammeSubmissionsComponent = (
  props: GetProgrammeProgrammeSubmissionsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >
    query={GetProgrammeProgrammeSubmissionsDocument}
    {...props}
  />
)

export type GetProgrammeProgrammeSubmissionsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >
} &
  TChildProps
export function withGetProgrammeProgrammeSubmissions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables,
    GetProgrammeProgrammeSubmissionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables,
    GetProgrammeProgrammeSubmissionsProps<TChildProps, TDataName>
  >(GetProgrammeProgrammeSubmissionsDocument, {
    alias: 'getProgrammeProgrammeSubmissions',
    ...operationOptions
  })
}

/**
 * __useGetProgrammeProgrammeSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetProgrammeProgrammeSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgrammeProgrammeSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgrammeProgrammeSubmissionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProgrammeProgrammeSubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >(GetProgrammeProgrammeSubmissionsDocument, baseOptions)
}
export function useGetProgrammeProgrammeSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProgrammeProgrammeSubmissionsQuery,
    GetProgrammeProgrammeSubmissionsQueryVariables
  >(GetProgrammeProgrammeSubmissionsDocument, baseOptions)
}
export type GetProgrammeProgrammeSubmissionsQueryHookResult = ReturnType<
  typeof useGetProgrammeProgrammeSubmissionsQuery
>
export type GetProgrammeProgrammeSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetProgrammeProgrammeSubmissionsLazyQuery
>
export type GetProgrammeProgrammeSubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetProgrammeProgrammeSubmissionsQuery,
  GetProgrammeProgrammeSubmissionsQueryVariables
>
export const GetMyProgrammeSubmissionDocument = gql`
  query getMyProgrammeSubmission($id: ID!) {
    programmeSubmission(id: $id) {
      data {
        id
        attributes {
          status
          progress
          competency_url {
            data {
              id
              attributes {
                url
              }
            }
          }
          feedfoward_url {
            data {
              id
              attributes {
                url
              }
            }
          }
          programme_type {
            data {
              id
              attributes {
                title
                description
                show_participants
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                is_coaching
                is_sub_module
                notes
                deadline
                programme_questions {
                  __typename
                  ... on ComponentQuestionTypesValuesNested {
                    id
                    title
                    question
                    description
                    questions {
                      id
                      titleEnglish
                      subTitleEnglish
                      titleFrench
                      subTitleFrench
                      titleGerman
                      subTitleGerman
                      titleSpanish
                      subTitleSpanish
                      titleMandarin
                      subTitleMandarin
                    }
                  }
                  ... on ComponentQuestionTypesCompetency2 {
                    titleEnglish
                    titleFrench
                    titleMandarin
                    questionscompetency2 {
                      title
                      question
                      maxiNumber
                      miniNumber
                      simpleQuestion
                      miniLabel
                      maxiLabel
                      midiLabel
                      textboxQuestion
                    }
                  }
                }
                articles {
                  data {
                    id
                    attributes {
                      createdAt
                      updatedAt
                      title_english
                      title_french
                      title_german
                      title_spanish
                      title_mandarin
                      content_english
                      content_french
                      content_german
                      content_spanish
                      content_mandarin
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                      date_published
                      read_time
                      link
                    }
                  }
                }
                events {
                  data {
                    id
                    attributes {
                      name
                      link
                      session_id
                    }
                  }
                }
                sub_modules {
                  data {
                    id
                    attributes {
                      name_english
                      description_english
                      cover {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      programme_type {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          programme_responses {
            answers {
              id
              questionId
              answer
              nestedAnswers {
                answer
                questionTitle
              }
              isCorrect
              feedback
              outcome
              questionType
              questionTitle
            }
            stakeholderChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            stakeholderItems {
              id
              valueX
              valueY
              name
              color
            }
            competencyAnswers {
              question
              rating
              response
              steps
              textboxQuestion
            }
            gravitasScales {
              id
              labelOne
              labelTwo
              labelThree
              question
              answerOne
              answerTwo
              answerThree
            }
            xYGraphChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            saboteurImage {
              data {
                id
                attributes {
                  url
                }
              }
            }
            xYGraphItems {
              id
              valueX
              valueY
              color
            }
          }
          participants {
            data {
              id
              attributes {
                name
                email
                type
              }
            }
          }
          submission_participants {
            id
            name
            email
          }
          my_notes {
            id
            title
            description
            document {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
          selected
        }
      }
    }
  }
`
export type GetMyProgrammeSubmissionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >,
  'query'
> &
  ({ variables: GetMyProgrammeSubmissionQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMyProgrammeSubmissionComponent = (
  props: GetMyProgrammeSubmissionComponentProps
) => (
  <ApolloReactComponents.Query<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >
    query={GetMyProgrammeSubmissionDocument}
    {...props}
  />
)

export type GetMyProgrammeSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >
} &
  TChildProps
export function withGetMyProgrammeSubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables,
    GetMyProgrammeSubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables,
    GetMyProgrammeSubmissionProps<TChildProps, TDataName>
  >(GetMyProgrammeSubmissionDocument, {
    alias: 'getMyProgrammeSubmission',
    ...operationOptions
  })
}

/**
 * __useGetMyProgrammeSubmissionQuery__
 *
 * To run a query within a React component, call `useGetMyProgrammeSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProgrammeSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProgrammeSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyProgrammeSubmissionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >(GetMyProgrammeSubmissionDocument, baseOptions)
}
export function useGetMyProgrammeSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMyProgrammeSubmissionQuery,
    GetMyProgrammeSubmissionQueryVariables
  >(GetMyProgrammeSubmissionDocument, baseOptions)
}
export type GetMyProgrammeSubmissionQueryHookResult = ReturnType<
  typeof useGetMyProgrammeSubmissionQuery
>
export type GetMyProgrammeSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetMyProgrammeSubmissionLazyQuery
>
export type GetMyProgrammeSubmissionQueryResult = ApolloReactCommon.QueryResult<
  GetMyProgrammeSubmissionQuery,
  GetMyProgrammeSubmissionQueryVariables
>
export const GetProgrammesDocument = gql`
  query getProgrammes {
    programmes {
      data {
        id
        attributes {
          name
          description
          logo {
            data {
              attributes {
                url
              }
            }
          }
          programme_types {
            data {
              attributes {
                title
                sub_modules {
                  data {
                    attributes {
                      sections {
                        data {
                          attributes {
                            title_english
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export type GetProgrammesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetProgrammesQuery, GetProgrammesQueryVariables>,
  'query'
>

export const GetProgrammesComponent = (props: GetProgrammesComponentProps) => (
  <ApolloReactComponents.Query<GetProgrammesQuery, GetProgrammesQueryVariables>
    query={GetProgrammesDocument}
    {...props}
  />
)

export type GetProgrammesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetProgrammesQuery, GetProgrammesQueryVariables>
} &
  TChildProps
export function withGetProgrammes<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetProgrammesQuery,
    GetProgrammesQueryVariables,
    GetProgrammesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetProgrammesQuery,
    GetProgrammesQueryVariables,
    GetProgrammesProps<TChildProps, TDataName>
  >(GetProgrammesDocument, {
    alias: 'getProgrammes',
    ...operationOptions
  })
}

/**
 * __useGetProgrammesQuery__
 *
 * To run a query within a React component, call `useGetProgrammesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgrammesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgrammesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgrammesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetProgrammesQuery, GetProgrammesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetProgrammesQuery, GetProgrammesQueryVariables>(
    GetProgrammesDocument,
    baseOptions
  )
}
export function useGetProgrammesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProgrammesQuery,
    GetProgrammesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetProgrammesQuery, GetProgrammesQueryVariables>(
    GetProgrammesDocument,
    baseOptions
  )
}
export type GetProgrammesQueryHookResult = ReturnType<typeof useGetProgrammesQuery>
export type GetProgrammesLazyQueryHookResult = ReturnType<typeof useGetProgrammesLazyQuery>
export type GetProgrammesQueryResult = ApolloReactCommon.QueryResult<
  GetProgrammesQuery,
  GetProgrammesQueryVariables
>
export const GetRegionsDocument = gql`
  query getRegions {
    regions {
      data {
        id
        attributes {
          name_english
          name_french
          name_german
          name_spanish
          name_mandarin
          createdAt
          updatedAt
        }
      }
    }
  }
`
export type GetRegionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetRegionsQuery, GetRegionsQueryVariables>,
  'query'
>

export const GetRegionsComponent = (props: GetRegionsComponentProps) => (
  <ApolloReactComponents.Query<GetRegionsQuery, GetRegionsQueryVariables>
    query={GetRegionsDocument}
    {...props}
  />
)

export type GetRegionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetRegionsQuery, GetRegionsQueryVariables>
} &
  TChildProps
export function withGetRegions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetRegionsQuery,
    GetRegionsQueryVariables,
    GetRegionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetRegionsQuery,
    GetRegionsQueryVariables,
    GetRegionsProps<TChildProps, TDataName>
  >(GetRegionsDocument, {
    alias: 'getRegions',
    ...operationOptions
  })
}

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    baseOptions
  )
}
export function useGetRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    baseOptions
  )
}
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>
export type GetRegionsQueryResult = ApolloReactCommon.QueryResult<
  GetRegionsQuery,
  GetRegionsQueryVariables
>
export const GetReportsGroupedDocument = gql`
  query getReportsGrouped {
    getReportsGrouped {
      companyName
      programmeType {
        programmeType
        programmeName
        sections {
          programmeName
          programmeType
          name
          report {
            id
            url
          }
        }
      }
    }
  }
`
export type GetReportsGroupedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetReportsGroupedQuery,
    GetReportsGroupedQueryVariables
  >,
  'query'
>

export const GetReportsGroupedComponent = (props: GetReportsGroupedComponentProps) => (
  <ApolloReactComponents.Query<GetReportsGroupedQuery, GetReportsGroupedQueryVariables>
    query={GetReportsGroupedDocument}
    {...props}
  />
)

export type GetReportsGroupedProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetReportsGroupedQuery,
    GetReportsGroupedQueryVariables
  >
} &
  TChildProps
export function withGetReportsGrouped<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetReportsGroupedQuery,
    GetReportsGroupedQueryVariables,
    GetReportsGroupedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetReportsGroupedQuery,
    GetReportsGroupedQueryVariables,
    GetReportsGroupedProps<TChildProps, TDataName>
  >(GetReportsGroupedDocument, {
    alias: 'getReportsGrouped',
    ...operationOptions
  })
}

/**
 * __useGetReportsGroupedQuery__
 *
 * To run a query within a React component, call `useGetReportsGroupedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsGroupedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsGroupedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportsGroupedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetReportsGroupedQuery,
    GetReportsGroupedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetReportsGroupedQuery, GetReportsGroupedQueryVariables>(
    GetReportsGroupedDocument,
    baseOptions
  )
}
export function useGetReportsGroupedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReportsGroupedQuery,
    GetReportsGroupedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetReportsGroupedQuery, GetReportsGroupedQueryVariables>(
    GetReportsGroupedDocument,
    baseOptions
  )
}
export type GetReportsGroupedQueryHookResult = ReturnType<typeof useGetReportsGroupedQuery>
export type GetReportsGroupedLazyQueryHookResult = ReturnType<typeof useGetReportsGroupedLazyQuery>
export type GetReportsGroupedQueryResult = ApolloReactCommon.QueryResult<
  GetReportsGroupedQuery,
  GetReportsGroupedQueryVariables
>
export const GetSectionDocument = gql`
  query getSection($id: ID!) {
    section(id: $id) {
      data {
        id
        attributes {
          title_english
          sub_title_english
          get_started_text
          cover {
            data {
              id
              attributes {
                url
              }
            }
          }
          video_english {
            data {
              id
              attributes {
                url
              }
            }
          }
          questions {
            __typename
            ... on ComponentQuestionTypesValuesNested {
              id
              title
              question
              description
              questions {
                id
                titleEnglish
                subTitleEnglish
                titleFrench
                subTitleFrench
                titleGerman
                subTitleGerman
                titleSpanish
                subTitleSpanish
                titleMandarin
                subTitleMandarin
              }
            }
            ... on ComponentQuestionTypesValuesCharacters {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesValuesBubble {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesValuesSliders {
              id
              title
              question
              description
              sliderQuestions {
                id
                question
                color
              }
            }
            ... on ComponentQuestionTypesValuesGaps {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesSimpleQuestion {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleSpanish
              subTitleMandarin
            }
            ... on ComponentQuestionTypesXYGraph {
              id
              labelXEnglish
              labelYEnglish
              titleEnglish
              subTitleEnglish
              labelXFrench
              labelYFrench
              titleFrench
              subTitleFrench
              labelXGerman
              labelYGerman
              titleGerman
              subTitleGerman
              labelXSpanish
              labelYSpanish
              titleSpanish
              subTitleSpanish
              labelXMandarin
              labelYMandarin
              titleMandarin
              subTitleMandarin
            }
            ... on ComponentQuestionTypesMultipleChoice {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              viewPrevious
              option {
                id
                optionEnglish
                optionFrench
                optionGerman
                optionSpanish
                optionMandarin
                isCorrect
              }
              numberOfAnswers
            }
            ... on ComponentQuestionTypesSaboteur {
              id
              titleEnglish
              subTitleEnglish
              titleFrench
              subTitleFrench
              titleGerman
              subTitleGerman
              titleSpanish
              subTitleSpanish
              titleMandarin
              subTitleMandarin
              saboteurQuestions {
                link
                skipProof
                labelEnglish
                labelFrench
                labelGerman
                labelSpanish
                labelMandarin
                proof {
                  data {
                    ...uploadFile
                  }
                }
              }
              labelEnglish
              labelFrench
              labelGerman
              labelSpanish
            }
            ... on ComponentQuestionTypesNestedQuestions {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              viewPrevious
              questions {
                id
                titleEnglish
                subTitleEnglish
                titleFrench
                subTitleFrench
                titleGerman
                subTitleGerman
                titleSpanish
                subTitleSpanish
                titleMandarin
                subTitleMandarin
                isNumeric
              }
            }
            ... on ComponentQuestionTypesGravitasScale {
              id
              titleEnglish
              subTitleEnglish
              questionOneEnglish
              questionTwoEnglish
              questionThreeEnglish
              titleOneEnglish
              titleTwoEnglish
              ratingInput {
                id
                labelOneEnglish
                labelTwoEnglish
              }
            }
            ... on ComponentQuestionTypesStakeholderMap {
              id
              titleEnglish
              subTitleEnglish
              titleFrench
              subTitleFrench
              titleSpanish
              subTitleSpanish
              titleGerman
              subTitleGerman
              titleMandarin
              subTitleMandarin
              questionX
              questionY
            }
            ... on ComponentQuestionTypesSaboteurVideos {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleSpanish
              subTitleMandarin
              SaboteurVideo {
                id
                videoTitleEnglish
                videoTitleFrench
                videoTitleGerman
                videoTitleSpanish
                videoTitleMandarin
                video {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            ... on ComponentQuestionTypesFeedForward1 {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesFeedForward2 {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesSimpleReadOnly {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleMandarin
              titleSpanish
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleMandarin
              subTitleSpanish
              contentEnglish
              contentFrench
              contentGerman
              contentMandarin
              contentSpanish
              photo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentQuestionTypesCompetency {
              id
              title
              question
              minNumber
              maxNumber
              miniLabel
              maxiLabel
              simpleQuestion
            }
            ... on ComponentQuestionTypesCompetency2 {
              titleEnglish
              titleFrench
              titleMandarin
              questionscompetency2 {
                title
                question
                maxiNumber
                miniNumber
                simpleQuestion
                miniLabel
                maxiLabel
                midiLabel
                textboxQuestion
              }
            }
            ... on ComponentQuestionTypesUploadDocuments {
              id
              titleEnglish
              titleFrench
              titleMandarin
              titleSpanish
              titleGerman
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleMandarin
              subTitleSpanish
            }
            ... on ComponentQuestionTypesGravitasGap {
              id
              title
              question
              description
              color1
              color2
              color3
            }
            ... on ComponentQuestionTypesGravitasSliders {
              titleEnglish
              titleFrench
              titleMandarin
              titleSpanish
              titleGerman
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              positiveHeadingEnglish
              positiveHeadingFrench
              positiveHeadingSpanish
              positiveHeadingMandarin
              positiveHeadingGerman
              negativeHeadingEnglish
              negativeHeadingFrench
              negativeHeadingSpanish
              negativeHeadingMandarin
              negativeHeadingGerman
              sliderQuestions {
                questionPositiveEnglish
                questionPositiveGerman
                questionPositiveMandarin
                questionPositiveSpanish
                questionPositiveFrench
                questionNegativeEnglish
                questionNegativeGerman
                questionNegativeFrench
                questionNegativeMandarin
                questionNegativeSpanish
              }
              sliderHeadings {
                headingEnglish
                headingSpanish
                headingGerman
                headingFrench
                rating
                color
              }
            }
          }
          events {
            data {
              id
              attributes {
                name
                link
                session_id
              }
            }
          }
          show_session
          sub_module {
            data {
              id
              attributes {
                programme_type {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UploadFileFragmentDoc}
`
export type GetSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetSectionQuery, GetSectionQueryVariables>,
  'query'
> &
  ({ variables: GetSectionQueryVariables; skip?: boolean } | { skip: boolean })

export const GetSectionComponent = (props: GetSectionComponentProps) => (
  <ApolloReactComponents.Query<GetSectionQuery, GetSectionQueryVariables>
    query={GetSectionDocument}
    {...props}
  />
)

export type GetSectionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetSectionQuery, GetSectionQueryVariables>
} &
  TChildProps
export function withGetSection<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSectionQuery,
    GetSectionQueryVariables,
    GetSectionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSectionQuery,
    GetSectionQueryVariables,
    GetSectionProps<TChildProps, TDataName>
  >(GetSectionDocument, {
    alias: 'getSection',
    ...operationOptions
  })
}

/**
 * __useGetSectionQuery__
 *
 * To run a query within a React component, call `useGetSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSectionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSectionQuery, GetSectionQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSectionQuery, GetSectionQueryVariables>(
    GetSectionDocument,
    baseOptions
  )
}
export function useGetSectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSectionQuery, GetSectionQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSectionQuery, GetSectionQueryVariables>(
    GetSectionDocument,
    baseOptions
  )
}
export type GetSectionQueryHookResult = ReturnType<typeof useGetSectionQuery>
export type GetSectionLazyQueryHookResult = ReturnType<typeof useGetSectionLazyQuery>
export type GetSectionQueryResult = ApolloReactCommon.QueryResult<
  GetSectionQuery,
  GetSectionQueryVariables
>
export const GetSectionsDocument = gql`
  query getSections {
    sections {
      data {
        id
        attributes {
          title_english
          sub_title_english
          title_french
          title_german
          title_spanish
          title_mandarin
          sub_title_french
          sub_title_german
          sub_title_spanish
          sub_title_mandarin
          video_english {
            data {
              id
              attributes {
                url
              }
            }
          }
          video_french {
            data {
              id
              attributes {
                url
              }
            }
          }
          video_german {
            data {
              id
              attributes {
                url
              }
            }
          }
          video_spanish {
            data {
              id
              attributes {
                url
              }
            }
          }
          video_mandarin {
            data {
              id
              attributes {
                url
              }
            }
          }
          questions {
            __typename
            ... on ComponentQuestionTypesValuesNested {
              id
              title
              question
              description
              questions {
                id
                titleEnglish
                subTitleEnglish
                titleFrench
                subTitleFrench
                titleGerman
                subTitleGerman
                titleSpanish
                subTitleSpanish
                titleMandarin
                subTitleMandarin
              }
            }
            ... on ComponentQuestionTypesValuesCharacters {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesValuesBubble {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesValuesSliders {
              id
              title
              question
              description
              sliderQuestions {
                id
                question
                color
              }
            }
            ... on ComponentQuestionTypesValuesGaps {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesSimpleQuestion {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleSpanish
              subTitleMandarin
            }
            ... on ComponentQuestionTypesXYGraph {
              id
              labelXEnglish
              labelYEnglish
              titleEnglish
              subTitleEnglish
              labelXFrench
              labelYFrench
              titleFrench
              subTitleFrench
              labelXGerman
              labelYGerman
              titleGerman
              subTitleGerman
              labelXSpanish
              labelYSpanish
              titleSpanish
              subTitleSpanish
              labelXMandarin
              labelYMandarin
              titleMandarin
              subTitleMandarin
            }
            ... on ComponentQuestionTypesMultipleChoice {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              option {
                id
                optionEnglish
                optionFrench
                optionGerman
                optionSpanish
                optionMandarin
                isCorrect
              }
              numberOfAnswers
            }
            ... on ComponentQuestionTypesSaboteur {
              id
              titleEnglish
              subTitleEnglish
              titleFrench
              subTitleFrench
              titleGerman
              subTitleGerman
              titleSpanish
              subTitleSpanish
              titleMandarin
              subTitleMandarin
              saboteurQuestions {
                link
                skipProof
                labelEnglish
                labelFrench
                labelGerman
                labelSpanish
                labelMandarin
                proof {
                  data {
                    ...uploadFile
                  }
                }
              }
              labelEnglish
              labelFrench
              labelGerman
              labelSpanish
            }
            ... on ComponentQuestionTypesNestedQuestions {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              questions {
                id
                titleEnglish
                subTitleEnglish
                titleFrench
                subTitleFrench
                titleGerman
                subTitleGerman
                titleSpanish
                subTitleSpanish
                titleMandarin
                subTitleMandarin
                isNumeric
              }
            }
            ... on ComponentQuestionTypesGravitasScale {
              id
              titleEnglish
              subTitleEnglish
              questionOneEnglish
              questionTwoEnglish
              questionThreeEnglish
              titleOneEnglish
              titleTwoEnglish
              titleFrench
              subTitleFrench
              questionOneFrench
              questionTwoFrench
              questionThreeFrench
              titleOneFrench
              titleTwoFrench
              titleGerman
              subTitleGerman
              questionOneGerman
              questionTwoGerman
              questionThreeGerman
              titleOneGerman
              titleTwoGerman
              titleSpanish
              subTitleSpanish
              questionOneSpanish
              questionTwoSpanish
              questionThreeSpanish
              titleOneSpanish
              titleTwoSpanish
              titleMandarin
              subTitleMandarin
              questionOneMandarin
              questionTwoMandarin
              questionThreeMandarin
              titleOneMandarin
              titleTwoMandarin
              ratingInput {
                id
                labelOneEnglish
                labelTwoEnglish
                labelOneFrench
                labelTwoFrench
                labelOneGerman
                labelTwoGerman
                labelOneSpanish
                labelTwoSpanish
                labelOneMandarin
                labelTwoMandarin
              }
            }
            ... on ComponentQuestionTypesStakeholderMap {
              id
              titleEnglish
              subTitleEnglish
              titleFrench
              subTitleFrench
              titleGerman
              subTitleGerman
              titleSpanish
              subTitleSpanish
              titleMandarin
              subTitleMandarin
              questionX
              questionY
            }
            ... on ComponentQuestionTypesSaboteurVideos {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleSpanish
              titleMandarin
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleSpanish
              subTitleMandarin
              SaboteurVideo {
                id
                videoTitleEnglish
                videoTitleFrench
                videoTitleGerman
                videoTitleSpanish
                videoTitleMandarin
                video {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            ... on ComponentQuestionTypesFeedForward1 {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesFeedForward2 {
              id
              title
              question
              description
            }
            ... on ComponentQuestionTypesSimpleReadOnly {
              id
              titleEnglish
              titleFrench
              titleGerman
              titleMandarin
              titleSpanish
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleMandarin
              subTitleSpanish
              contentEnglish
              contentFrench
              contentGerman
              contentMandarin
              contentSpanish
              photo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentQuestionTypesCompetency {
              id
              title
              question
              minNumber
              maxNumber
              miniLabel
              maxiLabel
              simpleQuestion
            }
            ... on ComponentQuestionTypesUploadDocuments {
              id
              titleEnglish
              titleFrench
              titleMandarin
              titleSpanish
              titleGerman
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              subTitleMandarin
              subTitleSpanish
            }
            ... on ComponentQuestionTypesGravitasGap {
              id
              title
              question
              description
              color1
              color2
              color3
            }
            ... on ComponentQuestionTypesGravitasSliders {
              titleEnglish
              titleFrench
              titleMandarin
              titleSpanish
              titleGerman
              subTitleEnglish
              subTitleFrench
              subTitleGerman
              positiveHeadingEnglish
              positiveHeadingFrench
              positiveHeadingSpanish
              positiveHeadingMandarin
              positiveHeadingGerman
              negativeHeadingEnglish
              negativeHeadingFrench
              negativeHeadingSpanish
              negativeHeadingMandarin
              negativeHeadingGerman
              sliderQuestions {
                questionPositiveEnglish
                questionPositiveGerman
                questionPositiveMandarin
                questionPositiveSpanish
                questionPositiveFrench
                questionNegativeEnglish
                questionNegativeGerman
                questionNegativeFrench
                questionNegativeMandarin
                questionNegativeSpanish
              }
              sliderHeadings {
                headingEnglish
                headingSpanish
                headingGerman
                headingFrench
                headingMandarin
                color
                rating
              }
            }
            ... on ComponentQuestionTypesCompetency2 {
              titleEnglish
              titleFrench
              titleMandarin
              questionscompetency2 {
                title
                question
                maxiNumber
                miniNumber
                simpleQuestion
                miniLabel
                maxiLabel
                midiLabel
                textboxQuestion
              }
            }
          }
          events {
            data {
              id
              attributes {
                name
                link
                session_id
              }
            }
          }
          show_session
          archive
        }
      }
    }
  }
  ${UploadFileFragmentDoc}
`
export type GetSectionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetSectionsQuery, GetSectionsQueryVariables>,
  'query'
>

export const GetSectionsComponent = (props: GetSectionsComponentProps) => (
  <ApolloReactComponents.Query<GetSectionsQuery, GetSectionsQueryVariables>
    query={GetSectionsDocument}
    {...props}
  />
)

export type GetSectionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetSectionsQuery, GetSectionsQueryVariables>
} &
  TChildProps
export function withGetSections<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSectionsQuery,
    GetSectionsQueryVariables,
    GetSectionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSectionsQuery,
    GetSectionsQueryVariables,
    GetSectionsProps<TChildProps, TDataName>
  >(GetSectionsDocument, {
    alias: 'getSections',
    ...operationOptions
  })
}

/**
 * __useGetSectionsQuery__
 *
 * To run a query within a React component, call `useGetSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSectionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSectionsQuery, GetSectionsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSectionsQuery, GetSectionsQueryVariables>(
    GetSectionsDocument,
    baseOptions
  )
}
export function useGetSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSectionsQuery, GetSectionsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSectionsQuery, GetSectionsQueryVariables>(
    GetSectionsDocument,
    baseOptions
  )
}
export type GetSectionsQueryHookResult = ReturnType<typeof useGetSectionsQuery>
export type GetSectionsLazyQueryHookResult = ReturnType<typeof useGetSectionsLazyQuery>
export type GetSectionsQueryResult = ApolloReactCommon.QueryResult<
  GetSectionsQuery,
  GetSectionsQueryVariables
>
export const GetCoachSessionsDocument = gql`
  query getCoachSessions {
    coachSessions {
      data {
        id
        attributes {
          title
          status
          user {
            data {
              id
            }
          }
          programme_submission {
            data {
              id
            }
          }
          sub_module_submission {
            data {
              id
            }
          }
          appointment {
            data {
              attributes {
                appointment_url
                appointment_start_date
                appointment_end_date
                status
              }
            }
          }
        }
      }
    }
  }
`
export type GetCoachSessionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >,
  'query'
>

export const GetCoachSessionsComponent = (props: GetCoachSessionsComponentProps) => (
  <ApolloReactComponents.Query<GetCoachSessionsQuery, GetCoachSessionsQueryVariables>
    query={GetCoachSessionsDocument}
    {...props}
  />
)

export type GetCoachSessionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >
} &
  TChildProps
export function withGetCoachSessions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables,
    GetCoachSessionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables,
    GetCoachSessionsProps<TChildProps, TDataName>
  >(GetCoachSessionsDocument, {
    alias: 'getCoachSessions',
    ...operationOptions
  })
}

/**
 * __useGetCoachSessionsQuery__
 *
 * To run a query within a React component, call `useGetCoachSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoachSessionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCoachSessionsQuery, GetCoachSessionsQueryVariables>(
    GetCoachSessionsDocument,
    baseOptions
  )
}
export function useGetCoachSessionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCoachSessionsQuery, GetCoachSessionsQueryVariables>(
    GetCoachSessionsDocument,
    baseOptions
  )
}
export type GetCoachSessionsQueryHookResult = ReturnType<typeof useGetCoachSessionsQuery>
export type GetCoachSessionsLazyQueryHookResult = ReturnType<typeof useGetCoachSessionsLazyQuery>
export type GetCoachSessionsQueryResult = ApolloReactCommon.QueryResult<
  GetCoachSessionsQuery,
  GetCoachSessionsQueryVariables
>
export const GetSubModuleDocument = gql`
  query getSubModule($id: ID!) {
    subModule(id: $id) {
      data {
        id
        attributes {
          name_english
          name_french
          name_spanish
          name_german
          name_mandarin
          description_english
          description_french
          description_spanish
          description_german
          description_mandarin
          cover {
            data {
              id
              attributes {
                url
              }
            }
          }
          sections {
            data {
              id
              attributes {
                title_french
                title_german
                title_english
                title_spanish
                title_mandarin
                sub_title_french
                sub_title_german
                sub_title_english
                sub_title_spanish
                sub_title_mandarin
                video_english {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_german {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_spanish {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_mandarin {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_french {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                questions {
                  ...questions
                }
                position
                archive
              }
            }
          }
          programme_type {
            data {
              id
              attributes {
                title
                description
                position
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                is_coaching
                is_sub_module
                notes
                show_article
                show_session
                show_participants
                competency_survey
                feedForward_survey
                deadline
                articles {
                  data {
                    id
                    attributes {
                      createdAt
                      updatedAt
                      title_english
                      title_french
                      title_german
                      title_spanish
                      title_mandarin
                      content_english
                      content_french
                      content_german
                      content_spanish
                      content_mandarin
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                      date_published
                      read_time
                      link
                    }
                  }
                }
                modal {
                  data {
                    id
                    attributes {
                      title
                      subtitle
                      message
                      image {
                        data {
                          id
                          attributes {
                            url
                            name
                          }
                        }
                      }
                    }
                  }
                }
                events {
                  data {
                    id
                    attributes {
                      name
                      link
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${QuestionsFragmentDoc}
`
export type GetSubModuleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetSubModuleQuery, GetSubModuleQueryVariables>,
  'query'
> &
  ({ variables: GetSubModuleQueryVariables; skip?: boolean } | { skip: boolean })

export const GetSubModuleComponent = (props: GetSubModuleComponentProps) => (
  <ApolloReactComponents.Query<GetSubModuleQuery, GetSubModuleQueryVariables>
    query={GetSubModuleDocument}
    {...props}
  />
)

export type GetSubModuleProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetSubModuleQuery, GetSubModuleQueryVariables>
} &
  TChildProps
export function withGetSubModule<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubModuleQuery,
    GetSubModuleQueryVariables,
    GetSubModuleProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubModuleQuery,
    GetSubModuleQueryVariables,
    GetSubModuleProps<TChildProps, TDataName>
  >(GetSubModuleDocument, {
    alias: 'getSubModule',
    ...operationOptions
  })
}

/**
 * __useGetSubModuleQuery__
 *
 * To run a query within a React component, call `useGetSubModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubModuleQuery, GetSubModuleQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSubModuleQuery, GetSubModuleQueryVariables>(
    GetSubModuleDocument,
    baseOptions
  )
}
export function useGetSubModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubModuleQuery, GetSubModuleQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSubModuleQuery, GetSubModuleQueryVariables>(
    GetSubModuleDocument,
    baseOptions
  )
}
export type GetSubModuleQueryHookResult = ReturnType<typeof useGetSubModuleQuery>
export type GetSubModuleLazyQueryHookResult = ReturnType<typeof useGetSubModuleLazyQuery>
export type GetSubModuleQueryResult = ApolloReactCommon.QueryResult<
  GetSubModuleQuery,
  GetSubModuleQueryVariables
>
export const GetMySubModuleSubmissionDocument = gql`
  query getMySubModuleSubmission($id: ID!) {
    subModuleSubmission(id: $id) {
      data {
        id
        attributes {
          view
          progress
          status
          time_spent
          participants {
            data {
              id
              attributes {
                name
                email
                type
              }
            }
          }
          submission_participants {
            id
            name
            email
          }
          feedfoward_url {
            data {
              id
            }
          }
          language {
            data {
              id
              attributes {
                name
              }
            }
          }
          sub_module {
            data {
              id
              attributes {
                name_french
                name_german
                name_english
                name_spanish
                name_mandarin
                description_french
                description_german
                description_english
                description_spanish
                description_mandarin
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                sections {
                  data {
                    id
                    attributes {
                      title_french
                      title_german
                      title_english
                      title_spanish
                      title_mandarin
                      sub_title_french
                      sub_title_german
                      sub_title_english
                      sub_title_spanish
                      sub_title_mandarin
                      cover {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      position
                      archive
                    }
                  }
                }
                position
                programme_type {
                  data {
                    id
                    attributes {
                      title
                      description
                      position
                      cover {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      is_coaching
                      is_sub_module
                      notes
                      show_article
                      show_session
                      show_participants
                      competency_survey
                      feedForward_survey
                      deadline
                      articles {
                        data {
                          id
                          attributes {
                            createdAt
                            updatedAt
                            title_english
                            title_french
                            title_german
                            title_spanish
                            title_mandarin
                            content_english
                            content_french
                            content_german
                            content_spanish
                            content_mandarin
                            image {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                            date_published
                            read_time
                            link
                          }
                        }
                      }
                      modal {
                        data {
                          id
                          attributes {
                            title
                            subtitle
                            message
                            image {
                              data {
                                id
                                attributes {
                                  url
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                      events {
                        data {
                          id
                          attributes {
                            name
                            link
                          }
                        }
                      }
                      show_statistics
                    }
                  }
                }
              }
            }
          }
          section {
            id
            progress
            section {
              data {
                id
              }
            }
            answers {
              id
              questionId
              answer
              nestedAnswers {
                answer
                questionTitle
              }
              isCorrect
              feedback
              outcome
              questionType
              questionTitle
            }
            stakeholderChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            stakeholderItems {
              id
              valueX
              valueY
              name
              color
            }
            gravitasScales {
              id
              labelOne
              labelTwo
              labelThree
              question
              answerOne
              answerTwo
              answerThree
            }
            gravitasSliderAnswers {
              negativeQuestion
              positiveQuestion
              colorAndRating {
                color
                rate
              }
            }
            gravitasGap {
              id
              gap
              commitment
            }
            xYGraphChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            saboteurImage {
              data {
                id
                attributes {
                  url
                }
              }
            }
            xYGraphItems {
              id
              valueX
              valueY
              color
            }
            characteristics {
              id
              answer
              type
            }
            sliderAnswers
            gapsAnswers {
              id
              gap
              commitment
            }
            competencyAnswers {
              id
              question
              rating
              steps
              response
            }
            uploadDocuments {
              saboteurProofValue {
                data {
                  id
                }
              }
            }
          }
          reports {
            data {
              id
              attributes {
                name
                type
                report {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                section_or_module_id
              }
            }
          }
        }
      }
    }
  }
`
export type GetMySubModuleSubmissionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >,
  'query'
> &
  ({ variables: GetMySubModuleSubmissionQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMySubModuleSubmissionComponent = (
  props: GetMySubModuleSubmissionComponentProps
) => (
  <ApolloReactComponents.Query<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >
    query={GetMySubModuleSubmissionDocument}
    {...props}
  />
)

export type GetMySubModuleSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >
} &
  TChildProps
export function withGetMySubModuleSubmission<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables,
    GetMySubModuleSubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables,
    GetMySubModuleSubmissionProps<TChildProps, TDataName>
  >(GetMySubModuleSubmissionDocument, {
    alias: 'getMySubModuleSubmission',
    ...operationOptions
  })
}

/**
 * __useGetMySubModuleSubmissionQuery__
 *
 * To run a query within a React component, call `useGetMySubModuleSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubModuleSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubModuleSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMySubModuleSubmissionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >(GetMySubModuleSubmissionDocument, baseOptions)
}
export function useGetMySubModuleSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMySubModuleSubmissionQuery,
    GetMySubModuleSubmissionQueryVariables
  >(GetMySubModuleSubmissionDocument, baseOptions)
}
export type GetMySubModuleSubmissionQueryHookResult = ReturnType<
  typeof useGetMySubModuleSubmissionQuery
>
export type GetMySubModuleSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetMySubModuleSubmissionLazyQuery
>
export type GetMySubModuleSubmissionQueryResult = ApolloReactCommon.QueryResult<
  GetMySubModuleSubmissionQuery,
  GetMySubModuleSubmissionQueryVariables
>
export const GetSubModulesDocument = gql`
  query getSubModules($where: SubModuleFiltersInput!) {
    subModules(filters: $where) {
      data {
        id
        attributes {
          name_english
          name_french
          name_german
          name_spanish
          name_mandarin
          description_english
          description_french
          description_german
          description_spanish
          description_mandarin
          cover {
            data {
              id
              attributes {
                url
              }
            }
          }
          sections {
            data {
              id
              attributes {
                title_english
                title_french
                title_german
                title_spanish
                title_mandarin
                sub_title_english
                sub_title_french
                sub_title_german
                sub_title_spanish
                sub_title_mandarin
                video_english {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_french {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_german {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_spanish {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                video_mandarin {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                questions {
                  ...questions
                }
                archive
              }
            }
          }
        }
      }
    }
  }
  ${QuestionsFragmentDoc}
`
export type GetSubModulesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetSubModulesQuery, GetSubModulesQueryVariables>,
  'query'
> &
  ({ variables: GetSubModulesQueryVariables; skip?: boolean } | { skip: boolean })

export const GetSubModulesComponent = (props: GetSubModulesComponentProps) => (
  <ApolloReactComponents.Query<GetSubModulesQuery, GetSubModulesQueryVariables>
    query={GetSubModulesDocument}
    {...props}
  />
)

export type GetSubModulesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetSubModulesQuery, GetSubModulesQueryVariables>
} &
  TChildProps
export function withGetSubModules<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubModulesQuery,
    GetSubModulesQueryVariables,
    GetSubModulesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubModulesQuery,
    GetSubModulesQueryVariables,
    GetSubModulesProps<TChildProps, TDataName>
  >(GetSubModulesDocument, {
    alias: 'getSubModules',
    ...operationOptions
  })
}

/**
 * __useGetSubModulesQuery__
 *
 * To run a query within a React component, call `useGetSubModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubModulesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSubModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubModulesQuery, GetSubModulesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSubModulesQuery, GetSubModulesQueryVariables>(
    GetSubModulesDocument,
    baseOptions
  )
}
export function useGetSubModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSubModulesQuery,
    GetSubModulesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetSubModulesQuery, GetSubModulesQueryVariables>(
    GetSubModulesDocument,
    baseOptions
  )
}
export type GetSubModulesQueryHookResult = ReturnType<typeof useGetSubModulesQuery>
export type GetSubModulesLazyQueryHookResult = ReturnType<typeof useGetSubModulesLazyQuery>
export type GetSubModulesQueryResult = ApolloReactCommon.QueryResult<
  GetSubModulesQuery,
  GetSubModulesQueryVariables
>
export const GetSectionReportDocument = gql`
  query getSectionReport($id: ID!) {
    getSectionReport(id: $id) {
      id
      name
      type
      report {
        data {
          id
          attributes {
            url
          }
        }
      }
      section_or_module_id
    }
  }
`
export type GetSectionReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSectionReportQuery,
    GetSectionReportQueryVariables
  >,
  'query'
> &
  ({ variables: GetSectionReportQueryVariables; skip?: boolean } | { skip: boolean })

export const GetSectionReportComponent = (props: GetSectionReportComponentProps) => (
  <ApolloReactComponents.Query<GetSectionReportQuery, GetSectionReportQueryVariables>
    query={GetSectionReportDocument}
    {...props}
  />
)

export type GetSectionReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSectionReportQuery,
    GetSectionReportQueryVariables
  >
} &
  TChildProps
export function withGetSectionReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSectionReportQuery,
    GetSectionReportQueryVariables,
    GetSectionReportProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSectionReportQuery,
    GetSectionReportQueryVariables,
    GetSectionReportProps<TChildProps, TDataName>
  >(GetSectionReportDocument, {
    alias: 'getSectionReport',
    ...operationOptions
  })
}

/**
 * __useGetSectionReportQuery__
 *
 * To run a query within a React component, call `useGetSectionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSectionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSectionReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSectionReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSectionReportQuery,
    GetSectionReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSectionReportQuery, GetSectionReportQueryVariables>(
    GetSectionReportDocument,
    baseOptions
  )
}
export function useGetSectionReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSectionReportQuery,
    GetSectionReportQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetSectionReportQuery, GetSectionReportQueryVariables>(
    GetSectionReportDocument,
    baseOptions
  )
}
export type GetSectionReportQueryHookResult = ReturnType<typeof useGetSectionReportQuery>
export type GetSectionReportLazyQueryHookResult = ReturnType<typeof useGetSectionReportLazyQuery>
export type GetSectionReportQueryResult = ApolloReactCommon.QueryResult<
  GetSectionReportQuery,
  GetSectionReportQueryVariables
>
export const GetMySubmissionDocument = gql`
  query getMySubmission($id: ID!) {
    submission(id: $id) {
      data {
        id
        attributes {
          views
          progress
          status
          time_spent
          language {
            data {
              id
              attributes {
                name
              }
            }
          }
          module {
            data {
              id
              attributes {
                name_french
                name_german
                name_english
                name_spanish
                name_mandarin
                description_french
                description_german
                description_english
                description_spanish
                description_mandarin
                cover {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                sections {
                  data {
                    id
                    attributes {
                      title_french
                      title_german
                      title_english
                      title_spanish
                      title_mandarin
                      sub_title_french
                      sub_title_german
                      sub_title_english
                      sub_title_spanish
                      sub_title_mandarin
                      cover {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                position
              }
            }
          }
          section {
            id
            progress
            section {
              data {
                id
              }
            }
            answers {
              id
              questionId
              answer
              nestedAnswers {
                answer
                questionTitle
              }
              isCorrect
              feedback
              outcome
              questionType
              questionTitle
            }
            stakeholderChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            stakeholderItems {
              id
              valueX
              valueY
              name
              color
            }
            gravitasScales {
              id
              labelOne
              labelTwo
              labelThree
              question
              answerOne
              answerTwo
              answerThree
            }
            xYGraphChart {
              data {
                id
                attributes {
                  url
                }
              }
            }
            saboteurImage {
              data {
                id
                attributes {
                  url
                }
              }
            }
            xYGraphItems {
              id
              valueX
              valueY
              color
            }
          }
          reports {
            data {
              id
              attributes {
                name
                type
                report {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                section_or_module_id
              }
            }
          }
        }
      }
    }
  }
`
export type GetMySubmissionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetMySubmissionQuery, GetMySubmissionQueryVariables>,
  'query'
> &
  ({ variables: GetMySubmissionQueryVariables; skip?: boolean } | { skip: boolean })

export const GetMySubmissionComponent = (props: GetMySubmissionComponentProps) => (
  <ApolloReactComponents.Query<GetMySubmissionQuery, GetMySubmissionQueryVariables>
    query={GetMySubmissionDocument}
    {...props}
  />
)

export type GetMySubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetMySubmissionQuery, GetMySubmissionQueryVariables>
} &
  TChildProps
export function withGetMySubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubmissionQuery,
    GetMySubmissionQueryVariables,
    GetMySubmissionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubmissionQuery,
    GetMySubmissionQueryVariables,
    GetMySubmissionProps<TChildProps, TDataName>
  >(GetMySubmissionDocument, {
    alias: 'getMySubmission',
    ...operationOptions
  })
}

/**
 * __useGetMySubmissionQuery__
 *
 * To run a query within a React component, call `useGetMySubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMySubmissionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubmissionQuery,
    GetMySubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMySubmissionQuery, GetMySubmissionQueryVariables>(
    GetMySubmissionDocument,
    baseOptions
  )
}
export function useGetMySubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubmissionQuery,
    GetMySubmissionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMySubmissionQuery, GetMySubmissionQueryVariables>(
    GetMySubmissionDocument,
    baseOptions
  )
}
export type GetMySubmissionQueryHookResult = ReturnType<typeof useGetMySubmissionQuery>
export type GetMySubmissionLazyQueryHookResult = ReturnType<typeof useGetMySubmissionLazyQuery>
export type GetMySubmissionQueryResult = ApolloReactCommon.QueryResult<
  GetMySubmissionQuery,
  GetMySubmissionQueryVariables
>
export const GetSubmissionReportGroupedDocument = gql`
  query getSubmissionReportGrouped {
    getSubmissionReportGrouped {
      id
      name
      type
      created_by
      updated_by
      programmeType
      sub_module_submission
      notification
      report {
        id
        url
      }
    }
  }
`
export type GetSubmissionReportGroupedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >,
  'query'
>

export const GetSubmissionReportGroupedComponent = (
  props: GetSubmissionReportGroupedComponentProps
) => (
  <ApolloReactComponents.Query<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >
    query={GetSubmissionReportGroupedDocument}
    {...props}
  />
)

export type GetSubmissionReportGroupedProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >
} &
  TChildProps
export function withGetSubmissionReportGrouped<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables,
    GetSubmissionReportGroupedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables,
    GetSubmissionReportGroupedProps<TChildProps, TDataName>
  >(GetSubmissionReportGroupedDocument, {
    alias: 'getSubmissionReportGrouped',
    ...operationOptions
  })
}

/**
 * __useGetSubmissionReportGroupedQuery__
 *
 * To run a query within a React component, call `useGetSubmissionReportGroupedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionReportGroupedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionReportGroupedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubmissionReportGroupedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >(GetSubmissionReportGroupedDocument, baseOptions)
}
export function useGetSubmissionReportGroupedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSubmissionReportGroupedQuery,
    GetSubmissionReportGroupedQueryVariables
  >(GetSubmissionReportGroupedDocument, baseOptions)
}
export type GetSubmissionReportGroupedQueryHookResult = ReturnType<
  typeof useGetSubmissionReportGroupedQuery
>
export type GetSubmissionReportGroupedLazyQueryHookResult = ReturnType<
  typeof useGetSubmissionReportGroupedLazyQuery
>
export type GetSubmissionReportGroupedQueryResult = ApolloReactCommon.QueryResult<
  GetSubmissionReportGroupedQuery,
  GetSubmissionReportGroupedQueryVariables
>
export const GetTranslationsDocument = gql`
  query getTranslations {
    translation {
      data {
        id
        attributes {
          menu {
            id
            homeEnglish
            myProgrammeEnglish
            myWorkEnglish
            myLibraryEnglish
            myProfileEnglish
            helpAndSupportEnglish
            signOutEnglish
            homeFrench
            myProgrammeFrench
            myWorkFrench
            myLibraryFrench
            myProfileFrench
            helpAndSupportFrench
            signOutFrench
            homeGerman
            myProgrammeGerman
            myWorkGerman
            myLibraryGerman
            myProfileGerman
            helpAndSupportGerman
            signOutGerman
            homeSpanish
            myProgrammeSpanish
            myWorkSpanish
            myLibrarySpanish
            myProfileSpanish
            helpAndSupportSpanish
            signOutSpanish
            homeMandarin
            myProgrammeMandarin
            myWorkMandarin
            myLibraryMandarin
            myProfileMandarin
            helpAndSupportMandarin
            signOutMandarin
            aboutUsEnglish
            aboutUsGerman
            aboutUsFrench
            myNotesEnglish
            myNotesGerman
            myNotesFrench
            myNotesSpanish
            myNotesMandarin
            notificationsEnglish
            notificationsGerman
            notificationsFrench
            notificationsSpanish
            notificationsMandarin
          }
          language_selection {
            id
            titleEnglish
            bodyEnglish
            nextButtonEnglish
            titleFrench
            bodyFrench
            nextButtonFrench
            titleGerman
            bodyGerman
            nextButtonGerman
            titleMandarin
            bodyMandarin
            nextButtonMandarin
            titleSpanish
            bodySpanish
            nextButtonSpanish
          }
          welcome_screen {
            id
            titleEnglish
            bodyEnglish
            videoEnglish {
              data {
                id
                attributes {
                  url
                }
              }
            }
            nextButtonEnglish
            titleFrench
            bodyFrench
            videoFrench {
              data {
                id
                attributes {
                  url
                }
              }
            }
            nextButtonFrench
            titleGerman
            bodyGerman
            videoGerman {
              data {
                id
                attributes {
                  url
                }
              }
            }
            nextButtonGerman
            titleMandarin
            bodyMandarin
            videoMandarin {
              data {
                id
                attributes {
                  url
                }
              }
            }
            nextButtonMandarin
            titleSpanish
            bodySpanish
            videoSpanish {
              data {
                id
                attributes {
                  url
                }
              }
            }
            nextButtonSpanish
          }
          lets_get_started_screen {
            id
            titleEnglish
            subTitleEnglish
            firstNameLabelEnglish
            firstNameErrorEnglish
            lastNameLabelEnglish
            lastNameErrorEnglish
            jobTitleLabelEnglish
            jobTitleErrorEnglish
            divisionLabelEnglish
            divisionErrorEnglish
            timezoneLabelEnglish
            regionLabelEnglish
            regionErrorEnglish
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
            nextButtonEnglish
            titleFrench
            subTitleFrench
            firstNameLabelFrench
            firstNameErrorFrench
            lastNameLabelFrench
            lastNameErrorFrench
            jobTitleLabelFrench
            jobTitleErrorFrench
            divisionLabelFrench
            divisionErrorFrench
            timezoneLabelFrench
            regionLabelFrench
            regionErrorFrench
            nextButtonFrench
            titleGerman
            subTitleGerman
            firstNameLabelGerman
            firstNameErrorGerman
            lastNameLabelGerman
            lastNameErrorGerman
            jobTitleLabelGerman
            jobTitleErrorGerman
            divisionLabelGerman
            divisionErrorGerman
            timezoneLabelGerman
            regionLabelGerman
            regionErrorGerman
            nextButtonGerman
            titleMandarin
            subTitleMandarin
            firstNameLabelMandarin
            firstNameErrorMandarin
            lastNameLabelMandarin
            lastNameErrorMandarin
            jobTitleLabelMandarin
            jobTitleErrorMandarin
            divisionLabelMandarin
            divisionErrorMandarin
            timezoneLabelMandarin
            regionLabelMandarin
            regionErrorMandarin
            nextButtonMandarin
            titleSpanish
            subTitleSpanish
            firstNameLabelSpanish
            firstNameErrorSpanish
            lastNameLabelSpanish
            lastNameErrorSpanish
            jobTitleLabelSpanish
            jobTitleErrorSpanish
            divisionLabelSpanish
            divisionErrorSpanish
            timezoneLabelSpanish
            regionLabelSpanish
            regionErrorSpanish
            nextButtonSpanish
            leftTitleEnglish
            leftSubtitleEnglish
            leftTitleSpanish
            leftSubtitleSpanish
            leftTitleMandarin
            leftSubTitleMandarin
            leftTitleFrench
            leftSubtitleFrench
            leftSubtitleGerman
            leftTitleGerman
            havingTroubleEnglish
            havingTroubleGerman
            havingTroubleFrench
            havingTroubleMandarin
            havingTroubleSpanish
            getHelpButtonEnglish
            getHelpButtonSpanish
            getHelpButtonFrench
            getHelpButtonGerman
            getHelpButtonMandarin
          }
          what_to_expect {
            id
            titleEnglish
            bodyEnglish
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
            getStartedButtonEnglish
            titleFrench
            bodyFrench
            getStartedButtonFrench
            titleGerman
            bodyGerman
            getStartedButtonGerman
            titleMandarin
            bodyMandarin
            getStartedButtonMandarin
            titleSpanish
            bodySpanish
            getStartedButtonSpanish
          }
          home_screen {
            id
            titleEnglish
            myProgrammeEnglish
            completedModulesEnglish
            noChangesEnglish
            changesUpEnglish
            changesDownEnglish
            viewModulesEnglish
            myLibraryEnglish
            viewedArticlesEnglish
            viewLibraryEnglish
            titleFrench
            myProgrammeFrench
            completedModulesFrench
            noChangesFrench
            changesUpFrench
            changesDownFrench
            viewModulesFrench
            myLibraryFrench
            viewedArticlesFrench
            viewLibraryFrench
            titleGerman
            myProgrammeGerman
            completedModulesGerman
            noChangesGerman
            changesUpGerman
            changesDownGerman
            viewModulesGerman
            myLibraryGerman
            viewedArticlesGerman
            viewLibraryGerman
            titleSpanish
            myProgrammeSpanish
            completedModulesSpanish
            noChangesSpanish
            changesUpSpanish
            changesDownSpanish
            viewModulesSpanish
            myLibrarySpanish
            viewedArticlesSpanish
            viewLibrarySpanish
            titleMandarin
            myProgrammeMandarin
            completedModulesMandarin
            noChangesMandarin
            changesUpMandarin
            changesDownMandarin
            viewModulesMandarin
            myLibraryMandarin
            viewedArticlesMandarin
            viewLibraryMandarin
            welcomeEnglish
            welcomeFrench
            welcomeGerman
            welcomeSpanish
            welcomeMandarin
            programmeProgressEnglish
            programmeProgressFrench
            programmeProgressGerman
            programmeProgressSpanish
            programmeProgressMandarin
            whatsNextEnglish
            whatsNextFrench
            whatsNextGerman
            whatsNextSpanish
            whatsNextMandarin
            whatToExpectEnglish
            whatToExpectFrench
            whatToExpectGerman
            whatToExpectSpanish
            whatToExpectMandarin
          }
          my_work_screen {
            id
            emptyStateTitleEnglish
            emptyStateDescEnglish
            subtitleEnglish
            feedbackEnglish
            feedbackNotEligibleEnglish
            feedbackNotReceivedEnglish
            notStartedEnglish
            emptyStateTitleFrench
            emptyStateDescFrench
            subtitleFrench
            feedbackFrench
            feedbackNotEligibleFrench
            feedbackNotReceivedFrench
            notStartedFrench
            emptyStateTitleGerman
            emptyStateDescGerman
            subtitleGerman
            feedbackGerman
            feedbackNotEligibleGerman
            feedbackNotReceivedGerman
            notStartedGerman
            emptyStateTitleSpanish
            emptyStateDescSpanish
            subtitleSpanish
            feedbackSpanish
            feedbackNotEligibleSpanish
            feedbackNotReceivedSpanish
            notStartedSpanish
            emptyStateTitleMandarin
            emptyStateDescMandarin
            subtitleMandarin
            feedbackMandarin
            feedbackNotEligibleMandarin
            feedbackNotReceivedMandarin
            notStartedMandarin
            downloadContentTextEnglish
            downloadContentTextFrench
            downloadContentTextGerman
            downloadContentTextSpanish
            downloadContentTextMandarin
            myNotesEnglish
            myNotesSpanish
            myNotesFrench
            myNotesGerman
            myNotesMandarin
            moduleReportsEnglish
            moduleReportsSpanish
            moduleReportsFrench
            moduleReportsGerman
            moduleReportsMandarin
            documentsEnglish
            documentsSpanish
            documentsMandarin
            documentsSpanish
            documentsFrench
          }
          my_library_screen {
            id
            allArticlesEnglish
            myBookmarksEnglish
            allArticlesFrench
            myBookmarksFrench
            allArticlesGerman
            myBookmarksGerman
            allArticlesSpanish
            myBookmarksSpanish
            allArticlesMandarin
            myBookmarksMandarin
            viewArticleEnglish
            viewArticleFrench
            viewArticleGerman
            viewArticleSpanish
            viewArticleMandarin
          }
          my_profile_screen {
            id
            detailsEnglish
            settingsEnglish
            logoutEnglish
            joinEnglish
            detailsFrench
            settingsFrench
            logoutFrench
            joinFrench
            detailsGerman
            settingsGerman
            logoutGerman
            joinGerman
            detailsSpanish
            settingsSpanish
            logoutSpanish
            joinSpanish
            detailsMandarin
            settingsMandarin
            logoutMandarin
          }
          personal_screen {
            id
            changePhotoEnglish
            firstNameLabelEnglish
            lastNameLabelEnglish
            emailLabelEnglish
            jobTitleLabelEnglish
            divisionLabelEnglish
            editInfoButtonEnglish
            changePhotoFrench
            firstNameLabelFrench
            lastNameLabelFrench
            emailLabelFrench
            jobTitleLabelFrench
            divisionLabelFrench
            editInfoButtonFrench
            changePhotoGerman
            firstNameLabelGerman
            lastNameLabelGerman
            emailLabelGerman
            jobTitleLabelGerman
            divisionLabelGerman
            editInfoButtonGerman
            changePhotoSpanish
            firstNameLabelSpanish
            lastNameLabelSpanish
            emailLabelSpanish
            jobTitleLabelSpanish
            divisionLabelSpanish
            editInfoButtonSpanish
            changePhotoMandarin
            firstNameLabelMandarin
            lastNameLabelMandarin
            emailLabelMandarin
            jobTitleLabelMandarin
            divisionLabelMandarin
            editInfoButtonMandarin
            resetPasswordEnglish
            resetPasswordFrench
            resetPasswordGerman
            resetPasswordSpanish
            resetPasswordMandarin
          }
          settings_screen {
            id
            titleEnglish
            saveButtonEnglish
            titleFrench
            saveButtonFrench
            titleGerman
            saveButtonGerman
            titleSpanish
            saveButtonSpanish
            titleMandarin
            saveButtonMandarin
          }
          help_and_support {
            id
            titleEnglish
            knowledgeBaseEnglish
            liveChatEnglish
            myTicketsEnglish
            titleFrench
            knowledgeBaseFrench
            liveChatFrench
            myTicketsFrench
            titleGerman
            knowledgeBaseGerman
            liveChatGerman
            myTicketsGerman
            titleSpanish
            knowledgeBaseSpanish
            liveChatSpanish
            myTicketsSpanish
            titleMandarin
            knowledgeBaseMandarin
            liveChatMandarin
            myTicketsMandarin
          }
          about_us {
            id
            textEnglish
            textFrench
            textGerman
            userGuidelinesTextEnglish
            userGuidelinesTextGerman
            userGuidelinesTextFrench
            userGuidelinesEnglish {
              data {
                id
                attributes {
                  url
                }
              }
            }
            userGuidelinesGerman {
              data {
                id
                attributes {
                  url
                }
              }
            }
            userGuidelinesFrench {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
          buttons {
            id
            backBtnsEnglish
            backBtnsFrench
            backBtnsGerman
            nextBtnsEnglish
            nextBtnsFrench
            nextBtnsGerman
            addMoreBtnsEnglish
            addMoreBtnsFrench
            addMoreBtnsGerman
          }
          programmes_screen {
            id
            changeButtonEnglish
            changeButtonFrench
            changeButtonGerman
            changeButtonSpanish
            changeButtonMandarin
            programmeOverviewEnglish
            programmeOverviewFrench
            programmeOverviewGerman
            programmeOverviewSpanish
            programmeOverviewMandarin
            programmeTextEnglish
            programmeTextFrench
            programmeTextGerman
            programmeTextSpanish
            programmeTextMandarin
            informationEnglish
            informationFrench
            informationGerman
            informationSpanish
            informationMandarin
            selectProgrammesTitleEnglish
            selectProgrammesTitleFrench
            selectProgrammesTitleGerman
            selectProgrammesTitleSpanish
            selectProgrammesTitleMandarin
            selectProgrammesSubTitleEnglish
            selectProgrammesSubTitleFrench
            selectProgrammesSubTitleGerman
            selectProgrammesSubTitleSpanish
            selectProgrammesSubTitleMandarin
            selectButtonEnglish
            selectButtonFrench
            selectButtonGerman
            selectButtonSpanish
            selectButtonMandarin
            changeProgrammeTypeEnglish
            changeProgrammeTypeFrench
            changeProgrammeTypeGerman
            changeProgrammeTypeSpanish
            changeProgrammeTypeMandarin
          }
          faqs {
            faqsEnglish {
              question
              answer
            }
            faqsFrench {
              question
              answer
            }
            faqsGerman {
              question
              answer
            }
            faqsMandarin {
              question
              answer
            }
            faqsSpanish {
              question
              answer
            }
          }
          dashboard_screen {
            id
            myProgrammesEnglish
            myProgrammesFrench
            myProgrammesGerman
            myProgrammesSpanish
            myProgrammesMandarin
            suggestedTitleEnglish
            suggestedTitleFrench
            suggestedTitleGerman
            suggestedTitleSpanish
            suggestedTitleMandarin
            uploadTitleEnglish
            uploadTitleFrench
            uploadTitleGerman
            uploadTitleSpanish
            uploadTitleMandarin
            viewAllEnglish
            viewAllFrench
            viewAllGerman
            viewAllSpanish
            viewAllMandarin
            logOutDashboardEnglish
            logOutDashboardFrench
            logOutDashboardGerman
            logOutDashboardSpanish
            logOutDashboardMandarin
            notepadTextEnglish
            notepadTextFrench
            notepadTextGerman
            notepadTextSpanish
            notepadTextMandarin
          }
        }
      }
    }
  }
`
export type GetTranslationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetTranslationsQuery, GetTranslationsQueryVariables>,
  'query'
>

export const GetTranslationsComponent = (props: GetTranslationsComponentProps) => (
  <ApolloReactComponents.Query<GetTranslationsQuery, GetTranslationsQueryVariables>
    query={GetTranslationsDocument}
    {...props}
  />
)

export type GetTranslationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetTranslationsQuery, GetTranslationsQueryVariables>
} &
  TChildProps
export function withGetTranslations<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetTranslationsQuery,
    GetTranslationsQueryVariables,
    GetTranslationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetTranslationsQuery,
    GetTranslationsQueryVariables,
    GetTranslationsProps<TChildProps, TDataName>
  >(GetTranslationsDocument, {
    alias: 'getTranslations',
    ...operationOptions
  })
}

/**
 * __useGetTranslationsQuery__
 *
 * To run a query within a React component, call `useGetTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTranslationsQuery,
    GetTranslationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetTranslationsQuery, GetTranslationsQueryVariables>(
    GetTranslationsDocument,
    baseOptions
  )
}
export function useGetTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTranslationsQuery,
    GetTranslationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetTranslationsQuery, GetTranslationsQueryVariables>(
    GetTranslationsDocument,
    baseOptions
  )
}
export type GetTranslationsQueryHookResult = ReturnType<typeof useGetTranslationsQuery>
export type GetTranslationsLazyQueryHookResult = ReturnType<typeof useGetTranslationsLazyQuery>
export type GetTranslationsQueryResult = ApolloReactCommon.QueryResult<
  GetTranslationsQuery,
  GetTranslationsQueryVariables
>
export const GetTypeformCompetencyResponsesDocument = gql`
  query getTypeformCompetencyResponses {
    getTypeformCompetencyResponses {
      success
    }
  }
`
export type GetTypeformCompetencyResponsesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >,
  'query'
>

export const GetTypeformCompetencyResponsesComponent = (
  props: GetTypeformCompetencyResponsesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >
    query={GetTypeformCompetencyResponsesDocument}
    {...props}
  />
)

export type GetTypeformCompetencyResponsesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >
} &
  TChildProps
export function withGetTypeformCompetencyResponses<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables,
    GetTypeformCompetencyResponsesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables,
    GetTypeformCompetencyResponsesProps<TChildProps, TDataName>
  >(GetTypeformCompetencyResponsesDocument, {
    alias: 'getTypeformCompetencyResponses',
    ...operationOptions
  })
}

/**
 * __useGetTypeformCompetencyResponsesQuery__
 *
 * To run a query within a React component, call `useGetTypeformCompetencyResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypeformCompetencyResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypeformCompetencyResponsesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTypeformCompetencyResponsesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >(GetTypeformCompetencyResponsesDocument, baseOptions)
}
export function useGetTypeformCompetencyResponsesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetTypeformCompetencyResponsesQuery,
    GetTypeformCompetencyResponsesQueryVariables
  >(GetTypeformCompetencyResponsesDocument, baseOptions)
}
export type GetTypeformCompetencyResponsesQueryHookResult = ReturnType<
  typeof useGetTypeformCompetencyResponsesQuery
>
export type GetTypeformCompetencyResponsesLazyQueryHookResult = ReturnType<
  typeof useGetTypeformCompetencyResponsesLazyQuery
>
export type GetTypeformCompetencyResponsesQueryResult = ApolloReactCommon.QueryResult<
  GetTypeformCompetencyResponsesQuery,
  GetTypeformCompetencyResponsesQueryVariables
>
export const GetUserUploadedDocumentDocument = gql`
  query getUserUploadedDocument(
    $where: UserUploadedDocumentFiltersInput!
    $sort: [String]!
    $limit: Int!
  ) {
    userUploadedDocuments(filters: $where, sort: $sort, pagination: { limit: $limit }) {
      data {
        id
        attributes {
          name
          documents {
            id
            name
            document {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
export type GetUserUploadedDocumentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables
  >,
  'query'
> &
  ({ variables: GetUserUploadedDocumentQueryVariables; skip?: boolean } | { skip: boolean })

export const GetUserUploadedDocumentComponent = (props: GetUserUploadedDocumentComponentProps) => (
  <ApolloReactComponents.Query<GetUserUploadedDocumentQuery, GetUserUploadedDocumentQueryVariables>
    query={GetUserUploadedDocumentDocument}
    {...props}
  />
)

export type GetUserUploadedDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables
  >
} &
  TChildProps
export function withGetUserUploadedDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables,
    GetUserUploadedDocumentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables,
    GetUserUploadedDocumentProps<TChildProps, TDataName>
  >(GetUserUploadedDocumentDocument, {
    alias: 'getUserUploadedDocument',
    ...operationOptions
  })
}

/**
 * __useGetUserUploadedDocumentQuery__
 *
 * To run a query within a React component, call `useGetUserUploadedDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserUploadedDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserUploadedDocumentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserUploadedDocumentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables
  >(GetUserUploadedDocumentDocument, baseOptions)
}
export function useGetUserUploadedDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetUserUploadedDocumentQuery,
    GetUserUploadedDocumentQueryVariables
  >(GetUserUploadedDocumentDocument, baseOptions)
}
export type GetUserUploadedDocumentQueryHookResult = ReturnType<
  typeof useGetUserUploadedDocumentQuery
>
export type GetUserUploadedDocumentLazyQueryHookResult = ReturnType<
  typeof useGetUserUploadedDocumentLazyQuery
>
export type GetUserUploadedDocumentQueryResult = ApolloReactCommon.QueryResult<
  GetUserUploadedDocumentQuery,
  GetUserUploadedDocumentQueryVariables
>
export const GetUserProgrammeSubmissionsDocument = gql`
  query getUserProgrammeSubmissions {
    getMyProgrammeSubmissions {
      id
      status
      progress
      programme_type {
        data {
          id
          attributes {
            title
            is_sub_module
            sub_modules {
              data {
                id
                attributes {
                  name_english
                  sections {
                    data {
                      id
                      attributes {
                        title_english
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      programme {
        data {
          id
          attributes {
            name
            position
            start
          }
        }
      }
    }
  }
`
export type GetUserProgrammeSubmissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >,
  'query'
>

export const GetUserProgrammeSubmissionsComponent = (
  props: GetUserProgrammeSubmissionsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >
    query={GetUserProgrammeSubmissionsDocument}
    {...props}
  />
)

export type GetUserProgrammeSubmissionsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >
} &
  TChildProps
export function withGetUserProgrammeSubmissions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables,
    GetUserProgrammeSubmissionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables,
    GetUserProgrammeSubmissionsProps<TChildProps, TDataName>
  >(GetUserProgrammeSubmissionsDocument, {
    alias: 'getUserProgrammeSubmissions',
    ...operationOptions
  })
}

/**
 * __useGetUserProgrammeSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetUserProgrammeSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProgrammeSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProgrammeSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProgrammeSubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >(GetUserProgrammeSubmissionsDocument, baseOptions)
}
export function useGetUserProgrammeSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetUserProgrammeSubmissionsQuery,
    GetUserProgrammeSubmissionsQueryVariables
  >(GetUserProgrammeSubmissionsDocument, baseOptions)
}
export type GetUserProgrammeSubmissionsQueryHookResult = ReturnType<
  typeof useGetUserProgrammeSubmissionsQuery
>
export type GetUserProgrammeSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetUserProgrammeSubmissionsLazyQuery
>
export type GetUserProgrammeSubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetUserProgrammeSubmissionsQuery,
  GetUserProgrammeSubmissionsQueryVariables
>
export const GetMyProgrammeSubmissionsDocument = gql`
  query getMyProgrammeSubmissions {
    getMyProgrammeSubmissions {
      id
      status
      progress
      competency_url {
        data {
          id
          attributes {
            url
          }
        }
      }
      feedfoward_url {
        data {
          id
          attributes {
            url
          }
        }
      }
      programme_type {
        data {
          id
          attributes {
            programme_type_instructions
            title
            description
            position
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
            is_coaching
            is_sub_module
            notes
            show_article
            show_session
            show_participants
            competency_survey
            feedForward_survey
            deadline
            programme_questions {
              __typename
              ... on ComponentQuestionTypesValuesNested {
                id
                title
                question
                description
                questions {
                  id
                  titleEnglish
                  subTitleEnglish
                  titleFrench
                  subTitleFrench
                  titleGerman
                  subTitleGerman
                  titleSpanish
                  subTitleSpanish
                  titleMandarin
                  subTitleMandarin
                }
              }
              ... on ComponentQuestionTypesValuesCharacters {
                id
                title
                question
                description
              }
              ... on ComponentQuestionTypesValuesBubble {
                id
                title
                question
                description
              }
              ... on ComponentQuestionTypesValuesSliders {
                id
                title
                question
                description
                sliderQuestions {
                  id
                  question
                  color
                }
              }
              ... on ComponentQuestionTypesValuesGaps {
                id
                title
                question
                description
              }
              ... on ComponentQuestionTypesSimpleQuestion {
                id
                titleEnglish
                titleFrench
                titleGerman
                titleSpanish
                titleMandarin
                subTitleEnglish
                subTitleFrench
                subTitleGerman
                subTitleSpanish
                subTitleMandarin
              }
              ... on ComponentQuestionTypesXYGraph {
                id
                labelXEnglish
                labelYEnglish
                titleEnglish
                subTitleEnglish
                labelXFrench
                labelYFrench
                titleFrench
                subTitleFrench
                labelXGerman
                labelYGerman
                titleGerman
                subTitleGerman
                labelXSpanish
                labelYSpanish
                titleSpanish
                subTitleSpanish
                labelXMandarin
                labelYMandarin
                titleMandarin
                subTitleMandarin
              }
              ... on ComponentQuestionTypesMultipleChoice {
                id
                titleEnglish
                titleFrench
                titleGerman
                titleSpanish
                titleMandarin
                option {
                  id
                  optionEnglish
                  isCorrect
                }
                numberOfAnswers
              }
              ... on ComponentQuestionTypesSaboteur {
                id
                titleEnglish
                subTitleEnglish
                titleFrench
                subTitleFrench
                titleGerman
                subTitleGerman
                titleSpanish
                subTitleSpanish
                titleMandarin
                subTitleMandarin
                saboteurQuestions {
                  link
                  skipProof
                  labelEnglish
                  labelFrench
                  labelGerman
                  labelSpanish
                  labelMandarin
                  proof {
                    data {
                      ...uploadFile
                    }
                  }
                }
                labelEnglish
                labelFrench
                labelGerman
                labelSpanish
              }
              ... on ComponentQuestionTypesNestedQuestions {
                id
                titleEnglish
                titleFrench
                titleGerman
                titleSpanish
                titleMandarin
                questions {
                  id
                  titleEnglish
                  subTitleEnglish
                  titleFrench
                  subTitleFrench
                  titleGerman
                  subTitleGerman
                  titleSpanish
                  subTitleSpanish
                  titleMandarin
                  subTitleMandarin
                  isNumeric
                }
              }
              ... on ComponentQuestionTypesGravitasScale {
                id
                titleEnglish
                subTitleEnglish
                questionOneEnglish
                questionTwoEnglish
                questionThreeEnglish
                titleOneEnglish
                titleTwoEnglish
                ratingInput {
                  id
                  labelOneEnglish
                  labelTwoEnglish
                }
              }
              ... on ComponentQuestionTypesStakeholderMap {
                id
                titleEnglish
                subTitleEnglish
                titleFrench
                subTitleFrench
                titleSpanish
                subTitleSpanish
                titleGerman
                subTitleGerman
                titleMandarin
                subTitleMandarin
                questionX
                questionY
              }
              ... on ComponentQuestionTypesSaboteurVideos {
                id
                titleEnglish
                titleFrench
                titleGerman
                titleSpanish
                titleMandarin
                subTitleEnglish
                subTitleFrench
                subTitleGerman
                subTitleSpanish
                subTitleMandarin
                SaboteurVideo {
                  id
                  videoTitleEnglish
                  videoTitleFrench
                  videoTitleGerman
                  videoTitleSpanish
                  videoTitleMandarin
                  video {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
              ... on ComponentQuestionTypesFeedForward1 {
                id
                title
                question
                description
              }
              ... on ComponentQuestionTypesFeedForward2 {
                id
                title
                question
                description
              }
              ... on ComponentQuestionTypesSimpleReadOnly {
                id
                titleEnglish
                titleFrench
                titleGerman
                titleMandarin
                titleSpanish
                subTitleEnglish
                subTitleFrench
                subTitleGerman
                subTitleMandarin
                subTitleSpanish
                contentEnglish
                contentFrench
                contentGerman
                contentMandarin
                contentSpanish
                photo {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
              ... on ComponentQuestionTypesCompetency {
                id
                title
                question
                minNumber
                maxNumber
                miniLabel
                maxiLabel
                simpleQuestion
              }
              ... on ComponentQuestionTypesCompetency2 {
                titleEnglish
                titleFrench
                titleMandarin
                questionscompetency2 {
                  title
                  question
                  maxiNumber
                  miniNumber
                  simpleQuestion
                  miniLabel
                  maxiLabel
                  midiLabel
                  textboxQuestion
                }
              }
              ... on ComponentQuestionTypesGravitasGap {
                id
                title
                question
                description
                color1
                color2
                color3
              }
              ... on ComponentQuestionTypesGravitasSliders {
                titleEnglish
                titleFrench
                titleMandarin
                titleSpanish
                titleGerman
                subTitleEnglish
                subTitleFrench
                subTitleGerman
                positiveHeadingEnglish
                positiveHeadingFrench
                positiveHeadingSpanish
                positiveHeadingMandarin
                positiveHeadingGerman
                negativeHeadingEnglish
                negativeHeadingFrench
                negativeHeadingSpanish
                negativeHeadingMandarin
                negativeHeadingGerman
                sliderQuestions {
                  questionPositiveEnglish
                  questionPositiveGerman
                  questionPositiveMandarin
                  questionPositiveSpanish
                  questionPositiveFrench
                  questionNegativeEnglish
                  questionNegativeGerman
                  questionNegativeFrench
                  questionNegativeMandarin
                  questionNegativeSpanish
                }
                sliderHeadings {
                  headingEnglish
                  headingSpanish
                  headingGerman
                  headingFrench
                  headingMandarin
                  color
                  rating
                }
              }
            }
            articles {
              data {
                id
                attributes {
                  createdAt
                  updatedAt
                  title_english
                  title_french
                  title_german
                  title_spanish
                  title_mandarin
                  content_english
                  content_french
                  content_german
                  content_spanish
                  content_mandarin
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  date_published
                  read_time
                  link
                }
              }
            }
            modal {
              data {
                id
                attributes {
                  title
                  subtitle
                  message
                  image {
                    data {
                      id
                      attributes {
                        url
                        name
                      }
                    }
                  }
                }
              }
            }
            events {
              data {
                id
                attributes {
                  name
                  link
                  session_id
                }
              }
            }
            sub_modules {
              data {
                id
                attributes {
                  name_english
                  description_english
                  cover {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  programme_type {
                    data {
                      id
                    }
                  }
                }
              }
            }
            archive
          }
        }
      }
      programme_responses {
        answers {
          id
          questionId
          answer
          nestedAnswers {
            answer
            questionTitle
          }
          isCorrect
          feedback
          outcome
          questionType
          questionTitle
        }
        stakeholderChart {
          data {
            id
            attributes {
              url
            }
          }
        }
        stakeholderItems {
          id
          valueX
          valueY
          name
          color
        }
        gravitasScales {
          id
          labelOne
          labelTwo
          labelThree
          question
          answerOne
          answerTwo
          answerThree
        }
        xYGraphChart {
          data {
            id
            attributes {
              url
            }
          }
        }
        saboteurImage {
          data {
            id
            attributes {
              url
            }
          }
        }
        xYGraphItems {
          id
          valueX
          valueY
          color
        }
        characteristics {
          id
          answer
          type
        }
        sliderAnswers
        gapsAnswers {
          id
          gap
          commitment
        }
        competencyAnswers {
          id
          question
          rating
          isSimpleQuestion
          response
          steps
        }
        gravitasSliderAnswers {
          negativeQuestion
          positiveQuestion
          colorAndRating {
            color
            rate
          }
        }
        gravitasGap {
          id
          gap
          commitment
        }
      }
      my_notes {
        id
        title
        description
        document {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
      participants {
        data {
          id
          attributes {
            name
            email
            type
          }
        }
      }
      submission_participants {
        id
        name
        email
      }
      programme_report {
        data {
          id
          attributes {
            name
            report {
              data {
                id
                attributes {
                  url
                  name
                }
              }
            }
          }
        }
      }
      programme {
        data {
          id
          attributes {
            is_custom_journey
            show_timeline
            programme_title
            logo {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
      selected
    }
  }
  ${UploadFileFragmentDoc}
`
export type GetMyProgrammeSubmissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >,
  'query'
>

export const GetMyProgrammeSubmissionsComponent = (
  props: GetMyProgrammeSubmissionsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >
    query={GetMyProgrammeSubmissionsDocument}
    {...props}
  />
)

export type GetMyProgrammeSubmissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >
} &
  TChildProps
export function withGetMyProgrammeSubmissions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables,
    GetMyProgrammeSubmissionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables,
    GetMyProgrammeSubmissionsProps<TChildProps, TDataName>
  >(GetMyProgrammeSubmissionsDocument, {
    alias: 'getMyProgrammeSubmissions',
    ...operationOptions
  })
}

/**
 * __useGetMyProgrammeSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetMyProgrammeSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProgrammeSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProgrammeSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProgrammeSubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >(GetMyProgrammeSubmissionsDocument, baseOptions)
}
export function useGetMyProgrammeSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMyProgrammeSubmissionsQuery,
    GetMyProgrammeSubmissionsQueryVariables
  >(GetMyProgrammeSubmissionsDocument, baseOptions)
}
export type GetMyProgrammeSubmissionsQueryHookResult = ReturnType<
  typeof useGetMyProgrammeSubmissionsQuery
>
export type GetMyProgrammeSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetMyProgrammeSubmissionsLazyQuery
>
export type GetMyProgrammeSubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetMyProgrammeSubmissionsQuery,
  GetMyProgrammeSubmissionsQueryVariables
>
export const GetMySubModuleSubmissionsDocument = gql`
  query getMySubModuleSubmissions {
    getMySubModuleSubmissions {
      id
      view
      progress
      status
      time_spent
      feedfoward_url {
        data {
          id
        }
      }
      competency_url {
        data {
          id
        }
      }
      competency_participants {
        name
        email
      }
      participants {
        data {
          id
          attributes {
            name
            email
            type
          }
        }
      }
      submission_participants {
        id
        name
        email
      }
      language {
        data {
          id
          attributes {
            name
          }
        }
      }
      section {
        id
        progress
        section {
          data {
            id
          }
        }
        answers {
          id
          questionId
          answer
          nestedAnswers {
            answer
            questionTitle
          }
          isCorrect
          feedback
          outcome
          questionType
          questionTitle
        }
        stakeholderChart {
          data {
            id
            attributes {
              url
            }
          }
        }
        stakeholderItems {
          id
          valueX
          valueY
          name
          color
        }
        gravitasScales {
          id
          labelOne
          labelTwo
          labelThree
          question
          answerOne
          answerTwo
          answerThree
        }
        gravitasSliderAnswers {
          negativeQuestion
          positiveQuestion
          colorAndRating {
            color
            rate
          }
        }
        gravitasGap {
          id
          gap
          commitment
        }
        xYGraphChart {
          data {
            id
            attributes {
              url
            }
          }
        }
        saboteurImage {
          data {
            id
            attributes {
              url
            }
          }
        }
        xYGraphItems {
          id
          valueX
          valueY
          color
        }
        characteristics {
          id
          answer
          type
        }
        sliderAnswers
        gapsAnswers {
          id
          gap
          commitment
        }
        competencyAnswers {
          id
          question
          rating
          steps
          response
        }
        uploadDocuments {
          saboteurProofValue {
            data {
              id
            }
          }
        }
      }
      sub_module {
        data {
          id
          attributes {
            name_french
            name_german
            name_english
            name_spanish
            name_mandarin
            description_french
            description_german
            description_english
            description_spanish
            description_mandarin
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
            sections {
              data {
                id
                attributes {
                  title_french
                  title_german
                  title_english
                  title_spanish
                  title_mandarin
                  sub_title_french
                  sub_title_german
                  sub_title_english
                  sub_title_spanish
                  sub_title_mandarin
                  cover {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  position
                  archive
                }
              }
            }
            position
            programme_type {
              data {
                id
                attributes {
                  title
                  description
                  position
                  cover {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  is_coaching
                  is_sub_module
                  notes
                  show_article
                  show_session
                  show_participants
                  competency_survey
                  feedForward_survey
                  deadline
                  articles {
                    data {
                      id
                      attributes {
                        createdAt
                        updatedAt
                        title_english
                        title_french
                        title_german
                        title_spanish
                        title_mandarin
                        content_english
                        content_french
                        content_german
                        content_spanish
                        content_mandarin
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                        date_published
                        read_time
                        link
                      }
                    }
                  }
                  modal {
                    data {
                      id
                      attributes {
                        title
                        subtitle
                        message
                        image {
                          data {
                            id
                            attributes {
                              url
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                  events {
                    data {
                      id
                      attributes {
                        name
                        link
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      reports {
        data {
          id
          attributes {
            name
            type
            report {
              data {
                id
                attributes {
                  url
                }
              }
            }
            section_or_module_id
          }
        }
      }
    }
  }
`
export type GetMySubModuleSubmissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >,
  'query'
>

export const GetMySubModuleSubmissionsComponent = (
  props: GetMySubModuleSubmissionsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >
    query={GetMySubModuleSubmissionsDocument}
    {...props}
  />
)

export type GetMySubModuleSubmissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >
} &
  TChildProps
export function withGetMySubModuleSubmissions<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables,
    GetMySubModuleSubmissionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables,
    GetMySubModuleSubmissionsProps<TChildProps, TDataName>
  >(GetMySubModuleSubmissionsDocument, {
    alias: 'getMySubModuleSubmissions',
    ...operationOptions
  })
}

/**
 * __useGetMySubModuleSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetMySubModuleSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubModuleSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubModuleSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySubModuleSubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >(GetMySubModuleSubmissionsDocument, baseOptions)
}
export function useGetMySubModuleSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMySubModuleSubmissionsQuery,
    GetMySubModuleSubmissionsQueryVariables
  >(GetMySubModuleSubmissionsDocument, baseOptions)
}
export type GetMySubModuleSubmissionsQueryHookResult = ReturnType<
  typeof useGetMySubModuleSubmissionsQuery
>
export type GetMySubModuleSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetMySubModuleSubmissionsLazyQuery
>
export type GetMySubModuleSubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetMySubModuleSubmissionsQuery,
  GetMySubModuleSubmissionsQueryVariables
>
export const GetMySubmissionsDocument = gql`
  query getMySubmissions {
    getMySubmissions {
      id
      views
      progress
      status
      time_spent
      language {
        data {
          id
          attributes {
            name
          }
        }
      }
      module {
        data {
          id
          attributes {
            name_french
            name_german
            name_english
            name_spanish
            name_mandarin
            description_french
            description_german
            description_english
            description_spanish
            description_mandarin
            cover {
              data {
                id
                attributes {
                  url
                }
              }
            }
            sections {
              data {
                id
                attributes {
                  title_french
                  title_german
                  title_english
                  title_spanish
                  title_mandarin
                  sub_title_french
                  sub_title_german
                  sub_title_english
                  sub_title_spanish
                  sub_title_mandarin
                  cover {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
            position
          }
        }
      }
      reports {
        data {
          id
          attributes {
            name
            type
            report {
              data {
                id
                attributes {
                  url
                }
              }
            }
            section_or_module_id
          }
        }
      }
    }
  }
`
export type GetMySubmissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMySubmissionsQuery,
    GetMySubmissionsQueryVariables
  >,
  'query'
>

export const GetMySubmissionsComponent = (props: GetMySubmissionsComponentProps) => (
  <ApolloReactComponents.Query<GetMySubmissionsQuery, GetMySubmissionsQueryVariables>
    query={GetMySubmissionsDocument}
    {...props}
  />
)

export type GetMySubmissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMySubmissionsQuery,
    GetMySubmissionsQueryVariables
  >
} &
  TChildProps
export function withGetMySubmissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMySubmissionsQuery,
    GetMySubmissionsQueryVariables,
    GetMySubmissionsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMySubmissionsQuery,
    GetMySubmissionsQueryVariables,
    GetMySubmissionsProps<TChildProps, TDataName>
  >(GetMySubmissionsDocument, {
    alias: 'getMySubmissions',
    ...operationOptions
  })
}

/**
 * __useGetMySubmissionsQuery__
 *
 * To run a query within a React component, call `useGetMySubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMySubmissionsQuery,
    GetMySubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMySubmissionsQuery, GetMySubmissionsQueryVariables>(
    GetMySubmissionsDocument,
    baseOptions
  )
}
export function useGetMySubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMySubmissionsQuery,
    GetMySubmissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMySubmissionsQuery, GetMySubmissionsQueryVariables>(
    GetMySubmissionsDocument,
    baseOptions
  )
}
export type GetMySubmissionsQueryHookResult = ReturnType<typeof useGetMySubmissionsQuery>
export type GetMySubmissionsLazyQueryHookResult = ReturnType<typeof useGetMySubmissionsLazyQuery>
export type GetMySubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetMySubmissionsQuery,
  GetMySubmissionsQueryVariables
>
export const UserDocument = gql`
  query user($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          username
          firstName
          lastName
          email
          company {
            data {
              id
              attributes {
                name
                logo {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                language_selection {
                  id
                  titleEnglish
                  titleFrench
                  titleSpanish
                  titleGerman
                  titleMandarin
                  bodyEnglish
                  bodyFrench
                  bodySpanish
                  bodyGerman
                  bodyMandarin
                  nextButtonEnglish
                  nextButtonFrench
                  nextButtonSpanish
                  nextButtonGerman
                  nextButtonMandarin
                }
                welcome_screen {
                  id
                  titleEnglish
                  titleFrench
                  titleSpanish
                  titleGerman
                  titleMandarin
                  bodyEnglish
                  bodyFrench
                  bodySpanish
                  bodyGerman
                  bodyMandarin
                  videoEnglish {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  videoFrench {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  videoSpanish {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  videoGerman {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  videoMandarin {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  nextButtonEnglish
                  nextButtonFrench
                  nextButtonSpanish
                  nextButtonGerman
                  nextButtonMandarin
                }
                lets_get_started_screen {
                  id
                  titleEnglish
                  subTitleEnglish
                  firstNameLabelEnglish
                  firstNameErrorEnglish
                  lastNameLabelEnglish
                  lastNameErrorEnglish
                  jobTitleLabelEnglish
                  jobTitleErrorEnglish
                  divisionLabelEnglish
                  divisionErrorEnglish
                  timezoneLabelEnglish
                  regionLabelEnglish
                  regionErrorEnglish
                  image {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  nextButtonEnglish
                  titleFrench
                  subTitleFrench
                  firstNameLabelFrench
                  firstNameErrorFrench
                  lastNameLabelFrench
                  lastNameErrorFrench
                  jobTitleLabelFrench
                  jobTitleErrorFrench
                  divisionLabelFrench
                  divisionErrorFrench
                  timezoneLabelFrench
                  regionLabelFrench
                  regionErrorFrench
                  nextButtonFrench
                  titleGerman
                  subTitleGerman
                  firstNameLabelGerman
                  firstNameErrorGerman
                  lastNameLabelGerman
                  lastNameErrorGerman
                  jobTitleLabelGerman
                  jobTitleErrorGerman
                  divisionLabelGerman
                  divisionErrorGerman
                  timezoneLabelGerman
                  regionLabelGerman
                  regionErrorGerman
                  nextButtonGerman
                  titleMandarin
                  subTitleMandarin
                  firstNameLabelMandarin
                  firstNameErrorMandarin
                  lastNameLabelMandarin
                  lastNameErrorMandarin
                  jobTitleLabelMandarin
                  jobTitleErrorMandarin
                  divisionLabelMandarin
                  divisionErrorMandarin
                  timezoneLabelMandarin
                  regionLabelMandarin
                  regionErrorMandarin
                  nextButtonMandarin
                  titleSpanish
                  subTitleSpanish
                  firstNameLabelSpanish
                  firstNameErrorSpanish
                  lastNameLabelSpanish
                  lastNameErrorSpanish
                  jobTitleLabelSpanish
                  jobTitleErrorSpanish
                  divisionLabelSpanish
                  divisionErrorSpanish
                  timezoneLabelSpanish
                  regionLabelSpanish
                  regionErrorSpanish
                  nextButtonSpanish
                  leftTitleEnglish
                  leftSubtitleEnglish
                  leftTitleSpanish
                  leftSubtitleSpanish
                  leftTitleMandarin
                  leftSubTitleMandarin
                  leftTitleFrench
                  leftSubtitleFrench
                  leftSubtitleGerman
                  leftTitleGerman
                  havingTroubleEnglish
                  havingTroubleGerman
                  havingTroubleFrench
                  havingTroubleMandarin
                  havingTroubleSpanish
                  getHelpButtonEnglish
                  getHelpButtonSpanish
                  getHelpButtonFrench
                  getHelpButtonGerman
                  getHelpButtonMandarin
                }
                what_to_expect {
                  id
                  titleEnglish
                  bodyEnglish
                  image {
                    data {
                      id
                      attributes {
                        url
                      }
                    }
                  }
                  getStartedButtonEnglish
                  titleFrench
                  bodyFrench
                  getStartedButtonFrench
                  titleGerman
                  bodyGerman
                  getStartedButtonGerman
                  titleMandarin
                  bodyMandarin
                  getStartedButtonMandarin
                  titleSpanish
                  bodySpanish
                  getStartedButtonSpanish
                }
                users_confirm_details
              }
            }
          }
          division {
            data {
              id
            }
          }
          language {
            data {
              id
              attributes {
                name
              }
            }
          }
          jobTitle
          confirmed
          blocked
          phoneNumber
          language {
            data {
              id
              attributes {
                name
              }
            }
          }
          profilePicture {
            data {
              attributes {
                url
              }
            }
          }
          role {
            data {
              id
              attributes {
                name
                description
                type
              }
            }
          }
          bookmarks
          modulesRead
          homePromptsRead
          programmePromptsRead
          modulePromptsRead
          articlesViewed
          certificate {
            data {
              ...uploadFile
            }
          }
          feedForwardUrl
          programmes {
            data {
              id
              attributes {
                name
                description
                start
                is_custom_journey
                position
                logo {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                programme_types {
                  data {
                    attributes {
                      title
                      sub_modules {
                        data {
                          attributes {
                            sections {
                              data {
                                attributes {
                                  title_english
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UploadFileFragmentDoc}
`
export type UserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserQuery, UserQueryVariables>,
  'query'
> &
  ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean })

export const UserComponent = (props: UserComponentProps) => (
  <ApolloReactComponents.Query<UserQuery, UserQueryVariables> query={UserDocument} {...props} />
)

export type UserProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<UserQuery, UserQueryVariables>
} &
  TChildProps
export function withUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserQuery,
    UserQueryVariables,
    UserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserQuery,
    UserQueryVariables,
    UserProps<TChildProps, TDataName>
  >(UserDocument, {
    alias: 'user',
    ...operationOptions
  })
}

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions)
}
export function useUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions)
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>
export const UsersDocument = gql`
  query Users(
    $sort: [String]
    $limit: Int
    $start: Int
    $where: UsersPermissionsUserFiltersInput!
  ) {
    usersPermissionsUsers(
      sort: $sort
      pagination: { limit: $limit, start: $start }
      filters: $where
    ) {
      data {
        id
        attributes {
          username
          firstName
          lastName
          email
          division {
            data {
              id
            }
          }
          jobTitle
          confirmed
          blocked
          phoneNumber
          language {
            data {
              id
              attributes {
                name
              }
            }
          }
          profilePicture {
            data {
              attributes {
                url
              }
            }
          }
          role {
            data {
              id
              attributes {
                name
                description
                type
              }
            }
          }
          bookmarks
          modulesRead
          homePromptsRead
          programmePromptsRead
          modulePromptsRead
          articlesViewed
          programmes {
            data {
              id
              attributes {
                name
                description
                start
                is_custom_journey
                position
                logo {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export type UsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>,
  'query'
> &
  ({ variables: UsersQueryVariables; skip?: boolean } | { skip: boolean })

export const UsersComponent = (props: UsersComponentProps) => (
  <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables> query={UsersDocument} {...props} />
)

export type UsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<UsersQuery, UsersQueryVariables>
} &
  TChildProps
export function withUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UsersQuery,
    UsersQueryVariables,
    UsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UsersQuery,
    UsersQueryVariables,
    UsersProps<TChildProps, TDataName>
  >(UsersDocument, {
    alias: 'users',
    ...operationOptions
  })
}

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions)
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions)
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>
