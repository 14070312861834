import * as React from 'react'
import { STRAPI_USER_STORAGE_KEY } from '../../constants'
import { UsersPermissionsUser, UsersPermissionsUserEntity } from '../../generated/graphql'
import { useBrowserStorage } from '../../hooks'
import { fetchJwt } from '../../utils'
import strapiHelpers, { StrapiLoginPayload } from '../../utils/strapiHelpers'

type AuthProviderProps = {
  logout: () => void
  isAuthenticating: boolean
  isAuthenticated: boolean
  user: UsersPermissionsUserEntity
  register: (email: string, password: string) => Promise<void>
  login: (email: string, password: string, rememberMe: boolean) => Promise<void>
  updateSessionStorage: (user: StrapiLoginPayload, rememberMe?: boolean) => void
  updateUser: (user: UsersPermissionsUserEntity) => Promise<void>
  setUser: React.Dispatch<React.SetStateAction<UsersPermissionsUserEntity | undefined>>
  socialAuth: (provider: string, accessToken: string) => Promise<void>
}

const AuthContext = React.createContext<Partial<AuthProviderProps>>({})

export const useAuthContext = () => React.useContext(AuthContext)

type UserStorage = StrapiLoginPayload | null

const AuthProvider: React.FC = ({ children }) => {
  const [localUser, setLocalUser, removeLocalUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'local'
  )

  const [sessionUser, setSessionUser, removeSessionUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'session'
  )
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)

  const [user, setUser] = React.useState(sessionUser?.user || localUser?.user)

  const persistUser = (data: StrapiLoginPayload, rememberMe?: boolean) => {
    rememberMe ? setLocalUser(data) : setSessionUser(data)
    setUser(data?.user)
  }

  const logout = () => {
    removeLocalUser()
    removeSessionUser()
    localStorage.clear()
    setIsAuthenticated(false)
    setUser(undefined)
  }

  React.useEffect(() => {
    if (user?.attributes?.confirmed) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setIsAuthenticating(false)
  }, [user])

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const { data } = await strapiHelpers.login(email, password)
      persistUser(data, rememberMe)
    } catch (error) {
      throw error
    }
  }

  const register = async (email: string, password: string) => {
    try {
      const { data } = await strapiHelpers.register(email, password)
      persistUser(data)
    } catch (error) {
      throw error
    }
  }

  const socialAuth = async (provider: string, accessToken: string) => {
    try {
      const { data } = await strapiHelpers.socialAuth(provider, accessToken)
      persistUser(data)
    } catch (err) {
      throw err
    }
  }
  const updateUser = async (user: UsersPermissionsUserEntity) => {
    const rememberMe = Boolean(localUser)
    const jwt = await fetchJwt()
    await persistUser({ user, jwt }, rememberMe)
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        updateSessionStorage: (updatedUser: StrapiLoginPayload, rememberMe?: boolean) =>
          persistUser(updatedUser, rememberMe),
        register,
        logout,
        isAuthenticated,
        isAuthenticating,
        user,
        updateUser,
        setUser,
        socialAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
