import get from 'lodash/get'
import client from '../client'
import { useAuthContext } from '../context/AuthProvider'
import { CompanyEntity, GetTranslationsDocument } from '../generated/graphql'

/**
 * Get language data from DMP and store locally
 */
export const getLanguageData = async () => {
  return client
    .query({
      query: GetTranslationsDocument
    })
    .then(({ data }) => {
      if (data && data.translation) {
        const langData = JSON.stringify(data.translation)
        return localStorage.setItem('translationsData', langData)
      }
    })
}

/**
 * Function to return translations for specified pages
 * @param screenName - current page
 * @param field - field you want to select
 */
export const DisplayLanguage = (screenName: string, field: string) => {
  // Get user details from context
  const { user } = useAuthContext()
  // Get users chosen language, if not default to English
  const language = get(user, 'language.name', 'English')
  // selector is fieldname and language e.g titleEglish

  let selector = ''

  if (field === 'images') {
    selector = 'image'
  } else {
    selector = field + language
  }

  // Get language from local storage
  const languageData = localStorage.getItem('translationsData')
  const modelObject = JSON.parse(languageData as string)

  // The app crashes if we return null
  if (modelObject === null) return ''
  // Return translated data
  if (
    screenName === 'welcome_screen' ||
    screenName === 'what_to_expect' ||
    screenName === 'lets_get_started_screen' ||
    screenName === 'language_selection' ||
    screenName === 'about_us'
  ) {
    return get(user?.attributes?.company?.data?.attributes?.[screenName], selector)
  }
  return get(modelObject[screenName], selector)
}
/**
 * Function to return translations for provided models
 * @param model model to get data from
 * @param field field to select
 */
export const TranslateTitle = (model: Object, field: string) => {
  // Get user details from context
  const { user } = useAuthContext()
  // Get users chosen language, if not default to English
  const language = get(user, 'language.name', 'English')
  // selector is fieldname and language e.g titleEglish
  const selector = field + language
  // Return translated data
  if (
    field === 'welcome_screen' ||
    field === 'what_to_expect' ||
    field === 'lets_get_started_screen' ||
    field === 'language_selection' ||
    field === 'about_us'
  ) {
    return get(user?.attributes?.company?.data?.attributes?.[field], selector, ' ')
  }
  return get(model, selector)
}
