import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/core'
import * as React from 'react'
import {
  ComponentGravitasScaleItemsGravitasScaleItemsInput,
  ComponentRatingInputRatingInput
} from '../../generated/graphql'
import { Text } from '../../typography'
import { TranslateTitle } from '../../utils/languageHelpers'

export type GravitasItemsProps = {
  value: number
  questionNumber: string
  labelNumber: string
  answerNumber: string
  setGravitasScales: Function
  question: object
  gravitasScales: ComponentGravitasScaleItemsGravitasScaleItemsInput[]
  pathValue: string
  index: number
  rating: ComponentRatingInputRatingInput
}

const GravitasItems: React.FC<GravitasItemsProps> = ({
  value,
  questionNumber,
  labelNumber,
  answerNumber,
  question,
  setGravitasScales,
  gravitasScales,
  pathValue,
  index,
  rating
}) => {
  return (
    <Box mt={3}>
      <Text>{TranslateTitle(question, questionNumber)}</Text>
      <NumberInput
        my={3}
        min={0}
        max={10}
        isFullWidth
        isDisabled={pathValue === 'mywork'}
        onChange={(val: any) => {
          setGravitasScales((prev: any) => {
            prev[index] = {
              ...gravitasScales[index],
              question: TranslateTitle(rating, labelNumber)
            }
            prev[index][labelNumber] = TranslateTitle(question, questionNumber)
            prev[index][answerNumber] = val
            return prev
          })
        }}
        placeholder="Enter value..."
        defaultValue={value}
      >
        <NumberInputField data-testid="gravitas-number-input" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  )
}

export default GravitasItems
