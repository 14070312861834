import { Box } from '@chakra-ui/core'
import React from 'react'
import { useAuthContext } from '../../../context/AuthProvider'
interface BookingPayload {
  title: any
  description: any
  duration: any
  date: string
  organiser: any
  user: string | undefined
  team: any
  confirmed: any
  eventId: any
  locations: any
}
export type CalModalProps = {
  link: string
  name: string
  closeModal: () => void
  setMyBookings: (booking: any) => void
}

let Cal:
  | ((
      arg0: string,
      arg1: {
        elementOrSelector?: HTMLDivElement | null
        calLink?: string
        config?: {
          name: string // Prefill Name
          email: string // Prefill Email
          notes: string // Prefill Notes
          // guests: string[] // Prefill Guests
          theme: string // "dark" or "light" theme
        }
      }
    ) => void)
  | null = null
if (typeof window !== 'undefined') {
  // @ts-ignore
  Cal = window.Cal
}

const CalModal: React.FC<CalModalProps> = ({ link, name, closeModal, setMyBookings }) => {
  const initializedRef = React.useRef(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const { user } = useAuthContext()

  React.useEffect(() => {
    if (!Cal || initializedRef.current) {
      return
    }
    initializedRef.current = true
    const element = ref.current
    Cal('inline', {
      elementOrSelector: element,
      calLink: link,
      config: {
        name: `${user?.attributes?.firstName} ${user?.attributes?.lastName}`,
        email: `${user?.attributes?.email}`,
        notes: name,
        // guests: [],
        theme: 'light' // "dark" or "light" theme
      }
    })
    Cal('on', {
      // @ts-ignore
      action: 'bookingSuccessful',
      callback: async (e: CustomEvent) => {
        if (e.detail) {
          const { data } = e.detail
          if (data.confirmed) {
            const payload = {
              title: data?.eventType?.title,
              description: data?.eventType?.description,
              duration: data?.duration,
              date: new Date(data?.date).toISOString(),
              organiser: data?.organiser,
              user: user?.id,
              team: data?.eventType?.team,
              confirmed: data?.confirmed,
              eventId: data?.eventType?.id,
              location: data?.eventType?.location
            }
            // @ts-ignore
            setMyBookings((prev) => {
              if (prev && prev.length > 0) {
                return []
              }
              return [payload]
            })
            closeModal()
          }
        }
      }
    })
  }, [link, name, user, setMyBookings, closeModal])

  if (!Cal) {
    return <Box>There was an error</Box>
  }
  return <Box w="100%" ref={ref} py="20px" px="8px" />
}

export default CalModal
