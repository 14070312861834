import { Grid, Button, Box, Image, FormControl, Flex, Divider } from '@chakra-ui/core'
import 'react-toastify/dist/ReactToastify.css'
import { get } from 'lodash'
import '@uppy/status-bar/dist/style.css'
import XHRUpload from '@uppy/xhr-upload'
import { fetchJwt } from '../../utils'
import { X, ChevronLeft, Paperclip } from 'react-feather'
import { H3, H4, H5, Text } from '../../typography'
import { ModalWrap, MotionFlex, FillLoader } from '../../components'
import { images } from '../../theme'
import { ConnectedFormGroup } from '../../components/FormElements'
import {
  useAddNoteMutation,
  GetMySubModuleNotesDocument,
  Notes,
  useGetFirstFourNotesQuery
} from '../../generated/graphql'
import { Dashboard } from '@uppy/react'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../constants'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useAuthContext } from '../../context/AuthProvider'
import { useNotesContext } from '../../context/NotesProvider'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import React, { useState } from 'react'

import { Markup } from 'interweave'
import { TextEditor } from '../TextEditor'
// @ts-ignore
const UploadModal = ({ showModal, setShowModal, setPhotosIdCollection }) => {
  const [validationNumber, setValidationNumber] = useState(0)

  const uppy = () => {
    return (
      Uppy({
        autoProceed: false,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: null,
          maxNumberOfFiles: 10,
          allowedFileTypes: [
            'image/*',
            'video/*',
            'audio/*',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'text/plain'
          ]
        }
      })
        .use(XHRUpload, {
          endpoint: process.env.REACT_APP_API_HOST
            ? process.env.REACT_APP_API_HOST + '/upload'
            : '/upload',
          fieldName: 'files',
          headers: {
            Authorization: `Bearer ${fetchJwt()}`
          }
        })
        //#endregion//
        .on('upload-success', async (file, response) => {
          try {
            setShowModal(false)
            const saboteurProofValue = await {
              saboteurProofValue: response.body[0].id
            }
            console.log('saboteurProofValue', saboteurProofValue)

            // @ts-ignore
            setPhotosIdCollection((prev) => [
              ...prev,
              {
                saboteurProofValue: response.body[0].id
              }
            ])
            setValidationNumber(validationNumber + 1)
            toast.success('File Uploaded Successfully', SUCCESS_TOAST)
          } catch (error) {
            toast.error('Something went wrong while uploading your photo.', ERROR_TOAST)
          }
        })
    )
  }
  return (
    <ModalWrap
      isCentered={true}
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      title="Upload Document"
    >
      <Dashboard uppy={uppy()} height="400px" />
    </ModalWrap>
  )
}

export const AddNoteForm = ({
  // @ts-ignore
  addNoteMutation,
  // @ts-ignore
  subModuleSubmissionId,
  // @ts-ignore
  programmeId,
  // @ts-ignore
  user,
  // @ts-ignore
  setShowModal,
  // @ts-ignore
  photosIdCollection
}) => {
  const NotesFormValidation = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    note: Yup.string().required('A note is required')
  })
  return (
    <Formik
      validationSchema={NotesFormValidation}
      enableReinitialize={true}
      initialValues={{
        name: '',
        note: ''
      }}
      onSubmit={async ({ name, note }, helpers) => {
        await addNoteMutation({
          variables: {
            input: {
              name,
              note,
              notes_id: photosIdCollection,
              // eslint-disable-next-line
              sub_module_submission: subModuleSubmissionId,
              programme: programmeId,
              user: user?.id
            }
          }
        })

        helpers.resetForm()
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        return (
          <MotionFlex width="100%" flexDir="column">
            <FormControl>
              <MotionFlex justifyContent="space-between" width="100%">
                <ConnectedFormGroup
                  name="name"
                  placeholder="Insert Title"
                  border={0}
                  id="my-note"
                />
                <Paperclip onClick={() => setShowModal(true)} cursor="pointer" />
              </MotionFlex>
              <MotionFlex mt={3} maxW="100%">
                <TextEditor
                  setFieldValue={(val) => setFieldValue('note', val)}
                  value={values.note}
                />
              </MotionFlex>
            </FormControl>

            <MotionFlex flexDirection="column" align="left" width="100%">
              <Button
                p={5}
                mt="50px"
                width="100%"
                variantColor="asera"
                fontWeight="normal"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Add Note
              </Button>
            </MotionFlex>
          </MotionFlex>
        )
      }}
    </Formik>
  )
}

const NotesSideBar: React.FC = () => {
  const MAX_LENGTH = 25

  const [photosIdCollection, setPhotosIdCollection] = useState<Array<any>>([{}])
  const [showModal, setShowModal] = useState(false)
  const [note, setNote] = useState<Notes>()
  const [viewNote, setViewNote] = useState(false)
  const [newNote, setNewNote] = useState(false)

  const {
    location: { pathname }
  } = useHistory()
  const { user } = useAuthContext()
  const { setOpenNotes } = useNotesContext()

  let subModuleSubmissionId = ''

  let programmeId = ''
  if (pathname.split('/')[2] === 'programmes') {
    if (pathname.split('/')[5]) {
      subModuleSubmissionId = pathname.split('/')[5]
    }
    if (pathname.split('/')[3]) {
      programmeId = pathname.split('/')[3]
    }
    programmeId = pathname.split('/')[3]
  }

  const [addNoteMutation] = useAddNoteMutation({
    onCompleted: async () => {
      setPhotosIdCollection([{}])
      setNewNote(false)
      await toast.success('Note added successfully', SUCCESS_TOAST)
    },
    refetchQueries: [
      {
        query: GetMySubModuleNotesDocument,
        variables: {
          where: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            user: { id: { eq: user?.id } }
          },
          sort: ['id:desc'],
          limit: -1
        }
      }
    ],
    onError: () => {
      toast.error("Couldn't create a note Check your connection!", ERROR_TOAST)
    }
  })

  const {
    data: getFirstFourNotes,
    // refetch: refetchUploadedReports,
    loading
  } = useGetFirstFourNotesQuery({
    onError: () => {
      toast.error(
        "Couldn't generate the report! Check your connection or report to Admin!",
        ERROR_TOAST
      )
    }
  })

  // useEffect(() => {
  //   refetchUploadedReports()
  //   // eslint-disable-next-line
  // }, [])

  const oderedFilteredNotes = get(getFirstFourNotes, 'getFirstFourNotes', []) as Notes[]

  const handleNoteChange = (notes: Notes) => {
    setViewNote(true)
    setNote(notes)
  }

  const handleNoteChangeToNotes = () => {
    setViewNote(false)
  }

  const downloadCoach = async (report: any) => {
    if (report) {
      const myUrl: string = report?.url
      const fileName: string = report.name + ' report'
      const link = document.createElement('a')
      link.href = myUrl
      link.setAttribute('target', '_blank')
      link.download = fileName
      document.body.appendChild(link)
      link.click()
    }
  }

  return (
    <>
      <MotionFlex w="100%" flexDir="column" p="16px" data-testid="my-side-bar">
        {newNote ? (
          <MotionFlex flexDir="row" justifyContent="space-between" align="center">
            <ChevronLeft onClick={() => setNewNote(!newNote)} />
            <X onClick={() => setNewNote(!newNote)} cursor="pointer" />
          </MotionFlex>
        ) : (
          <MotionFlex>
            <X onClick={() => setOpenNotes(false)} cursor="pointer" />
          </MotionFlex>
        )}
        <Divider />
        {newNote ? (
          <AddNoteForm
            addNoteMutation={addNoteMutation}
            subModuleSubmissionId={subModuleSubmissionId}
            programmeId={programmeId}
            user={user}
            setShowModal={setShowModal}
            photosIdCollection={photosIdCollection}
          />
        ) : (
          <>
            {viewNote ? (
              <MotionFlex width="100%" flexDir="column">
                <ChevronLeft onClick={handleNoteChangeToNotes} />
                <H4 p={3}>{note?.name}</H4>

                <Text p={3} fontSize={16} color="#989898">
                  <Markup content={note?.note} />
                </Text>
                <MotionFlex flexDir="column">
                  {note?.notesId?.map((note) => (
                    <Box
                      key={note?.id}
                      bg=" rgba(152, 152, 152, 0.1)"
                      borderRadius="8px"
                      cursor="pointer"
                      flexDir="column"
                      m={2}
                      p={3}
                      h="44px"
                      justifyContent="space-between"
                    >
                      <Flex flexDir="row">
                        <Image src={images.file} pr={2} />
                        <H3
                          fontSize={14}
                          color="asera.500"
                          fontWeight={400}
                          textAlign="left"
                          pr={5}
                          onClick={() => downloadCoach(note?.saboteurProofValue)}
                        >
                          {note?.saboteurProofValue?.data?.attributes?.name}
                        </H3>
                        <div style={{ float: 'right' }}></div>
                      </Flex>
                    </Box>
                  ))}
                </MotionFlex>
              </MotionFlex>
            ) : (
              <MotionFlex width="100%" flexDir="column">
                {loading ? (
                  <FillLoader />
                ) : oderedFilteredNotes.length > 0 ? (
                  <MotionFlex width="100%" flexDir="column">
                    <H3 color="asera.500" fontWeight={600} fontSize="24px">
                      Notepad
                    </H3>
                    <H3 color="#989898" fontWeight={600} fontSize="16px">
                      Recent Notes
                    </H3>
                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                      {oderedFilteredNotes.map((notes) => (
                        <Box
                          key={notes?.id}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                          rounded="md"
                          bg="white"
                          p={3}
                          m={1}
                          onClick={() => handleNoteChange(notes)}
                          cursor="pointer"
                        >
                          <H3 fontSize={16} fontWeight={600}>
                            {notes.programme?.name || notes.name}
                          </H3>

                          <H4 fontSize={14}>
                            {notes?.note!.length > MAX_LENGTH ? (
                              <Markup content={` ${notes?.note!.substring(0, MAX_LENGTH)}...`} />
                            ) : (
                              <Markup content={note?.note} />
                            )}
                          </H4>
                        </Box>
                      ))}
                    </Grid>
                    <Link to="/auth/mywork">
                      <H4
                        color="asera.500"
                        textAlign="center"
                        fontSize={16}
                        fontWeight={600}
                        m={3}
                        style={{ cursor: 'pointer' }}
                      >
                        View All
                      </H4>
                    </Link>
                  </MotionFlex>
                ) : (
                  <MotionFlex flexDir="column" alignItems="center" mt="150px" marginBottom={20}>
                    <Image src={images.emptyNotes} w="121px" />
                    <H4 fontSize={16} fontWeight={600} color="asera.500">
                      All your notes in one place{' '}
                    </H4>

                    <H5 fontSize={14} fontWeight={400} color="#989898">
                      Looks like you do not have any notes yet
                    </H5>
                  </MotionFlex>
                )}
                <MotionFlex flexDir="column">
                  <Image
                    mt={2}
                    src={images.addNote}
                    position="absolute"
                    right={0}
                    onClick={() => setNewNote(true)}
                  />
                </MotionFlex>
              </MotionFlex>
            )}
          </>
        )}
      </MotionFlex>
      <UploadModal
        showModal={showModal}
        setShowModal={setShowModal}
        setPhotosIdCollection={setPhotosIdCollection}
      />
    </>
  )
}

export default NotesSideBar
