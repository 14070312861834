import {
  Button,
  Divider,
  Flex,
  Box,
  Text,
  Image,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  MenuItemOption,
  Stack
} from '@chakra-ui/core'
import React from 'react'

import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthProvider'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { get } from 'styled-system'
import {
  useUpdateNotificationMutation,
  useGetMyNotificationsQuery,
  NotificationEntity
} from '../../generated/graphql'
import { images } from '../../theme'
import { H2, H4 } from '../../typography'
import Card from '../Card'
import RevealFlex from '../RevealFlex'
import { MdNotifications } from 'react-icons/md'

const Notifications = () => {
  const { user } = useAuthContext()
  const [updateNotification] = useUpdateNotificationMutation()
  const history = useHistory()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const { data, refetch } = useGetMyNotificationsQuery({
    variables: {
      where: { users: { id: { eq: user?.id } } }
    },
    fetchPolicy: 'network-only'
  })

  let notifications = get(data?.notifications, 'notifications') as NotificationEntity[]
  notifications =
    notifications &&
    notifications.sort((a, b) => {
      return Number(b?.id) - Number(a?.id)
    })

  const unreadNotifications = notifications?.filter((n) => !n?.attributes?.read)

  const hasNew = notifications?.some((i) => i?.attributes?.read !== true)

  const markAsRead = async (notification: NotificationEntity) => {
    await updateNotification({
      variables: {
        id: notification?.id ?? '',
        data: {
          read: true
        }
      }
    })
    refetch()
  }

  const handleMarkAllAsRead = async () => {
    await Promise.all(
      unreadNotifications?.map(async (notification) => {
        await updateNotification({
          variables: {
            id: notification?.id || '',
            data: {
              read: true
            }
          }
        })
      })
    )
    await refetch()
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton>
        <Box>
          <Icon color="white" as={MdNotifications} size="24px" mr="25px" />
          {hasNew ? (
            <Badge
              variant="solid"
              variantColor="lucaniaOrange"
              borderRadius="50%"
              top="-8px"
              left="-35px"
              position="relative"
              fontSize="10px"
              width="14px"
              height="14px"
            >
              <Flex justifyContent="center" alignItems="center">
                {unreadNotifications.length}
              </Flex>
            </Badge>
          ) : null}
        </Box>
      </MenuButton>
      <MenuList width="40%">
        <MenuItemOption w="100%">
          <Flex
            flexDir="row"
            justify="space-between"
            width="100%"
            alignItems="center"
            borderBottom="1px solid rgba(152, 152, 152, 0.5);"
            mt={isTabletOrMobile ? '4px' : '10px'}
            data-testid="notifications"
          >
            <Text fontSize="16px" fontWeight={600} color="#2F2F2F">
              Notifications
            </Text>
            <Text fontSize="14px" fontWeight={400} color="#989898">
              {unreadNotifications?.length} Unread
            </Text>
          </Flex>
        </MenuItemOption>
        {(() => {
          if (unreadNotifications && unreadNotifications.length > 0) {
            return (
              <Stack width="100%">
                <Flex
                  flexDir="column"
                  overflowX="scroll"
                  width="100%"
                  height={isTabletOrMobile ? '100%' : '350px'}
                >
                  {unreadNotifications.map((notification) => {
                    dayjs.extend(relativeTime)
                    const diff = dayjs().from(dayjs(notification?.attributes?.time_stamp), true)

                    return (
                      <MenuItemOption w="100%">
                        <Box
                          key={notification?.id ?? ''}
                          mt="20px"
                          h="45px"
                          onClick={async () => {
                            if (!notification?.attributes?.read) {
                              await markAsRead(notification)
                            }

                            if (notification?.attributes?.path) {
                              history.push(`${notification?.attributes?.path}`)
                            }
                          }}
                          data-testid="notification"
                        >
                          <Flex
                            flexDir="row"
                            w="100%"
                            cursor="pointer"
                            justifyContent="space-between"
                          >
                            <Flex w="2%" flexDir="row" justifyContent="center" alignItems="center">
                              <Box w="6px" h="6px" bg="#F4A807" borderRadius="50%" />
                            </Flex>
                            <Flex
                              flexDir="column"
                              align="flex-start"
                              textAlign="left"
                              overflow="hidden"
                              mx="8px"
                              w="68%"
                              h="45px"
                            >
                              <Text fontSize="14px" fontWeight={400} color="#989898">
                                {notification?.attributes?.title}
                              </Text>
                            </Flex>
                            <Flex flexDir="column" w="30%">
                              <Flex flexDir="row" alignItems="center" justifyContent="end">
                                <Button
                                  variant="ghost"
                                  color="#989898"
                                  onClick={() => markAsRead(notification)}
                                  py="2px"
                                  h="auto"
                                  w="auto"
                                  data-testid={`mark-as-read-${notification?.id}`}
                                >
                                  x
                                </Button>
                              </Flex>
                              <Flex flexDir="row" mt={2} alignItems="center" justifyContent="end">
                                <Text fontSize="14px" fontWeight={400} color="#989898">
                                  {diff}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Divider />
                        </Box>
                      </MenuItemOption>
                    )
                  })}
                </Flex>

                <Flex
                  flexDir="row"
                  justify="center"
                  width="100%"
                  alignItems="center"
                  mt="10px"
                  mb="10px"
                  bottom={0}
                >
                  <Button
                    fontSize="16px"
                    fontWeight={500}
                    color="#115264"
                    variant="link"
                    onClick={handleMarkAllAsRead}
                    data-testid="mark-all-as-read"
                  >
                    Mark all as read
                  </Button>
                </Flex>
              </Stack>
            )
          }
          return (
            <Card
              p={4}
              flex={1}
              width="100%"
              align="center"
              maxWidth="100%"
              justify="center"
              flexDirection="column"
            >
              <RevealFlex>
                <Image src={images.nodata} width="103px" maxWidth="100%" height="101px" />
                <H2 my={3} fontWeight={600} fontSize="16px" color="#115264">
                  You&apos;re up to date
                </H2>
                <H4 fontSize="14px" color="#989898" fontWeight={400}>
                  You currently have no notifications
                </H4>
              </RevealFlex>
            </Card>
          )
        })()}
      </MenuList>
    </Menu>
  )
}

export default Notifications
