import React, { useEffect, useState } from 'react'
import JoyRide from 'react-joyride'
import { useLocation } from 'react-router-dom'
import { TOUR_STEPS } from '../../constants'
import JoyRideCustomComponent from './JoyRideCustomComponent'

import { useAuthContext } from '../../context/AuthProvider'

import { useUpdateSelfMutation } from '../../generated/graphql'
const OnboardingTour: React.FC = () => {
  const [onboardingStatus, setonboardingStatus] = useState<boolean>(true)
  const { pathname } = useLocation()
  const isOnboardingPage = pathname?.includes('auth/onboarding') // don't display guided tour on onboarding pages

  const { user } = useAuthContext()
  const [updateSelfMutation] = useUpdateSelfMutation()
  useEffect(() => {
    if (user?.attributes?.onboarding || Boolean(window.localStorage.getItem('onboarded'))) {
      setonboardingStatus(false)
    }
    // eslint-disable-next-line
  }, [])

  // Manage user onboarding state
  const handleJoyrideCallback = async (data: { status: string }) => {
    const { status } = data
    if (status === 'finished') {
      window.localStorage.setItem('onboarded', 'true')
      await updateSelfMutation({
        variables: {
          input: { onboarding: true }
        }
      })
    }
  }

  return (
    <>
      {onboardingStatus && !isOnboardingPage ? (
        <JoyRide
          data-testid="joyride-container"
          steps={TOUR_STEPS}
          continuous={true}
          callback={handleJoyrideCallback}
          tooltipComponent={JoyRideCustomComponent}
          hideBackButton={true}
          styles={{
            options: {
              overlayColor: '#00000040',
              zIndex: 10000
            },
            tooltipTitle: {
              color: 'black',
              fontWeight: 'bold',
              fontSize: '16px'
            },
            tooltipContent: {
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '140%',
              letterSpacing: '0.1px',
              color: '#989898'
            },
            buttonNext: {
              backgroundColor: '#115264',
              border: 'none'
            }
          }}
          hideCloseButton={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          disableScrollParentFix={true}
          showSkipButton={true}
          disableScrolling={true}
          locale={{
            last: 'Get Started'
          }}
        />
      ) : null}
    </>
  )
}

export default OnboardingTour
